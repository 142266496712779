import {
  Card,
  CardHeader,
  CardContent,
  Button,
  Box,
  Grid,
  Typography,
} from "@mui/material";
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridToolbar,
} from "@mui/x-data-grid";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../../redux/store/Store";
import dayjs from "dayjs";
import RefreshIcon from "@mui/icons-material/Refresh";
import DescriptionIcon from "@mui/icons-material/Description";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import DeletePatientData from "../DeletePatientData";
import AddEditPatientData from "../AddEditPatientData";
import MWExceptionList from "../../../../../components/MWExceptionList";
import {
  GetPatientDataDetailsBody,
  PatientDetailList,
} from "../../../../../redux/FilePreparation/GetPatientDataList/Model";
import { t } from "i18next";
import { ModifiedPatientDetailList } from "./Model";
import { getPatientDataList } from "../../../../../redux/FilePreparation/GetPatientDataList/API";
import { useParams } from "react-router-dom";
import DocumentViewer from "../../../../../components/DocumentViewer";

interface Props {
  label?: string;
  type?: string;
  prescription?: string;
  finalOpinion?: boolean | any;
}

export default function DataList({
  label,
  type,
  prescription,
  finalOpinion,
}: Props) {
  const { caseid } = useParams() as {
    caseid: string;
  };
  const dispatch = useDispatch();
  const patientDataListRes = useSelector(
    (state: AppState) => state.patientDataListRes
  );
  const [patientDataList, setPatientDataList] = useState(
    [] as ModifiedPatientDetailList[]
  );
  const [sectionTye, setSectionType] = useState("");
  useEffect(() => {
    type !== undefined
      ? type !== ""
        ? setSectionType(type)
        : setSectionType("")
      : setSectionType("");
  }, [type]);
  useEffect(() => {
    if (
      patientDataListRes?.getPatientDataListRes?.patientDetailList !== undefined
    ) {
      let questionItem =
        patientDataListRes?.getPatientDataListRes?.patientDetailList.map(
          (item: PatientDetailList) => {
            return {
              id: item.id,
              description:
                item?.description !== undefined ? item.description : "",
              startDate:
                item?.startDate !== undefined
                  ? item?.startDate !== null
                    ? dayjs(item?.startDate).format("DD-MM-YYYY")
                    : `${t("common.notSet", { ns: ["home"] })!}`
                  : `${t("common.notSet", { ns: ["home"] })!}`,
              endDate:
                item?.endDate !== undefined
                  ? item?.endDate !== null
                    ? dayjs(item?.endDate).format("DD-MM-YYYY")
                    : `${t("common.notSet", { ns: ["home"] })!}`
                  : `${t("common.notSet", { ns: ["home"] })!}`,
              supportingDocument:
                item?.patientDocument !== undefined
                  ? item?.patientDocument?.document !== undefined
                    ? item?.patientDocument?.document
                    : ""
                  : "",
            } as ModifiedPatientDetailList;
          }
        );
      setPatientDataList(questionItem);
    } else setPatientDataList([] as ModifiedPatientDetailList[]);
  }, [patientDataListRes]);

  const [dialogOpen, setDialogOpen] = useState(false);
  const dialogUpdateState = (passedVal: boolean) => {
    setDialogOpen(passedVal);
  };
  const [addEditDialogOpen, setAddEditDialogOpen] = useState(false);
  const addEditDialogUpdateState = (passedVal: boolean) => {
    setAddEditDialogOpen(passedVal);
  };
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const deleteDialogUpdateState = (passedVal: boolean) => {
    setDeleteDialogOpen(passedVal);
  };

  const [itemId, setItemId] = useState(0);

  const [selectedItem, setSelectedItem] = useState({} as any);
  const selectedItemUpdateState = (passedVal: any) => {
    setSelectedItem(passedVal);
  };
  const columns: GridColDef[] = [
    {
      field: "supportingDocument",
      headerName: `${t("addpatientpage.doc", { ns: ["home"] })}`,
      minWidth: 80,
      renderCell: (params: GridCellParams) => (
        <Button
          variant="outlined"
          disabled={params.row.supportingDocument === "" ? true : false}
          onClick={() => {
            setDialogOpen(true);
            setSelectedItem(params.row);
          }}
          sx={{ textTransform: "none" }}
        >
          <DescriptionIcon />
        </Button>
      ),
    },
    {
      field: "viewItem",
      headerName: `${t("appointment.view", { ns: ["home"] })}`,
      minWidth: 30,
      renderCell: (params: GridCellParams) => (
        <Button
          variant="outlined"
          onClick={() => {
            setAddEditDialogOpen(true);
            setSelectedItem(params.row);
          }}
          sx={{ textTransform: "none" }}
        >
          <VisibilityIcon />
        </Button>
      ),
    },
    {
      field: "description",
      headerName: `${t("appointment.desc", { ns: ["home"] })}`,
      flex: 1,
    },
    {
      field: "startDate",
      headerName: `${t("common.stDate", { ns: ["home"] })}`,
      minWidth: 120,
    },
    {
      field: "endDate",
      headerName: `${t("appointment.enddate", { ns: ["home"] })}`,
      minWidth: 120,
    },
    {
      field: "id",
      headerName: `${t("common.del", { ns: ["home"] })}`,
      minWidth: 20,
      renderCell: (params: GridCellParams) => (
        <Button
          variant="outlined"
          onClick={() => {
            setDeleteDialogOpen(true);
            setItemId(params.row.id);
          }}
          sx={{ textTransform: "none" }}
        >
          <DeleteIcon />
        </Button>
      ),
    },
  ];
  const finalOpinionColumn: GridColDef[] = [
    {
      field: "supportingDocument",
      headerName: `${t("addpatientpage.doc", { ns: ["home"] })}`,
      minWidth: 80,
      renderCell: (params: GridCellParams) => (
        <Button
          variant="outlined"
          disabled={params.row.supportingDocument === "" ? true : false}
          onClick={() => {
            setDialogOpen(true);
            setSelectedItem(params.row);
          }}
          sx={{ textTransform: "none" }}
        >
          <DescriptionIcon />
        </Button>
      ),
    },
    {
      field: "description",
      headerName: `${t("appointment.desc", { ns: ["home"] })}`,
      flex: 1,
    },
    {
      field: "startDate",
      headerName: `${t("common.stDate", { ns: ["home"] })}`,
      minWidth: 120,
    },
    {
      field: "endDate",
      headerName: `${t("appointment.enddate", { ns: ["home"] })}`,
      minWidth: 120,
    },
  ];
  return (
    <Box>
      <Card>
        <CardHeader
          title={
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="flex-start"
            >
              <Grid item>
                {prescription !== undefined &&
                type === "ordersAndPrescriptions" ? (
                  <Typography variant="h5">
                    {t("filePreparationTab.orderPres", { ns: ["home"] })}
                  </Typography>
                ) : prescription !== undefined && type === "imptdData" ? (
                  <Typography variant="h5">
                    {t("filePreparationTab.imptdData", { ns: ["home"] })}
                  </Typography>
                ) : (
                  <Typography variant="h5">
                    {t("common.docs", { ns: ["home"] })}
                  </Typography>
                )}
              </Grid>
              <Grid item>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item>
                    <Button
                      variant="outlined"
                      startIcon={<RefreshIcon />}
                      onClick={() =>
                        dispatch(
                          getPatientDataList({
                            caseId: caseid,
                            secondOpinionUiSection: type,
                          } as GetPatientDataDetailsBody)
                        )
                      }
                    >
                      {t("userprofile.reload", { ns: ["home"] })!}
                    </Button>
                  </Grid>
                  <Grid item>
                    {!finalOpinion && type !== `importedData` ? (
                      <Grid item>
                        <Button
                          variant="contained"
                          startIcon={<AddIcon />}
                          onClick={() => setAddEditDialogOpen(true)}
                          sx={{ textTransform: "none" }}
                        >
                          {prescription !== undefined &&
                          type === "ordersAndPrescriptions"
                            ? `${t("orderPresPage.addDoc", { ns: ["home"] })}`
                            : `${t("common.addData", { ns: ["home"] })}`}
                        </Button>
                      </Grid>
                    ) : null}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          }
        />
        <CardContent>
          {patientDataList.length > 0 ? (
            <DataGrid
              rows={patientDataList}
              columns={finalOpinion ? finalOpinionColumn : columns}
              pageSize={10}
              hideFooter
              hideFooterPagination
              disableSelectionOnClick
              experimentalFeatures={{ newEditingApi: true }}
              autoHeight
              components={{ Toolbar: GridToolbar }}
              componentsProps={{
                toolbar: {
                  csvOptions: { disableToolbarButton: true },
                  printOptions: { disableToolbarButton: true },
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 250 },
                },
              }}
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector
            />
          ) : (
            <MWExceptionList />
          )}
        </CardContent>
      </Card>
      {dialogOpen ? (
        <DocumentViewer
          dialogEnable={dialogOpen}
          dialogUpdateState={dialogUpdateState}
          selectedItem={selectedItem}
          selectedItemUpdateState={selectedItemUpdateState}
          finalOpinion={finalOpinion}
        />
      ) : null}
      {addEditDialogOpen ? (
        <AddEditPatientData
          dialogEnable={addEditDialogOpen}
          dialogUpdateState={addEditDialogUpdateState}
          docTypeName={label}
          itemId={itemId}
          type={sectionTye}
          selectedItem={selectedItem}
          selectedItemUpdateState={selectedItemUpdateState}
        />
      ) : null}
      {deleteDialogOpen ? (
        <DeletePatientData
          dialogEnable={deleteDialogOpen}
          dialogUpdateState={deleteDialogUpdateState}
          docTypeName={label}
          itemId={itemId}
          type={sectionTye}
        />
      ) : null}
    </Box>
  );
}
