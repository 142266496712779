import {
  answerQuestionDetails,
  LogOutActionTypes,
  ADD_PATIENT_TEAM_FAIL,
  ADD_PATIENT_TEAM_LOADING,
  ADD_PATIENT_TEAM_SUCCESS,
} from "./ActionTypes";
import { AddPatientTeamResponse } from "./Model";

const initialStateGetPosts: answerQuestionDetails = {
  loading: false,
  getPatientTeamRes: {} as AddPatientTeamResponse,
  errRes: "",
};
export const addPatientTeamReducer = (
  state = initialStateGetPosts,
  action: LogOutActionTypes
): answerQuestionDetails => {
  switch (action.type) {
    case ADD_PATIENT_TEAM_SUCCESS:
      return {
        ...state,
        loading: false,
        getPatientTeamRes: action.payload,
        errRes: "",
      };
    case ADD_PATIENT_TEAM_LOADING:
      return {
        ...state,
        loading: true,
        getPatientTeamRes: {} as AddPatientTeamResponse,
      };
    case ADD_PATIENT_TEAM_FAIL:
      return {
        ...state,
        getPatientTeamRes: {} as AddPatientTeamResponse,
        loading: false,
        errRes: action.errRes,
      };
    default:
      return state;
  }
};
