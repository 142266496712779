import { DoctorListResponse } from "./Model";
export const DOCTOR_LIST_LOADING = "DOCTOR_LIST_LOADING";
export const DOCTOR_LIST_SUCCESS = "DOCTOR_LIST_SUCCESS";
export const DOCTOR_LIST_FAIL = "DOCTOR_LIST_FAIL";
export const DOCTOR_LIST_API_MSG = "DOCTOR_LIST_API_MSG";
export const DOCTOR_LIST_API_RES = "DOCTOR_LIST_API_RES";

export interface ActiveDoctorListState {
  doctorDetailsResponse: DoctorListResponse;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface ActiveDoctorListLoading {
  type: typeof DOCTOR_LIST_LOADING;
  loading: boolean;
}
export interface ActiveDoctorListSuccess {
  type: typeof DOCTOR_LIST_SUCCESS;
  payload: DoctorListResponse;
  successMsg: string;
}
export interface ActiveDoctorListFail {
  type: typeof DOCTOR_LIST_FAIL;
  payload: DoctorListResponse;
  errorMsg: string;
  status: number;
}
export interface ActiveDoctorListAPIMsg {
  type: typeof DOCTOR_LIST_API_MSG;
  payload: DoctorListResponse;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface ActiveDoctorListAPIRes {
  type: typeof DOCTOR_LIST_API_RES;
  payload: DoctorListResponse;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type ActiveDoctorListActionTypes =
  | ActiveDoctorListLoading
  | ActiveDoctorListSuccess
  | ActiveDoctorListFail
  | ActiveDoctorListAPIMsg
  | ActiveDoctorListAPIRes;
