import {
  FORGOT_PASSWORD_FAIL,
  FORGOT_PASSWORD_LOADING,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_UPDATE_API_MSG,
  FORGOT_PASSWORD_UPDATE_API_RES,
  ForgotPasswordActionTypes,
} from "./ActionTypes";
import { forgotPasswordValue } from "./Model";

export const ForgotPasswordLoadingAction = (
  loading: boolean
): ForgotPasswordActionTypes => {
  return {
    type: FORGOT_PASSWORD_LOADING,
    loading: loading,
  };
};

export const ForgotPasswordSuccessAction = (
  forgotPasswordResponse: forgotPasswordValue,
  successMsg: string
): ForgotPasswordActionTypes => {
  return {
    type: FORGOT_PASSWORD_SUCCESS,
    payload: forgotPasswordResponse,
    successMsg: successMsg,
  };
};

export const ForgotPasswordErrorAction = (
  forgotPasswordResponse: forgotPasswordValue,
  errMsg: string,
  status: number
): ForgotPasswordActionTypes => {
  return {
    type: FORGOT_PASSWORD_FAIL,
    payload: forgotPasswordResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const ForgotPasswordUpdateAPIMsgAction = (
  forgotPasswordResponse: forgotPasswordValue,
  successMsg: string,
  errMsg: string,
  status: number
): ForgotPasswordActionTypes => {
  return {
    type: FORGOT_PASSWORD_UPDATE_API_MSG,
    payload: forgotPasswordResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const ForgotPasswordAPIResClearAction = (
  forgotPasswordResponse: forgotPasswordValue,
  successMsg: string,
  errMsg: string,
  status: number
): ForgotPasswordActionTypes => {
  return {
    type: FORGOT_PASSWORD_UPDATE_API_RES,
    payload: forgotPasswordResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
