import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../Url";
import { PaymentApproveActionTypes } from "./ActionTypes";
import { OrderId, PaymentApproveDetailsResponse } from "./Model";
import {
  paymentApproveAPIResClearAction,
  paymentApproveErrorAction,
  paymentApproveLoadingAction,
  paymentApproveSuccessAction,
  paymentApproveAPIMsgAction,
} from "./Action";
import { headers } from "../../../components/Utility";
import { setupInterceptorsTo } from "../../Authentication/Interceptors";

let apiRes = {} as PaymentApproveDetailsResponse;
export const getPaymentApproveValue = (id: OrderId) => {
  return function (dispatch: Dispatch<PaymentApproveActionTypes>) {
    dispatch(paymentApproveLoadingAction(true));
    setupInterceptorsTo(axios)
      .get(Url.baseUrl + `/paymentapp/api/approve-details-by-id/` + id.orderid, {
        headers: headers,
      })
      .then((res) => {
        apiRes = res.data;
        dispatch(paymentApproveLoadingAction(false));
        dispatch(
          paymentApproveSuccessAction(
            res.data,
            res.data.message !== undefined
              ? res.data.message
              : "Payment done successfully."
          )
        );
      })
      .catch((error) => {
        dispatch(paymentApproveLoadingAction(false));
        dispatch(
          paymentApproveErrorAction(
            {} as PaymentApproveDetailsResponse,
            error.response.data !== undefined
              ? error.response.data.message !== undefined
                ? error.response.data.message
                : "Something went wrong"
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0
          )
        );
      });
  };
};

export const paymentApproveAPIResMsg = () => {
  return function (dispatch: Dispatch<PaymentApproveActionTypes>) {
    dispatch(paymentApproveAPIMsgAction(apiRes as PaymentApproveDetailsResponse, "", "", 0));
  };
};

export const clearPaymentApproveAPIRes = () => {
  return function (dispatch: Dispatch<PaymentApproveActionTypes>) {
    dispatch(paymentApproveAPIResClearAction({} as PaymentApproveDetailsResponse, "", "", 0));
  };
};
