import { EditCaseResponse } from "./Model";
export const EDIT_CASE_LOADING = "EDIT_CASE_LOADING";
export const EDIT_CASE_SUCCESS = "EDIT_CASE_SUCCESS";
export const EDIT_CASE_FAIL = "EDIT_CASE_FAIL";
export const EDIT_CASE_API_MSG = "EDIT_CASE_API_MSG";
export const EDIT_CASE_API_RES = "EDIT_CASE_API_RES";

export interface EditCaseState {
  editCaseResponse: EditCaseResponse;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface EditCaseLoading {
  type: typeof EDIT_CASE_LOADING;
  loading: boolean;
}
export interface EditCaseSuccess {
  type: typeof EDIT_CASE_SUCCESS;
  payload: EditCaseResponse;
  successMsg: string;
}
export interface EditCaseFail {
  type: typeof EDIT_CASE_FAIL;
  payload: EditCaseResponse;
  errorMsg: string;
  status: number;
}
export interface EditCaseAPIMsg {
  type: typeof EDIT_CASE_API_MSG;
  payload: EditCaseResponse;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface EditCaseAPIRes {
  type: typeof EDIT_CASE_API_RES;
  payload: EditCaseResponse;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type EditCaseActionTypes =
  | EditCaseLoading
  | EditCaseSuccess
  | EditCaseFail
  | EditCaseAPIMsg
  | EditCaseAPIRes;
