import React, { useEffect, useState } from "react";
import {
  Alert,
  AlertColor,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
} from "@mui/material";
import { t } from "i18next";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../redux/store/Store";
import {
  clearCreateAppointmentAPIRes,
  createAppointment,
} from "../../../../redux/Appointment/RequestAppointment/API";
import { CreateAppointmentReqBody } from "../../../../redux/Appointment/RequestAppointment/Model";
export interface Props {
  caseId: string | undefined;
}
export default function RequestAppointment({ caseId }: Props) {
  const dispatch = useDispatch();
  const createAppointmentRes = useSelector(
    (state: AppState) => state.createAppointmentRes
  );

  const [createAppointmentMsg, setCreateAppointmentMsg] = useState("");
  const [createAppointmentMsgType, setCreateAppointmentMsgType] =
    useState<AlertColor>("success");

  useEffect(() => {
    if (createAppointmentRes?.successMsg !== "") {
      setCreateAppointmentMsgType("success");
      setCreateAppointmentMsg(createAppointmentRes?.successMsg);
      // dispatch(getAwardList());
    }
    if (createAppointmentRes?.errorMsg !== "") {
      setCreateAppointmentMsgType("error");
      setCreateAppointmentMsg(createAppointmentRes?.errorMsg);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createAppointmentRes]);
  return (
    <Card>
      <CardHeader
        title={
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>{t("selOpPage.reqApp", { ns: ["home"] })!}</Grid>
            <Grid item>
              <Button
                variant="contained"
                onClick={() =>
                  dispatch(
                    createAppointment({
                      caseId: caseId,
                      phoneNumberForPatientCase: "",
                    } as CreateAppointmentReqBody)
                  )
                }
                sx={{ textTransform: "none" }}
              >
                {t("selOpPage.reqApp", { ns: ["home"] })!}
              </Button>
            </Grid>
          </Grid>
        }
      />
      <CardContent>
        {createAppointmentMsg !== "" ? (
          <Box my={2}>
            <Alert
              severity={createAppointmentMsgType}
              onClose={() => {
                dispatch(clearCreateAppointmentAPIRes());
                setCreateAppointmentMsg("");
              }}
            >
              {createAppointmentMsg}
            </Alert>
          </Box>
        ) : null}
      </CardContent>
    </Card>
  );
}
