import { AddPatientInsuranceRes } from "./Model";
export const ADD_PATIENT_INSURANCE_LOADING = "ADD_PATIENT_INSURANCE_LOADING";
export const ADD_PATIENT_INSURANCE_SUCCESS = "ADD_PATIENT_INSURANCE_SUCCESS";
export const ADD_PATIENT_INSURANCE_FAIL = "ADD_PATIENT_INSURANCE_FAIL";
export const ADD_PATIENT_INSURANCE_API_MSG = "ADD_PATIENT_INSURANCE_API_MSG";
export const ADD_PATIENT_INSURANCE_API_RES = "ADD_PATIENT_INSURANCE_API_RES";

export interface AddPatientInsuranceState {
  addPatientInsuranceRes: AddPatientInsuranceRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface AddPatientInsuranceLoading {
  type: typeof ADD_PATIENT_INSURANCE_LOADING;
  loading: boolean;
}
export interface AddPatientInsuranceSuccess {
  type: typeof ADD_PATIENT_INSURANCE_SUCCESS;
  payload: AddPatientInsuranceRes;
  successMsg: string;
}
export interface AddPatientInsuranceFail {
  type: typeof ADD_PATIENT_INSURANCE_FAIL;
  payload: AddPatientInsuranceRes;
  errorMsg: string;
  status: number;
}
export interface AddPatientInsuranceAPIMsg {
  type: typeof ADD_PATIENT_INSURANCE_API_MSG;
  payload: AddPatientInsuranceRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface AddPatientInsuranceAPIRes {
  type: typeof ADD_PATIENT_INSURANCE_API_RES;
  payload: AddPatientInsuranceRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type AddPatientInsuranceActionTypes =
  | AddPatientInsuranceLoading
  | AddPatientInsuranceSuccess
  | AddPatientInsuranceFail
  | AddPatientInsuranceAPIMsg
  | AddPatientInsuranceAPIRes;
