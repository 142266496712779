import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../../Url";
import { AskDoctorQuestionActionTypes } from "./ActionTypes";
import { AskQuestionBody, AskQuestionRes } from "./Model";
import {
  askDoctorQuestionAPIResClearAction,
  askDoctorQuestionErrorAction,
  askDoctorQuestionLoadingAction,
  askDoctorQuestionSuccessAction,
  askDoctorQuestionAPIMsgAction,
} from "./Action";
import { headers } from "../../../../components/Utility";
import { setupInterceptorsTo } from "../../../Authentication/Interceptors";

let apiRes = {} as AskQuestionRes;
export const askDoctorQuestion = (payload: AskQuestionBody) => {
  return function (dispatch: Dispatch<AskDoctorQuestionActionTypes>) {
    dispatch(askDoctorQuestionLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        Url.baseUrl + `/questionandanswerapp/api/question/ask_Question`,
        payload,
        {
          headers: headers,
        }
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(askDoctorQuestionLoadingAction(false));
        dispatch(
          askDoctorQuestionSuccessAction(
            res.data,
            res.data.message !== undefined
              ? res.data.message
              : "Patient's question for doctor has been submitted successfully"
          )
        );
      })
      .catch((error) => {
        dispatch(askDoctorQuestionLoadingAction(false));
        dispatch(
          askDoctorQuestionErrorAction(
            {} as AskQuestionRes,
            error.response.data !== undefined
              ? error.response.data.message !== undefined
                ? error.response.data.message
                : "Something went wrong"
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0
          )
        );
      });
  };
};

export const updateAskDoctorQuestionAPIResMsg = () => {
  return function (dispatch: Dispatch<AskDoctorQuestionActionTypes>) {
    dispatch(
      askDoctorQuestionAPIMsgAction(apiRes as AskQuestionRes, "", "", 0)
    );
  };
};

export const clearAskDoctorQuestionAPIRes = () => {
  return function (dispatch: Dispatch<AskDoctorQuestionActionTypes>) {
    dispatch(
      askDoctorQuestionAPIResClearAction({} as AskQuestionRes, "", "", 0)
    );
  };
};
