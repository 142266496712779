import {
  LANGUAGE_LIST_FAIL,
  LANGUAGE_LIST_LOADING,
  LANGUAGE_LIST_SUCCESS,
  LANGUAGE_LIST_API_MSG,
  LANGUAGE_LIST_API_RES,
  LanguageListActionTypes,
} from "./ActionTypes";
import { LanguageListResponse } from "./Model";

export const LanguageListLoadingAction = (loading: boolean): LanguageListActionTypes => {
  return {
    type: LANGUAGE_LIST_LOADING,
    loading: loading,
  };
};

export const LanguageListSuccessAction = (
  languageListResponse: LanguageListResponse,
  successMsg: string
): LanguageListActionTypes => {
  return {
    type: LANGUAGE_LIST_SUCCESS,
    payload: languageListResponse,
    successMsg: successMsg,
  };
};

export const LanguageListErrorAction = (
  languageListResponse: LanguageListResponse,
  errMsg: string,
  status: number
): LanguageListActionTypes => {
  return {
    type: LANGUAGE_LIST_FAIL,
    payload: languageListResponse,
    errorMsg: errMsg,
    status: status,
  };
};

export const LanguageListAPIMsgAction = (
  languageListResponse: LanguageListResponse,
  successMsg: string,
  errMsg: string,
  status: number
): LanguageListActionTypes => {
  return {
    type: LANGUAGE_LIST_API_MSG,
    payload: languageListResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const LanguageListAPIResClearAction = (
  languageListResponse: LanguageListResponse,
  successMsg: string,
  errMsg: string,
  status: number
): LanguageListActionTypes => {
  return {
    type: LANGUAGE_LIST_API_RES,
    payload: languageListResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
