import * as React from "react";
import { Box, Container, Typography } from "@mui/material";
import { t } from "i18next";

export default function InformConsent() {
  const pagebody = (
    <Box>
      <p>
        <Typography>{t("codeOfconduct.point1", { ns: ["home"] })}</Typography>
      </p>
      <p>{t("codeOfconduct.point39", { ns: ["home"] })} </p>
      <p>{t("codeOfconduct.point40", { ns: ["home"] })} </p>
      <p>{t("codeOfconduct.point41", { ns: ["home"] })} </p>
      <p>{t("codeOfconduct.point42", { ns: ["home"] })}</p>
      <p>
        <Typography>{t("codeOfconduct.point2", { ns: ["home"] })}</Typography>
      </p>
      <p>{t("codeOfconduct.point43", { ns: ["home"] })} </p>
      <p>{t("codeOfconduct.point44", { ns: ["home"] })} </p>
      <p>{t("codeOfconduct.point45", { ns: ["home"] })} </p>
      <p>{t("codeOfconduct.point46", { ns: ["home"] })} </p>
      <p>
        <Typography>{t("codeOfconduct.point3", { ns: ["home"] })}</Typography>
      </p>
      <p>
        <Typography>{t("codeOfconduct.point4", { ns: ["home"] })}</Typography>
      </p>
      <p>
        <Typography>{t("codeOfconduct.point5", { ns: ["home"] })}</Typography>
      </p>
      <p>
        <Typography>{t("codeOfconduct.point6", { ns: ["home"] })}</Typography>
      </p>
      <p>
        <Typography>{t("codeOfconduct.point7", { ns: ["home"] })}</Typography>
      </p>
      <p>
        <Typography>{t("codeOfconduct.point8", { ns: ["home"] })}</Typography>
      </p>
      <p>
        <p>{t("codeOfconduct.point47", { ns: ["home"] })} </p>
      </p>
      <p>
        <Typography>{t("codeOfconduct.point9", { ns: ["home"] })}</Typography>
      </p>
      <p>
        <Typography>{t("codeOfconduct.point10", { ns: ["home"] })}</Typography>
      </p>
      <p>
        <Typography>{t("codeOfconduct.point11", { ns: ["home"] })}</Typography>
      </p>
      <p>
        <Typography>{t("codeOfconduct.point12", { ns: ["home"] })}</Typography>
      </p>
      <p>
        <Typography>{t("codeOfconduct.point13", { ns: ["home"] })}</Typography> 
      </p>
      <p>{t("codeOfconduct.point48", { ns: ["home"] })}</p>
      <p>
        <Typography>{t("codeOfconduct.point14", { ns: ["home"] })}</Typography>
      </p>
      <p>
        <Typography>{t("codeOfconduct.point15", { ns: ["home"] })}</Typography>
      </p>
      <p>
        <Typography>{t("codeOfconduct.point16", { ns: ["home"] })}</Typography>
      </p>
      <p>
        <Typography>{t("codeOfconduct.point17", { ns: ["home"] })}</Typography>
      </p>
      <p>
        <Typography>{t("codeOfconduct.point18", { ns: ["home"] })}</Typography>
      </p>
      <p>
        <Typography>{t("codeOfconduct.point19", { ns: ["home"] })}</Typography>
      </p>
      <p>
        <Typography>{t("codeOfconduct.point20", { ns: ["home"] })}</Typography>
      </p>
      <p>
        <Typography>{t("codeOfconduct.point21", { ns: ["home"] })}</Typography>
      </p>
      <p>
        <Typography>{t("codeOfconduct.point22", { ns: ["home"] })}</Typography>
      </p>
      <p>
        <Typography>{t("codeOfconduct.point23", { ns: ["home"] })}</Typography>
      </p>
      <p>
        <Typography>{t("codeOfconduct.point24", { ns: ["home"] })}</Typography>
      </p>
      <p>
        <Typography>{t("codeOfconduct.point25", { ns: ["home"] })}</Typography>
      </p>
      <p>
        <Typography>{t("codeOfconduct.point26", { ns: ["home"] })}</Typography>
      </p>
      <p>{t("codeOfconduct.point27", { ns: ["home"] })}</p>
      <p>{t("codeOfconduct.point28", { ns: ["home"] })}</p>
      <p>{t("codeOfconduct.point29", { ns: ["home"] })}</p>
      <p>{t("codeOfconduct.point30", { ns: ["home"] })}</p>
      <p>{t("codeOfconduct.point31", { ns: ["home"] })}</p>
      <p>{t("codeOfconduct.point32", { ns: ["home"] })}</p>
      <p>{t("codeOfconduct.point33", { ns: ["home"] })}</p>
      <p>{t("codeOfconduct.point34", { ns: ["home"] })}</p>
      <p>{t("codeOfconduct.point35", { ns: ["home"] })}</p>
      <p>{t("codeOfconduct.point36", { ns: ["home"] })}</p>
      <p>{t("codeOfconduct.point37", { ns: ["home"] })}</p>
      <p>{t("codeOfconduct.point38", { ns: ["home"] })}</p>
    </Box>
  );

  return <Container>{pagebody}</Container>;
}
