import {
  CONSULTATION_TYPE_FAIL,
  CONSULTATION_TYPE_LOADING,
  CONSULTATION_TYPE_SUCCESS,
  CONSULTATION_TYPE_API_MSG,
  CONSULTATION_TYPE_API_RES,
  ConsultationTypeActionTypes,
} from "./ActionTypes";
import { ConsultationTypeListResponse } from "./Model";

export const consultationTypeLoadingAction = (loading: boolean): ConsultationTypeActionTypes => {
  return {
    type: CONSULTATION_TYPE_LOADING,
    loading: loading,
  };
};

export const consultationTypeSuccessAction = (
  consultationTypeResponse: ConsultationTypeListResponse,
  successMsg: string
): ConsultationTypeActionTypes => {
  return {
    type: CONSULTATION_TYPE_SUCCESS,
    payload: consultationTypeResponse,
    successMsg: successMsg,
  };
};

export const consultationTypeErrorAction = (
  consultationTypeResponse: ConsultationTypeListResponse,
  errMsg: string,
  status: number
): ConsultationTypeActionTypes => {
  return {
    type: CONSULTATION_TYPE_FAIL,
    payload: consultationTypeResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const consultationTypeAPIMsgAction = (
  consultationTypeResponse: ConsultationTypeListResponse,
  successMsg: string,
  errMsg: string,
  status: number
): ConsultationTypeActionTypes => {
  return {
    type: CONSULTATION_TYPE_API_MSG,
    payload: consultationTypeResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const consultationTypeAPIResClearAction = (
  consultationTypeResponse: ConsultationTypeListResponse,
  successMsg: string,
  errMsg: string,
  status: number
): ConsultationTypeActionTypes => {
  return {
    type: CONSULTATION_TYPE_API_RES,
    payload: consultationTypeResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
