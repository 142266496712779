import React, { useState, useEffect } from "react";
import {} from "react-router-dom";
import "react-big-calendar/lib/css/react-big-calendar.css";
import VisibilityIcon from "@mui/icons-material/Visibility";
import RefreshIcon from "@mui/icons-material/Refresh";
import PaymentIcon from "@mui/icons-material/Payment";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../redux/store/Store";
import { t } from "i18next";
import {
  Card,
  CardHeader,
  CardContent,
  Grid,
  Button,
  AlertColor,
} from "@mui/material";
import PageLayout from "../Layout/PageLayout";
import MWExceptionList from "../../components/MWExceptionList";
import {
  clearPaymentStatusAPIRes,
  paymentStatus,
} from "../../redux/appointmentPayment/CheckPaymentStatusById/API";
import { CheckPaymentStatusReq } from "../../redux/appointmentPayment/CheckPaymentStatusById/Model";
import { ownerPaymentListApi } from "../../redux/payment/AppointmentPaymentList/API";
import { ModifiedOwnerPaymentList } from "./Model";
import { PaymentList } from "../../redux/payment/AppointmentPaymentList/Model";
import dayjs from "dayjs";
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridToolbar,
} from "@mui/x-data-grid";
import MWSnackbar from "../../components/MWSnackbar";

export default function Payment() {
  const dispatch = useDispatch();
  const [paymentList, setPaymentList] = useState<ModifiedOwnerPaymentList[]>(
    []
  );
  const [paymentStatusMsgType, setPaymentStatusMsgType] =
    useState<AlertColor>("success");
  const [paymentStatusResponseMsg, setPaymentStatusResponseMsg] = useState("");

  const getPaymentListByOwnerRes = useSelector(
    (state: AppState) => state.getPaymentListByOwner
  );
  const checkPaymentStatusByIdRes = useSelector(
    (state: AppState) => state.checkPaymentStatusById
  );

  function paymentLink(response: any) {
    window.open(response);
  }
  // const [paymentDetailsId, setPaymentDetailsId] = useState('');
  const checkPaymentStatusDetails = (id: number) => {
    const checkPaymentStatusByIdBody = {
      paymentId: String(id),
    } as CheckPaymentStatusReq;
    dispatch(paymentStatus(checkPaymentStatusByIdBody));
  };

  const closeAlert = (passedVal: boolean) => {
    if (passedVal) {
      setPaymentStatusResponseMsg("");
      dispatch(clearPaymentStatusAPIRes());
    }
  };

  const ownerPaymentColumn: GridColDef[] = [
    {
      field: "note",
      headerName: `${t("payment.desc", { ns: ["home"] })}`,
      flex: 1,
    },
    {
      field: "create_date",
      headerName: `${t("payment.cdate", { ns: ["home"] })}`,
      minWidth: 150,
    },
    {
      field: "amount",
      headerName: `${t("payment.amt", { ns: ["home"] })}`,
      minWidth: 150,
    },
    {
      field: "paymentLink",
      headerName: `${t("payment.pay", { ns: ["home"] })}`,
      minWidth: 150,
      renderCell: (params: GridCellParams) => (
        <Button
          variant="outlined"
          onClick={() => {
            paymentLink(params.row.paymentLink);
          }}
          sx={{ textTransform: "none" }}
          startIcon={<PaymentIcon />}
          disabled={params.row.status === "expired"}
        >
          {t("payment.paynow", { ns: ["home"] })}
        </Button>
      ),
    },
    {
      field: "paidTime",
      headerName: `${t("payment.paidon", { ns: ["home"] })}`,
      minWidth: 150,
    },
    {
      field: "status",
      headerName: `${t("payment.payStat", { ns: ["home"] })}`,
      minWidth: 150,
    },
    {
      field: "id",
      headerName: `${t("payment.chStat", { ns: ["home"] })}`,
      minWidth: 150,
      renderCell: (params: GridCellParams) => (
        <Button
          variant="outlined"
          onClick={() => {
            checkPaymentStatusDetails(params.row.id);
          }}
          sx={{ textTransform: "none" }}
          startIcon={<VisibilityIcon />}
          disabled={params.row.status === "expired"}
        >
          {t("appointment.view", { ns: ["home"] })}
        </Button>
      ),
    },
  ];

  useEffect(() => {
    dispatch(ownerPaymentListApi());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      getPaymentListByOwnerRes?.ownerPaymentListResponse?.paymentList !==
      undefined
    ) {
      if (
        getPaymentListByOwnerRes?.ownerPaymentListResponse?.paymentList.length >
        0
      ) {
        let ownerApptListItem =
          getPaymentListByOwnerRes?.ownerPaymentListResponse?.paymentList.map(
            (element: PaymentList) =>
              ({
                id: element?.id,
                amount:
                  element?.amount !== undefined
                    ? "Rs " + Number(element?.amount) / 100
                    : `${t("common.notSet", { ns: ["home"] })!}`,
                note:
                  element?.note !== undefined
                    ? element?.note
                    : `${t("common.notSet", { ns: ["home"] })!}`,
                create_date:
                  element?.create_date !== undefined
                    ? dayjs(element?.create_date).format("DD-MM-YYYY")
                    : `${t("common.notSet", { ns: ["home"] })!}`,
                paymentLink:
                  element?.paymentLink !== undefined
                    ? element?.paymentLink
                    : `${t("common.notSet", { ns: ["home"] })!}`,
                status:
                  element?.status !== null
                    ? element?.status
                    : `${t("common.notSet", { ns: ["home"] })!}`,
                paidTime:
                  element?.paidTime !== null
                    ? element?.paidTime
                    : `${t("common.notSet", { ns: ["home"] })!}`,
              } as ModifiedOwnerPaymentList)
          );
        setPaymentList(ownerApptListItem);
      } else setPaymentList([] as ModifiedOwnerPaymentList[]);
    } else setPaymentList([] as ModifiedOwnerPaymentList[]);
  }, [getPaymentListByOwnerRes]);

  useEffect(() => {
    if (checkPaymentStatusByIdRes?.successMsg !== "") {
      setPaymentStatusResponseMsg(checkPaymentStatusByIdRes?.successMsg);
      setPaymentStatusMsgType("success");
    }
    if (checkPaymentStatusByIdRes?.errorMsg !== "") {
      setPaymentStatusResponseMsg(checkPaymentStatusByIdRes?.errorMsg);
      setPaymentStatusMsgType("error");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkPaymentStatusByIdRes]);

  const pageBody = (
    <Card>
      <CardHeader
        title={
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-end"
          >
            <Grid item>
              <Button
                variant="outlined"
                startIcon={<RefreshIcon />}
                onClick={() => dispatch(ownerPaymentListApi())}
                sx={{ textTransform: "none" }}
              >
                {t("payment.reloadPayList", { ns: ["home"] })!}
              </Button>
            </Grid>
          </Grid>
        }
      />
      <CardContent>
        <>
          {paymentList && paymentList.length ? (
            <DataGrid
              rows={paymentList}
              columns={ownerPaymentColumn}
              pageSize={10}
              hideFooter
              hideFooterPagination
              disableSelectionOnClick
              experimentalFeatures={{ newEditingApi: true }}
              autoHeight
              components={{ Toolbar: GridToolbar }}
              componentsProps={{
                toolbar: {
                  csvOptions: { disableToolbarButton: true },
                  printOptions: { disableToolbarButton: true },
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 250 },
                },
              }}
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector
            />
          ) : (
            <MWExceptionList />
          )}
        </>
      </CardContent>
    </Card>
  );

  return (
    <>
      {/* {loading ? <MWLoader /> : null} */}
      <PageLayout>
        {pageBody}
        {paymentStatusResponseMsg !== "" ? (
          <MWSnackbar
            type={paymentStatusMsgType}
            msg={paymentStatusResponseMsg}
            alertClose={closeAlert}
          />
        ) : null}
      </PageLayout>
    </>
  );
}
