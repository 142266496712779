import {
  PATIENT_DELETE_FAIL,
  PATIENT_DELETE_LOADING,
  PATIENT_DELETE_SUCCESS,
  PATIENT_DELETE_API_MSG,
  PATIENT_DELETE_API_RES,
  PatientDeleteActionTypes,
} from "./ActionTypes";
import { DeletePatientResponse } from "./Model";

export const PatientDeleteLoadingAction = (loading: boolean): PatientDeleteActionTypes => {
  return {
    type: PATIENT_DELETE_LOADING,
    loading: loading,
  };
};

export const PatientDeleteSuccessAction = (
  PatientDeleteResponse: DeletePatientResponse,
  successMsg: string
): PatientDeleteActionTypes => {
  return {
    type: PATIENT_DELETE_SUCCESS,
    payload: PatientDeleteResponse,
    successMsg: successMsg,
  };
};

export const PatientDeleteErrorAction = (
  PatientDeleteResponse: DeletePatientResponse,
  errMsg: string,
  status: number
): PatientDeleteActionTypes => {
  return {
    type: PATIENT_DELETE_FAIL,
    payload: PatientDeleteResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const PatientDeleteAPIMsgAction = (
  PatientDeleteResponse: DeletePatientResponse,
  successMsg: string,
  errMsg: string,
  status: number
): PatientDeleteActionTypes => {
  return {
    type: PATIENT_DELETE_API_MSG,
    payload: PatientDeleteResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const PatientDeleteAPIResClearAction = (
  PatientDeleteResponse: DeletePatientResponse,
  successMsg: string,
  errMsg: string,
  status: number
): PatientDeleteActionTypes => {
  return {
    type: PATIENT_DELETE_API_RES,
    payload: PatientDeleteResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
