import {
  PAYMENT_LIST_FAIL,
  PAYMENT_LIST_LOADING,
  PAYMENT_LIST_SUCCESS,
  PAYMENT_LIST_API_MSG,
  PAYMENT_LIST_API_RES,
  PaymentListActionTypes,
} from "./ActionTypes";
import { PaymentListResponse } from "./Model";

export const paymentListLoadingAction = (
  loading: boolean
): PaymentListActionTypes => {
  return {
    type: PAYMENT_LIST_LOADING,
    loading: loading,
  };
};

export const paymentListSuccessAction = (
  paymentListResponse: PaymentListResponse,
  successMsg: string
): PaymentListActionTypes => {
  return {
    type: PAYMENT_LIST_SUCCESS,
    payload: paymentListResponse,
    successMsg: successMsg,
  };
};

export const paymentListErrorAction = (
  paymentListResponse: PaymentListResponse,
  errMsg: string,
  status: number
): PaymentListActionTypes => {
  return {
    type: PAYMENT_LIST_FAIL,
    payload: paymentListResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const paymentListAPIMsgAction = (
  paymentListResponse: PaymentListResponse,
  successMsg: string,
  errMsg: string,
  status: number
): PaymentListActionTypes => {
  return {
    type: PAYMENT_LIST_API_MSG,
    payload: paymentListResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const paymentListAPIResClearAction = (
  paymentListResponse: PaymentListResponse,
  successMsg: string,
  errMsg: string,
  status: number
): PaymentListActionTypes => {
  return {
    type: PAYMENT_LIST_API_RES,
    payload: paymentListResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
