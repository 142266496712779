import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../Url";
import { PatientDeleteActionTypes } from "./ActionTypes";
import { DeletePatientBody, DeletePatientResponse } from "./Model";
import {
  PatientDeleteAPIResClearAction,
  PatientDeleteErrorAction,
  PatientDeleteLoadingAction,
  PatientDeleteSuccessAction,
  PatientDeleteAPIMsgAction,
} from "./Action";
import { headers } from "../../../components/Utility";
import { setupInterceptorsTo } from "../../Authentication/Interceptors";

let apiRes = {} as DeletePatientResponse;
export const deletePatient = (payload: DeletePatientBody) => {
  return function (dispatch: Dispatch<PatientDeleteActionTypes>) {
    dispatch(PatientDeleteLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        Url.baseUrl + `/patientapp/api/patient/deactivate_patient`,
        payload,
        {
          headers: headers,
        }
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(PatientDeleteLoadingAction(false));
        dispatch(
          PatientDeleteSuccessAction(
            res.data,
            res.data.message !== undefined
              ? res.data.message
              : "Patient deleted successfully."
          )
        );
      })
      .catch((error) => {
        dispatch(PatientDeleteLoadingAction(false));
        dispatch(
          PatientDeleteErrorAction(
            {} as DeletePatientResponse,
            error.response.data !== undefined
              ? error.response.data.message !== undefined
                ? error.response.data.message
                : "Something went wrong"
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0
          )
        );
      });
  };
};

export const PatientDeleteAPIResMsg = () => {
  return function (dispatch: Dispatch<PatientDeleteActionTypes>) {
    dispatch(
      PatientDeleteAPIMsgAction(apiRes as DeletePatientResponse, "", "", 0)
    );
  };
};

export const clearPatientDeleteAPIRes = () => {
  return function (dispatch: Dispatch<PatientDeleteActionTypes>) {
    dispatch(
      PatientDeleteAPIResClearAction({} as DeletePatientResponse, "", "", 0)
    );
  };
};
