import axios from "axios";
import { Dispatch } from "redux";
import { GetDoctorListForCaseActionTypes } from "./ActionTypes";
import { GetDoctorListForCaseBody, GetDoctorListForCaseRes } from "./Model";
import {
  getDoctorListForCaseAPIResClearAction,
  getDoctorListForCaseErrorAction,
  getDoctorListForCaseLoadingAction,
  getDoctorListForCaseSuccessAction,
  getDoctorListForCaseUpdateAPIMsgAction,
} from "./Actions";
import Url from "../../../Url";
import { headers } from "../../../components/Utility";
import { setupInterceptorsTo } from "../../Authentication/Interceptors";

let apiRes = {} as GetDoctorListForCaseRes;
export const getDoctorListForCase = (payload: GetDoctorListForCaseBody) => {
  return function (dispatch: Dispatch<GetDoctorListForCaseActionTypes>) {
    dispatch(getDoctorListForCaseLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        Url.baseUrl +
          `/patientapp/api/patientMedicalData/doctorList_for_patientCase`,
        payload,
        {
          headers: headers,
        }
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(getDoctorListForCaseLoadingAction(false));
        dispatch(
          getDoctorListForCaseSuccessAction(
            res.data,
            res.data.message !== undefined
              ? res.data.message
              : "Doctor team type list has been fetched successfully"
          )
        );
      })
      .catch((error) => {
        dispatch(getDoctorListForCaseLoadingAction(false));
        dispatch(
          getDoctorListForCaseErrorAction(
            {} as GetDoctorListForCaseRes,
            error.response !== undefined
              ? error.response.data.message || error.response.data.detail
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || -1 : -1
          )
        );
      });
  };
};

export const updateGetDoctorTeamTypeAPIResMsg = () => {
  return function (dispatch: Dispatch<GetDoctorListForCaseActionTypes>) {
    dispatch(
      getDoctorListForCaseUpdateAPIMsgAction(
        apiRes as GetDoctorListForCaseRes,
        "",
        "",
        0
      )
    );
  };
};

export const clearGetDoctorListForCaseAPIRes = () => {
  return function (dispatch: Dispatch<GetDoctorListForCaseActionTypes>) {
    dispatch(
      getDoctorListForCaseAPIResClearAction(
        {} as GetDoctorListForCaseRes,
        "",
        "",
        0
      )
    );
  };
};
