import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import DataList from "../../FIleData/DataList";
import { GetPatientDataDetailsBody } from "../../../../../redux/FilePreparation/GetPatientDataList/Model";
import { getPatientDataList } from "../../../../../redux/FilePreparation/GetPatientDataList/API";
interface Props {
  label?: string;
  type?: string;
}
export default function ImportedDataList({ label, type }: Props) {
  const dispatch = useDispatch();
  const { caseid } = useParams() as {
    caseid: string;
  };
  const getPatientDataDetails = {
    caseId: caseid,
    secondOpinionUiSection: type,
  } as GetPatientDataDetailsBody;
  useEffect(() => {
    dispatch(getPatientDataList(getPatientDataDetails));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <DataList label={label} type={type} />;
}
