import { MaritalStatusResponse } from "./Model";
export const MARITAL_STATUS_LOADING = "MARITAL_STATUS_LOADING";
export const MARITAL_STATUS_SUCCESS = "MARITAL_STATUS_SUCCESS";
export const MARITAL_STATUS_FAIL = "MARITAL_STATUS_FAIL";
export const MARITAL_STATUS_API_MSG = "MARITAL_STATUS_API_MSG";
export const MARITAL_STATUS_API_RES = "MARITAL_STATUS_API_RES";

export interface MaritalListState {
  maritalStatusResponse: MaritalStatusResponse;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface MaritalListLoading {
  type: typeof MARITAL_STATUS_LOADING;
  loading: boolean;
}
export interface MaritalListSuccess {
  type: typeof MARITAL_STATUS_SUCCESS;
  payload: MaritalStatusResponse;
  successMsg: string;
}
export interface MaritalListFail {
  type: typeof MARITAL_STATUS_FAIL;
  payload: MaritalStatusResponse;
  errorMsg: string;
  status: number;
}
export interface MaritalListAPIMsg {
  type: typeof MARITAL_STATUS_API_MSG;
  payload: MaritalStatusResponse;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface MaritalListAPIRes {
  type: typeof MARITAL_STATUS_API_RES;
  payload: MaritalStatusResponse;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type MaritalListActionTypes =
  | MaritalListLoading
  | MaritalListSuccess
  | MaritalListFail
  | MaritalListAPIMsg
  | MaritalListAPIRes;
