import { DeletePatientResponse } from "./Model";
export const PATIENT_DELETE_LOADING = "PATIENT_DELETE_LOADING";
export const PATIENT_DELETE_SUCCESS = "PATIENT_DELETE_SUCCESS";
export const PATIENT_DELETE_FAIL = "PATIENT_DELETE_FAIL";
export const PATIENT_DELETE_API_MSG = "PATIENT_DELETE_API_MSG";
export const PATIENT_DELETE_API_RES = "PATIENT_DELETE_API_RES";

export interface PatientDeleteState {
  PatientDeleteResponse: DeletePatientResponse;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface PatientDeleteLoading {
  type: typeof PATIENT_DELETE_LOADING;
  loading: boolean;
}
export interface PatientDeleteSuccess {
  type: typeof PATIENT_DELETE_SUCCESS;
  payload: DeletePatientResponse;
  successMsg: string;
}
export interface PatientDeleteFail {
  type: typeof PATIENT_DELETE_FAIL;
  payload: DeletePatientResponse;
  errorMsg: string;
  status: number;
}
export interface PatientDeleteAPIMsg {
  type: typeof PATIENT_DELETE_API_MSG;
  payload: DeletePatientResponse;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface PatientDeleteAPIRes {
  type: typeof PATIENT_DELETE_API_RES;
  payload: DeletePatientResponse;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type PatientDeleteActionTypes =
  | PatientDeleteLoading
  | PatientDeleteSuccess
  | PatientDeleteFail
  | PatientDeleteAPIMsg
  | PatientDeleteAPIRes;
