import {
  REQUEST_BOOK_APPOINTMENT_LIST,
  requestBookAppoiontmentActionTypes,
  REQUEST_BOOK_APPOINTMENT_UPDATE_LIST,
} from "./ActionTypes";
import { requestBookAppoiontmentValue } from "./Model";

export const requestBookAppoiontmentAction = (
  requestBookAppoiontmentDetails: requestBookAppoiontmentValue
): requestBookAppoiontmentActionTypes => {
  return {
    type: REQUEST_BOOK_APPOINTMENT_LIST,
    payload: requestBookAppoiontmentDetails,
  };
};
export const updateBookAppoiontmentAction =
  (): requestBookAppoiontmentActionTypes => {
    return {
      type: REQUEST_BOOK_APPOINTMENT_UPDATE_LIST,
      payload: {} as requestBookAppoiontmentValue,
    };
  };
