import { emailOtpAuthValue } from "./Model";

// Patient Meeting Loading State
export const EMAIL_OTP_AUTH_LOADING =
  "EMAIL_OTP_AUTH_LOADING";
export const EMAIL_OTP_AUTH_SUCCESS =
  "EMAIL_OTP_AUTH_SUCCESS";
export const EMAIL_OTP_AUTH_FAIL = "EMAIL_OTP_AUTH_FAIL";
export const EMAIL_OTP_AUTH_LIST = "EMAIL_OTP_AUTH_LIST";
export const REQUEST_BOOK_APPOINTMENT_UPDATE_LIST =
  "REQUEST_BOOK_APPOINTMENT_UPDATE_LIST";

export interface emailOtpAuthDetails {
  emailOtpAuthRes: emailOtpAuthValue;
  loading: boolean;
  errRes: string;
}
export interface emailOtpAuthLoading {
  type: typeof EMAIL_OTP_AUTH_LOADING;
}
export interface emailOtpAuthSuccess {
  type: typeof EMAIL_OTP_AUTH_SUCCESS;
  payload: emailOtpAuthValue;
  errRes: string;
}
export interface emailOtpAuthFail {
  type: typeof EMAIL_OTP_AUTH_FAIL;
  errRes: string;
}

interface emailOtpAuthAction {
  type: typeof EMAIL_OTP_AUTH_LIST;
  payload: emailOtpAuthValue;
  errRes: string;
}

export type emailOtpAuthActionTypes =
  | emailOtpAuthAction
  | emailOtpAuthLoading
  | emailOtpAuthSuccess
  | emailOtpAuthFail;
