import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Autocomplete,
  Alert,
  AlertTitle,
  Grid,
  Stack,
  TextField,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { patientDropDownList } from "../../redux/CaseDetails/AppointmentSelectPatient/patientListDropdown/API";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../redux/store/Store";
import { t } from "i18next";
import MWLoader from "../../components/MWLoader";
import { PatientList } from "../../redux/CaseDetails/AppointmentSelectPatient/patientListDropdown/Model";

export default function AppointmentSelectPatient() {
  const { patientid } = useParams() as {
    patientid: string;
  };
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const [patientList, setPatientList] = useState([] as PatientList[]);
  const [selectedPatient, setSelectedPatient] = useState<PatientList | null>(
    null
  );
  const selectedPatientChange = (_event: {}, passedVal: PatientList | any) => {
    setSelectedPatient(passedVal);
  };

  const getPatientList = () => {
    dispatch(patientDropDownList());
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getPatientList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getPatientDropdownList = useSelector(
    (state: AppState) => state.patientDropDownListRes
  );
  useEffect(() => {
    setIsLoading(getPatientDropdownList?.loading);
    if (
      getPatientDropdownList?.patientDropDownListRes?.patientList !== undefined
    ) {
      if (
        getPatientDropdownList?.patientDropDownListRes?.patientList.length > 0
      ) {
        setPatientList(
          getPatientDropdownList?.patientDropDownListRes?.patientList
        );
      } else setPatientList([] as PatientList[]);
    } else setPatientList([] as PatientList[]);
  }, [getPatientDropdownList]);

  useEffect(() => {
    if (patientList.length > 0 && patientid !== undefined) {
      setSelectedPatient(
        patientList!.find((o: PatientList) => o.value === Number(patientid))!
      );
    } else setSelectedPatient(patientList[0]!);
  }, [patientList, patientid]);

  return (
    <Box>
      {isLoading ? <MWLoader /> : null}
      <Stack
        direction="column"
        justifyContent="flex-start"
        alignItems="stretch"
        spacing={2}
      >
        <Alert variant="outlined" severity="info">
          <AlertTitle>{t("appointment.patAppo", { ns: ["home"] })!}</AlertTitle>
          {t("appointment.selBooking", { ns: ["home"] })!}
        </Alert>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
            <Typography style={{ fontWeight: "bold" }}>
              {t("addpatientpage.selpatient", { ns: ["home"] })!}
            </Typography>
            <Typography style={{ color: "#3F5CCF" }}>
              {t("appointment.selPatappo", { ns: ["home"] })!}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <Autocomplete
              value={selectedPatient !== undefined ? selectedPatient : null}
              id="controllable-states-demo"
              options={patientList}
              onChange={(event: any, newValue: any) => {
                selectedPatientChange(event, newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={
                    t("addpatientpage.selpatient", { ns: ["home"] })!
                  }
                  size="small"
                  fullWidth
                />
              )}
            />
          </Grid>
        </Grid>
      </Stack>
    </Box>
  );
}
