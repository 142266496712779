import { AddPatientTeamResponse } from "./Model";

// Patient Meeting Loading State
export const ADD_PATIENT_TEAM_LOADING = "ADD_PATIENT_TEAM_LOADING";
export const ADD_PATIENT_TEAM_SUCCESS = "ADD_PATIENT_TEAM_SUCCESS";
export const ADD_PATIENT_TEAM_FAIL = "ADD_PATIENT_TEAM_FAIL";
export const ADD_PATIENT_TEAM_DATA = "ADD_PATIENT_TEAM_DATA";

export interface answerQuestionDetails {
  getPatientTeamRes: AddPatientTeamResponse;
  loading: boolean;
  errRes: string;
}
export interface LogOutActionLoading {
  type: typeof ADD_PATIENT_TEAM_LOADING;
}
export interface LogOutActionSuccess {
  type: typeof ADD_PATIENT_TEAM_SUCCESS;
  payload: AddPatientTeamResponse;
  errRes: string;
}
export interface LogOutActionFail {
  type: typeof ADD_PATIENT_TEAM_FAIL;
  payload: AddPatientTeamResponse;
  errRes: string;
}
interface LogOutActionAction {
  type: typeof ADD_PATIENT_TEAM_DATA;
  payload: AddPatientTeamResponse;
  errRes: string;
}

export type LogOutActionTypes =
  | LogOutActionAction
  | LogOutActionLoading
  | LogOutActionSuccess
  | LogOutActionFail;
