import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
} from "@mui/material";
import React from "react";
import PageLayout from "../Layout/PageLayout";
import { t } from "i18next";
import RefreshIcon from "@mui/icons-material/Refresh";
import MWExceptionList from "../../components/MWExceptionList";

export default function PaymentMethod() {
  const pageBody = (
    <Card>
      <CardHeader
        title={
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-end"
          >
            <Grid item>
              <Button
                variant="outlined"
                startIcon={<RefreshIcon />}
                //   onClick={() => dispatch(ownerPaymentListApi())}
                sx={{ textTransform: "none" }}
              >
                {t("payment.reloadPayMethodList", { ns: ["home"] })!}
              </Button>
            </Grid>
          </Grid>
        }
      />
      <CardContent>
        <MWExceptionList />
      </CardContent>
    </Card>
  );

  return (
    <>
      <PageLayout>
        {pageBody}
      </PageLayout>
    </>
  );
}
