import {
  ANSWER_DOCTOR_QUESTION_FAIL,
  ANSWER_DOCTOR_QUESTION_LOADING,
  ANSWER_DOCTOR_QUESTION_SUCCESS,
  ANSWER_DOCTOR_QUESTION_API_MSG,
  ANSWER_DOCTOR_QUESTION_API_RES,
  AnswerDoctorQuestionActionTypes,
} from "./ActionTypes";
import { AnsDoctorQuestionRes } from "./Model";

export const answerDoctorQuestionLoadingAction = (
  loading: boolean
): AnswerDoctorQuestionActionTypes => {
  return {
    type: ANSWER_DOCTOR_QUESTION_LOADING,
    loading: loading,
  };
};

export const answerDoctorQuestionSuccessAction = (
  answerDoctorQuestionResponse: AnsDoctorQuestionRes,
  successMsg: string
): AnswerDoctorQuestionActionTypes => {
  return {
    type: ANSWER_DOCTOR_QUESTION_SUCCESS,
    payload: answerDoctorQuestionResponse,
    successMsg: successMsg,
  };
};

export const answerDoctorQuestionErrorAction = (
  answerDoctorQuestionResponse: AnsDoctorQuestionRes,
  errMsg: string,
  status: number
): AnswerDoctorQuestionActionTypes => {
  return {
    type: ANSWER_DOCTOR_QUESTION_FAIL,
    payload: answerDoctorQuestionResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const answerDoctorQuestionAPIMsgAction = (
  answerDoctorQuestionResponse: AnsDoctorQuestionRes,
  successMsg: string,
  errMsg: string,
  status: number
): AnswerDoctorQuestionActionTypes => {
  return {
    type: ANSWER_DOCTOR_QUESTION_API_MSG,
    payload: answerDoctorQuestionResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const answerDoctorQuestionAPIResClearAction = (
  answerDoctorQuestionResponse: AnsDoctorQuestionRes,
  successMsg: string,
  errMsg: string,
  status: number
): AnswerDoctorQuestionActionTypes => {
  return {
    type: ANSWER_DOCTOR_QUESTION_API_RES,
    payload: answerDoctorQuestionResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
