import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../../Url";
import { PatientDropDownListActionTypes } from "./ActionTypes";
import { PatientListRes } from "./Model";
import {
  patientDropDownListAPIResClearAction,
  patientDropDownListErrorAction,
  patientDropDownListLoadingAction,
  patientDropDownListSuccessAction,
  patientDropDownListAPIMsgAction,
} from "./Action";
import { headers } from "../../../../components/Utility";
import { setupInterceptorsTo } from "../../../Authentication/Interceptors";

let apiRes = {} as PatientListRes;
export const patientDropDownList = () => {
  return function (dispatch: Dispatch<PatientDropDownListActionTypes>) {
    dispatch(patientDropDownListLoadingAction(true));
    setupInterceptorsTo(axios)
      .get(Url.baseUrl + `/patientapp/api/patient/patient_list_dropdown`, {
        headers: headers,
      })
      .then((res) => {
        apiRes = res.data;
        dispatch(patientDropDownListLoadingAction(false));
        dispatch(
          patientDropDownListSuccessAction(
            res.data,
            res.data.message !== undefined
              ? res.data.message
              : "Patient list has been fetched successfully"
          )
        );
      })
      .catch((error) => {
        dispatch(patientDropDownListLoadingAction(false));
        dispatch(
          patientDropDownListErrorAction(
            {} as PatientListRes,
            error.response.data !== undefined
              ? error.response.data.message !== undefined
                ? error.response.data.message
                : "Something went wrong"
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0
          )
        );
      });
  };
};

export const updatePatientDropDownListAPIResMsg = () => {
  return function (dispatch: Dispatch<PatientDropDownListActionTypes>) {
    dispatch(
      patientDropDownListAPIMsgAction(apiRes as PatientListRes, "", "", 0)
    );
  };
};

export const clearPatientDropDownListAPIRes = () => {
  return function (dispatch: Dispatch<PatientDropDownListActionTypes>) {
    dispatch(
      patientDropDownListAPIResClearAction({} as PatientListRes, "", "", 0)
    );
  };
};
