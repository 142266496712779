import {
  DOCTOR_DETAILS_FAIL,
  DOCTOR_DETAILS_LOADING,
  DOCTOR_DETAILS_SUCCESS,
  DOCTOR_DETAILS_API_MSG,
  DOCTOR_DETAILS_API_RES,
  DoctorDetailsActionTypes,
} from "./ActionTypes";
import { DoctorDetailsResponse } from "./Model";

export const DoctorDetailsLoadingAction = (loading: boolean): DoctorDetailsActionTypes => {
  return {
    type: DOCTOR_DETAILS_LOADING,
    loading: loading,
  };
};

export const DoctorDetailsSuccessAction = (
  doctorDetailsResponse: DoctorDetailsResponse,
  successMsg: string
): DoctorDetailsActionTypes => {
  return {
    type: DOCTOR_DETAILS_SUCCESS,
    payload: doctorDetailsResponse,
    successMsg: successMsg,
  };
};

export const DoctorDetailsErrorAction = (
  doctorDetailsResponse: DoctorDetailsResponse,
  errMsg: string,
  status: number
): DoctorDetailsActionTypes => {
  return {
    type: DOCTOR_DETAILS_FAIL,
    payload: doctorDetailsResponse,
    errorMsg: errMsg,
    status: status,
  };
};

export const DoctorDetailsAPIMsgAction = (
  doctorDetailsResponse: DoctorDetailsResponse,
  successMsg: string,
  errMsg: string,
  status: number
): DoctorDetailsActionTypes => {
  return {
    type: DOCTOR_DETAILS_API_MSG,
    payload: doctorDetailsResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const DoctorDetailsAPIResClearAction = (
  doctorDetailsResponse: DoctorDetailsResponse,
  successMsg: string,
  errMsg: string,
  status: number
): DoctorDetailsActionTypes => {
  return {
    type: DOCTOR_DETAILS_API_RES,
    payload: doctorDetailsResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
