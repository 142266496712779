import { AskQuestionRes } from "./Model";
export const ASK_DOCTOR_QUESTION_LOADING = "ASK_DOCTOR_QUESTION_LOADING";
export const ASK_DOCTOR_QUESTION_SUCCESS = "ASK_DOCTOR_QUESTION_SUCCESS";
export const ASK_DOCTOR_QUESTION_FAIL = "ASK_DOCTOR_QUESTION_FAIL";
export const ASK_DOCTOR_QUESTION_API_MSG = "ASK_DOCTOR_QUESTION_API_MSG";
export const ASK_DOCTOR_QUESTION_API_RES = "ASK_DOCTOR_QUESTION_API_RES";

export interface AskDoctorQuestionState {
  askDoctorQuestionRes: AskQuestionRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface AskDoctorQuestionLoading {
  type: typeof ASK_DOCTOR_QUESTION_LOADING;
  loading: boolean;
}
export interface AskDoctorQuestionSuccess {
  type: typeof ASK_DOCTOR_QUESTION_SUCCESS;
  payload: AskQuestionRes;
  successMsg: string;
}
export interface AskDoctorQuestionFail {
  type: typeof ASK_DOCTOR_QUESTION_FAIL;
  payload: AskQuestionRes;
  errorMsg: string;
  status: number;
}
export interface AskDoctorQuestionAPIMsg {
  type: typeof ASK_DOCTOR_QUESTION_API_MSG;
  payload: AskQuestionRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface AskDoctorQuestionAPIRes {
  type: typeof ASK_DOCTOR_QUESTION_API_RES;
  payload: AskQuestionRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type AskDoctorQuestionActionTypes =
  | AskDoctorQuestionLoading
  | AskDoctorQuestionSuccess
  | AskDoctorQuestionFail
  | AskDoctorQuestionAPIMsg
  | AskDoctorQuestionAPIRes;
