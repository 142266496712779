import {
  ResetPasswordDetails,
  resetPasswordActionTypes,
  RESET_PASSWORD_FAIL,
  RESET_PASSWORD_LOADING,
  RESET_PASSWORD_SUCCESS,
} from "./ActionTypes";
import { ResetPasswordValue } from "./Model";

const initialStateGetPosts: ResetPasswordDetails = {
  loading: false,
  resetPasswordRes: {} as ResetPasswordValue,
  errRes: "",
};
export const resetPasswordReducer = (
  state = initialStateGetPosts,
  action: resetPasswordActionTypes
): ResetPasswordDetails => {
  switch (action.type) {
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        resetPasswordRes: action.payload,
        errRes: "",
      };
    case RESET_PASSWORD_LOADING:
      return {
        ...state,
        loading: true,
        resetPasswordRes: {} as ResetPasswordValue,
      };
    case RESET_PASSWORD_FAIL:
      return {
        ...state,
        resetPasswordRes: {} as ResetPasswordValue,
        loading: false,
        errRes: action.errRes,
      };
    default:
      return state;
  }
};
