import React, { useEffect } from "react";
import DescriptionIcon from "@mui/icons-material/Description";
import { useParams, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { AppState } from "../../redux/store/Store";
import { TaskList } from "../../redux/interfaces/Model";
import { getTaskList } from "../../redux/effects/ApiCall";
import * as _ from "lodash";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import MWLoader from "../../components/MWLoader";
export default function TaskDetails() {
  const loadingMarkup = <MWLoader />;
  const dispatch = useDispatch();
  const { rowIndex } = useParams() as {
    rowIndex: string;
  };

  const history = useHistory();
  // Call Store
  const taskReduxValue = useSelector((state: AppState) => state.taskValue);
  const taskDetails: TaskList = _.filter(taskReduxValue.taskValue, [
    "id",
    Number(rowIndex),
  ])[0];

  function gotoCaseDetails() {
    const paymentRedirect = Number(0);
    localStorage.setItem("paymentRedirect", `${paymentRedirect}`);
    history.push(
      `/casedetails/${taskDetails.patientId}/${taskDetails.caseId}/${paymentRedirect}`
    );
  }
  function gotoPayment() {
    const paymentRedirect = Number(1);
    localStorage.setItem("paymentRedirect", `${paymentRedirect}`);
    history.push(
      `/casedetails/${taskDetails.patientId}/${taskDetails.caseId}/${paymentRedirect}`
    );
    localStorage.setItem("paymentRedirect", `${paymentRedirect}`);
  }

  useEffect(() => {
    dispatch(getTaskList());
    localStorage.removeItem("paymentRedirect");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const taskDetailsView = (
    <Card>
      <CardActions>
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="flex-end"
        >
          <Button variant="outlined" onClick={() => gotoPayment}>
            Go for Payment
          </Button>
          &nbsp;&nbsp;&nbsp;
          <Button variant="contained" onClick={() => gotoCaseDetails}>
            View Case Details
          </Button>
        </Grid>
      </CardActions>
      <CardContent>
        {taskDetails ? (
          <Stack
            direction="column"
            justifyContent="flex-start"
            alignItems="stretch"
            spacing={2}
          >
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={2}
            >
              <Grid item xs={4} sm={4} md={4} lg={4}>
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                  Title
                </Typography>
              </Grid>
              <Grid item xs={8} sm={8} md={8} lg={8}>
                <Typography component="dd" variant="body2">
                  {taskDetails.title}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Divider />
              </Grid>
              <Grid item xs={4} sm={4} md={4} lg={4}>
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                  Patient Name
                </Typography>
              </Grid>
              <Grid item xs={8} sm={8} md={8} lg={8}>
                <Typography component="dd" variant="body2">
                  {taskDetails.patientName}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Divider />
              </Grid>
              <Grid item xs={4} sm={4} md={4} lg={4}>
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                  Problem Name
                </Typography>
              </Grid>
              <Grid item xs={8} sm={8} md={8} lg={8}>
                <Typography component="dd" variant="body2">
                  {taskDetails.whyWant2ndOpinion}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Divider />
              </Grid>
              <Grid item xs={4} sm={4} md={4} lg={4}>
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                  Reason for 2ndOpinion
                </Typography>
              </Grid>
              <Grid item xs={8} sm={8} md={8} lg={8}>
                <Typography component="dd" variant="body2">
                  {taskDetails.secondOpinionReasons}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Divider />
              </Grid>

              <Grid item xs={4} sm={4} md={4} lg={4}>
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                  Consultation Type
                </Typography>
              </Grid>
              <Grid item xs={8} sm={8} md={8} lg={8}>
                <Typography component="dd" variant="body2">
                  {taskDetails.consultationType}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Divider />
              </Grid>
              <Grid item xs={4} sm={4} md={4} lg={4}>
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                  Note
                </Typography>
              </Grid>
              <Grid item xs={8} sm={8} md={8} lg={8}>
                <Typography component="dd" variant="body2">
                  {taskDetails.note}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Divider />
              </Grid>
              <Grid item xs={4} sm={4} md={4} lg={4}>
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                  Create Date
                </Typography>
              </Grid>
              <Grid item xs={8} sm={8} md={8} lg={8}>
                <Typography component="dd" variant="body2">
                  {taskDetails.created_date}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Divider />
              </Grid>

              <Grid item xs={4} sm={4} md={4} lg={4}>
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                  Due Date
                </Typography>
              </Grid>
              <Grid item xs={8} sm={8} md={8} lg={8}>
                <Typography component="dd" variant="body2">
                  {taskDetails.due_date}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Divider />
              </Grid>
              <Grid item xs={4} sm={4} md={4} lg={4}>
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                  Completed
                </Typography>
              </Grid>
              <Grid item xs={8} sm={8} md={8} lg={8}>
                <Typography component="dd" variant="body2">
                  {taskDetails.completed}
                </Typography>
              </Grid>
            </Grid>
          </Stack>
        ) : (
          <Typography>
            <DescriptionIcon />
            &nbsp;&nbsp; No Task Details is available!
          </Typography>
        )}
      </CardContent>
    </Card>
  );

  return (
    <Container maxWidth="xl" sx={{ pt: 2 }}>
      <Typography variant="h4" gutterBottom>
        Task Details
      </Typography>
      {taskReduxValue.loading ? loadingMarkup : ""}
      {taskDetailsView}
    </Container>
  );
}
