import React, { useEffect } from "react";
import { useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../redux/store/Store";
import { needHelpAddApi } from "../redux/needHelp/addNeedHelp/API";
import { needHelpBody } from "../redux/needHelp/addNeedHelp/Model";
import { dropdownApi } from "../redux/needHelp/patientList/API";
import { t } from "i18next";
import { Redirect } from "react-router-dom";
import {
  Alert,
  Box,
  Button,
  Card,
  CardHeader,
  Container,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import MWLoader from "../components/MWLoader";
import MWAutocomplete from "../components/MWAutocomplete";

export default function NeedHelp() {
  const dispatch = useDispatch();
  const [helpPhoneNumber, setHelpPhoneNumber] = useState("");
  const [textValue, setTextValue] = useState("");
  const [patientName, setPatientName] = useState([] as any[]);
  const [isLoading, setIsLoading] = useState(false);
  const [toSignin] = useState(false);

  const handleChangePhoneNumber = useCallback(
    (value) => setHelpPhoneNumber(value.target.value),
    []
  );
  const handleChange = useCallback(
    (newValue) => setTextValue(newValue.target.value),
    []
  );
  // const handleNameChange = useCallback(
  //   (value) => setSelectedPatientName(value.target.value),
  //   []
  // );
  const [selectedPatientName, setSelectedPatientName] = useState<number | null>(
    null
  );
  const patientValueFromChild = async (passedval: any) => {
    setSelectedPatientName(passedval);
  };
  const loadingMarkup = isLoading ? <MWLoader /> : null;
  // const [selectedPatientName, setSelectedPatientName] = useState("");
  const [helpQueryMessage, setHelpQueryMessage] = useState("");
  const [helpQueryError, setHelpQueryError] = useState("");
  const handleBannerDismiss = () => {
    setHelpQueryMessage("");
    setHelpQueryError("");
  };

  const successMessage = helpQueryMessage ? (
    <Alert
      severity="success"
      onClose={() => {
        handleBannerDismiss();
      }}
    >
      <Typography>{helpQueryMessage}</Typography>
    </Alert>
  ) : (
    ""
  );

  const errorHelpDiv = helpQueryError ? (
    <Alert
      severity="error"
      onClose={() => {
        handleBannerDismiss();
      }}
    >
      <Typography>{helpQueryError}</Typography>
    </Alert>
  ) : (
    ""
  );

  const [selectedDate, setSelectedDate] = React.useState<Date | null>();
  //new Date(''),
  const today = new Date();
  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
  };

  const addNeedHelpValue = useSelector((state: AppState) => state.addNeedHelp);
  const dropdownListValue = useSelector((state: AppState) => state.dropdown);

  // Submit help api call section  ......
  const submitQuaryBody = {
    queryText: textValue,
    phoneNumber: helpPhoneNumber,
    patientId: String(selectedPatientName),
  };
  const needHelp = async () => {
    dispatch(needHelpAddApi(submitQuaryBody as needHelpBody));
  };

  useEffect(() => {
    setIsLoading(addNeedHelpValue.loading);
    setHelpQueryMessage(addNeedHelpValue.needHelpDetails.message);
  }, [addNeedHelpValue]);

  useEffect(() => {
    setIsLoading(dropdownListValue.loading);
    if (dropdownListValue?.patientList?.patientList !== undefined) {
      setPatientName(dropdownListValue.patientList.patientList);
    }
  }, [dropdownListValue]);

  useEffect(() => {
    dispatch(dropdownApi());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const pageBody = (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      spacing={2}
    >
      <Grid item xs={4} sm={4} md={4} lg={4}>
        <Typography variant="h6" gutterBottom>
          {t("needhelppage.deschead", { ns: ["home"] })!}
        </Typography>
        <Typography sx={{ color: "#1E90FF" }}>
          {t("needhelppage.desctext", { ns: ["home"] })!}
        </Typography>
      </Grid>
      <Grid item xs={8} sm={8} md={8} lg={8}>
        <Card>
          <CardHeader title="Get call back from Cloud Doctor"></CardHeader>

          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Grid item xs={7} sm={7} md={7} lg={7}>
              <Box p={2}>
                <TextField
                  label={t("needhelppage.patientphnno", { ns: ["home"] })!}
                  value={helpPhoneNumber}
                  placeholder={t("needhelppage.phnnodesc", { ns: ["home"] })!}
                  id="othersignificantillness"
                  onChange={handleChangePhoneNumber}
                  variant="outlined"
                  fullWidth
                />
                <Box>
                  <Typography sx={{ pt: 4 }}>
                    {t("needhelppage.patiename", { ns: ["home"] })!}
                  </Typography>
                  <MWAutocomplete
                    placeHolder="Patient List"
                    listvalue={patientName}
                    selectedItem={selectedPatientName}
                    dropdownValue={patientValueFromChild}
                  />
                  <br />
                </Box>
                <Box>
                  <Typography sx={{ pt: 4 }}>
                    {t("needhelppage.date", { ns: ["home"] })!}
                  </Typography>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        inputFormat="DD/MM/YYYY"
                        minDate={today}
                        value={selectedDate}
                        onChange={(date) => handleDateChange(date!)}
                        renderInput={(params: any) => (
                          <TextField
                            {...params}
                            fullWidth
                            inputProps={{
                              ...params.inputProps,
                              readOnly: true,
                            }}
                            size=""
                          />
                        )}
                        InputAdornmentProps={{ position: "start" }}
                      />
                    </LocalizationProvider>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={5} sm={5} md={5} lg={5}>
              <Box p={2}>
                <TextField
                  label={t("needhelppage.desc", { ns: ["home"] })!}
                  value={textValue}
                  onChange={handleChange}
                  placeholder={t("needhelppage.oppdesc", { ns: ["home"] })!}
                  maxRows={11}
                  minRows={11}
                  fullWidth
                  multiline
                />
              </Box>
            </Grid>
          </Grid>
          <Box style={{ float: "right" }} p={2}>
            <Button variant="contained" onClick={needHelp}>
              {t("needhelppage.submit", { ns: ["home"] })!}
            </Button>
          </Box>
          <Box my={3}>{successMessage}</Box>
          <Box my={3}>{errorHelpDiv}</Box>
        </Card>
      </Grid>
    </Grid>
  );

  return (
    <Box>
      {toSignin ? <Redirect to="/signin" /> : null}
      {loadingMarkup}
      <Container maxWidth="xl" sx={{ pt: 2 }}>
        <Typography variant="h4" gutterBottom>
          {t("needhelppage.needhelp", { ns: ["home"] })!}
        </Typography>
        {pageBody}
      </Container>
    </Box>
  );
}
