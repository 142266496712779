import { GetMeetingDetailsRes } from "./Model";
export const GET_MEETING_DETAILS_LOADING = "GET_MEETING_DETAILS_LOADING";
export const GET_MEETING_DETAILS_SUCCESS = "GET_MEETING_DETAILS_SUCCESS";
export const GET_MEETING_DETAILS_FAIL = "GET_MEETING_DETAILS_FAIL";
export const GET_MEETING_DETAILS_API_MSG = "GET_MEETING_DETAILS_API_MSG";
export const GET_MEETING_DETAILS_API_RES = "GET_MEETING_DETAILS_API_RES";

export interface GetMeetingDetailsState {
  getMeetingDetailsRes: GetMeetingDetailsRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetMeetingDetailsLoading {
  type: typeof GET_MEETING_DETAILS_LOADING;
  loading: boolean;
}
export interface GetMeetingDetailsSuccess {
  type: typeof GET_MEETING_DETAILS_SUCCESS;
  payload: GetMeetingDetailsRes;
  successMsg: string;
}
export interface GetMeetingDetailsFail {
  type: typeof GET_MEETING_DETAILS_FAIL;
  payload: GetMeetingDetailsRes;
  errorMsg: string;
  status: number;
}
export interface GetMeetingDetailsAPIMsg {
  type: typeof GET_MEETING_DETAILS_API_MSG;
  payload: GetMeetingDetailsRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetMeetingDetailsAPIRes {
  type: typeof GET_MEETING_DETAILS_API_RES;
  payload: GetMeetingDetailsRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type GetMeetingDetailsActionTypes =
  | GetMeetingDetailsLoading
  | GetMeetingDetailsSuccess
  | GetMeetingDetailsFail
  | GetMeetingDetailsAPIMsg
  | GetMeetingDetailsAPIRes;
