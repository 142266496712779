import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Alert,
  AlertColor,
  AlertTitle,
  Autocomplete,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Stack,
  TextField,
} from "@mui/material";
import {
  DoctorsList,
  GetDoctorListByCategory,
} from "../../../../redux/AppointmentSelectDoctor/DoctorListByCategory/Model";
import { getDoctorListByCategory } from "../../../../redux/AppointmentSelectDoctor/DoctorListByCategory/API";
import { AddDoctorForCaseBody } from "../../../../redux/AppointmentSelectDoctor/AddDoctorForCase/Model";
import {
  addDoctorForCase,
} from "../../../../redux/AppointmentSelectDoctor/AddDoctorForCase/API";
import { AppState } from "../../../../redux/store/Store";
import { getDoctorCategoryList } from "../../../../redux/Doctor/DoctorCategoryList/API";
import { t } from "i18next";
import { getDoctorListForCase } from "../../../../redux/AppointmentSelectDoctor/DoctorListForCase/ApiCall";//redux/AppointmentSelectDoctor/DoctorListForCase/ApiCall";
import { GetDoctorTeamTypeForCaseBody } from "../../../../redux/AppointmentSelectDoctor/DoctorTeamTypeForCase/Model";

export default function SelectDoctorForCase() {
  const dispatch = useDispatch();

  const { caseid } = useParams() as {
    caseid: string;
  };

  const [disabledDoctorList, setDisabledDoctorList] = useState(true);

  const [categoryValues, setCategoryValues] = useState<any[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<any>(null);
  const handleSelectedCategoryChange = (_e: any, dropdownDayName: any) => {
    setSelectedCategory(dropdownDayName);
    if (dropdownDayName === null) {
      setDisabledDoctorList(true);
    } else {
      const body = {
        doctorCategoryId: String(dropdownDayName?.value!),
      } as GetDoctorListByCategory;
      dispatch(getDoctorListByCategory(body));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const [doctorList, setDoctorList] = useState<any[]>([]);
  const [selectedDocName, setSelectedDocName] = useState<any | null>(null);
  const handleDocNameChange = (_e: any, dropdownDayName: any) => {
    setSelectedDocName(dropdownDayName);
  };

  // Doctor Add api body
  const doctorAddBody = {
    caseId: caseid,
    doctorId: String(selectedDocName?.value!),
  } as AddDoctorForCaseBody;

  // Add Doctor in Patient case API
  const handleAddDoctor = () => {
    dispatch(addDoctorForCase(doctorAddBody));
  };
  const addDoctorForCaseRes = useSelector(
    (state: AppState) => state.addDoctorForCaseRes
  );

  const [addDoctorForCaseMsg, setAddDoctorForCaseMsg] = useState("");
  const [addDoctorForCaseMsgType, setAddDoctorForCaseMsgType] =
    useState<AlertColor>("success");
  useEffect(() => {
    if (addDoctorForCaseRes?.successMsg !== "") {
      setAddDoctorForCaseMsg(addDoctorForCaseRes?.successMsg);
      setAddDoctorForCaseMsgType("success");
      dispatch(
        getDoctorListForCase({ caseId: caseid } as GetDoctorTeamTypeForCaseBody)
      );
    }
    if (addDoctorForCaseRes?.errorMsg !== "") {
      setAddDoctorForCaseMsg(addDoctorForCaseRes?.errorMsg);
      setAddDoctorForCaseMsgType("error");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addDoctorForCaseRes]);

  useEffect(() => {
    dispatch(getDoctorCategoryList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getDoctorCategoryListRes = useSelector(
    (state: AppState) => state.getDoctorCategoryListRes
  );
  useEffect(() => {
    if (
      getDoctorCategoryListRes?.getDoctorCategoryListRes?.doctorCategoryList !==
      undefined
    ) {
      setCategoryValues(
        getDoctorCategoryListRes?.getDoctorCategoryListRes?.doctorCategoryList
      );
    } else {
      setCategoryValues([] as any[]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getDoctorCategoryListRes]);

  const closeAlertSelectDoctorForCase = () => {
    setAddDoctorForCaseMsg("");
  };

  const getDoctorListByCategoryRes = useSelector(
    (state: AppState) => state.getDoctorListByCategoryRes
  );
  useEffect(() => {
    if (
      getDoctorListByCategoryRes?.getDoctorListByCategoryRes?.doctorsList !==
      undefined
    ) {
      setDisabledDoctorList(false);
      if (
        getDoctorListByCategoryRes?.getDoctorListByCategoryRes?.doctorsList
          .length > 0
      ) {
        let doctorsListItem =
          getDoctorListByCategoryRes?.getDoctorListByCategoryRes?.doctorsList.map(
            (element: DoctorsList) => {
              return {
                value: element.id,
                label: element.fullName,
              };
            }
          );
        setDoctorList(doctorsListItem);
      } else setDoctorList([] as any[]);
    } else setDoctorList([] as any[]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getDoctorListByCategoryRes]);

  return (
    <Card>
      <CardHeader title={t("selDocPage.selDoc", { ns: ["home"] })} />
      <CardContent>
        <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="stretch"
          spacing={2}
        >
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
              <Box style={{ marginBottom: "5px" }}>
                {t("finddoc.category", { ns: ["home"] })}
              </Box>
              <Autocomplete
                value={selectedCategory}
                id="templateId"
                options={categoryValues}
                onChange={(event: any, newValue: any) => {
                  handleSelectedCategoryChange(event, newValue);
                }}
                disabled={
                  getDoctorListByCategoryRes?.loading ||
                  addDoctorForCaseRes?.loading
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder={t("finddoc.selCat", { ns: ["home"] })!}
                    size="small"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                spacing={2}
              >
                <Grid item xs={12} sm={10} md={10} lg={10} xl={10}>
                  <Box style={{ marginBottom: "5px" }}>
                    {t("selDocPage.selDoc", { ns: ["home"] })!}
                  </Box>
                  <Autocomplete
                    value={selectedDocName}
                    id="templateId"
                    options={doctorList}
                    disabled={
                      disabledDoctorList || addDoctorForCaseRes?.loading
                    }
                    onChange={(event: any, newValue: any) => {
                      handleDocNameChange(event, newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder={t("selDocPage.selDoc", { ns: ["home"] })!}
                        size="small"
                      />
                    )}
                  />
                </Grid>
                <Grid item>
                  <Box pt={4}>
                    <Button
                      variant="outlined"
                      size="medium"
                      onClick={() => handleAddDoctor()}
                      disabled={
                        (selectedCategory !== null && selectedDocName !== null
                          ? false
                          : true) || addDoctorForCaseRes?.loading
                      }
                      sx={{ textTransform: "none" }}
                    >
                      {t("common.add", { ns: ["home"] })}
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {addDoctorForCaseMsg !== "" ? (
            <Alert
              severity={addDoctorForCaseMsgType}
              onClose={() => {
                closeAlertSelectDoctorForCase();
              }}
            >
              <AlertTitle>{addDoctorForCaseMsg}</AlertTitle>
            </Alert>
          ) : null}
        </Stack>
      </CardContent>
    </Card>
  );
}
