import axios from "axios";
import { Dispatch } from "redux";
import { AddPatientImportConfigDataActionTypes } from "./ActionTypes";
import {
  AddPatientImportConfigDetailsBody,
  AddPatientImportConfigDataRes,
} from "./Model";
import {
  addAddPatientImportConfigDataAPIResClearAction,
  addAddPatientImportConfigDataErrorAction,
  addAddPatientImportConfigDataLoadingAction,
  addAddPatientImportConfigDataSuccessAction,
  addAddPatientImportConfigDataUpdateAPIMsgAction,
} from "./Actions";
import baseUrl from "../../../../Url";
import { headers } from "../../../../components/Utility";
import { setupInterceptorsTo } from "../../../Authentication/Interceptors";

let apiRes = {} as AddPatientImportConfigDataRes;
export const addPatientImportConfigData = (
  payload: AddPatientImportConfigDetailsBody
) => {
  return function (dispatch: Dispatch<AddPatientImportConfigDataActionTypes>) {
    dispatch(addAddPatientImportConfigDataLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        baseUrl.baseUrl +
          `/patientapp/api/dataimport/add_patient_import_config`,
        payload,
        {
          headers: headers,
        }
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(addAddPatientImportConfigDataLoadingAction(false));
        dispatch(
          addAddPatientImportConfigDataSuccessAction(
            res.data,
            res.data.message !== undefined
              ? res.data.message
              : "Patient data for imported Config has been added successfully"
          )
        );
      })
      .catch((error) => {
        dispatch(addAddPatientImportConfigDataLoadingAction(false));
        dispatch(
          addAddPatientImportConfigDataErrorAction(
            {} as AddPatientImportConfigDataRes,
            error.response !== undefined
              ? error.response.data.message || error.response.data.detail
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0
          )
        );
      });
  };
};

export const updateAddAddPatientImportConfigDataAPIResMsg = () => {
  return function (dispatch: Dispatch<AddPatientImportConfigDataActionTypes>) {
    dispatch(
      addAddPatientImportConfigDataUpdateAPIMsgAction(
        apiRes as AddPatientImportConfigDataRes,
        "",
        "",
        0
      )
    );
  };
};

export const clearAddAddPatientImportConfigDataAPIRes = () => {
  return function (dispatch: Dispatch<AddPatientImportConfigDataActionTypes>) {
    dispatch(
      addAddPatientImportConfigDataAPIResClearAction(
        {} as AddPatientImportConfigDataRes,
        "",
        "",
        0
      )
    );
  };
};
