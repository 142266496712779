import { OpinionTypeListResponse } from "./Model";
export const OPINION_TYPE_LOADING = "OPINION_TYPE_LOADING";
export const OPINION_TYPE_SUCCESS = "OPINION_TYPE_SUCCESS";
export const OPINION_TYPE_FAIL = "OPINION_TYPE_FAIL";
export const OPINION_TYPE_API_MSG = "OPINION_TYPE_API_MSG";
export const OPINION_TYPE_API_RES = "OPINION_TYPE_API_RES";

export interface OpinionTypeState {
  opinionTypeResponse: OpinionTypeListResponse;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface OpinionTypeLoading {
  type: typeof OPINION_TYPE_LOADING;
  loading: boolean;
}
export interface OpinionTypeSuccess {
  type: typeof OPINION_TYPE_SUCCESS;
  payload: OpinionTypeListResponse;
  successMsg: string;
}
export interface OpinionTypeFail {
  type: typeof OPINION_TYPE_FAIL;
  payload: OpinionTypeListResponse;
  errorMsg: string;
  status: number;
}
export interface OpinionTypeAPIMsg {
  type: typeof OPINION_TYPE_API_MSG;
  payload: OpinionTypeListResponse;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface OpinionTypeAPIRes {
  type: typeof OPINION_TYPE_API_RES;
  payload: OpinionTypeListResponse;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type OpinionTypeActionTypes =
  | OpinionTypeLoading
  | OpinionTypeSuccess
  | OpinionTypeFail
  | OpinionTypeAPIMsg
  | OpinionTypeAPIRes;
