import React, { useEffect, useState } from "react";
import { t } from "i18next";
import { NavLink, BrowserRouter as Router } from "react-router-dom";
import { Link } from "react-router-dom";
import {
  AppBar,
  Avatar,
  Box,
  Divider,
  Drawer,
  Grid,
  Button,
  Popover,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  Toolbar,
  Stack,
} from "@mui/material";
import { DrawerList } from "./components/DrawerList";
import ComponentRouter from "./Routes";
import MWLoader from "./components/MWLoader";
import appLogo from "./components/image/logo/appLogo.png";
import MWSelectLang from "./components/MWSelectLang";
import { logOut } from "./redux/logOut/API";
import { useDispatch } from "react-redux";

const drawerWidth = 240;

export default function App() {
  const [isLoading, setIsLoading] = useState(false);
  const loadingMarkup = isLoading ? <MWLoader /> : null;
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const dispatch = useDispatch();
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const Bearer = localStorage.getItem("access");

  let usernameCheck: string;

  const userFirstName = function () {
    return localStorage.getItem("firstname");
  };

  let firstName: string = userFirstName()!;

  const userLastName = function () {
    return localStorage.getItem("lastname");
  };

  let lastName: string = userLastName()!;

  if (firstName === "") {
    usernameCheck = "Unknown";
  } else {
    usernameCheck = firstName + " " + lastName;
  }

  const userEmailCheck = function () {
    return localStorage.getItem("email");
  };
  let userEmail: string = userEmailCheck()?.toLowerCase()!;

  const handleLogout = () => {
    setIsLoading(true);
    dispatch(logOut());
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Box sx={{ display: "flex" }}>
      <Router>
        {Bearer ? (
          <AppBar
            position="fixed"
            sx={{
              zIndex: (theme) => theme.zIndex.drawer + 1,
              backgroundColor: "#ffffff",
            }}
          >
            <Toolbar>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                px={1}
              >
                <Grid item>
                  <Link href="/" to={""}>
                    <Box
                      component="img"
                      sx={{
                        height: 40,
                      }}
                      alt="medicalWISDOM logo"
                      src={appLogo}
                    />
                  </Link>
                </Grid>
                <Grid item>
                  <MWSelectLang isMenu />
                  <Button
                    size="small"
                    aria-describedby={id}
                    onClick={handleClick}
                  >
                    <Grid
                      container
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                      spacing={0.5}
                    >
                      <Grid item>
                        <Avatar />
                      </Grid>
                      <Grid item>
                        <Grid
                          container
                          direction="column"
                          alignItems="flex-start"
                          justifyContent="flex-start"
                          spacing={0}
                        >
                          <Grid item>
                            <Typography
                              sx={{ textTransform: "capitalize" }}
                              variant="subtitle2"
                              noWrap
                            >
                              <b>{usernameCheck}</b>
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography
                              noWrap
                              variant="caption"
                              style={{
                                textTransform: "lowercase",
                              }}
                            >
                              {userEmail}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Button>
                  <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                  >
                    <List dense>
                      <ListItem disablePadding>
                        <ListItemButton
                          component={Link}
                          to="/userprofile"
                          style={{
                            textDecoration: "none",
                          }}
                          onClick={() => handleClose()}
                        >
                          <ListItemText>
                            {t("menu.profile", { ns: ["home"] })}
                          </ListItemText>
                        </ListItemButton>
                      </ListItem>
                    </List>
                  </Popover>
                </Grid>
              </Grid>
            </Toolbar>
          </AppBar>
        ) : null}

        {Bearer ? (
          <Drawer
            variant="permanent"
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              [`& .MuiDrawer-paper`]: {
                width: drawerWidth,
                boxSizing: "border-box",
              },
            }}
          >
            <Stack
              direction="column"
              justifyContent="space-between"
              alignItems="flex-start"
              spacing={2}
              py={1}
              sx={{
                overflow: "auto",
                display: "flex",
                flexDirection: "column",
                height: "100%",
              }}
            >
              <Stack
                direction="column"
                justifyContent="flex-start"
                alignItems="stretch"
                style={{
                  width: "100%",
                }}
              >
                <Toolbar />
                <List
                  style={{
                    width: "100%",
                  }}
                  dense
                >
                  {DrawerList.slice(0, 1).map((item) => {
                    return (
                      <ListItem
                        component={NavLink}
                        activeClassName="Mui-selected"
                        to={item.path}
                        key={item.name}
                        disablePadding
                      >
                        <ListItemButton sx={{ py: 0, minHeight: 32 }}>
                          <ListItemIcon sx={{ minWidth: 40 }}>
                            {item.icon}
                          </ListItemIcon>
                          <ListItemText
                            primary={t(`menu.${item.name}`, { ns: ["home"] })!}
                            primaryTypographyProps={{
                              fontSize: 14,
                              fontWeight: "medium",
                            }}
                          ></ListItemText>
                        </ListItemButton>
                      </ListItem>
                    );
                  })}
                  <Divider sx={{ bgcolor: "#6faeff" }} />
                  {DrawerList.slice(1, 5).map((item) => {
                    return (
                      <ListItem
                        component={NavLink}
                        activeClassName="Mui-selected"
                        to={item.path}
                        key={item.name}
                        disablePadding
                      >
                        <ListItemButton sx={{ py: 0, minHeight: 32 }}>
                          <ListItemIcon sx={{ minWidth: 40 }}>
                            {item.icon}
                          </ListItemIcon>
                          <ListItemText
                            primary={t(`menu.${item.name}`, { ns: ["home"] })!}
                            primaryTypographyProps={{
                              fontSize: 14,
                              fontWeight: "medium",
                            }}
                          ></ListItemText>
                        </ListItemButton>
                      </ListItem>
                    );
                  })}
                </List>
              </Stack>
              <Box
                style={{
                  textDecoration: "none",
                  width: "100%",
                }}
              >
                <Divider sx={{ bgcolor: "#6faeff" }} />
                <List
                  dense
                  style={{
                    width: "100%",
                  }}
                >
                  {DrawerList.slice(-2, -1).map((item) => {
                    return (
                      <ListItem
                        component={NavLink}
                        activeClassName="Mui-selected"
                        to={item.path}
                        key={item.name}
                        disablePadding
                      >
                        <ListItemButton sx={{ py: 0, minHeight: 32 }}>
                          <ListItemIcon sx={{ minWidth: 40 }}>
                            {item.icon}
                          </ListItemIcon>
                          <ListItemText
                            primary={t(`menu.${item.name}`, { ns: ["home"] })!}
                            primaryTypographyProps={{
                              fontSize: 14,
                              fontWeight: "medium",
                            }}
                          ></ListItemText>
                        </ListItemButton>
                      </ListItem>
                    );
                  })}
                  {DrawerList.slice(-1).map((item) => {
                    return (
                      <ListItem key={item.name} disablePadding>
                        <ListItemButton
                          onClick={() => handleLogout()}
                          sx={{ py: 0, minHeight: 32 }}
                        >
                          <ListItemIcon sx={{ minWidth: 40 }}>
                            {item.icon}
                          </ListItemIcon>
                          <ListItemText
                            primary={t(`menu.${item.name}`, { ns: ["home"] })!}
                            primaryTypographyProps={{
                              fontSize: 14,
                              fontWeight: "medium",
                            }}
                          ></ListItemText>
                        </ListItemButton>
                      </ListItem>
                    );
                  })}
                </List>
              </Box>
            </Stack>
          </Drawer>
        ) : null}
        <Box component="main" sx={{ flexGrow: 1 }}>
          {Bearer ? <Toolbar /> : null}
          {Bearer ? (
            <>
              {loadingMarkup}
              <ComponentRouter />
            </>
          ) : (
            <ComponentRouter />
          )}
        </Box>
      </Router>
    </Box>
  );
}
