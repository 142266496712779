import { DoctorDetailsResponse } from "./Model";
export const DOCTOR_DETAILS_LOADING = "DOCTOR_DETAILS_LOADING";
export const DOCTOR_DETAILS_SUCCESS = "DOCTOR_DETAILS_SUCCESS";
export const DOCTOR_DETAILS_FAIL = "DOCTOR_DETAILS_FAIL";
export const DOCTOR_DETAILS_API_MSG = "DOCTOR_DETAILS_API_MSG";
export const DOCTOR_DETAILS_API_RES = "DOCTOR_DETAILS_API_RES";

export interface DoctorDetailsState {
  doctorDetailsResponse: DoctorDetailsResponse;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface DoctorDetailsLoading {
  type: typeof DOCTOR_DETAILS_LOADING;
  loading: boolean;
}
export interface DoctorDetailsSuccess {
  type: typeof DOCTOR_DETAILS_SUCCESS;
  payload: DoctorDetailsResponse;
  successMsg: string;
}
export interface DoctorDetailsFail {
  type: typeof DOCTOR_DETAILS_FAIL;
  payload: DoctorDetailsResponse;
  errorMsg: string;
  status: number;
}
export interface DoctorDetailsAPIMsg {
  type: typeof DOCTOR_DETAILS_API_MSG;
  payload: DoctorDetailsResponse;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface DoctorDetailsAPIRes {
  type: typeof DOCTOR_DETAILS_API_RES;
  payload: DoctorDetailsResponse;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type DoctorDetailsActionTypes =
  | DoctorDetailsLoading
  | DoctorDetailsSuccess
  | DoctorDetailsFail
  | DoctorDetailsAPIMsg
  | DoctorDetailsAPIRes;
