import { Box, Button, Chip, Grid, IconButton, Typography } from "@mui/material";
import * as React from "react";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import RefreshIcon from '@mui/icons-material/Refresh';
import { useHistory } from "react-router-dom";

type Props = {
  backButton?: boolean | any;
  title?: string | any;
  enableSubtitle?: boolean | any;
  subtitle?: string | any;
  enableCount?: boolean | any;
  count?: string | any;
  reload?: boolean | any;
  reloadAction?: boolean | any;
};

export default function MWPageTitle({
  backButton,
  title,
  enableSubtitle,
  subtitle,
  enableCount,
  count,
  reload,
  reloadAction,
}: Props) {
  const history = useHistory();
  return (
    <Box>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={2}
        pt={2}
      >
        {backButton ? (
          <Grid item>
            <Button variant="outlined" onClick={() => history.goBack()}>
              <ArrowBackOutlinedIcon />
            </Button>
          </Grid>
        ) : null}
        <Grid item>
          {title !== undefined ? (
            <Typography variant="h5" fontWeight="bold">
              {title !== "" ? title : null}
            </Typography>
          ) : null}
          {enableSubtitle ? (
            <Typography variant="subtitle1"  sx={{ color: "#1E90FF" }}>
              {subtitle !== "" ? subtitle : null}
            </Typography>
          ) : null}
        </Grid>
        <Grid item>
          {enableCount ? (
            <Chip
              label={count !== undefined ? String(count) : "0"}
              color="primary"
              size="small"
            />
          ) : null}
        </Grid>
        {reload ? (
          <Grid item>
            <IconButton onClick={() => reloadAction(true)}>
              <RefreshIcon />
            </IconButton>
          </Grid>
        ) : null}
      </Grid>
    </Box>
  );
}
