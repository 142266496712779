import { GetQuestionListRes } from "./Model";
export const GET_QUESTION_LIST_LOADING = "GET_QUESTION_LIST_LOADING";
export const GET_QUESTION_LIST_SUCCESS = "GET_QUESTION_LIST_SUCCESS";
export const GET_QUESTION_LIST_FAIL = "GET_QUESTION_LIST_FAIL";
export const GET_QUESTION_LIST_API_MSG = "GET_QUESTION_LIST_API_MSG";
export const GET_QUESTION_LIST_API_RES = "GET_QUESTION_LIST_API_RES";

export interface GetQuestionListState {
  getQuestionListRes: GetQuestionListRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetQuestionListLoading {
  type: typeof GET_QUESTION_LIST_LOADING;
  loading: boolean;
}
export interface GetQuestionListSuccess {
  type: typeof GET_QUESTION_LIST_SUCCESS;
  payload: GetQuestionListRes;
  successMsg: string;
}
export interface GetQuestionListFail {
  type: typeof GET_QUESTION_LIST_FAIL;
  payload: GetQuestionListRes;
  errorMsg: string;
  status: number;
}
export interface GetQuestionListAPIMsg {
  type: typeof GET_QUESTION_LIST_API_MSG;
  payload: GetQuestionListRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetQuestionListAPIRes {
  type: typeof GET_QUESTION_LIST_API_RES;
  payload: GetQuestionListRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type GetQuestionListActionTypes =
  | GetQuestionListLoading
  | GetQuestionListSuccess
  | GetQuestionListFail
  | GetQuestionListAPIMsg
  | GetQuestionListAPIRes;
