import {
  GET_STEPS_DETAILS_FAIL,
  GET_STEPS_DETAILS_LOADING,
  GET_STEPS_DETAILS_SUCCESS,
  GET_STEPS_DETAILS_API_MSG,
  GET_STEPS_DETAILS_API_RES,
  GetStepsDetailsActionTypes,
} from "./ActionTypes";
import { GetStepsDetailsRes } from "./Model";

export const getStepsDetailsLoadingAction = (
  loading: boolean
): GetStepsDetailsActionTypes => {
  return {
    type: GET_STEPS_DETAILS_LOADING,
    loading: loading,
  };
};

export const getStepsDetailsSuccessAction = (
  getStepsDetailsResponse: GetStepsDetailsRes,
  successMsg: string
): GetStepsDetailsActionTypes => {
  return {
    type: GET_STEPS_DETAILS_SUCCESS,
    payload: getStepsDetailsResponse,
    successMsg: successMsg,
  };
};

export const getStepsDetailsErrorAction = (
  getStepsDetailsResponse: GetStepsDetailsRes,
  errMsg: string,
  status: number
): GetStepsDetailsActionTypes => {
  return {
    type: GET_STEPS_DETAILS_FAIL,
    payload: getStepsDetailsResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const getStepsDetailsAPIMsgAction = (
  getStepsDetailsResponse: GetStepsDetailsRes,
  successMsg: string,
  errMsg: string,
  status: number
): GetStepsDetailsActionTypes => {
  return {
    type: GET_STEPS_DETAILS_API_MSG,
    payload: getStepsDetailsResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const getStepsDetailsAPIResClearAction = (
  getStepsDetailsResponse: GetStepsDetailsRes,
  successMsg: string,
  errMsg: string,
  status: number
): GetStepsDetailsActionTypes => {
  return {
    type: GET_STEPS_DETAILS_API_RES,
    payload: getStepsDetailsResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
