import {
  CREATE_APPOINTMENT_DETAILS_FOR_CASE_FAIL,
  CREATE_APPOINTMENT_DETAILS_FOR_CASE_LOADING,
  CREATE_APPOINTMENT_DETAILS_FOR_CASE_SUCCESS,
  CREATE_APPOINTMENT_DETAILS_FOR_CASE_UPDATE_API_MSG,
  CREATE_APPOINTMENT_DETAILS_FOR_CASE_UPDATE_API_RES,
  CreateAppointmentActionTypes,
} from "./ActionTypes";
import { CreateAppointmentReqRes } from "./Model";

export const createAppointmentLoadingAction = (
  loading: boolean
): CreateAppointmentActionTypes => {
  return {
    type: CREATE_APPOINTMENT_DETAILS_FOR_CASE_LOADING,
    loading: loading,
  };
};

export const createAppointmentSuccessAction = (
  CreateAppointmentResponse: CreateAppointmentReqRes,
  successMsg: string
): CreateAppointmentActionTypes => {
  return {
    type: CREATE_APPOINTMENT_DETAILS_FOR_CASE_SUCCESS,
    payload: CreateAppointmentResponse,
    successMsg: successMsg,
  };
};

export const createAppointmentErrorAction = (
  CreateAppointmentResponse: CreateAppointmentReqRes,
  errMsg: string,
  status: number
): CreateAppointmentActionTypes => {
  return {
    type: CREATE_APPOINTMENT_DETAILS_FOR_CASE_FAIL,
    payload: CreateAppointmentResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const createAppointmentUpdateAPIMsgAction = (
  CreateAppointmentResponse: CreateAppointmentReqRes,
  successMsg: string,
  errMsg: string,
  status: number
): CreateAppointmentActionTypes => {
  return {
    type: CREATE_APPOINTMENT_DETAILS_FOR_CASE_UPDATE_API_MSG,
    payload: CreateAppointmentResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const createAppointmentAPIResClearAction = (
  CreateAppointmentResponse: CreateAppointmentReqRes,
  successMsg: string,
  errMsg: string,
  status: number
): CreateAppointmentActionTypes => {
  return {
    type: CREATE_APPOINTMENT_DETAILS_FOR_CASE_UPDATE_API_RES,
    payload: CreateAppointmentResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
