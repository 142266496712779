import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../Url";
import { CaseDetailsActionTypes } from "./ActionTypes";
import { CaseDetailsBody, PatientCaseDetailsResponse } from "./Model";
import {
  caseDetailsAPIResClearAction,
  caseDetailsErrorAction,
  caseDetailsLoadingAction,
  caseDetailsSuccessAction,
  caseDetailsAPIMsgAction,
} from "./Action";
import { headers } from "../../../components/Utility";
import { setupInterceptorsTo } from "../../Authentication/Interceptors";

let apiRes = {} as PatientCaseDetailsResponse;
export const patientCaseDetails = (payload: CaseDetailsBody) => {
  return function (dispatch: Dispatch<CaseDetailsActionTypes>) {
    dispatch(caseDetailsLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(Url.baseUrl + `/patientapp/api/patient/patient_caseDetails_by_id`, payload, {
        headers: headers,
      })
      .then((res) => {
        apiRes = res.data;
        dispatch(caseDetailsLoadingAction(false));
        dispatch(
          caseDetailsSuccessAction(
            res.data,
            res.data.message !== undefined
              ? res.data.message
              : "Case details fetch successfully."
          )
        );
      })
      .catch((error) => {
        dispatch(caseDetailsLoadingAction(false));
        dispatch(
          caseDetailsErrorAction(
            {} as PatientCaseDetailsResponse,
            error.response.data !== undefined
              ? error.response.data.message !== undefined
                ? error.response.data.message
                : "Something went wrong"
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0
          )
        );
      });
  };
};

export const caseDetailsAPIResMsg = () => {
  return function (dispatch: Dispatch<CaseDetailsActionTypes>) {
    dispatch(caseDetailsAPIMsgAction(apiRes as PatientCaseDetailsResponse, "", "", 0));
  };
};

export const clearCaseDetailsAPIRes = () => {
  return function (dispatch: Dispatch<CaseDetailsActionTypes>) {
    dispatch(caseDetailsAPIResClearAction({} as PatientCaseDetailsResponse, "", "", 0));
  };
};
