import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../redux/store/Store";
import CoPresentIcon from "@mui/icons-material/CoPresent";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import {
  Link,
  Typography,
  IconButton,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Box,
  Button,
  Grid,
  Stack,
  Snackbar,
  Alert,
  AlertColor,
} from "@mui/material";
import {
  requestBookAppointmentApi,
  updateApiResponse,
} from "../../../redux/requestAppointmentByPatient/API";
import { t } from "i18next";
import { getSharedPatientListValue } from "../../../redux/dashboard/sharedList/API";
import { SharedPatientList } from "../../../redux/dashboard/sharedList/Model";
import EmptyPage from "../../../components/EmptyPage";
import ActionDialog from "../Dialog";
import { clearPatientDeleteAPIRes } from "../../../redux/dashboard/deletePatient/API";
import { getPatientListValue } from "../../../redux/dashboard/patientList/API";

export default function SharePatientList(loader: boolean | any) {
  const { sharedPatientListLoader } = loader;
  const history = useHistory();
  const SharedPatientListFromAPI = useSelector(
    (state: AppState) => state.sharedPatientValue
  );
  const requestBookAppointmentResult = useSelector(
    (state: AppState) => state.bookAppointmentRequest
  );
  const deletePatientAPI = useSelector(
    (state: AppState) => state.deletePatientValues
  );
  const [modalName, setModalName] = useState("");
  const [deleteModalState, setDeleteModalState] = useState(false);
  const [patientId, setPatientId] = useState("");
  const [deletePatientMsg, setDeletePatientMsg] = useState("");
  const [deletePatientMsgType, setDeletePatientMsgType] =
    useState<AlertColor>("success");
  const [open, setOpen] = React.useState(false);

  const shareRecord = (
    modal: string,
    dialogState: boolean,
    patientId: string
  ) => {
    setModalName(modal);
    setDeleteModalState(dialogState);
    setPatientId(patientId);
  };

  function deleteRecord(
    modal: string,
    dialogState: boolean,
    patientId: string
  ) {
    setModalName(modal);
    setDeleteModalState(dialogState);
    setPatientId(patientId);
  }

  const updateValueFromChild = (valueFromChild: boolean) => {
    setDeleteModalState(valueFromChild);
  };

  function BookAppointment(patientId: any) {
    dispatch(requestBookAppointmentApi(patientId));
  }

  const truncate = (str: string) => {
    return str.length > 10 ? str.substring(0, 20) + "..." : str;
  };

  const DATE_OPTIONS = {
    year: "numeric",
    month: "short",
    day: "numeric",
  } as const;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSharedPatientListValue());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    sharedPatientListLoader(requestBookAppointmentResult?.loading);
    if (
      requestBookAppointmentResult?.bookAppoinmentRes?.patientId !==
        undefined &&
      requestBookAppointmentResult?.bookAppoinmentRes?.caseId !== undefined &&
      requestBookAppointmentResult?.bookAppoinmentRes?.whyWant2ndOpinion !==
        undefined
    ) {
      history.push(
        `/casedetails/${requestBookAppointmentResult.bookAppoinmentRes.patientId}/${requestBookAppointmentResult.bookAppoinmentRes.caseId}`
      );
      dispatch(updateApiResponse());
    } else if (
      requestBookAppointmentResult?.bookAppoinmentRes?.patientId !==
        undefined &&
      requestBookAppointmentResult?.bookAppoinmentRes?.caseId === undefined
    ) {
      history.push(
        `/casedetails/${requestBookAppointmentResult.bookAppoinmentRes.patientId}`
      );
      dispatch(updateApiResponse());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestBookAppointmentResult]);

  useEffect(() => {
    sharedPatientListLoader(SharedPatientListFromAPI?.loading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [SharedPatientListFromAPI]);

  useEffect(() => {
    sharedPatientListLoader(deletePatientAPI?.loading!);
    if (deletePatientAPI?.successMsg !== "") {
      setDeletePatientMsgType("success");
      setDeletePatientMsg(deletePatientAPI?.successMsg);
      setOpen(true);
    }
    if (deletePatientAPI?.errorMsg !== "") {
      setDeletePatientMsgType("error");
      setDeletePatientMsg(deletePatientAPI?.errorMsg);
      setOpen(true);
    }
    if (deletePatientMsg !== "") {
      dispatch(getPatientListValue());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deletePatientAPI]);

  const actualPageMarkup = (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      spacing={2}
    >
      {SharedPatientListFromAPI?.SharedPatientListResponse?.patientList !==
        undefined &&
      SharedPatientListFromAPI?.SharedPatientListResponse?.patientList.length >
        0
        ? SharedPatientListFromAPI?.SharedPatientListResponse?.patientList.map(
            (patientItem: SharedPatientList, index: number) => (
              <Grid item xs={12} sm={4} lg={4} key={"patient-" + index}>
                <Card>
                  <CardHeader
                    key={"patient-header-" + index}
                    avatar={
                      <img
                        id={`${patientItem.id}`}
                        src={
                          patientItem.profilePicture === null
                            ? "medical.png"
                            : patientItem?.profilePicture?.document
                        }
                        alt=""
                        width="64"
                        height="64"
                        style={{
                          objectFit: "cover",
                          objectPosition: "center",
                          borderRadius: "32px",
                        }}
                        key={"patient-image-" + index}
                      />
                    }
                    action={[
                      <Box key={"patient-action-" + index}>
                        <Link
                          onClick={() =>
                            shareRecord("shared", true, String(patientItem.id))
                          }
                        >
                          <IconButton>
                            <CoPresentIcon />
                          </IconButton>
                        </Link>
                        <Link
                          ml={1}
                          onClick={() =>
                            deleteRecord("delete", true, String(patientItem.id))
                          }
                        >
                          <IconButton color="error">
                            <PersonRemoveIcon />
                          </IconButton>
                        </Link>
                      </Box>,
                    ]}
                    title={
                      <Typography
                        variant="h6"
                        fontWeight="bold"
                        key={"patient-title-" + index}
                      >
                        {patientItem.firstname} {patientItem.lastname}
                      </Typography>
                    }
                    subheader={
                      <Typography
                        variant="subtitle1"
                        key={"patient-sub-title" + index}
                      >
                        {patientItem.patientRelationshipWithOwner?.displayValue}
                      </Typography>
                    }
                  />
                  <CardContent>
                    <Typography variant="body1" align="left">
                      {patientItem.description
                        ? truncate(patientItem.description)
                        : "Not Set"}
                    </Typography>
                    <Box my={2}>
                      <Divider />
                    </Box>
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-between"
                      alignItems="flex-start"
                    >
                      <Grid item>
                        <Stack
                          direction="column"
                          justifyContent="space-between"
                          alignItems="center"
                          spacing={2}
                        >
                          <Typography>
                            {t("perHealthRecPage.gen", { ns: ["home"] })!}
                          </Typography>
                          <Typography fontWeight="bold">
                            {patientItem.gender?.label}
                          </Typography>
                        </Stack>
                      </Grid>
                      <Divider orientation="vertical" flexItem />
                      <Grid item>
                        <Stack
                          direction="column"
                          justifyContent="space-between"
                          alignItems="center"
                          spacing={2}
                        >
                          <Typography>
                            {
                              t("addpatientpage.bloodgroup", {
                                ns: ["home"],
                              })!
                            }
                          </Typography>
                          <Typography fontWeight="bold">
                            {patientItem.bloodGroup === null
                              ? "Not Known"
                              : patientItem.bloodGroup.label}
                          </Typography>
                        </Stack>
                      </Grid>
                      <Divider orientation="vertical" flexItem />
                      <Grid item>
                        <Stack
                          direction="column"
                          justifyContent="space-between"
                          alignItems="center"
                          spacing={2}
                        >
                          <Typography>
                            {t("perHealthRecPage.patDob", {
                              ns: ["home"],
                            })}
                          </Typography>
                          <Typography fontWeight="bold">
                            {new Date(patientItem.dob).toLocaleDateString(
                              "en-US",
                              DATE_OPTIONS
                            )}
                          </Typography>
                        </Stack>
                      </Grid>
                    </Grid>
                    <Box my={2}>
                      <Divider orientation="horizontal" flexItem />
                    </Box>
                  </CardContent>
                  <CardActions>
                    <Grid
                      container
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      spacing={2}
                    >
                      <Grid item>
                        <Link href={`/casehistory/${patientItem.id}`}>
                          <Button variant="contained" size="medium">
                            {t("perHealthRecPage.caseDet", {
                              ns: ["home"],
                            })}
                          </Button>
                        </Link>
                      </Grid>
                      <Grid item>
                        <Link>
                          <Button
                            variant="outlined"
                            size="medium"
                            onClick={() => BookAppointment(patientItem.id)}
                          >
                            {t("finddoc.bookAppo", { ns: ["home"] })}
                          </Button>
                        </Link>
                      </Grid>
                    </Grid>
                  </CardActions>
                </Card>
              </Grid>
            )
          )
        : null}
    </Grid>
  );

  return (
    <>
      {SharedPatientListFromAPI?.SharedPatientListResponse?.patientList !==
      undefined ? (
        SharedPatientListFromAPI?.SharedPatientListResponse?.patientList
          .length === 0 ? (
          <EmptyPage />
        ) : (
          actualPageMarkup
        )
      ) : null}
      <ActionDialog
        modalName={modalName}
        dialogState={deleteModalState}
        patientId={patientId}
        dialogChildState={updateValueFromChild}
      />
      {deletePatientMsg !== "" ? (
        <Snackbar
          open={open}
          autoHideDuration={2000}
          onClose={() => {
            dispatch(clearPatientDeleteAPIRes());
            setDeletePatientMsg("");
          }}
        >
          <Alert
            onClose={() => {
              dispatch(clearPatientDeleteAPIRes());
              setDeletePatientMsg("");
            }}
            severity={deletePatientMsgType}
            sx={{ width: "100%" }}
            variant="filled"
          >
            {deletePatientMsg}
          </Alert>
        </Snackbar>
      ) : null}
    </>
  );
}
