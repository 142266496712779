import {
  CASE_LIST_FAIL,
  CASE_LIST_LOADING,
  CASE_LIST_SUCCESS,
  CASE_LIST_UPDATE_API_MSG,
  CASE_LIST_UPDATE_API_RES,
  CaseListActionTypes,
} from "./ActionTypes";
import { CaseListResponse } from "./Model";

export const caseListLoadingAction = (
  loading: boolean
): CaseListActionTypes => {
  return {
    type: CASE_LIST_LOADING,
    loading: loading,
  };
};

export const caseListSuccessAction = (
  caseListResponse: CaseListResponse,
  successMsg: string
): CaseListActionTypes => {
  return {
    type: CASE_LIST_SUCCESS,
    payload: caseListResponse,
    successMsg: successMsg,
  };
};

export const caseListErrorAction = (
  caseListResponse: CaseListResponse,
  errMsg: string,
  status: number
): CaseListActionTypes => {
  return {
    type: CASE_LIST_FAIL,
    payload: caseListResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const caseListUpdateAPIMsgAction = (
  caseListResponse: CaseListResponse,
  successMsg: string,
  errMsg: string,
  status: number
): CaseListActionTypes => {
  return {
    type: CASE_LIST_UPDATE_API_MSG,
    payload: caseListResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const caseListAPIResClearAction = (
  caseListResponse: CaseListResponse,
  successMsg: string,
  errMsg: string,
  status: number
): CaseListActionTypes => {
  return {
    type: CASE_LIST_UPDATE_API_RES,
    payload: caseListResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
