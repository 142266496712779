import { EditPatientInsuranceRes } from "./Model";
export const EDIT_PATIENT_INSURANCE_LOADING = "EDIT_PATIENT_INSURANCE_LOADING";
export const EDIT_PATIENT_INSURANCE_SUCCESS = "EDIT_PATIENT_INSURANCE_SUCCESS";
export const EDIT_PATIENT_INSURANCE_FAIL = "EDIT_PATIENT_INSURANCE_FAIL";
export const EDIT_PATIENT_INSURANCE_API_MSG = "EDIT_PATIENT_INSURANCE_API_MSG";
export const EDIT_PATIENT_INSURANCE_API_RES = "EDIT_PATIENT_INSURANCE_API_RES";

export interface EditPatientInsuranceState {
  editPatientInsuranceRes: EditPatientInsuranceRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface EditPatientInsuranceLoading {
  type: typeof EDIT_PATIENT_INSURANCE_LOADING;
  loading: boolean;
}
export interface EditPatientInsuranceSuccess {
  type: typeof EDIT_PATIENT_INSURANCE_SUCCESS;
  payload: EditPatientInsuranceRes;
  successMsg: string;
}
export interface EditPatientInsuranceFail {
  type: typeof EDIT_PATIENT_INSURANCE_FAIL;
  payload: EditPatientInsuranceRes;
  errorMsg: string;
  status: number;
}
export interface EditPatientInsuranceAPIMsg {
  type: typeof EDIT_PATIENT_INSURANCE_API_MSG;
  payload: EditPatientInsuranceRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface EditPatientInsuranceAPIRes {
  type: typeof EDIT_PATIENT_INSURANCE_API_RES;
  payload: EditPatientInsuranceRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type EditPatientInsuranceActionTypes =
  | EditPatientInsuranceLoading
  | EditPatientInsuranceSuccess
  | EditPatientInsuranceFail
  | EditPatientInsuranceAPIMsg
  | EditPatientInsuranceAPIRes;
