import React, { useState, useCallback, useEffect } from "react";
import {
  Box,
  InputAdornment,
  IconButton,
  OutlinedInput,
  FormHelperText,
  Grid,
  Alert,
  Button,
  FormControl,
  Typography,
  TextField,
  InputLabel,
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
  AlertColor,
  Snackbar,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../redux/store/Store";
import {
  clearForgotPasswordAPIRes,
  forgotPasswordApi,
} from "../../redux/forgotPassword/API";
import { forgotPasswordBody } from "../../redux/forgotPassword/Model";
import MWLoader from "../../components/MWLoader";
import {
  clearLoginAPIRes,
  jwtTokenApiCall,
} from "../../redux/jwtPatientLogIn/API";
import { JwtTokenAuthBody } from "../../redux/jwtPatientLogIn/Model";
import {
  clearGetPatientDetailsAPIRes,
  patientLoginDetailsApiCall,
} from "../../redux/jwtPatientLogIn/patientLoginDetails/API";
import { LoginBody } from "../../redux/jwtPatientLogIn/patientLoginDetails/Model";
import { t } from "i18next";

export default function Loginwithpasswordcomponent() {
  const dispatch = useDispatch();
  const apiAuthToken = useSelector((state: AppState) => state.apiAuthToken);
  const forgotPassword = useSelector((state: AppState) => state.forgotPassword);
  const getPatientLoginDetailsValue = useSelector(
    (state: AppState) => state.getPatientLoginDetailsRes
  );
  const [email, setEmail] = useState("");
  const [errormail, setErrorMail] = useState("");
  const [errorpassword, setErrorPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [apiMsgType, setAPIMsgType] = useState<AlertColor>("success");
  const [error, setError] = useState("");
  const [modalActive, setModalActive] = useState(false);
  const [supportEmail, setSupportEmail] = useState("");

  const [forgotpasswordRes, setForgotpasswordRes] = useState("");
  const [open, setOpen] = React.useState(false);
  const [deletePatientMsgType, setDeletePatientMsgType] =
    useState<AlertColor>("success");
  const [errorsupportemail, setErrorsupportemail] = useState("");
  const errorSupportemail = errorsupportemail ? (
    <Typography>{errorsupportemail}</Typography>
  ) : (
    ""
  );

  const forgotPasswordemailRes = forgotpasswordRes ? (
    <Snackbar
      open={open}
      autoHideDuration={6000}
      onClose={() => {
        dispatch(clearForgotPasswordAPIRes());
        setErrorsupportemail("");
      }}
    >
      <Alert
        onClose={() => {
          dispatch(clearForgotPasswordAPIRes());
          setErrorsupportemail("");
        }}
        severity={deletePatientMsgType}
        sx={{ width: "100%" }}
        variant="filled"
      >
        {forgotpasswordRes}
      </Alert>
    </Snackbar>
  ) : (
    ""
  );

  // new code
  interface State {
    password: string;
    showPassword: boolean;
  }

  const [password, setPass] = React.useState<State>({
    password: "",
    showPassword: false,
  });
  const keyDownEvent = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      handleLogin();
    }
  };
  const handleChange =
    (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setPass({ ...password, [prop]: event.target.value });
    };

  const handleClickShowPassword = () => {
    setPass({ ...password, showPassword: !password.showPassword });
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const errorEmail = errormail ? <Typography>{errormail}</Typography> : "";

  const errorPassword = errorpassword ? (
    <Typography>{errorpassword}</Typography>
  ) : (
    ""
  );

  const loadingMarkup = isLoading ? <MWLoader /> : null;

  const body = {
    username: email.toLowerCase(),
    password: password.password,
  } as JwtTokenAuthBody;

  useEffect(() => {
    apiAuthToken?.loading ? setIsLoading(true) : setIsLoading(false);
  }, [apiAuthToken]);

  //handle button click of login form Tab-1
  const handleLogin = () => {
    if (email === "" && password.password === "") {
      setErrorMail(`${t("loginpage.enteremailorphno", { ns: ["home"] })}`);
      setErrorPassword(`${t("loginpage.plsenterpass", { ns: ["home"] })}`);
      return;
    } else if (email === "") {
      setErrorMail(`${t("loginpage.enteremailorphno", { ns: ["home"] })}`);
      setErrorPassword("");
      return;
    } else if (password.password === "") {
      setErrorMail("");
      setErrorPassword(`${t("loginpage.plsenterpass", { ns: ["home"] })}`);
      return;
    } else if (
      new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(
        email.toLowerCase()
      ) === false &&
      new RegExp(/^[0]?[6789]\d{9}$/).test(email.toLowerCase()) === false
    ) {
      setErrorMail(`${t("loginpage.entrvalidenorpass", { ns: ["home"] })}`);
    } else {
      dispatch(jwtTokenApiCall(body));
    }
  };

  useEffect(() => {
    if (apiAuthToken?.loginRes?.access !== undefined) {
      dispatch(
        patientLoginDetailsApiCall({
          access: apiAuthToken?.loginRes?.access,
        } as LoginBody)
      );
    }
    if (apiAuthToken?.errorMsg !== "") {
      setAPIMsgType("error");
      setError(apiAuthToken?.errorMsg);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiAuthToken]);

  useEffect(() => {
    if (
      getPatientLoginDetailsValue?.getPatientLoginDetailsRes?.user !== undefined
    ) {
      window.location.reload();
    }
    if (getPatientLoginDetailsValue?.errorMsg !== "") {
      setError(getPatientLoginDetailsValue?.errorMsg);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getPatientLoginDetailsValue]);

  // ******* forgot password section modal **********

  const forgetPwd = useCallback(
    () => setModalActive((modalActive) => !modalActive),
    []
  );

  const supportEmailBody = {
    email: supportEmail,
  } as forgotPasswordBody;

  const forgetPasswordApicall = () => {
    if (supportEmail === "") {
      setErrorsupportemail(
        `${t("loginpage.entrvalidenorpass", { ns: ["home"] })}`
      );
    } else if (
      new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(
        supportEmail
      ) === false
    ) {
      setErrorsupportemail(`${t("loginpage.invalidem", { ns: ["home"] })}`);
    } else {
      dispatch(forgotPasswordApi(supportEmailBody));
    }
  };

  useEffect(() => {
    if (forgotPassword?.ForgotPasswordRes?.message !== undefined) {
      setDeletePatientMsgType("success");
      setOpen(true);
      setForgotpasswordRes(forgotPassword?.ForgotPasswordRes?.message);
      setModalActive((modalActive) => !modalActive);
    } else {
      setDeletePatientMsgType("error");
      setOpen(true);
      setForgotpasswordRes(forgotPassword?.errorMsg);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forgotPassword]);

  const forgetPwdPopup = () => {
    setSupportEmail("");
    setForgotpasswordRes("");
    setModalActive((modalActive) => !modalActive);
  };
  const modalMarkup = (
    <Dialog open={modalActive} onClose={forgetPwdPopup}>
      <DialogTitle>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Grid item>
            <Typography variant="h6">
              {t("loginpage.forgotpassword", { ns: ["home"] })}
            </Typography>
          </Grid>
          <Grid item>
            <IconButton
              aria-label="close"
              onClick={forgetPwdPopup}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t("loginpage.passResetLink", { ns: ["home"] })}
        </DialogContentText>
        <TextField
          sx={{ my: 2 }}
          autoFocus
          margin="dense"
          size="medium"
          fullWidth
          label={t("loginpage.emailorphno", { ns: ["home"] })}
          value={supportEmail}
          id="supportemail"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setSupportEmail(event.target.value);
            setErrorsupportemail("");
          }}
          helperText={
            errorSupportemail === ""
              ? `${t("loginpage.provEmailOrPhn", { ns: ["home"] })}`
              : errorSupportemail
          }
          error={errorSupportemail === "" ? false : true}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={forgetPwdPopup}>
          {t("common.cancel", { ns: ["home"] })}
        </Button>
        <Button variant="contained" onClick={forgetPasswordApicall}>
          {t("loginpage.send", { ns: ["home"] })}
        </Button>
      </DialogActions>
    </Dialog>
  );

  const actualPageMarkup = (
    <Box onKeyDown={keyDownEvent}>
      {loadingMarkup}
      <TextField
        fullWidth
        sx={{ my: 2 }}
        size="medium"
        value={email}
        error={errorEmail === "" ? false : true}
        label={t("loginpage.emailorphno", { ns: ["home"] })}
        placeholder={t("loginpage.enterregemail", { ns: ["home"] })!}
        id="emailFieldID"
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setEmail(event.target.value);
          setErrorMail("");
        }}
        helperText={
          errorEmail === ""
            ? `${t("loginpage.provEmailOrPhn", { ns: ["home"] })}`
            : errorEmail
        }
        InputLabelProps={{ shrink: true }}  
      />
      <FormControl fullWidth variant="outlined">
        <InputLabel size="normal" htmlFor="outlined-adornment-password" shrink>
          {t("loginpage.enterpassword", { ns: ["home"] })!}
        </InputLabel>
        <OutlinedInput
         notched
          id="outlined-adornment-password"
          error={errorPassword === "" ? false : true}
          placeholder={t("loginpage.enterpassword", { ns: ["home"] })!}
          label={t("loginpage.enterpassword", { ns: ["home"] })!}
          type={password.showPassword ? "text" : "Password"}
          value={password.password|| ''}
          onChange={handleChange("password")}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {password.showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
        />
        <FormHelperText>
          <Grid
            container
            item
            direction="column"
            justifyContent="center"
            xs={12}
          >
            <Grid
              container
              item
              direction="row"
              justifyContent="space-between"
              alignItems="flex-start"
            >
              <Box>
                {errorPassword === ""
                  ? `${t("loginpage.plsenterpass", { ns: ["home"] })!}`
                  : errorPassword}
              </Box>
              <Button size="small" color="primary" onClick={forgetPwd}>
                {t("loginpage.forgotpassword", { ns: ["home"] })!}
              </Button>
            </Grid>
          </Grid>
        </FormHelperText>
      </FormControl>
      <Button
        sx={{ my: 2 }}
        fullWidth
        variant="contained"
        color="primary"
        onClick={handleLogin}
        size="large"
      >
        {t("loginpage.login", { ns: ["home"] })!}
      </Button>
      {error !== "" ? (
        <Box>
          <Alert
            severity={apiMsgType}
            onClose={() => {
              dispatch(clearLoginAPIRes());
              dispatch(clearGetPatientDetailsAPIRes());
              setError("");
            }}
          >
            {error}
          </Alert>
        </Box>
      ) : null}
    </Box>
  );
  return (
    <>
      {actualPageMarkup}
      {modalMarkup}
      {forgotPasswordemailRes}
    </>
  );
}
