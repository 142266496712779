import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../Url";
import { BloodGrpActionTypes } from "./ActionTypes";
import { BloodGroupListResponse } from "./Model";
import {
  BloodGrpAPIResClearAction,
  BloodGrpErrorAction,
  BloodGrpLoadingAction,
  BloodGrpSuccessAction,
  BloodGrpAPIMsgAction,
} from "./Action";
import { headers } from "../../../components/Utility";
import { setupInterceptorsTo } from "../../Authentication/Interceptors";

let apiRes = {} as BloodGroupListResponse;
export const BloodGrpList = () => {
  return function (dispatch: Dispatch<BloodGrpActionTypes>) {
    dispatch(BloodGrpLoadingAction(true));
    setupInterceptorsTo(axios)
      .get(Url.baseUrl + `/patientapp/api/patient/patient_bloodgroup_list`, {
        headers: headers,
      })
      .then((res) => {
        apiRes = res.data;
        dispatch(BloodGrpLoadingAction(false));
        dispatch(
          BloodGrpSuccessAction(
            res.data,
            res.data.message !== undefined
              ? res.data.message
              : "Blood group list fetch successfully."
          )
        );
      })
      .catch((error) => {
        dispatch(BloodGrpLoadingAction(false));
        dispatch(
          BloodGrpErrorAction(
            {} as BloodGroupListResponse,
            error.response.data !== undefined
              ? error.response.data.message !== undefined
                ? error.response.data.message
                : "Something went wrong"
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0
          )
        );
      });
  };
};

export const BloodGrpAPIResMsg = () => {
  return function (dispatch: Dispatch<BloodGrpActionTypes>) {
    dispatch(BloodGrpAPIMsgAction(apiRes as BloodGroupListResponse, "", "", 0));
  };
};

export const clearBloodGrpAPIRes = () => {
  return function (dispatch: Dispatch<BloodGrpActionTypes>) {
    dispatch(BloodGrpAPIResClearAction({} as BloodGroupListResponse, "", "", 0));
  };
};
