import React, { useEffect } from "react";
import { Box, Grid, Tab, Typography } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { t } from "i18next";
import MWLoader from "../../components/MWLoader";
import PageLayout from "../Layout/PageLayout";
import Payment from "./Payment";
import PaymentMethod from "./PaymentMethod";
import { useSelector } from "react-redux";
import { AppState } from "../../redux/store/Store";

export default function PaymentPage() {
  const [selected, setSelected] = React.useState("1");
  const handleTabChange = (_event: React.SyntheticEvent, newValue: string) => {
    setSelected(newValue);
  };
  const [loading, setLoading] = React.useState<boolean | undefined>(false);
  const getPaymentListByOwnerRes = useSelector(
    (state: AppState) => state.getPaymentListByOwner
  );
  const checkPaymentStatusByIdRes = useSelector(
    (state: AppState) => state.checkPaymentStatusById
  );

  useEffect(() => {
    getPaymentListByOwnerRes?.loading ? setLoading(true) : setLoading(false);
  }, [getPaymentListByOwnerRes]);

  useEffect(() => {
    checkPaymentStatusByIdRes?.loading ? setLoading(true) : setLoading(false);
  }, [checkPaymentStatusByIdRes]);

  const actualPageMarkup = (
    <Grid>
      <Grid item>
        <Box sx={{ width: "100%" }}>
          <TabContext value={String(selected)}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList onChange={handleTabChange}>
                <Tab label={t("payment.paylist", { ns: ["home"] })} value="1" />
                <Tab
                  label={t("payment.payMethod", { ns: ["home"] })}
                  value="2"
                />
              </TabList>
            </Box>
            <TabPanel value="1">{<Payment />}</TabPanel>
            <TabPanel value="2">{<PaymentMethod />}</TabPanel>
          </TabContext>
        </Box>
      </Grid>
    </Grid>
  );

  return (
    <>
      {loading ? <MWLoader /> : null}
      <PageLayout>
        <Typography variant="h5" sx={{ mb: 2 }} fontWeight="bold">
          {selected === "1" ? `${t("payment.paylist", { ns: ["home"] })}` : `${t("payment.payMethod", { ns: ["home"] })}`}
        </Typography>
        {actualPageMarkup}
      </PageLayout>
    </>
  );
}
