import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../Url";
import { ConsultationTypeActionTypes } from "./ActionTypes";
import { ConsultationTypeListResponse } from "./Model";
import {
  consultationTypeAPIResClearAction,
  consultationTypeErrorAction,
  consultationTypeLoadingAction,
  consultationTypeSuccessAction,
  consultationTypeAPIMsgAction,
} from "./Action";
import { headers } from "../../../components/Utility";
import { setupInterceptorsTo } from "../../Authentication/Interceptors";

let apiRes = {} as ConsultationTypeListResponse;
export const patientConsultationType = () => {
  return function (dispatch: Dispatch<ConsultationTypeActionTypes>) {
    dispatch(consultationTypeLoadingAction(true));
    setupInterceptorsTo(axios)
      .get(Url.baseUrl + `/secondopinionapp/api/consultationtype/consultation_type`, {
        headers: headers,
      })
      .then((res) => {
        apiRes = res.data;
        dispatch(consultationTypeLoadingAction(false));
        dispatch(
          consultationTypeSuccessAction(
            res.data,
            res.data.message !== undefined
              ? res.data.message
              : "Consultation type fetch successfully."
          )
        );
      })
      .catch((error) => {
        dispatch(consultationTypeLoadingAction(false));
        dispatch(
          consultationTypeErrorAction(
            {} as ConsultationTypeListResponse,
            error.response.data !== undefined
              ? error.response.data.message !== undefined
                ? error.response.data.message
                : "Something went wrong"
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0
          )
        );
      });
  };
};

export const consultationTypeAPIResMsg = () => {
  return function (dispatch: Dispatch<ConsultationTypeActionTypes>) {
    dispatch(consultationTypeAPIMsgAction(apiRes as ConsultationTypeListResponse, "", "", 0));
  };
};

export const clearconsultationTypeAPIRes = () => {
  return function (dispatch: Dispatch<ConsultationTypeActionTypes>) {
    dispatch(consultationTypeAPIResClearAction({} as ConsultationTypeListResponse, "", "", 0));
  };
};
