import * as React from "react";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import InformConsent from "./InformConsent";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../redux/store/Store";
import { getGenderList } from "../../../redux/DemographicsDetails/GenderList/API";
import { UpdateDemographicsDetailsReq } from "../../../redux/DemographicsDetails/UpdateDemographicsDetails/Model";
import {
  clearUpdateDemographicsDetailsAPIRes,
  updateDemographicsDetailsByPatientId,
} from "../../../redux/DemographicsDetails/UpdateDemographicsDetails/API";
import { AddDemographicsDetailsReq } from "../../../redux/DemographicsDetails/AddDemographicsDetails/Model";
import {
  addDemographicsDetails,
  clearAddDemographicsDetailsAPIRes,
} from "../../../redux/DemographicsDetails/AddDemographicsDetails/API";
import {
  Alert,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Typography,
  Box,
  Stack,
  AlertTitle,
  TextField,
  Autocomplete,
  AlertColor,
} from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import PageLayout from "../../Layout/PageLayout";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { GetDemographicsDetailsByPtIdRequest } from "../../../redux/DemographicsDetails/GetPatientDemographicsDetails/Model";
import { getDemographicsDetailsByPtId } from "../../../redux/DemographicsDetails/GetPatientDemographicsDetails/API";
import MWTextField from "../../../components/MWTextField";
import MWUploader from "../../../components/MWUploader";
import MWMultipleAutocomplete from "../../../components/MWMultipleAutoComplete";
import MWLoader from "../../../components/MWLoader";
import MWPageTitle from "../../../components/MWPageTitle";
import MWSnackbar from "../../../components/MWSnackbar";
import { t } from "i18next";
import { BloodGrpList } from "../../../redux/DemographicsDetails/BloodGroupList/API";
import { languageListApi } from "../../../redux/DemographicsDetails/LanguageList/API";
import { maritalListApi } from "../../../redux/DemographicsDetails/MaritalStatusList/API";
import { relationShipListApi } from "../../../redux/DemographicsDetails/RelationShipList/API";
import { sexualOrientationListApi } from "../../../redux/DemographicsDetails/SexualOrientationList/API";

var utc = require("dayjs/plugin/utc");
dayjs.extend(utc);

export default function DemographicDetails() {
  const { patientid } = useParams() as { patientid: string };
  const [isEdit, setIsEdit] = useState(
    patientid === "self" || patientid === "other"
      ? false
      : patientid === undefined
      ? false
      : true
  );
  const dispatch = useDispatch();
  const location = useLocation();

  const [pathName, setPathName] = useState("");
  // check current url path name
  useEffect(() => {
    setPathName(location.pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    if (patientid === "self") {
      setFirstName(String(localStorage.getItem("firstname")));
      setLastName(String(localStorage.getItem("lastname")));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientid]);

  const [isLoading, setIsLoading] = useState(false);
  // firstName
  const [firstName, setFirstName] = useState("");

  // lastName
  const [lastName, setLastName] = useState("");

  // D.O.B.
  const [selectedDate, setSelectedDate] = React.useState<Dayjs | null>(
    dayjs(new Date())
  );
  const handleDateChange = (date: Dayjs | null) => {
    setSelectedDate(date);
  };

  // gender list
  const [genderValues, setGenderValues] = useState([] as any[]);
  // selected gender list
  const [selectedGen, setSelectedGen] = useState<number | null>(null);
  const genderValueFromChild = (_selectedGenVal: any, newvalue: any) => {
    setSelectedGen(newvalue.value);
  };

  // upload image
  const [imageId, setImageId] = useState("");
  const [imageInfo, setImageInfo] = useState("");
  const [delImg, setDelImg] = useState(false);
  // delete selected image from image uploader
  const delImgAction = (passeVal: boolean) => {
    setDelImg(passeVal);
  };
  const [checkImgUpload, setCheckImgUpload] = useState(false);
  // Get PreviewImg Details
  const viewPreviewImageData = (passedVal: any) => {
    setCheckImgUpload(passedVal);
  };
  const updateDataStateFromChild = async (selectedImgId: any) => {
    setImageId(selectedImgId);
  };

  // Phone Number
  const [phoneNo, setPhoneNo] = useState("");

  // relationship list
  const [relationValues, setRelationValues] = useState([] as any[]);
  // select relationship Values from dropdown
  const [selectedRel, setSelectedRel] = useState<number | null>(null);
  // get selected relationship value from common component
  const relationValueFromChild = async (
    _selectedRelationValue: any,
    newvalue: any
  ) => {
    setSelectedRel(newvalue.value);
  };

  // show guardian
  const [guardian, setGuardian] = useState("");

  // blood group list
  const [bloodGroupValues, setBloodGroupValues] = useState([] as any[]);
  // select bloodGroup Values from dropdown
  const [selectedBloodGroup, setSelectedBloodGroup] = useState<number | null>(
    null
  );
  // get blood group from child component
  const bloodGrpValueFromChild = async (
    _selectedBloodGroupVal: any,
    newvalue: any
  ) => {
    setSelectedBloodGroup(newvalue.value);
  };

  // language list
  const [language, setLanguage] = useState([] as any[]);
  // selected language Value (Multiple) from dropdown
  const [languageSelected, setLanguageSelected] = useState([]);
  const languageValueFromChild = async (selectedMultiLanguageVal: any) => {
    setLanguageSelected(selectedMultiLanguageVal);
  };
  // maritalStatus list
  const [maritalStatusValues, setMaritalStatusValues] = useState([] as any[]);
  // select MaritalStatusValues from dropdown
  const [selectedMaritalStatus, setSelectedMaritalStatus] = useState<
    number | null
  >(null);
  const maritalStatusValueFromChild = async (
    _selectedMaritalStatus: any,
    newvalue: any
  ) => {
    setSelectedMaritalStatus(newvalue.value);
  };

  // sexual Orientation list
  const [sexualOrientationValues, setSexualOrientationValues] = useState(
    [] as any[]
  );
  // selected sexual Orientation Value from dropdown
  const [selectedSexualOrientation, setSelectedSexualOrientation] = useState<
    number | null
  >(null);
  //get sexual Orientation Value from child
  const sexValueFromChild = async (
    _selectedSexualOrientationVal: any,
    newvalue: any
  ) => {
    setSelectedSexualOrientation(newvalue.value);
  };

  // show city
  const [city, setCity] = useState("");

  // pin code
  const NUMERIC_REGEX = /^(0|[1-9][0-9]*)$/;
  const [textValue, setPin] = useState("");

  const [chooseLang, SetChooseLang] = useState([] as any);
  const [addedLang, SetAddedLang] = useState([] as any);

  const [
    patientDemographicsDetailsAPIMsg,
    setPatientDemographicsDetailsAPIMsg,
  ] = useState("");
  const [
    patientDemographicsDetailsAPIMsgType,
    setPatientDemographicsDetailsAPIMsgType,
  ] = useState<AlertColor>("success");

  const handleBannerErrorDismiss = () => {
    setError("");
  };
  const [error, setError] = useState("");

  const [isEnableSubmit, setEnableSubmit] = useState(true);
  const history = useHistory();

  const closeSnackbar = (passedVal: boolean) => {
    if (passedVal) {
      setPatientDemographicsDetailsAPIMsg("");
      dispatch(clearAddDemographicsDetailsAPIRes());
      dispatch(clearUpdateDemographicsDetailsAPIRes());
    }
  };

  const demographicsBody = {
    gender: selectedGen!,
    relationship: selectedRel!,
    firstname: firstName,
    lastname: lastName,
    dob: dayjs(selectedDate!).format("YYYY-MM-DDTHH:mm:ssZ"),
    pincode: textValue,
    bloodGroup: selectedBloodGroup!,
    city: city,
    guardianName: guardian!,
    maritialStatusId: selectedMaritalStatus!,
    sexualorientationId: selectedSexualOrientation!,
    languageId: chooseLang,
    phone: phoneNo,
  } as AddDemographicsDetailsReq;

  const userToken = localStorage.getItem("access");
  const isFirstName = firstName;
  const isLastName = lastName;

  const addHealthRecord = () => {
    if (
      patientid === "self" ||
      patientid === "other" ||
      patientid === undefined
    ) {
      // Create / Add Patient Details
      if (!isFirstName.trim()) {
        setError(`${t("addpatientpage.fnamenotempty", { ns: ["home"] })!}`);
        return;
      } else if (!isLastName.trim()) {
        setError(`${t("addpatientpage.lnamenotempty", { ns: ["home"] })!}`);
        return;
      } else if (selectedGen === null) {
        setError(`${t("addpatientpage.gendernotempty", { ns: ["home"] })!}`);
        return;
      } else if (selectedDate === null) {
        setError(`${t("addpatientpage.dobnotempty", { ns: ["home"] })!}`);
        return;
      } else if (
        new RegExp("^[1-9][0-9]{4}$").test(textValue.toString()) === false
      ) {
        setError(`${t("addpatientpage.pinvalid", { ns: ["home"] })!}`);
      } else {
        if (userToken) {
          if (imageId === undefined) {
            setError("");
            // When user does not upload image on patient creation
            dispatch(addDemographicsDetails(demographicsBody));
          } else {
            setError("");
            // When user uploads image on patient creation
            dispatch(
              addDemographicsDetails(
                Object.assign(demographicsBody, {
                  patientImageId: String(imageId),
                })
              )
            );
          }
        }
      }
    } else {
      // Edit Patient Details
      if (!isFirstName.trim()) {
        setError(`${t("addpatientpage.fnamenotempty", { ns: ["home"] })!}`);
        return;
      } else if (!isLastName.trim()) {
        setError(`${t("addpatientpage.lnamenotempty", { ns: ["home"] })!}`);
        return;
      } else if (selectedGen === null) {
        setError(`${t("addpatientpage.gendernotempty", { ns: ["home"] })!}`);
        return;
      } else if (selectedDate === null) {
        setError(`${t("addpatientpage.gendernotempty", { ns: ["home"] })!}`);
        return;
      } else if (
        new RegExp("^[1-9][0-9]{4}$").test(textValue.toString()) === false
      ) {
        setError("Please enter valid Pincode");
      } else {
        if (userToken) {
          if (delImg) {
            setError("");
            // When user delete image
            // delete demographicsBody.govtIdImage;
            let deleteImgPayload = Object.assign(demographicsBody, {
              healthRecordId: patientid,
              patientImageId: "-1",
            }) as UpdateDemographicsDetailsReq;
            dispatch(updateDemographicsDetailsByPatientId(deleteImgPayload));
          } else {
            if (imageId !== undefined) {
              setError("");
              // when user add new image
              // delete demographicsBody.govtIdImage;
              let addImgPayload = Object.assign(demographicsBody, {
                healthRecordId: patientid,
                patientImageId: String(imageId),
              }) as UpdateDemographicsDetailsReq;
              dispatch(updateDemographicsDetailsByPatientId(addImgPayload));
            } else {
              setError("");
              // when user edit other details
              // delete demographicsBody.govtIdImage;
              let updateWithoutImg = Object.assign(demographicsBody, {
                healthRecordId: patientid,
              }) as UpdateDemographicsDetailsReq;
              dispatch(updateDemographicsDetailsByPatientId(updateWithoutImg));
            }
          }
        }
      }
    }
  };

  // Get Demographics Details by Patient Id Body
  const getDemographicsDetailsByPtIdReqBody = {
    healthRecordId: patientid,
  } as GetDemographicsDetailsByPtIdRequest;
  useEffect(() => {
    window.scrollTo(0, 0);
    if (patientid !== undefined) {
      dispatch(
        getDemographicsDetailsByPtId(getDemographicsDetailsByPtIdReqBody)
      );
    }
    dispatch(relationShipListApi());
    dispatch(BloodGrpList());
    dispatch(getGenderList());
    dispatch(languageListApi());
    dispatch(maritalListApi());
    dispatch(sexualOrientationListApi());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Call Gender List store
  const getPatientGenderListRes = useSelector(
    (state: AppState) => state.getPatientGenderList
  );
  // take following actions when Gender list api store updated
  useEffect(() => {
    setIsLoading(getPatientGenderListRes?.loading);
    if (getPatientGenderListRes?.getGenderListRes?.genderList !== undefined) {
      setGenderValues(getPatientGenderListRes?.getGenderListRes?.genderList);
      if (getPatientBloodGroupListRes?.errorMsg !== "") {
        setPatientDemographicsDetailsAPIMsg(
          getPatientBloodGroupListRes?.errorMsg
        );
        setPatientDemographicsDetailsAPIMsgType("error");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getPatientGenderListRes]);

  // Call Relationship list store
  const getPatientRelationshipListRes = useSelector(
    (state: AppState) => state.getPatientRelationshipList
  );
  // take following actions when Relationship list api store updated
  useEffect(() => {
    setIsLoading(getPatientRelationshipListRes?.loading);
    if (
      getPatientRelationshipListRes?.maritalStatusResponse
        ?.patientRelationshipList !== undefined
    ) {
      setRelationValues(
        getPatientRelationshipListRes?.maritalStatusResponse
          ?.patientRelationshipList
      );
      if (getPatientRelationshipListRes?.errorMsg !== "") {
        setPatientDemographicsDetailsAPIMsg(
          getPatientRelationshipListRes?.errorMsg
        );
        setPatientDemographicsDetailsAPIMsgType("error");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getPatientRelationshipListRes]);

  // Call Blood Group List store
  const getPatientBloodGroupListRes = useSelector(
    (state: AppState) => state.getPatientBloodGroupList
  );
  // take following actions when Blood group list api store updated
  useEffect(() => {
    setIsLoading(getPatientBloodGroupListRes?.loading);
    if (
      getPatientBloodGroupListRes?.BloodGrpResponse?.bloodGroupList !==
      undefined
    ) {
      setBloodGroupValues(
        getPatientBloodGroupListRes?.BloodGrpResponse?.bloodGroupList
      );
      if (getPatientBloodGroupListRes?.errorMsg !== "") {
        setPatientDemographicsDetailsAPIMsg(
          getPatientBloodGroupListRes?.errorMsg
        );
        setPatientDemographicsDetailsAPIMsgType("error");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getPatientBloodGroupListRes]);

  // Call Language List store
  const getPatientLanguageListRes = useSelector(
    (state: AppState) => state.getPatientLanguageList
  );
  // take following actions when language list api store updated
  useEffect(() => {
    setIsLoading(getPatientLanguageListRes?.loading);
    if (
      getPatientLanguageListRes?.languageListResponse?.languageList !==
      undefined
    ) {
      setLanguage(getPatientLanguageListRes?.languageListResponse.languageList);
      if (getPatientBloodGroupListRes?.errorMsg !== "") {
        setPatientDemographicsDetailsAPIMsg(
          getPatientBloodGroupListRes?.errorMsg
        );
        setPatientDemographicsDetailsAPIMsgType("error");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getPatientLanguageListRes]);
  // take actions when language list dropdown value updated
  useEffect(() => {
    let multiLang = languageSelected?.map((item: any) => item.value);
    SetChooseLang(multiLang);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [languageSelected]);

  // Call Marital Status List store
  const getPatientMaritalListRes = useSelector(
    (state: AppState) => state.getPatientMaritalList
  );
  // take following actions when Marital Status list api store updated
  useEffect(() => {
    setIsLoading(getPatientMaritalListRes?.loading);
    if (
      getPatientMaritalListRes?.maritalStatusResponse?.maritialStatusList !==
      undefined
    ) {
      setMaritalStatusValues(
        getPatientMaritalListRes?.maritalStatusResponse?.maritialStatusList
      );
      if (getPatientBloodGroupListRes?.errorMsg !== "") {
        setPatientDemographicsDetailsAPIMsg(
          getPatientBloodGroupListRes?.errorMsg
        );
        setPatientDemographicsDetailsAPIMsgType("error");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getPatientMaritalListRes]);

  // Call Sexual Orientation List store
  const getPatientSexualOrientationRes = useSelector(
    (state: AppState) => state.getPatientSexualOrientation
  );
  // take following actions when Sexual Orientations list api store updated
  useEffect(() => {
    setIsLoading(getPatientSexualOrientationRes?.loading);
    if (
      getPatientSexualOrientationRes?.sexualOrientationResponse
        ?.sexualOrientationList !== undefined
    ) {
      setSexualOrientationValues(
        getPatientSexualOrientationRes?.sexualOrientationResponse
          ?.sexualOrientationList
      );
      if (getPatientBloodGroupListRes?.errorMsg !== "") {
        setPatientDemographicsDetailsAPIMsg(
          getPatientBloodGroupListRes?.errorMsg
        );
        setPatientDemographicsDetailsAPIMsgType("error");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getPatientSexualOrientationRes]);

  // Call Update Demographics Details store
  const updateDemographicsDetailsRes = useSelector(
    (state: AppState) => state.updateDemographicsDetails
  );
  // take following actions when Update Demographics api store updated
  useEffect(() => {
    setIsLoading(updateDemographicsDetailsRes?.loading);
    if (
      updateDemographicsDetailsRes?.updatePatientDemographicsRes?.message !== ""
    ) {
      setPatientDemographicsDetailsAPIMsg(
        updateDemographicsDetailsRes?.updatePatientDemographicsRes?.message
      );
      setPatientDemographicsDetailsAPIMsgType("success");
      // Call get demographics details by patient id api
      if (patientid) {
        dispatch(
          getDemographicsDetailsByPtId(getDemographicsDetailsByPtIdReqBody)
        );
      }
    }
    if (updateDemographicsDetailsRes?.errorMsg !== "") {
      setPatientDemographicsDetailsAPIMsg(
        updateDemographicsDetailsRes?.errorMsg
      );
      setPatientDemographicsDetailsAPIMsgType("error");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateDemographicsDetailsRes]);

  // Call Get Demographics Details by patient id  store
  const getDemographicsDetailsByPtIdRes = useSelector(
    (state: AppState) => state.getDemographicsDetailsByPtId
  );
  // take following actions when Get Demographics Details by patient id store updated
  useEffect(() => {
    setIsLoading(getDemographicsDetailsByPtIdRes?.loading);

    if (
      getDemographicsDetailsByPtIdRes?.getDemographicsDetailsByPtIdRes
        ?.personalDetails !== undefined
    ) {
      getDemographicsDetailsByPtIdRes?.getDemographicsDetailsByPtIdRes
        ?.personalDetails?.firstname === null ||
      getDemographicsDetailsByPtIdRes?.getDemographicsDetailsByPtIdRes
        ?.personalDetails?.firstname === ""
        ? setFirstName("")
        : setFirstName(
            getDemographicsDetailsByPtIdRes?.getDemographicsDetailsByPtIdRes
              ?.personalDetails?.firstname
          );
      getDemographicsDetailsByPtIdRes?.getDemographicsDetailsByPtIdRes
        ?.personalDetails?.lastname === null ||
      getDemographicsDetailsByPtIdRes?.getDemographicsDetailsByPtIdRes
        ?.personalDetails.lastname === ""
        ? setLastName("")
        : setLastName(
            getDemographicsDetailsByPtIdRes?.getDemographicsDetailsByPtIdRes
              ?.personalDetails.lastname
          );
      getDemographicsDetailsByPtIdRes?.getDemographicsDetailsByPtIdRes
        ?.personalDetails.gender === null
        ? setSelectedGen(null)
        : setSelectedGen(
            getDemographicsDetailsByPtIdRes?.getDemographicsDetailsByPtIdRes
              ?.personalDetails.gender.value
          );

      getDemographicsDetailsByPtIdRes?.getDemographicsDetailsByPtIdRes
        ?.personalDetails?.dob === null ||
      getDemographicsDetailsByPtIdRes?.getDemographicsDetailsByPtIdRes
        ?.personalDetails?.dob === ""
        ? setSelectedDate(dayjs(new Date()))
        : setSelectedDate(
            dayjs(
              getDemographicsDetailsByPtIdRes?.getDemographicsDetailsByPtIdRes
                ?.personalDetails?.dob
            )
          );

      getDemographicsDetailsByPtIdRes?.getDemographicsDetailsByPtIdRes
        ?.personalDetails.guardianName === null ||
      getDemographicsDetailsByPtIdRes?.getDemographicsDetailsByPtIdRes
        ?.personalDetails.guardianName === ""
        ? setGuardian("")
        : setGuardian(
            getDemographicsDetailsByPtIdRes?.getDemographicsDetailsByPtIdRes
              ?.personalDetails.guardianName
          );

      getDemographicsDetailsByPtIdRes?.getDemographicsDetailsByPtIdRes
        ?.personalDetails.patientRelationshipWithOwner === null
        ? setSelectedRel(null)
        : setSelectedRel(
            getDemographicsDetailsByPtIdRes?.getDemographicsDetailsByPtIdRes
              ?.personalDetails.patientRelationshipWithOwner.id
          );
      getDemographicsDetailsByPtIdRes?.getDemographicsDetailsByPtIdRes
        ?.personalDetails.pincode === null ||
      getDemographicsDetailsByPtIdRes?.getDemographicsDetailsByPtIdRes
        ?.personalDetails.pincode === "" ||
      getDemographicsDetailsByPtIdRes?.getDemographicsDetailsByPtIdRes
        ?.personalDetails.pincode === undefined
        ? setPin("")
        : setPin(
            getDemographicsDetailsByPtIdRes?.getDemographicsDetailsByPtIdRes
              ?.personalDetails.pincode
          );
      getDemographicsDetailsByPtIdRes?.getDemographicsDetailsByPtIdRes
        ?.personalDetails.maritialStatus === null
        ? setSelectedMaritalStatus(null)
        : setSelectedMaritalStatus(
            getDemographicsDetailsByPtIdRes?.getDemographicsDetailsByPtIdRes
              ?.personalDetails.maritialStatus.value
          );
      getDemographicsDetailsByPtIdRes?.getDemographicsDetailsByPtIdRes
        ?.personalDetails.sexualOrientation === null
        ? setSelectedSexualOrientation(null)
        : setSelectedSexualOrientation(
            getDemographicsDetailsByPtIdRes?.getDemographicsDetailsByPtIdRes
              ?.personalDetails.sexualOrientation.value
          );

      getDemographicsDetailsByPtIdRes?.getDemographicsDetailsByPtIdRes
        ?.personalDetails?.profilePicture === null ||
      getDemographicsDetailsByPtIdRes?.getDemographicsDetailsByPtIdRes
        ?.personalDetails?.profilePicture === ""
        ? setImageInfo("")
        : setImageInfo(
            getDemographicsDetailsByPtIdRes?.getDemographicsDetailsByPtIdRes
              ?.personalDetails?.profilePicture.document
          );

      getDemographicsDetailsByPtIdRes?.getDemographicsDetailsByPtIdRes
        ?.personalDetails.bloodGroup === null
        ? setSelectedBloodGroup(null)
        : setSelectedBloodGroup(
            getDemographicsDetailsByPtIdRes?.getDemographicsDetailsByPtIdRes
              ?.personalDetails.bloodGroup.value
          );
      getDemographicsDetailsByPtIdRes?.getDemographicsDetailsByPtIdRes
        ?.personalDetails.city === null ||
      getDemographicsDetailsByPtIdRes?.getDemographicsDetailsByPtIdRes
        ?.personalDetails.city === ""
        ? setCity("")
        : setCity(
            getDemographicsDetailsByPtIdRes?.getDemographicsDetailsByPtIdRes
              ?.personalDetails.city
          );

      let languageValue =
        getDemographicsDetailsByPtIdRes?.getDemographicsDetailsByPtIdRes
          ?.personalDetails.languagePreferred;
      SetAddedLang(languageValue);

      getDemographicsDetailsByPtIdRes?.getDemographicsDetailsByPtIdRes
        ?.personalDetails?.phone !== undefined
        ? getDemographicsDetailsByPtIdRes?.getDemographicsDetailsByPtIdRes
            ?.personalDetails?.phone !== null
          ? setPhoneNo(
              getDemographicsDetailsByPtIdRes?.getDemographicsDetailsByPtIdRes
                ?.personalDetails?.phone
            )
          : setPhoneNo("")
        : setPhoneNo("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getDemographicsDetailsByPtIdRes]);

  // Add new Demographics details api store
  const addDemographics = useSelector(
    (state: AppState) => state.addDemographics
  );
  // Take actions when Add Patient API calls & it's store has been updated
  useEffect(() => {
    setIsLoading(addDemographics?.loading);
    if (addDemographics?.successMsg !== "") {
      setPatientDemographicsDetailsAPIMsg(
        addDemographics?.addPatientDemographicsRes?.message
      );
      setPatientDemographicsDetailsAPIMsgType("success");
      dispatch(clearAddDemographicsDetailsAPIRes())
      history.push("/dashboard");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addDemographics]);

  // Add Patient Inform Concent Layout
  const addPtInfoConcent = (
    <>
      {patientid === "self" ||
      patientid === "other" ||
      patientid === undefined ? (
        <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="stretch"
          spacing={1}
        >
          <Alert severity="info">
            <AlertTitle>
              {t("addpatientpage.info", { ns: ["home"] })!}
            </AlertTitle>
            <Box style={{ maxHeight: "200px", overflow: "auto" }}>
              <InformConsent />
            </Box>
          </Alert>
          <Typography variant="subtitle1">
            {t("addpatientpage.agreeConsent", { ns: ["home"] })}
          </Typography>
        </Stack>
      ) : null}
    </>
  );
  ////// *********** Main Demographics Layout ********* ///////////
  const formBody = (
    <React.Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="stretch"
            spacing={3}
          >
            <Grid item>
              <MWTextField
                label={t("addpatientpage.firstname", { ns: ["home"] })}
                required
                placeholder={
                  t("addpatientpage.firstnamedesc", { ns: ["home"] })!
                }
                value={firstName}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setFirstName(event.target.value);
                }}
                disabled={isEdit}
                fullWidth
                size="small"
              />
            </Grid>
            <Grid item>
              <MWTextField
                label={t("addpatientpage.lastname", { ns: ["home"] })}
                required
                placeholder={
                  t("addpatientpage.lastnamedesc", { ns: ["home"] })!
                }
                value={lastName}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setLastName(event.target.value);
                }}
                disabled={isEdit}
                fullWidth
                size="small"
              />
            </Grid>
            <Grid item>
              <Autocomplete
                value={
                  genderValues.find(
                    (element: any) => element.value === selectedGen
                  ) || null
                }
                id="templateId"
                options={genderValues}
                onChange={(event: any, newValue: any) => {
                  genderValueFromChild(event, newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("addpatientpage.gender", { ns: ["home"] })!}
                    placeholder={t("addpatientpage.gender", { ns: ["home"] })!}
                    size="small"
                    required
                  />
                )}
                disabled={isEdit}
                getOptionLabel={(option) => `${option.label}`}
              />
            </Grid>
            <Grid item>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  inputFormat="DD/MM/YYYY"
                  value={selectedDate}
                  onChange={handleDateChange}
                  views={["year", "month", "day"]}
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      fullWidth
                      inputProps={{ ...params.inputProps }}
                      size="small"
                      variant="outlined"
                      label={t("addpatientpage.dob", { ns: ["home"] })}
                      required
                    />
                  )}
                  InputAdornmentProps={{ position: "start" }}
                  disabled={isEdit}
                  maxDate={isEdit ? dayjs(new Date()) : undefined}
                  disableFuture
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
          <MWUploader
            documentType="patient-image"
            criteria={t("allergyPage.uploadFile", { ns: ["home"] })!}
            updatedDataState={updateDataStateFromChild}
            title={t("allergyPage.uploadFile", { ns: ["home"] })!}
            visibility={isEdit}
            imageUrl={imageInfo}
            deleteImgAction={delImgAction}
            previewImageData={viewPreviewImageData}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Autocomplete
            value={
              relationValues.find(
                (element: any) => element.value === selectedRel
              ) || null
            }
            id="templateId"
            options={relationValues}
            onChange={(event: any, newValue: any) => {
              relationValueFromChild(event, newValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("addpatientpage.relation", { ns: ["home"] })!}
                placeholder={t("addpatientpage.relation", { ns: ["home"] })!}
                size="small"
              />
            )}
            disabled={isEdit}
            getOptionLabel={(option) => `${option.label}`}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <MWTextField
            label={t("addpatientpage.grd", { ns: ["home"] })}
            placeholder={t("addpatientpage.enterGrd", { ns: ["home"] })!}
            value={guardian}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setGuardian(event.target.value);
            }}
            disabled={isEdit}
            fullWidth
            size="small"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Autocomplete
            value={
              bloodGroupValues.find(
                (element: any) => element.value === selectedBloodGroup
              ) || null
            }
            id="templateId"
            options={bloodGroupValues}
            onChange={(event: any, newValue: any) => {
              bloodGrpValueFromChild(event, newValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("addpatientpage.bloodgroup", { ns: ["home"] })!}
                placeholder={t("addpatientpage.bloodgroup", { ns: ["home"] })!}
                size="small"
              />
            )}
            disabled={isEdit}
            getOptionLabel={(option) => `${option.label}`}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <MWMultipleAutocomplete
            placeHolder={t("addpatientpage.lang", { ns: ["home"] })!}
            listvalue={language}
            selectedItem={addedLang}
            dropdownValue={languageValueFromChild}
            visibility={isEdit}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Autocomplete
            value={
              maritalStatusValues.find(
                (element: any) => element.value === selectedMaritalStatus
              ) || null
            }
            id="templateId"
            options={maritalStatusValues}
            onChange={(event: any, newValue: any) => {
              maritalStatusValueFromChild(event, newValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("addpatientpage.maritalstatus", { ns: ["home"] })!}
                placeholder={
                  t("addpatientpage.maritalstatus", { ns: ["home"] })!
                }
                size="small"
              />
            )}
            disabled={isEdit}
            getOptionLabel={(option) => `${option.label}`}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Autocomplete
            value={
              sexualOrientationValues.find(
                (element: any) => element.value === selectedSexualOrientation
              ) || null
            }
            id="templateId"
            options={sexualOrientationValues}
            onChange={(event: any, newValue: any) => {
              sexValueFromChild(event, newValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("addpatientpage.sexualorient", { ns: ["home"] })!}
                placeholder={
                  t("addpatientpage.sexualorient", { ns: ["home"] })!
                }
                size="small"
              />
            )}
            disabled={isEdit}
            getOptionLabel={(option) => `${option.label}`}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <MWTextField
            label={t("addpatientpage.city", { ns: ["home"] })}
            placeholder={t("addpatientpage.entercity", { ns: ["home"] })!}
            value={city}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setCity(event.target.value);
            }}
            disabled={isEdit}
            fullWidth
            size="small"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <MWTextField
            label={t("addpatientpage.pin", { ns: ["home"] })!}
            placeholder={t("addpatientpage.pindesc", { ns: ["home"] })!}
            value={textValue}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              const value = event.target.value;
              if (value !== "" && !NUMERIC_REGEX.test(value)) {
                return;
              }
              setPin(value);
            }}
            disabled={isEdit}
            fullWidth
            size="small"
            type="text"
            inputProps={{ maxLength: 5, minLength: 5 }}
            error={textValue !== "" && textValue.length !== 5 ? true : false}
            required
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          {error !== "" && error !== undefined ? (
            <Box my={2}>
              <Alert onClose={handleBannerErrorDismiss} severity="error">
                <Typography>{error}</Typography>
              </Alert>
            </Box>
          ) : null}
        </Grid>
      </Grid>
    </React.Fragment>
  );
  ////// *********** ADD ********* ///////////
  const ActualPageMarkup = (
    //edit health record part.........
    <React.Fragment>
      {patientid === "self" ||
      patientid === "other" ||
      patientid === undefined ? (
        <>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="stretch"
            spacing={2}
          >
            <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
              {addPtInfoConcent}
            </Grid>
            <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
              <Card>
                <CardContent>{formBody}</CardContent>
                <CardActions>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="flex-end"
                  >
                    <Grid item>
                      <Button
                        size="large"
                        variant="contained"
                        onClick={() => addHealthRecord()}
                        sx={{ textTransform: "none" }}
                        disabled={isLoading || checkImgUpload}
                      >
                        {t("needhelppage.submit", { ns: ["home"] })!}
                      </Button>
                    </Grid>
                  </Grid>
                </CardActions>
              </Card>
            </Grid>
          </Grid>
        </>
      ) : (
        <Card>
          <CardContent>{formBody}</CardContent>
          <CardActions>
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="flex-end"
              spacing={2}
            >
              <Grid item>
                <Button
                  size="large"
                  variant="outlined"
                  onClick={() => {
                    setIsEdit((isEdit) => !isEdit);
                    setEnableSubmit((isEnableSubmit) => !isEnableSubmit);
                  }}
                >
                  {isEdit
                    ? `${t("common.edit", { ns: ["home"] })!}`
                    : `${t("common.cancel", { ns: ["home"] })!}`}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  size="large"
                  variant="contained"
                  disabled={isEnableSubmit}
                  onClick={() => {
                    addHealthRecord();
                  }}
                >
                  {t("common.update", { ns: ["home"] })!}
                </Button>
              </Grid>
            </Grid>
          </CardActions>
        </Card>
      )}
    </React.Fragment>
  );

  return (
    <>
      {pathName !== "" ? (
        pathName.includes("/demographicdetails") ? (
          // Render in Add Patient page & View Patient Demographics details page
          <>
            {pathName !== "" ? (
              pathName.includes("/demographicdetails") && isLoading ? (
                <MWLoader />
              ) : null
            ) : null}
            <PageLayout>
              <MWPageTitle
                backButton={true}
                title={
                  patientid
                    ? `${t("addpatientpage.demodetail", {
                        ns: ["home"],
                      })!}  ${firstName} ${lastName}`
                    : `${t("menu.patient", { ns: ["home"] })!}`
                }
              />
              {ActualPageMarkup}
            </PageLayout>
          </>
        ) : (
          // Render in File Preparation Section
          <>
            {pathName !== "" ? (
              pathName.includes("/demographicdetails") && isLoading ? (
                <MWLoader />
              ) : null
            ) : null}
            <Stack
              direction="column"
              justifyContent="flex-start"
              alignItems="stretch"
              spacing={2}
            >
              <Typography variant="h4" sx={{ my: 2, fontWeight: "bold" }}>
                {patientid
                  ? `${t("addpatientpage.demodetail", {
                      ns: ["home"],
                    })!}  ${firstName} ${lastName} `
                  : `${t("menu.patient", { ns: ["home"] })!}`}
              </Typography>
              {ActualPageMarkup}
            </Stack>
          </>
        )
      ) : null}
      {patientDemographicsDetailsAPIMsg !== undefined &&
      patientDemographicsDetailsAPIMsg !== "" ? (
        <MWSnackbar
          msg={patientDemographicsDetailsAPIMsg}
          type={patientDemographicsDetailsAPIMsgType}
          alertClose={closeSnackbar}
        />
      ) : null}
    </>
  );
}
