import {
  GET_PATIENT_INSURANCE_LIST_FAIL,
  GET_PATIENT_INSURANCE_LIST_LOADING,
  GET_PATIENT_INSURANCE_LIST_SUCCESS,
  GET_PATIENT_INSURANCE_LIST_API_MSG,
  GET_PATIENT_INSURANCE_LIST_API_RES,
  GetPatientInsuranceListActionTypes,
} from "./ActionTypes";
import { GetInsuranceListRes } from "./Model";

export const getPatientInsuranceListLoadingAction = (
  loading: boolean
): GetPatientInsuranceListActionTypes => {
  return {
    type: GET_PATIENT_INSURANCE_LIST_LOADING,
    loading: loading,
  };
};

export const getPatientInsuranceListSuccessAction = (
  getPatientInsuranceListResponse: GetInsuranceListRes,
  successMsg: string
): GetPatientInsuranceListActionTypes => {
  return {
    type: GET_PATIENT_INSURANCE_LIST_SUCCESS,
    payload: getPatientInsuranceListResponse,
    successMsg: successMsg,
  };
};

export const getPatientInsuranceListErrorAction = (
  getPatientInsuranceListResponse: GetInsuranceListRes,
  errMsg: string,
  status: number
): GetPatientInsuranceListActionTypes => {
  return {
    type: GET_PATIENT_INSURANCE_LIST_FAIL,
    payload: getPatientInsuranceListResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const getPatientInsuranceListAPIMsgAction = (
  getPatientInsuranceListResponse: GetInsuranceListRes,
  successMsg: string,
  errMsg: string,
  status: number
): GetPatientInsuranceListActionTypes => {
  return {
    type: GET_PATIENT_INSURANCE_LIST_API_MSG,
    payload: getPatientInsuranceListResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const getPatientInsuranceListAPIResClearAction = (
  getPatientInsuranceListResponse: GetInsuranceListRes,
  successMsg: string,
  errMsg: string,
  status: number
): GetPatientInsuranceListActionTypes => {
  return {
    type: GET_PATIENT_INSURANCE_LIST_API_RES,
    payload: getPatientInsuranceListResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
