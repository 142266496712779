import React, { useEffect, useState } from "react";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import ClearIcon from "@mui/icons-material/Clear";
import Grid from "@mui/material/Grid";
import { useDispatch, useSelector } from "react-redux";
import {
  Typography,
  TextField,
  InputLabel,
  Stack,
  Autocomplete,
  Card,
  CardHeader,
  Avatar,
  Box,
  CardContent,
} from "@mui/material";
import { t } from "i18next";
import { getDoctorCategoryList } from "../../../redux/Doctor/DoctorCategoryList/API";
import { AppState } from "../../../redux/store/Store";
import { getSpecialistList } from "../../../redux/Doctor/specialist/API";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
import SearchIcon from "@mui/icons-material/Search";
import { Link } from "react-router-dom";

type Props = {
  loading?: boolean | any;
  selectedCategoryValue?: any;
  selectedSpecialistValue?: any;
  selectedNameValue?: any;
  searchdoctorList?: any;
};

export default function FindDoctorList({
  loading,
  selectedCategoryValue,
  selectedSpecialistValue,
  selectedNameValue,
  searchdoctorList,
}: Props) {
  const dispatch = useDispatch();
  interface State {
    doctorName: string;
  }
  const [doctorName, setDoctorName] = React.useState<State>({
    doctorName: "",
  });

  const handleChange =
    (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setDoctorName({ ...doctorName, [prop]: event.target.value });
    };

  const clearDoctorName = () => {
    setDoctorName({ ...doctorName, doctorName: "" });
  };
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const [categoryListValue, setCategoryListValue] = useState<any[]>([]);
  const [speciaListValue, setSpecialistListValue] = useState<any[]>([]);

  const categoryListList = useSelector(
    (state: AppState) => state.getDoctorCategoryListRes
  );
  const specialistList = useSelector((state: AppState) => state.speciaList);

  useEffect(() => {
    dispatch(getDoctorCategoryList());
    dispatch(getSpecialistList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (categoryListList !== undefined) {
      setCategoryListValue(
        categoryListList?.getDoctorCategoryListRes?.doctorCategoryList
      );
    }

    if (specialistList !== undefined) {
      setSpecialistListValue(
        specialistList?.getDoctorSpecialityListRes?.doctorSpecialtyList
      );
    }
  }, [categoryListList, specialistList]);

  ///// 1. ****mui category dropdown**** /////
  const [inputValue, setInputValue] = React.useState("");
  const selectCategory = (selectedCategory: any) => {
    setInputValue(selectedCategory);
  };
  const selectCategoryOnChnage = (selectedCategory: any) => {
    selectedCategoryValue({ doctorCategoryId: selectedCategory.value });
  };

  ///// 2. ****mui specialist dropdown**** /////
  const [specialistinputValue, setSpecialistInputValue] = React.useState("");
  const selectSpecialist = (Specialist: any) => {
    setSpecialistInputValue(Specialist);
  };
  const selectSpecialistOnChnage = (Specialist: any) => {
    selectedSpecialistValue({ speciality: Specialist.value });
  };

  ///// 3. **** Text search **** /////
  const searchDoctor = () => {
    selectedNameValue({ searchKey: doctorName.doctorName });
  };

  const actualPageMarkup = (
    <>
      <Stack
        direction="column"
        justifyContent="flex-start"
        alignItems="stretch"
        spacing={2}
      >
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={2}
        >
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <Typography variant="subtitle1">
              {t("finddoc.category", { ns: ["home"] })!}
            </Typography>
            <Autocomplete
              inputValue={inputValue}
              onInputChange={(_event, newInputValue) => {
                selectCategory(newInputValue);
              }}
              id="controllable-states-demo"
              options={categoryListValue !== undefined ? categoryListValue : []}
              onChange={(_event: any, newValue: string | null) => {
                selectCategoryOnChnage(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  placeholder={t("finddoc.selCat", { ns: ["home"] })!}
                  variant="outlined"
                />
              )}
              size="small"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <Typography variant="subtitle1">
              {t("allDocPage.spcl", { ns: ["home"] })!}
            </Typography>
            <Autocomplete
              inputValue={specialistinputValue}
              onInputChange={(_event, newInputValue) => {
                selectSpecialist(newInputValue);
              }}
              id="controllable-states-demo"
              options={speciaListValue !== undefined ? speciaListValue : []}
              onChange={(_event: any, newValue: string | null) => {
                selectSpecialistOnChnage(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  placeholder={t("allDocPage.selSpecl", { ns: ["home"] })!}
                  variant="outlined"
                />
              )}
              size="small"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <Typography variant="subtitle1">
              {t("finddoc.selBName", { ns: ["home"] })!}
            </Typography>
            <InputLabel htmlFor="outlined-adornment-doctorName"></InputLabel>
            <OutlinedInput
              id="doctorNameID"
              placeholder={t("finddoc.searcDoc", { ns: ["home"] })!}
              value={doctorName.doctorName}
              onChange={handleChange("doctorName")}
              fullWidth
              endAdornment={
                <InputAdornment position="end">
                  {doctorName.doctorName === "" ? (
                    ""
                  ) : (
                    <IconButton
                      aria-label={t("finddoc.togDocName", { ns: ["home"] })!}
                      onClick={clearDoctorName}
                      edge="end"
                      size="large"
                    >
                      <ClearIcon />
                    </IconButton>
                  )}
                  <IconButton
                    aria-label={t("finddoc.searDocNam", { ns: ["home"] })!}
                    onClick={searchDoctor}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                    size="large"
                  >
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              }
              size="small"
            />
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={2}
        >
          {searchdoctorList !== undefined
            ? searchdoctorList.map((doctorItem: any, index: number) => (
                <Grid item xs={12} sm={4} md={4} lg={4} xl={4} key={index}>
                  <Link
                    to={`/doctordetails/${doctorItem.id}`}
                    style={{ textDecoration: "none" }}
                  >
                    <Card>
                      <CardHeader
                        avatar={
                          <Avatar
                            sx={{ bgcolor: "#3f51b5" }}
                            aria-label="recipe"
                          >
                            {<LocalHospitalIcon />}
                          </Avatar>
                        }
                        title={<Typography>{doctorItem.fullName}</Typography>}
                        subheader={
                          <Typography color="#6574c4">
                            {doctorItem?.qualification}
                          </Typography>
                        }
                      />
                      <Stack
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        spacing={2}
                      >
                        <Box style={{ height: "300px", width: "300px" }}>
                          <img
                            alt=""
                            width="100%"
                            height="100%"
                            src={
                              doctorItem?.image !== null
                                ? doctorItem?.image?.document
                                : "./dummydocImage.jpg"
                            }
                          />
                        </Box>
                      </Stack>

                      <CardContent>
                        <Grid
                          container
                          direction="row"
                          justifyContent="flex-start"
                          alignItems="flex-start"
                          spacing={2}
                        >
                          <Grid item>
                            <Typography>
                              {t("finddoc.spcl", { ns: ["home"] })}
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography variant="body1">
                              {doctorItem.specialtyWithYearsOfExperience}
                            </Typography>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Link>
                </Grid>
              ))
            : null}
        </Grid>
      </Stack>
    </>
  );

  return <>{actualPageMarkup}</>;
}
