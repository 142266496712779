import { Autocomplete, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";

interface Props {
  listvalue?: any;
  selectedItem?: any;
  placeHolder?: string;
  visibility?: boolean;
  dropdownValue: any;
  multipleValue?: boolean;
  filterSelectedOptionsValue?: boolean
}

const MWAutocomplete: React.FC<Props> = ({
  listvalue,
  selectedItem,
  placeHolder,
  visibility,
  dropdownValue,
  multipleValue,
  filterSelectedOptionsValue
}) => {
  const [itemValue, setItemValue] = useState([] as any[]);
  const [itemPlaceHolder, setItemPlaceHolder] = React.useState("");
  const [itemVisibility, setItemVisibility] = React.useState(false);
  
  const [selectedItemValue, setItemSelected] = useState<number | null>(null);

  const filterItem = (dropdownPatientName: any, e: any) => {
    setItemSelected(dropdownPatientName.value);
  };

  useEffect(() => {
    setItemPlaceHolder(placeHolder!)
    setItemVisibility(visibility!)
    setItemValue(listvalue!)
    setItemSelected(selectedItem)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listvalue, placeHolder, visibility, selectedItem]);

  useEffect(() => {
    dropdownValue(selectedItemValue)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItemValue])

  return (
    <div>
      <Autocomplete
        value={
          itemValue.find((element: any) => element.value === selectedItemValue) ||
          null
        }
        id="dropdown-states"
        options={itemValue}
        onChange={(event: any, newValue: any) => {
          filterItem(newValue, event);
        }}
        renderInput={(params) => (
          <TextField {...params} label={itemPlaceHolder} />
        )}
        disabled={itemVisibility}
        multiple={multipleValue}
        filterSelectedOptions={filterSelectedOptionsValue}
        disableClearable
        size="small"
      />
    </div>
  );
};

export default MWAutocomplete;
