import { needHelpValue } from "./Model";

// Patient Meeting Loading State
export const NEED_HELP_LOADING =
  "NEED_HELP_LOADING";
export const NEED_HELP_SUCCESS =
  "NEED_HELP_SUCCESS";
export const NEED_HELP_FAIL = "NEED_HELP_FAIL";
export const NEED_HELP = "NEED_HELP";

export interface needHelpDetails {
  needHelpDetails: needHelpValue;
  loading: boolean;
  errRes: string;
}
export interface needHelpLoading {
  type: typeof NEED_HELP_LOADING;
}
export interface needHelpSuccess {
  type: typeof NEED_HELP_SUCCESS;
  payload: needHelpValue;
  errRes: string;
}
export interface needHelpFail {
  type: typeof NEED_HELP_FAIL;
  errRes: string;
}

interface needHelpAction {
  type: typeof NEED_HELP;
  payload: needHelpValue;
  errRes: string;
}

export type needHelpActionTypes =
  | needHelpAction
  | needHelpLoading
  | needHelpSuccess
  | needHelpFail;
