import {
  SHARED_PATIENT_LIST_FAIL,
  SHARED_PATIENT_LIST_LOADING,
  SHARED_PATIENT_LIST_SUCCESS,
  SHARED_PATIENT_LIST_API_MSG,
  SHARED_PATIENT_LIST_API_RES,
  SharedPatientListActionTypes,
} from "./ActionTypes";
import { SharedPatientDetailsResponse } from "./Model";

export const SharedPatientListLoadingAction = (loading: boolean): SharedPatientListActionTypes => {
  return {
    type: SHARED_PATIENT_LIST_LOADING,
    loading: loading,
  };
};

export const SharedPatientListSuccessAction = (
  SharedPatientListResponse: SharedPatientDetailsResponse,
  successMsg: string
): SharedPatientListActionTypes => {
  return {
    type: SHARED_PATIENT_LIST_SUCCESS,
    payload: SharedPatientListResponse,
    successMsg: successMsg,
  };
};

export const SharedPatientListErrorAction = (
  SharedPatientListResponse: SharedPatientDetailsResponse,
  errMsg: string,
  status: number
): SharedPatientListActionTypes => {
  return {
    type: SHARED_PATIENT_LIST_FAIL,
    payload: SharedPatientListResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const SharedPatientListAPIMsgAction = (
  SharedPatientListResponse: SharedPatientDetailsResponse,
  successMsg: string,
  errMsg: string,
  status: number
): SharedPatientListActionTypes => {
  return {
    type: SHARED_PATIENT_LIST_API_MSG,
    payload: SharedPatientListResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const SharedPatientListAPIResClearAction = (
  SharedPatientListResponse: SharedPatientDetailsResponse,
  successMsg: string,
  errMsg: string,
  status: number
): SharedPatientListActionTypes => {
  return {
    type: SHARED_PATIENT_LIST_API_RES,
    payload: SharedPatientListResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
