import {
  SEARCH_DOCTOR_FAIL,
  SEARCH_DOCTOR_LOADING,
  SEARCH_DOCTOR_SUCCESS,
  SEARCH_DOCTOR_API_MSG,
  SEARCH_DOCTOR_API_RES,
  SearchDoctorActionTypes,
} from "./ActionTypes";
import { SearchDoctorResponse } from "./Model";

export const searchDoctorLoadingAction = (loading: boolean): SearchDoctorActionTypes => {
  return {
    type: SEARCH_DOCTOR_LOADING,
    loading: loading,
  };
};

export const searchDoctorSuccessAction = (
  searchDoctorResponse: SearchDoctorResponse,
  successMsg: string
): SearchDoctorActionTypes => {
  return {
    type: SEARCH_DOCTOR_SUCCESS,
    payload: searchDoctorResponse,
    successMsg: successMsg,
  };
};

export const searchDoctorErrorAction = (
  searchDoctorResponse: SearchDoctorResponse,
  errMsg: string,
  status: number
): SearchDoctorActionTypes => {
  return {
    type: SEARCH_DOCTOR_FAIL,
    payload: searchDoctorResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const searchDoctorAPIMsgAction = (
  searchDoctorResponse: SearchDoctorResponse,
  successMsg: string,
  errMsg: string,
  status: number
): SearchDoctorActionTypes => {
  return {
    type: SEARCH_DOCTOR_API_MSG,
    payload: searchDoctorResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const searchDoctorAPIResClearAction = (
  searchDoctorResponse: SearchDoctorResponse,
  successMsg: string,
  errMsg: string,
  status: number
): SearchDoctorActionTypes => {
  return {
    type: SEARCH_DOCTOR_API_RES,
    payload: searchDoctorResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
