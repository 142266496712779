import {
  needHelpDetails,
  needHelpActionTypes,
  NEED_HELP_FAIL,
  NEED_HELP_LOADING,
  NEED_HELP_SUCCESS,
} from "./ActionTypes";
import { needHelpValue } from "./Model";

const initialStateGetPosts: needHelpDetails = {
  loading: false,
  needHelpDetails: {} as needHelpValue,
  errRes: "",
};
export const needHelpReducer = (
  state = initialStateGetPosts,
  action: needHelpActionTypes
): needHelpDetails => {
  switch (action.type) {
    case NEED_HELP_SUCCESS:
      return {
        ...state,
        loading: false,
        needHelpDetails: action.payload,
        errRes: "",
      };
    case NEED_HELP_LOADING:
      return {
        ...state,
        loading: true,
        needHelpDetails: {} as needHelpValue,
      };
    case NEED_HELP_FAIL:
      return {
        ...state,
        needHelpDetails: {} as needHelpValue,
        loading: false,
        errRes: action.errRes,
      };
    default:
      return state;
  }
};
