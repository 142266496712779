import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import InformConsent from "./InformConsent";
import Importeddata from "./ImportedData";
import DemographicDetails from "./DemographicDetails";
import { useParams } from "react-router-dom";
import {
  Tabs,
  Tab,
  Typography,
  Grid,
  Paper,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Alert,
} from "@mui/material";
import { t } from "i18next";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </Box>
  );
}

function a11yProps(index: any) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export default function AddlHealthRecord(props: any) {
  const { patientid } = useParams() as {
    patientid: string;
  };
  const [tabValue, setTabValue] = React.useState(1);
  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
    localStorage.setItem("selctedprofiletab", String(newValue));
    window.scrollTo(0, 0);
  };
  //information modal fot patient consent.....
  const [active, setActive] = useState(false);
  const handleCosentChange = useCallback(() => setActive(!active), [active]);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (
      localStorage.getItem("consultationopt") ===
      "Comprehensive ( Recommended )"
    ) {
      setTabValue(19);
    } else if (
      localStorage.getItem("consultationopt") === "Medical Report Based"
    ) {
      setTabValue(1);
    }
  }, []);
  // Consent
  const ConsentModal = (
    <Dialog open={active} onClose={handleCosentChange} maxWidth="lg">
      <DialogTitle>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
          spacing={2}
        >
          <Grid item>{t("addHealthRecPage.teleMed", { ns: ["home"] })}</Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Box
          style={{
            border: "1px #6574c4 solid",
            backgroundColor: "#f0f3fe",
            borderRadius: "3px",
          }}
          p={3}
        >
          <Typography>
            <InformConsent />
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleCosentChange}>
          {t("addHealthRecPage.agree", { ns: ["home"] })}
        </Button>
      </DialogActions>
    </Dialog>
  );

  const patientAddDesc = patientid ? (
    <Tabs
      orientation="vertical"
      variant="scrollable"
      value={tabValue}
      onChange={handleTabChange}
      aria-label="Patient Health Record"
      indicatorColor="primary"
      sx={{
        backgroundColor: "#cddefa",
        alignItems: "center",
        textTransform: "none",
        fontSize: "1.5rem",
      }}
    >
      <Tab
        label={t("addpatientpage.demodetail", { ns: ["home"] })}
        {...a11yProps(0)}
      />
      <Tab
        label={t("preCompPage.presComp", { ns: ["home"] })}
        {...a11yProps(1)}
      />
      <Tab
        label={t("ideaPage.ideaTitle", { ns: ["home"] })}
        {...a11yProps(2)}
      />
      <Tab
        label={t("PresentingCompPage.preComp", { ns: ["home"] })}
        {...a11yProps(3)}
      />
      <Tab
        label={t("reviewSysPage.revSys", { ns: ["home"] })}
        {...a11yProps(4)}
      />
      <Tab
        label={t("pastHisPage.pastHis", { ns: ["home"] })}
        {...a11yProps(5)}
      />
      <Tab
        label={t("testProcInvPage.testProcTitle", { ns: ["home"] })}
        {...a11yProps(6)}
      />
      <Tab
        label={t("medOrPresPage.medPres", { ns: ["home"] })}
        {...a11yProps(7)}
      />
      <Tab
        label={t("allergyPage.allergy", { ns: ["home"] })}
        {...a11yProps(8)}
      />
      <Tab label={t("immPage.immTitle", { ns: ["home"] })} {...a11yProps(9)} />
      <Tab
        label={t("famHisPage.famHis", { ns: ["home"] })}
        {...a11yProps(10)}
      />
      <Tab
        label={t("nutriHisPage.nutriHis", { ns: ["home"] })}
        {...a11yProps(11)}
      />
      <Tab
        label={t("perSocHisPage.perSocHis", { ns: ["home"] })}
        {...a11yProps(12)}
      />
      <Tab
        label={t("healthMainPage.healthMain", { ns: ["home"] })}
        {...a11yProps(13)}
      />
      <Tab
        label={t("riskFacPage.riskFac", { ns: ["home"] })}
        {...a11yProps(14)}
      />
      <Tab
        label={t("diagPage.diagTitle", { ns: ["home"] })}
        {...a11yProps(15)}
      />
      <Tab
        label={t("medSumPage.medSum", { ns: ["home"] })}
        {...a11yProps(16)}
      />
      <Tab
        label={t("probListPage.probList", { ns: ["home"] })}
        {...a11yProps(17)}
      />
      <Tab
        label={t("treatmentPage.trtTitle", { ns: ["home"] })}
        {...a11yProps(18)}
      />
      <Tab
        label={t("impDataPage.imptdData", { ns: ["home"] })}
        {...a11yProps(19)}
      />
    </Tabs>
  ) : (
    <div>
      <Alert>
        <Box sx={{ maxHeight: "340px", overflow: "auto" }}>
          <InformConsent />
        </Box>
      </Alert>
      <Box py="5">
        <Typography>{t("addpatientpage.subcon", { ns: ["home"] })}</Typography>
      </Box>
    </div>
  );

  const ActualPageMarkup = (
    //edit health record part.........
    <div>
      <Grid container spacing={1}>
        <Grid item xs={3}>
          <Paper variant="outlined" sx={{ backgroundColor: "#FBEAE5" }}>
            {patientAddDesc}
          </Paper>
        </Grid>
        <Grid item xs={9}>
          {patientid ? (
            <Box>
              <Box sx={{ marginTop: "-30px" }}>
                <TabPanel value={tabValue} index={0}>
                  <DemographicDetails />
                </TabPanel>
                <TabPanel value={tabValue} index={19}>
                  <Importeddata />
                </TabPanel>
              </Box>
            </Box>
          ) : (
            ""
          )}
        </Grid>
      </Grid>
    </div>
  );

  return (
    <Box>
      {ActualPageMarkup}
      {ConsentModal}
    </Box>
  );
}
