import { CaseListResponse } from "./Model";

export const CASE_LIST_LOADING =
  "CASE_LIST_LOADING";
export const CASE_LIST_SUCCESS =
  "CASE_LIST_SUCCESS";
export const CASE_LIST_FAIL = "CASE_LIST_FAIL";
export const CASE_LIST_UPDATE_API_MSG =
  "CASE_LIST_UPDATE_API_MSG";
export const CASE_LIST_UPDATE_API_RES =
  "CASE_LIST_UPDATE_API_RES";

export interface CaseListDetailsState {
  caseListResponse: CaseListResponse;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface CaseListDetailsLoading {
  type: typeof CASE_LIST_LOADING;
  loading: boolean;
}
export interface CaseListDetailsSuccess {
  type: typeof CASE_LIST_SUCCESS;
  payload: CaseListResponse;
  successMsg: string;
}
export interface CaseListDetailsFail {
  type: typeof CASE_LIST_FAIL;
  payload: CaseListResponse;
  errorMsg: string;
  status: number;
}
export interface CaseListDetailsUpdateAPIMsg {
  type: typeof CASE_LIST_UPDATE_API_MSG;
  payload: CaseListResponse;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface CaseListDetailsUpdateAPIRes {
  type: typeof CASE_LIST_UPDATE_API_RES;
  payload: CaseListResponse;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type CaseListActionTypes =
  | CaseListDetailsLoading
  | CaseListDetailsSuccess
  | CaseListDetailsFail
  | CaseListDetailsUpdateAPIMsg
  | CaseListDetailsUpdateAPIRes;
