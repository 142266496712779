import axios from "axios";
import { Dispatch } from "redux";
import baseUrl from "../../../Url";
import { GetPatientInsuranceListActionTypes } from "./ActionTypes";
import { GetInsuranceListRes, GetPatientBody } from "./Model";
import {
  getPatientInsuranceListAPIResClearAction,
  getPatientInsuranceListErrorAction,
  getPatientInsuranceListLoadingAction,
  getPatientInsuranceListSuccessAction,
  getPatientInsuranceListAPIMsgAction,
} from "./Actions";
import { headers } from "../../../components/Utility";
import { setupInterceptorsTo } from "../../Authentication/Interceptors";

let apiRes = {} as GetInsuranceListRes;
export const getInsuranceList = (payload: GetPatientBody) => {
  return function (dispatch: Dispatch<GetPatientInsuranceListActionTypes>) {
    dispatch(getPatientInsuranceListLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        baseUrl.baseUrl +
          `/patientapp/api/patient/get_insurance_details_by_patient`,
          payload,
        {
          headers: headers,
        }
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(getPatientInsuranceListLoadingAction(false));
        dispatch(
          getPatientInsuranceListSuccessAction(
            res.data,
            res.data.message !== undefined
              ? res.data.message
              : "Patient. insurance list has been fetched successfully"
          )
        );
      })
      .catch((error) => {
        dispatch(getPatientInsuranceListLoadingAction(false));
        dispatch(
          getPatientInsuranceListErrorAction(
            {} as GetInsuranceListRes,
            error.response.data !== undefined
              ? error.response.data.message !== undefined
                ? error.response.data.message
                : "Something went wrong"
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0
          )
        );
      });
  };
};

export const getPatientInsuranceListAPIResMsg = () => {
  return function (dispatch: Dispatch<GetPatientInsuranceListActionTypes>) {
    dispatch(
      getPatientInsuranceListAPIMsgAction(
        apiRes as GetInsuranceListRes,
        "",
        "",
        0
      )
    );
  };
};

export const clearGetPatientInsuranceListAPIRes = () => {
  return function (dispatch: Dispatch<GetPatientInsuranceListActionTypes>) {
    dispatch(
      getPatientInsuranceListAPIResClearAction(
        {} as GetInsuranceListRes,
        "",
        "",
        0
      )
    );
  };
};
