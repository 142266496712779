import {
  GET_PATIENT_LOGIN_DETAILS_FAIL,
  GET_PATIENT_LOGIN_DETAILS_LOADING,
  GET_PATIENT_LOGIN_DETAILS_SUCCESS,
  GET_PATIENT_LOGIN_DETAILS_API_MSG,
  GET_PATIENT_LOGIN_DETAILS_API_RES,
  GetPatientDetailsActionTypes,
} from "./ActionTypes";
import { GetPatientDetailsRes } from "./Model";

export const getPatientDetailsLoadingAction = (
  loading: boolean
): GetPatientDetailsActionTypes => {
  return {
    type: GET_PATIENT_LOGIN_DETAILS_LOADING,
    loading: loading,
  };
};

export const getPatientDetailsSuccessAction = (
  getPatientLoginDetailsResponse: GetPatientDetailsRes,
  successMsg: string
): GetPatientDetailsActionTypes => {
  return {
    type: GET_PATIENT_LOGIN_DETAILS_SUCCESS,
    payload: getPatientLoginDetailsResponse,
    successMsg: successMsg
  };
};

export const getPatientDetailsErrorAction = (
  getPatientLoginDetailsResponse: GetPatientDetailsRes,
  errMsg: string,
  status: number
): GetPatientDetailsActionTypes => {
  return {
    type: GET_PATIENT_LOGIN_DETAILS_FAIL,
    payload: getPatientLoginDetailsResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const getPatientDetailsAPIMsgAction = (
  getPatientLoginDetailsResponse: GetPatientDetailsRes,
  errMsg: string,
  status: number
): GetPatientDetailsActionTypes => {
  return {
    type: GET_PATIENT_LOGIN_DETAILS_API_MSG,
    payload: getPatientLoginDetailsResponse,
    errorMsg: errMsg,
    status: status,
  };
};

export const getPatientDetailsAPIResClearAction = (
  getPatientLoginDetailsResponse: GetPatientDetailsRes,
  errMsg: string,
  status: number
): GetPatientDetailsActionTypes => {
  return {
    type: GET_PATIENT_LOGIN_DETAILS_API_RES,
    payload: getPatientLoginDetailsResponse,
    errorMsg: errMsg,
    status: status,
  };
};
