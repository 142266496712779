import {
  OWNER_PAYMENT_LIST_FAIL,
  OWNER_PAYMENT_LIST_LOADING,
  OWNER_PAYMENT_LIST_SUCCESS,
  OWNER_PAYMENT_LIST_API_MSG,
  OWNER_PAYMENT_LIST_API_RES,
  OwnerPaymentListActionTypes,
} from "./ActionTypes";
import { OwnerPaymentListResponse } from "./Model";

export const ownerPaymentListLoadingAction = (
  loading: boolean
): OwnerPaymentListActionTypes => {
  return {
    type: OWNER_PAYMENT_LIST_LOADING,
    loading: loading,
  };
};

export const ownerPaymentListSuccessAction = (
  ownerPaymentListResponse: OwnerPaymentListResponse,
  successMsg: string
): OwnerPaymentListActionTypes => {
  return {
    type: OWNER_PAYMENT_LIST_SUCCESS,
    payload: ownerPaymentListResponse,
    successMsg: successMsg,
  };
};

export const ownerPaymentListErrorAction = (
  ownerPaymentListResponse: OwnerPaymentListResponse,
  errMsg: string,
  status: number
): OwnerPaymentListActionTypes => {
  return {
    type: OWNER_PAYMENT_LIST_FAIL,
    payload: ownerPaymentListResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const ownerPaymentListAPIMsgAction = (
  ownerPaymentListResponse: OwnerPaymentListResponse,
  successMsg: string,
  errMsg: string,
  status: number
): OwnerPaymentListActionTypes => {
  return {
    type: OWNER_PAYMENT_LIST_API_MSG,
    payload: ownerPaymentListResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const ownerPaymentListAPIResClearAction = (
  ownerPaymentListResponse: OwnerPaymentListResponse,
  successMsg: string,
  errMsg: string,
  status: number
): OwnerPaymentListActionTypes => {
  return {
    type: OWNER_PAYMENT_LIST_API_RES,
    payload: ownerPaymentListResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
