import { SexualOrientationResponse } from "./Model";
export const SEXUALORIENTATION_LIST_LOADING = "SEXUALORIENTATION_LIST_LOADING";
export const SEXUALORIENTATION_LIST_SUCCESS = "SEXUALORIENTATION_LIST_SUCCESS";
export const SEXUALORIENTATION_LIST_FAIL = "SEXUALORIENTATION_LIST_FAIL";
export const SEXUALORIENTATION_LIST_API_MSG = "SEXUALORIENTATION_LIST_API_MSG";
export const SEXUALORIENTATION_LIST_API_RES = "SEXUALORIENTATION_LIST_API_RES";

export interface SexualOrientationState {
  sexualOrientationResponse: SexualOrientationResponse;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface SexualOrientationLoading {
  type: typeof SEXUALORIENTATION_LIST_LOADING;
  loading: boolean;
}
export interface SexualOrientationSuccess {
  type: typeof SEXUALORIENTATION_LIST_SUCCESS;
  payload: SexualOrientationResponse;
  successMsg: string;
}
export interface SexualOrientationFail {
  type: typeof SEXUALORIENTATION_LIST_FAIL;
  payload: SexualOrientationResponse;
  errorMsg: string;
  status: number;
}
export interface SexualOrientationAPIMsg {
  type: typeof SEXUALORIENTATION_LIST_API_MSG;
  payload: SexualOrientationResponse;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface SexualOrientationAPIRes {
  type: typeof SEXUALORIENTATION_LIST_API_RES;
  payload: SexualOrientationResponse;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type SexualOrientationActionTypes =
  | SexualOrientationLoading
  | SexualOrientationSuccess
  | SexualOrientationFail
  | SexualOrientationAPIMsg
  | SexualOrientationAPIRes;
