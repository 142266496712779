import React from "react";
import { Route, Switch } from "react-router-dom";
import { PublicRoute } from "./routes/PublicRoute";
import { PrivateRoute } from "./routes/PrivateRoute";
import Login from "./pages/PatientOnBoarding/Login";
import Signup from "./pages/PatientOnBoarding/Signup";
import NotFound from "./pages/NotFound";
import Dashboard from "./pages/Dashboard";
import AddHealthRecord from "./pages/CaseDetails/FilePreparation/AddHealthRecord";
import Userprofile from "./pages/UserProfile/Userprofile";
import NeedHelp from "./pages/NeedHelp";
import CodeOfConduct from "./pages/CodeOfConduct";
import CaseHistory from "./pages/CaseHistory";
import FilePreparation from "./pages/CaseDetails/FilePreparation";
import DemographicDetails from "./pages/CaseDetails/FilePreparation/DemographicDetails";
import InsuranceDetails from "./pages/Insurance";
import TaskDetails from "./pages/Task/TaskDetails";
import AppointmentDetails from "./pages/Appointment/AppoinmentDetails";
import DoctorDetails from "./pages/DoctorDetails";
import Doctor from "./pages/Doctor";
import PaymentPage from "./pages/Payment";
import PayPal from "./pages/CaseDetails/PaymentConfirm/PayPal";

function ComponentRouter() {
  return (
    <Switch>
      <PublicRoute exact path="/" component={Login} />
      <PublicRoute exact path="/signin" component={Login} />
      <PublicRoute exact path="/signup" component={Signup} />
      <PrivateRoute path="/dashboard" component={Dashboard} />
      <PrivateRoute
        path="/addhealthrecord/:patientid?"
        component={AddHealthRecord}
      />
      <PrivateRoute path="/casehistory/:patientid?" component={CaseHistory} />
      <PrivateRoute
        path="/casedetails/:patientid?/:caseid?/:paymentRedirect?"
        component={FilePreparation}
      />
      <PrivateRoute path="/userprofile" component={Userprofile} />
      <PrivateRoute path="/doctor" component={Doctor} />
      <PrivateRoute path="/payment" component={PaymentPage} />
      <PrivateRoute path="/payment-confirmation" component={PayPal} />
      <PrivateRoute path="/taskdetails/:rowIndex?" component={TaskDetails} />
      <PrivateRoute path="/needhelp" component={NeedHelp} />
      <PrivateRoute path="/codeofconduct" component={CodeOfConduct} />
      <PrivateRoute
        path="/demographicdetails/:patientid?"
        component={DemographicDetails}
      />
      <PrivateRoute
        path="/insurancedetails/:patientid?"
        component={InsuranceDetails}
      />
      <PrivateRoute path="/appoinmentDetails" component={AppointmentDetails} />
      <PrivateRoute path="/doctordetails/:id?" component={DoctorDetails} />
      <Route component={NotFound} />
    </Switch>
  );
}

export default ComponentRouter;
