import React, { useEffect } from "react";
import {
  Alert,
  AlertTitle,
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { t } from "i18next";
import { useSelector } from "react-redux";
import { AppState } from "../../../../redux/store/Store";
export default function SelectedOptions() {
  const getAppointmentDetailsForCaseRes = useSelector(
    (state: AppState) => state.getAppointmentDetailsForCaseRes
  );
  useEffect(() => {}, []);
  return (
    <Card>
      <CardHeader title={t("selOpPage.selOp", { ns: ["home"] })!} />
      <CardContent>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Grid item>
            <Stack
              direction="column"
              justifyContent="flex-start"
              alignItems="stretch"
              spacing={2}
            >
              <Typography component="h6" style={{ fontWeight: "bold" }}>
                {t("selOpPage.conType", { ns: ["home"] })}
              </Typography>
              <Divider />
              <Box>
                <Alert variant="outlined" severity="info">
                  <AlertTitle>
                    <Typography variant="subtitle1" fontWeight="bold">
                      {getAppointmentDetailsForCaseRes
                        ?.getAppointmentDetailsForCaseRes?.consultationType !==
                      undefined
                        ? getAppointmentDetailsForCaseRes
                            ?.getAppointmentDetailsForCaseRes
                            ?.consultationType !== null
                          ? getAppointmentDetailsForCaseRes
                              ?.getAppointmentDetailsForCaseRes
                              ?.consultationType !== ""
                            ? getAppointmentDetailsForCaseRes
                                ?.getAppointmentDetailsForCaseRes
                                ?.consultationType
                            : null
                          : null
                        : null}
                    </Typography>
                  </AlertTitle>
                </Alert>
              </Box>
            </Stack>
          </Grid>
          <Grid item>
            <Stack
              direction="column"
              justifyContent="flex-start"
              alignItems="stretch"
              spacing={2}
            >
              <Typography component="h6" style={{ fontWeight: "bold" }}>
                {t("consultOpPage.resTime", { ns: ["home"] })}
              </Typography>
              <Divider />
              <Box>
                <Alert variant="outlined" severity="info">
                  <AlertTitle>
                    <Typography variant="subtitle1" fontWeight="bold">
                      {getAppointmentDetailsForCaseRes
                        ?.getAppointmentDetailsForCaseRes?.responseTimeType !==
                      undefined
                        ? getAppointmentDetailsForCaseRes
                            ?.getAppointmentDetailsForCaseRes
                            ?.responseTimeType !== null
                          ? getAppointmentDetailsForCaseRes
                              ?.getAppointmentDetailsForCaseRes
                              ?.responseTimeType !== ""
                            ? getAppointmentDetailsForCaseRes
                                ?.getAppointmentDetailsForCaseRes
                                ?.responseTimeType
                            : null
                          : null
                        : null}
                    </Typography>
                  </AlertTitle>
                </Alert>
              </Box>
            </Stack>
          </Grid>
          <Grid item>
            <Stack
              direction="column"
              justifyContent="flex-start"
              alignItems="stretch"
              spacing={2}
            >
              <Typography component="h6" style={{ fontWeight: "bold" }}>
                {t("selOpPage.docSelType", { ns: ["home"] })}
              </Typography>
              <Divider />
              <Box>
                <Alert variant="outlined" severity="info">
                  <AlertTitle>
                    <Typography variant="subtitle1" fontWeight="bold">
                      {getAppointmentDetailsForCaseRes
                        ?.getAppointmentDetailsForCaseRes?.doctorTeamType !==
                      undefined
                        ? getAppointmentDetailsForCaseRes
                            ?.getAppointmentDetailsForCaseRes
                            ?.doctorTeamType !== null
                          ? getAppointmentDetailsForCaseRes
                              ?.getAppointmentDetailsForCaseRes
                              ?.doctorTeamType !== ""
                            ? getAppointmentDetailsForCaseRes
                                ?.getAppointmentDetailsForCaseRes
                                ?.doctorTeamType
                            : null
                          : null
                        : null}
                    </Typography>
                  </AlertTitle>
                </Alert>
              </Box>
            </Stack>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
