import {
  GET_QUESTION_LIST_FAIL,
  GET_QUESTION_LIST_LOADING,
  GET_QUESTION_LIST_SUCCESS,
  GET_QUESTION_LIST_API_MSG,
  GET_QUESTION_LIST_API_RES,
  GetQuestionListActionTypes,
} from "./ActionTypes";
import { GetQuestionListRes } from "./Model";

export const getQuestionListLoadingAction = (
  loading: boolean
): GetQuestionListActionTypes => {
  return {
    type: GET_QUESTION_LIST_LOADING,
    loading: loading,
  };
};

export const getQuestionListSuccessAction = (
  getQuestionListResponse: GetQuestionListRes,
  successMsg: string
): GetQuestionListActionTypes => {
  return {
    type: GET_QUESTION_LIST_SUCCESS,
    payload: getQuestionListResponse,
    successMsg: successMsg,
  };
};

export const getQuestionListErrorAction = (
  getQuestionListResponse: GetQuestionListRes,
  errMsg: string,
  status: number
): GetQuestionListActionTypes => {
  return {
    type: GET_QUESTION_LIST_FAIL,
    payload: getQuestionListResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const getQuestionListAPIMsgAction = (
  getQuestionListResponse: GetQuestionListRes,
  successMsg: string,
  errMsg: string,
  status: number
): GetQuestionListActionTypes => {
  return {
    type: GET_QUESTION_LIST_API_MSG,
    payload: getQuestionListResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const getQuestionListAPIResClearAction = (
  getQuestionListResponse: GetQuestionListRes,
  successMsg: string,
  errMsg: string,
  status: number
): GetQuestionListActionTypes => {
  return {
    type: GET_QUESTION_LIST_API_RES,
    payload: getQuestionListResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
