import {
  CASE_DETAILS_FAIL,
  CASE_DETAILS_LOADING,
  CASE_DETAILS_SUCCESS,
  CASE_DETAILS_API_MSG,
  CASE_DETAILS_API_RES,
  CaseDetailsActionTypes,
} from "./ActionTypes";
import { PatientCaseDetailsResponse } from "./Model";

export const caseDetailsLoadingAction = (loading: boolean): CaseDetailsActionTypes => {
  return {
    type: CASE_DETAILS_LOADING,
    loading: loading,
  };
};

export const caseDetailsSuccessAction = (
  caseDetailsResponse: PatientCaseDetailsResponse,
  successMsg: string
): CaseDetailsActionTypes => {
  return {
    type: CASE_DETAILS_SUCCESS,
    payload: caseDetailsResponse,
    successMsg: successMsg,
  };
};

export const caseDetailsErrorAction = (
  caseDetailsResponse: PatientCaseDetailsResponse,
  errMsg: string,
  status: number
): CaseDetailsActionTypes => {
  return {
    type: CASE_DETAILS_FAIL,
    payload: caseDetailsResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const caseDetailsAPIMsgAction = (
  caseDetailsResponse: PatientCaseDetailsResponse,
  successMsg: string,
  errMsg: string,
  status: number
): CaseDetailsActionTypes => {
  return {
    type: CASE_DETAILS_API_MSG,
    payload: caseDetailsResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const caseDetailsAPIResClearAction = (
  caseDetailsResponse: PatientCaseDetailsResponse,
  successMsg: string,
  errMsg: string,
  status: number
): CaseDetailsActionTypes => {
  return {
    type: CASE_DETAILS_API_RES,
    payload: caseDetailsResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
