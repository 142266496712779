import axios from "axios";
import { Dispatch } from "redux";
import { CreateAppointmentActionTypes } from "./ActionTypes";
import { CreateAppointmentReqBody, CreateAppointmentReqRes } from "./Model";
import {
  createAppointmentAPIResClearAction,
  createAppointmentErrorAction,
  createAppointmentLoadingAction,
  createAppointmentSuccessAction,
  createAppointmentUpdateAPIMsgAction,
} from "./Action";
import { setupInterceptorsTo } from "../../Authentication/Interceptors";
import Url from "../../../Url";
import { headers } from "../../../components/Utility";

let apiRes = {} as CreateAppointmentReqRes;

export const createAppointment = (payload: CreateAppointmentReqBody) => {
  return function (dispatch: Dispatch<CreateAppointmentActionTypes>) {
    dispatch(createAppointmentLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        Url.baseUrl + `/appointmentapp/api/appointment/book_appointment`,
        payload,
        {
          headers: headers,
        }
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(createAppointmentLoadingAction(false));
        dispatch(
          createAppointmentSuccessAction(
            res.data !== undefined ? res.data : ({} as CreateAppointmentReqRes),
            res.data.message !== undefined
              ? res.data.message
              : "Appointment has been created for patient case successfully"
          )
        );
      })
      .catch((error) => {
        dispatch(createAppointmentLoadingAction(false));
        dispatch(
          createAppointmentErrorAction(
            {} as CreateAppointmentReqRes,
            error.response !== undefined
              ? error.response.data.message || error.response.data.detail
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0
          )
        );
      });
  };
};

export const updateEditDoctorTeamTypeAPIResMsg = () => {
  return function (dispatch: Dispatch<CreateAppointmentActionTypes>) {
    dispatch(
      createAppointmentUpdateAPIMsgAction(
        apiRes as CreateAppointmentReqRes,
        "",
        "",
        0
      )
    );
  };
};

export const clearCreateAppointmentAPIRes = () => {
  return function (dispatch: Dispatch<CreateAppointmentActionTypes>) {
    dispatch(
      createAppointmentAPIResClearAction(
        {} as CreateAppointmentReqRes,
        "",
        "",
        0
      )
    );
  };
};
