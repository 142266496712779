import {
  RELATIONSHIP_LIST_FAIL,
  RELATIONSHIP_LIST_LOADING,
  RELATIONSHIP_LIST_SUCCESS,
  RELATIONSHIP_LIST_API_MSG,
  RELATIONSHIP_LIST_API_RES,
  RelationshipListActionTypes,
} from "./ActionTypes";
import { RelationshipResponse } from "./Model";

export const RelationshipListLoadingAction = (loading: boolean): RelationshipListActionTypes => {
  return {
    type: RELATIONSHIP_LIST_LOADING,
    loading: loading,
  };
};

export const RelationshipListSuccessAction = (
  maritalStatusResponse: RelationshipResponse,
  successMsg: string
): RelationshipListActionTypes => {
  return {
    type: RELATIONSHIP_LIST_SUCCESS,
    payload: maritalStatusResponse,
    successMsg: successMsg,
  };
};

export const RelationshipListErrorAction = (
  maritalStatusResponse: RelationshipResponse,
  errMsg: string,
  status: number
): RelationshipListActionTypes => {
  return {
    type: RELATIONSHIP_LIST_FAIL,
    payload: maritalStatusResponse,
    errorMsg: errMsg,
    status: status,
  };
};

export const RelationshipListAPIMsgAction = (
  maritalStatusResponse: RelationshipResponse,
  successMsg: string,
  errMsg: string,
  status: number
): RelationshipListActionTypes => {
  return {
    type: RELATIONSHIP_LIST_API_MSG,
    payload: maritalStatusResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const RelationshipListAPIResClearAction = (
  maritalStatusResponse: RelationshipResponse,
  successMsg: string,
  errMsg: string,
  status: number
): RelationshipListActionTypes => {
  return {
    type: RELATIONSHIP_LIST_API_RES,
    payload: maritalStatusResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
