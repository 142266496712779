import axios from "axios";
import { Dispatch } from "redux";
import { GetDoctorSpecialityListActionTypes } from "./ActionTypes";
import { GetDoctorSpecialistListRes } from "./Model";
import {
  getDoctorSpecialityListAPIResClearAction,
  getDoctorSpecialityListErrorAction,
  getDoctorSpecialityListLoadingAction,
  getDoctorSpecialityListSuccessAction,
  getDoctorSpecialityListUpdateAPIMsgAction,
} from "./Actions";
import Url from "../../../Url";
import { headers } from "../../../components/Utility";
import { setupInterceptorsTo } from "../../Authentication/Interceptors";

let apiRes = {} as GetDoctorSpecialistListRes;
export const getSpecialistList = () => {
  return function (dispatch: Dispatch<GetDoctorSpecialityListActionTypes>) {
    dispatch(getDoctorSpecialityListLoadingAction(true));
    setupInterceptorsTo(axios)
      .get(
        Url.baseUrl + `/doctorapp/api/onboarding/speciality_list`,

        {
          headers: headers,
        }
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(getDoctorSpecialityListLoadingAction(false));
        dispatch(
          getDoctorSpecialityListSuccessAction(
            res.data !== undefined
              ? res.data
              : ({} as GetDoctorSpecialistListRes),
            res.data.message !== undefined
              ? res.data.message !== ""
                ? res.data.message
                : "Doctor category list details has been fetched successfully"
              : "Doctor category list details has been fetched successfully"
          )
        );
      })
      .catch((error) => {
        dispatch(getDoctorSpecialityListLoadingAction(false));
        dispatch(
          getDoctorSpecialityListErrorAction(
            {} as GetDoctorSpecialistListRes,
            error.response !== undefined
              ? error.response.data.message || error.response.data.detail
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || -1 : -1
          )
        );
      });
  };
};

export const updateGetDoctorSpeciaListAPIResMsg = () => {
  return function (dispatch: Dispatch<GetDoctorSpecialityListActionTypes>) {
    dispatch(
      getDoctorSpecialityListUpdateAPIMsgAction(
        apiRes as GetDoctorSpecialistListRes,
        "",
        "",
        0
      )
    );
  };
};

export const clearDoctorSpeciaListAPIRes = () => {
  return function (dispatch: Dispatch<GetDoctorSpecialityListActionTypes>) {
    dispatch(
      getDoctorSpecialityListAPIResClearAction(
        {} as GetDoctorSpecialistListRes,
        "",
        "",
        0
      )
    );
  };
};
