import { PatientListRes } from "./Model";
export const PATIENT_DROPDOWN_LIST_LOADING = "PATIENT_DROPDOWN_LIST_LOADING";
export const PATIENT_DROPDOWN_LIST_SUCCESS = "PATIENT_DROPDOWN_LIST_SUCCESS";
export const PATIENT_DROPDOWN_LIST_FAIL = "PATIENT_DROPDOWN_LIST_FAIL";
export const PATIENT_DROPDOWN_LIST_API_MSG = "PATIENT_DROPDOWN_LIST_API_MSG";
export const PATIENT_DROPDOWN_LIST_API_RES = "PATIENT_DROPDOWN_LIST_API_RES";

export interface PatientDropDownListState {
  patientDropDownListRes: PatientListRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface PatientDropDownListLoading {
  type: typeof PATIENT_DROPDOWN_LIST_LOADING;
  loading: boolean;
}
export interface PatientDropDownListSuccess {
  type: typeof PATIENT_DROPDOWN_LIST_SUCCESS;
  payload: PatientListRes;
  successMsg: string;
}
export interface PatientDropDownListFail {
  type: typeof PATIENT_DROPDOWN_LIST_FAIL;
  payload: PatientListRes;
  errorMsg: string;
  status: number;
}
export interface PatientDropDownListAPIMsg {
  type: typeof PATIENT_DROPDOWN_LIST_API_MSG;
  payload: PatientListRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface PatientDropDownListAPIRes {
  type: typeof PATIENT_DROPDOWN_LIST_API_RES;
  payload: PatientListRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type PatientDropDownListActionTypes =
  | PatientDropDownListLoading
  | PatientDropDownListSuccess
  | PatientDropDownListFail
  | PatientDropDownListAPIMsg
  | PatientDropDownListAPIRes;
