import {
  RESPONSE_TIME_FAIL,
  RESPONSE_TIME_LOADING,
  RESPONSE_TIME_SUCCESS,
  RESPONSE_TIME_API_MSG,
  RESPONSE_TIME_API_RES,
  ResponseTimeActionTypes,
} from "./ActionTypes";
import { ResponseTimeResponse } from "./Model";

export const responseTimeLoadingAction = (loading: boolean): ResponseTimeActionTypes => {
  return {
    type: RESPONSE_TIME_LOADING,
    loading: loading,
  };
};

export const responseTimeSuccessAction = (
  responseTimeResponse: ResponseTimeResponse,
  successMsg: string
): ResponseTimeActionTypes => {
  return {
    type: RESPONSE_TIME_SUCCESS,
    payload: responseTimeResponse,
    successMsg: successMsg,
  };
};

export const responseTimeErrorAction = (
  responseTimeResponse: ResponseTimeResponse,
  errMsg: string,
  status: number
): ResponseTimeActionTypes => {
  return {
    type: RESPONSE_TIME_FAIL,
    payload: responseTimeResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const responseTimeAPIMsgAction = (
  responseTimeResponse: ResponseTimeResponse,
  successMsg: string,
  errMsg: string,
  status: number
): ResponseTimeActionTypes => {
  return {
    type: RESPONSE_TIME_API_MSG,
    payload: responseTimeResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const responseTimeAPIResClearAction = (
  responseTimeResponse: ResponseTimeResponse,
  successMsg: string,
  errMsg: string,
  status: number
): ResponseTimeActionTypes => {
  return {
    type: RESPONSE_TIME_API_RES,
    payload: responseTimeResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
