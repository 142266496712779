import React, { useEffect } from "react";
import { Stack } from "@mui/material";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import SelectedOptions from "./SelectedOptions";
import RequestAppointment from "./RequestAppointment";
import ExistingAppointmentDetails from "./ExistingAppointmentDetails";
import { getAppointmentDetailsForCase } from "../../../redux/Appointment/GetAppointmentList/API";
import { GetAppointmentDetailsForCaseBody } from "../../../redux/Appointment/GetAppointmentList/Model";

export default function AppointmentBooking() {
  const { caseid } = useParams() as {
    caseid: string;
  };
  const dispatch = useDispatch();
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(
      getAppointmentDetailsForCase({
        caseId: caseid,
      } as GetAppointmentDetailsForCaseBody)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Stack
      direction="column"
      justifyContent="flex-start"
      alignItems="stretch"
      spacing={2}
    >
      <SelectedOptions />
      <RequestAppointment caseId={caseid} />
      <ExistingAppointmentDetails caseId={caseid} />
    </Stack>
  );
}
