import {
  BloodGrpActionTypes,
  BLOOD_GRP_FAIL,
  BLOOD_GRP_LOADING,
  BLOOD_GRP_SUCCESS,
  BloodGrpState,
  BLOOD_GRP_API_MSG,
  BLOOD_GRP_API_RES,
} from "./ActionTypes";
import { BloodGroupListResponse } from "./Model";

const initialStateEditPosts: BloodGrpState = {
  loading: false,
  BloodGrpResponse: {} as BloodGroupListResponse,
  successMsg: "",
  errorMsg: "",
  status: 0,
};
export const BloodGrpReducer = (
  state = initialStateEditPosts,
  action: BloodGrpActionTypes
): BloodGrpState => {
  switch (action.type) {
    case BLOOD_GRP_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case BLOOD_GRP_SUCCESS:
      return {
        ...state,
        BloodGrpResponse: action.payload,
        successMsg: action.successMsg,
      };
    case BLOOD_GRP_FAIL:
      return {
        ...state,
        BloodGrpResponse: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case BLOOD_GRP_API_MSG:
      return {
        ...state,
        BloodGrpResponse: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case BLOOD_GRP_API_RES:
      return {
        ...state,
        BloodGrpResponse: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
