import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
} from "@mui/material";
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridToolbar,
} from "@mui/x-data-grid";
import RefreshIcon from "@mui/icons-material/Refresh";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { ModifiedQuestionList } from "./Model";
import MWExceptionList from "../../../../components/MWExceptionList";
import { useSelector } from "react-redux";
import { AppState } from "../../../../redux/store/Store";
import {
  GetQuestionListBody,
  QuestionList,
} from "../../../../redux/CaseDetails/DoctorPatientInteraction/getQuestionListForPatient/Model";
import { useDispatch } from "react-redux";
import { getQuestionList } from "../../../../redux/CaseDetails/DoctorPatientInteraction/getQuestionListForPatient/API";
import ViewAddAnswer from "./ViewAddAnswer";

export interface Props {
  caseId: string | any;
}
export default function QuestionAnswer({ caseId }: Props) {
  const dispatch = useDispatch();
  const [questionList, setQuestionList] = useState(
    [] as ModifiedQuestionList[]
  );

  const questionListRes = useSelector(
    (state: AppState) => state.getQuestionListRes
  );
  useEffect(() => {
    if (questionListRes?.getQuestionListRes?.questionList !== undefined) {
      if (questionListRes?.getQuestionListRes?.questionList.length > 0) {
        let questionItem =
          questionListRes?.getQuestionListRes?.questionList.map(
            (item: QuestionList) => {
              return {
                id: item.id,
                viewAnswer: item.id,
                description:
                  item?.questionText !== undefined
                    ? item?.questionText !== ""
                      ? item?.questionText
                      : `${t("common.notSet", { ns: ["home"] })!}`
                    : `${t("common.notSet", { ns: ["home"] })!}`,
                askedBy:
                  item?.askedBy !== undefined
                    ? item?.askedBy !== ""
                      ? item?.askedBy
                      : `${t("common.notSet", { ns: ["home"] })!}`
                    : `${t("common.notSet", { ns: ["home"] })!}`,
                answer:
                  item?.answerText !== undefined
                    ? item?.answerText !== ""
                      ? item?.answerText
                      : `${t("common.notSet", { ns: ["home"] })!}`
                    : `${t("common.notSet", { ns: ["home"] })!}`,
                answerBy:
                  item?.answeredBy !== undefined
                    ? item?.answeredBy !== ""
                      ? item?.answeredBy
                      : `${t("common.notSet", { ns: ["home"] })!}`
                    : `${t("common.notSet", { ns: ["home"] })!}`,
                status:
                  item?.questionStatus !== undefined
                    ? item?.questionStatus !== ""
                      ? item?.questionStatus
                      : `${t("common.notSet", { ns: ["home"] })!}`
                    : `${t("common.notSet", { ns: ["home"] })!}`,
              } as ModifiedQuestionList;
            }
          );
        setQuestionList(questionItem);
      }
    }
  }, [questionListRes]);
  const columns: GridColDef[] = [
    {
      field: "description",
      headerName: `${t("appointment.desc", { ns: ["home"] })}`,
      minWidth: 120,
      flex: 1,
    },
    {
      field: "askedBy",
      headerName: `${t("orderPresPage.askby", { ns: ["home"] })}`,
      minWidth: 120,
      flex: 1,
    },
    {
      field: "answer",
      headerName: `${t("orderPresPage.ans", { ns: ["home"] })}`,
      minWidth: 120,
      flex: 1,
    },
    {
      field: "answerBy",
      headerName: `${t("orderPresPage.ansby", { ns: ["home"] })}`,
      minWidth: 120,
      flex: 1,
    },
    {
      field: "status",
      headerName: `${t("appointment.status", { ns: ["home"] })}`,
      minWidth: 80,
    },
    {
      field: "id",
      headerName: `${t("orderPresPage.subAns", { ns: ["home"] })}`,
      minWidth: 130,
      align: "center",
      renderCell: (params: GridCellParams) => (
        <Button
          variant="outlined"
          startIcon={<EditIcon />}
          disabled={params.row.status === "answered" ? true : false}
          onClick={() => {
            setDialogOpen(true);
            setSelectedItem(params.row);
            setReadOnly(false);
          }}
          sx={{ textTransform: "none" }}
        >
          {t("orderPresPage.ans", { ns: ["home"] })!}
        </Button>
      ),
    },
    {
      field: "viewAnswer",
      headerName: `${t("orderPresPage.viewFullAns", { ns: ["home"] })}`,
      minWidth: 200,
      align: "center",
      renderCell: (params: GridCellParams) => (
        <Button
          variant="outlined"
          startIcon={<VisibilityIcon />}
          onClick={() => {
            setDialogOpen(true);
            setSelectedItem(params.row);
            setReadOnly(true);
          }}
          sx={{ textTransform: "none" }}
        >
          {t("orderPresPage.viewAns", { ns: ["home"] })!}
        </Button>
      ),
    },
  ];
  const [readOnly, setReadOnly] = useState(false);
  const [addQuestion, setAddQuestion] = useState(false);
  const addQuestionUpdateState = (passedVal: any) => {
    setAddQuestion(passedVal);
  };
  const [dialogOpen, setDialogOpen] = useState(false);
  const dialogUpdateState = (passedVal: boolean) => {
    setDialogOpen(passedVal);
  };
  const [selectedItem, setSelectedItem] = useState({} as any);
  const selectedItemUpdateState = (passedVal: any) => {
    setSelectedItem(passedVal);
  };
  return (
    <Box>
      <Card>
        <CardHeader
          title={
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  spacing={2}
                >
                  <Grid item>{t("orderPresPage.qA", { ns: ["home"] })}</Grid>
                  <Grid item>
                    <IconButton
                      onClick={() =>
                        dispatch(
                          getQuestionList({
                            caseId: caseId,
                          } as GetQuestionListBody)
                        )
                      }
                    >
                      <RefreshIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  onClick={() => {
                    setDialogOpen(true);
                    setReadOnly(false);
                    setAddQuestion(true);
                  }}
                  sx={{ textTransform: "none" }}
                >
                  {t("orderPresPage.askQues", { ns: ["home"] })!}
                </Button>
              </Grid>
            </Grid>
          }
        />
        <CardContent>
          {questionList.length > 0 ? (
            <DataGrid
              rows={questionList}
              columns={columns}
              pageSize={10}
              hideFooter
              hideFooterPagination
              disableSelectionOnClick
              experimentalFeatures={{ newEditingApi: true }}
              autoHeight
              components={{ Toolbar: GridToolbar }}
              componentsProps={{
                toolbar: {
                  csvOptions: { disableToolbarButton: true },
                  printOptions: { disableToolbarButton: true },
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 250 },
                },
              }}
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector
            />
          ) : (
            <MWExceptionList />
          )}
        </CardContent>
      </Card>
      {dialogOpen ? (
        <ViewAddAnswer
          dialogEnable={dialogOpen}
          dialogUpdateState={dialogUpdateState}
          addQuestion={addQuestion}
          addQuestionUpdateState={addQuestionUpdateState}
          readOnly={readOnly}
          selectedItem={selectedItem}
          selectedItemUpdateState={selectedItemUpdateState}
        />
      ) : null}
    </Box>
  );
}
