import {
  OpinionTypeActionTypes,
  OPINION_TYPE_FAIL,
  OPINION_TYPE_LOADING,
  OPINION_TYPE_SUCCESS,
  OpinionTypeState,
  OPINION_TYPE_API_MSG,
  OPINION_TYPE_API_RES,
} from "./ActionTypes";
import { OpinionTypeListResponse } from "./Model";

const initialStateEditPosts: OpinionTypeState = {
  loading: false,
  opinionTypeResponse: {} as OpinionTypeListResponse,
  successMsg: "",
  errorMsg: "",
  status: 0,
};
export const opinionTypeReducer = (
  state = initialStateEditPosts,
  action: OpinionTypeActionTypes
): OpinionTypeState => {
  switch (action.type) {
    case OPINION_TYPE_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case OPINION_TYPE_SUCCESS:
      return {
        ...state,
        opinionTypeResponse: action.payload,
        successMsg: action.successMsg,
      };
    case OPINION_TYPE_FAIL:
      return {
        ...state,
        opinionTypeResponse: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case OPINION_TYPE_API_MSG:
      return {
        ...state,
        opinionTypeResponse: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case OPINION_TYPE_API_RES:
      return {
        ...state,
        opinionTypeResponse: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
