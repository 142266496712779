import React, { useEffect, useState } from "react";
import {
  Alert,
  AlertColor,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../../redux/store/Store";
import CloseIcon from "@mui/icons-material/Close";
import MWLoader from "../../../../../components/MWLoader";
import MWTextField from "../../../../../components/MWTextField";
import MWUploader from "../../../../../components/MWUploader";
import {
  addPatientData,
  clearAddPatientDataAPIRes,
  updateAddPatientDataAPIResMsg,
} from "../../../../../redux/FilePreparation/AddPatientDataList/API";
import { clearImageUploadAPIRes } from "../../../../../redux/FilePreparation/ImageUpload/API";
import { GetPatientDataDetailsBody } from "../../../../../redux/FilePreparation/GetPatientDataList/Model";
import { getPatientDataList } from "../../../../../redux/FilePreparation/GetPatientDataList/API";
import { addPatientDataBody } from "../../../../../redux/FilePreparation/AddPatientDataList/Model";
import { t } from "i18next";
import dayjs, { Dayjs } from "dayjs";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);
interface Props {
  dialogEnable?: boolean | any;
  dialogUpdateState: boolean | any;
  docTypeName?: string;
  itemId?: number | any;
  type?: string | any;
  selectedItem?: any;
  selectedItemUpdateState: any;
}

export default function AddEditPatientData({
  dialogEnable,
  dialogUpdateState,
  docTypeName,
  type,
  selectedItem,
  selectedItemUpdateState,
}: Props) {
  const dispatch = useDispatch();
  const { patientid } = useParams() as {
    patientid: string;
  };
  const { caseid } = useParams() as {
    caseid: string;
  };
  const [dialogOpen, setDialogOpen] = useState(false);
  const handleClose = () => {
    setDialogOpen(false);
    dialogUpdateState(false);
    setHealthProblem("");
    setHealthProblemError("");
    setPatientDataImgId("");
    setStartDate(dayjs(new Date()));
    setEndDate(null);
    dispatch(updateAddPatientDataAPIResMsg());
    dispatch(clearAddPatientDataAPIRes());
    dispatch(clearImageUploadAPIRes());
    selectedItemUpdateState({} as any);
  };
  const [healthProblem, setHealthProblem] = useState("");
  const [patientDataImgId, setPatientDataImgId] = useState<String | undefined>(
    ""
  );

  const updateGovtDataStateFromChild = async (passedVal: any) => {
    if (passedVal !== undefined) {
      setPatientDataImgId(String(passedVal));
    } else {
      setPatientDataImgId(undefined);
    }
  };
  const [startDate, setStartDate] = React.useState<Dayjs | null>(
    dayjs(new Date())
  );
  const handleDateChange = (date: Dayjs | null) => {
    setStartDate(date);
  };
  const [endDate, setEndDate] = React.useState<Dayjs | null>(null);
  const handleToDateChange = (date: Dayjs | null) => {
    setEndDate(date);
  };
  const [fieldDisabled, setFieldDisabled] = useState(false);
  // enable dialog
  useEffect(() => {
    dialogEnable !== undefined
      ? setDialogOpen(dialogEnable)
      : setDialogOpen(false);
  }, [dialogEnable]);
  // Set Selected item in the corresponding filed which are passed from the table
  useEffect(() => {
    console.log("selectedItem", selectedItem);
    "id" in selectedItem ? setFieldDisabled(true) : setFieldDisabled(false);
    selectedItem !== undefined
      ? selectedItem.description !== ""
        ? setHealthProblem(selectedItem.description)
        : setHealthProblem("")
      : setHealthProblem("");
    // selectedItem !== undefined
    //   ? selectedItem.startDate !== ""
    //     ? selectedItem.startDate !== "Not Set"
    //       ? setStartDate(selectedItem.startDate)
    //       : setStartDate(null)
    //     : setStartDate(null)
    //   : setStartDate(null);
    // selectedItem !== undefined
    //   ? selectedItem.endDate !== ""
    //     ? selectedItem.endDate !== "Not Set"
    //       ? setEndDate(selectedItem.endDate)
    //       : setEndDate(null)
    //     : setEndDate(null)
    //   : setEndDate(null);
    if (selectedItem?.startDate !== undefined) {
      let year = selectedItem?.startDate.split("-")[2];
      let month = selectedItem?.startDate.split("-")[1];
      let day = selectedItem?.startDate.split("-")[0];
      let date = year + "-" + month + "-" + day;
      setStartDate(dayjs(date));
    }
    if (selectedItem?.startDate === undefined) {
      setStartDate(dayjs(new Date()));
    }

    if (selectedItem?.endDate !== undefined) {
      let year = selectedItem?.endDate.split("-")[2];
      let month = selectedItem?.endDate.split("-")[1];
      let day = selectedItem?.endDate.split("-")[0];
      let date = year + "-" + month + "-" + day;
      setEndDate(dayjs(date));
    }
    if (selectedItem?.endDate === undefined) {
      setEndDate(null);
    }
  }, [selectedItem]);
  const [healthProblemError, setHealthProblemError] = useState("");
  const delImgAction = (passeVal: boolean) => {
    if (passeVal) {
      console.log("passeVal", passeVal);
    }
  };

  const addPatientDataPayload = {
    patientId: patientid,
    caseId: caseid,
    secondOpinionUiSection: type,
    description: healthProblem,
    startDate:
      startDate !== null
        ? dayjs(startDate).utc().format("YYYY-MM-DDTHH:mm:ssZ")
        : null,
    endDate:
      endDate !== null
        ? dayjs(endDate).utc().format("YYYY-MM-DDTHH:mm:ssZ")
        : null,
  } as addPatientDataBody;

  const submitPatientData = () => {
    if (healthProblem === "" || healthProblem === undefined) {
      setHealthProblemError(
        `${t("preCompPage.plsAddDesc", { ns: ["home"] })!}`
      );
    } else {
      setHealthProblemError("");
      if (patientDataImgId === undefined) {
        // When user does not upload image on patient creation
        dispatch(addPatientData(addPatientDataPayload));
      } else {
        // When user uploads image on patient creation
        dispatch(
          addPatientData(
            Object.assign(addPatientDataPayload, {
              patientDocumentId: Number(patientDataImgId),
            } as addPatientDataBody)
          )
        );
      }
    }
  };
  const addPatientDataListValRes = useSelector(
    (state: AppState) => state.addPatientDataListRes
  );

  const [addPatientDataMsgType, setDeletePatientDataMsgType] =
    useState<AlertColor>("success");
  const [addPatientDataErrorMsg, setDeletePatientDataErrorMsg] = useState("");

  const getPatientDataDetails = {
    caseId: caseid,
    secondOpinionUiSection: type,
  } as GetPatientDataDetailsBody;
  useEffect(() => {
    if (addPatientDataListValRes?.successMsg !== "") {
      dispatch(getPatientDataList(getPatientDataDetails));
      handleClose();
    } else if (addPatientDataListValRes?.errorMsg !== "") {
      setDeletePatientDataErrorMsg(addPatientDataListValRes?.errorMsg);
      setDeletePatientDataMsgType("error");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addPatientDataListValRes]);
  const [checkImgUpload, setCheckImgUpload] = useState(false);
  // Get PreviewImg Details
  const viewPreviewImageData = (passedVal: boolean) => {
    console.log("passedVal", passedVal);
    setCheckImgUpload(passedVal);
  };

  return (
    <Dialog open={dialogOpen} onClose={handleClose} fullWidth maxWidth="lg">
      {addPatientDataListValRes?.loading ? <MWLoader /> : null}
      <DialogTitle>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
          spacing={2}
        >
          <Grid item>
            {!fieldDisabled
              ? `${t("common.add", { ns: ["home"] })}`
              : `${t("appointment.view", { ns: ["home"] })}`}{" "}
            {t("common.dataFor", { ns: ["home"] })}{" "}
            {t(`filePreparationTab.${docTypeName}`, { ns: ["home"] })}{" "}
            {t("common.details", { ns: ["home"] })}
          </Grid>
          <Grid item>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={2}
        >
          <Grid item xs={12} sm={6} lg={6} xl={6}>
            <Typography style={{ marginBottom: "5px" }}>
              {t("preCompPage.MenProb", { ns: ["home"] })}
            </Typography>
            <MWTextField
              placeholder={`${t("common.pleaseEnter", {
                ns: ["home"],
              })} ${t(`filePreparationTab.${docTypeName}`, {
                ns: ["home"],
              })} ${t("appointment.desc", { ns: ["home"] })}`}
              value={healthProblem}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setHealthProblem(event.target.value)
              }
              disabled={fieldDisabled}
              multiline
              rows={8}
              fullWidth
            />
            {healthProblemError !== "" ? (
              <Typography sx={{ color: "#c62828" }}>
                {healthProblemError}
              </Typography>
            ) : null}
          </Grid>
          <Grid item xs={12} sm={6} lg={6} xl={6}>
            <Typography style={{ marginBottom: "5px" }}>
              {t("preCompPage.suptDocu", { ns: ["home"] })}
            </Typography>
            <Box
              style={{
                textAlign: "center",
                border: "1px #6574c4 solid",
                borderRadius: "3px",
              }}
              p={1}
            >
              <MWUploader
                documentType="patient-image"
                criteria={t("preCompPage.docuSiz", { ns: ["home"] })!}
                updatedDataState={updateGovtDataStateFromChild}
                title={`${t("common.upload", { ns: ["home"] })} ${t(
                  `filePreparationTab.${docTypeName}`,
                  {
                    ns: ["home"],
                  }
                )} ${t("common.data", { ns: ["home"] })}`}
                visibility={fieldDisabled}
                imageUrl={selectedItem?.supportingDocument}
                deleteImgAction={delImgAction}
                previewImageData={viewPreviewImageData}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} lg={6} xl={6}>
            <Typography>{t("common.stDate", { ns: ["home"] })!}</Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                inputFormat="DD/MM/YYYY"
                value={startDate}
                disableFuture={true}
                onChange={handleDateChange}
                views={["year", "month", "day"]}
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                    fullWidth
                    inputProps={{ ...params.inputProps, readOnly: true }}
                    size="small"
                    variant="outlined"
                  />
                )}
                InputAdornmentProps={{ position: "start" }}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} sm={6} lg={6} xl={6}>
            <Typography>
              {t("appointment.enddate", { ns: ["home"] })!}
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                inputFormat="DD/MM/YYYY"
                value={endDate}
                onChange={handleToDateChange}
                views={["year", "month", "day"]}
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                    fullWidth
                    inputProps={{ ...params.inputProps, readOnly: true }}
                    size="small"
                    variant="outlined"
                  />
                )}
                InputAdornmentProps={{ position: "start" }}
                // maxDate={isEdit ? dayjs(new Date()) : undefined}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
        {addPatientDataErrorMsg !== "" ? (
          <Box py={2}>
            <Alert
              severity={addPatientDataMsgType}
              onClose={() => dispatch(updateAddPatientDataAPIResMsg())}
            >
              {addPatientDataErrorMsg}
            </Alert>
          </Box>
        ) : null}
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          variant={fieldDisabled ? "contained" : "outlined"}
          onClick={() => handleClose()}
          sx={{ textTransform: "none" }}
        >
          {fieldDisabled
            ? `${t("common.close", { ns: ["home"] })}`
            : `${t("common.cancel", { ns: ["home"] })}`}
        </Button>
        {!fieldDisabled ? (
          <Button
            variant="contained"
            disabled={checkImgUpload}
            onClick={() => submitPatientData()}
            sx={{ textTransform: "none" }}
          >
            {t("needhelppage.submit", { ns: ["home"] })}
          </Button>
        ) : null}
      </DialogActions>
    </Dialog>
  );
}
