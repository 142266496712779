import { GetAppointmentDetailsForCaseRes } from "./Model";

export const GET_APPOINTMENT_DETAILS_FOR_CASE_LOADING =
  "GET_APPOINTMENT_DETAILS_FOR_CASE_LOADING ";
export const GET_APPOINTMENT_DETAILS_FOR_CASE_SUCCESS =
  "GET_APPOINTMENT_DETAILS_FOR_CASE_SUCCESS";
export const GET_APPOINTMENT_DETAILS_FOR_CASE_FAIL =
  "GET_APPOINTMENT_DETAILS_FOR_CASE_FAIL";
export const GET_APPOINTMENT_DETAILS_FOR_CASE_UPDATE_API_MSG =
  "GET_APPOINTMENT_DETAILS_FOR_CASE_UPDATE_API_MSG";
export const GET_APPOINTMENT_DETAILS_FOR_CASE_UPDATE_API_RES =
  "GET_APPOINTMENT_DETAILS_FOR_CASE_UPDATE_API_RES";

export interface GetAppointmentDetailsForCaseState {
  getAppointmentDetailsForCaseRes: GetAppointmentDetailsForCaseRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetAppointmentDetailsForCaseLoading {
  type: typeof GET_APPOINTMENT_DETAILS_FOR_CASE_LOADING;
  loading: boolean;
}
export interface GetAppointmentDetailsForCaseSuccess {
  type: typeof GET_APPOINTMENT_DETAILS_FOR_CASE_SUCCESS;
  payload: GetAppointmentDetailsForCaseRes;
  successMsg: string;
}
export interface GetAppointmentDetailsForCaseFail {
  type: typeof GET_APPOINTMENT_DETAILS_FOR_CASE_FAIL;
  payload: GetAppointmentDetailsForCaseRes;
  errorMsg: string;
  status: number;
}
export interface GetAppointmentDetailsForCaseUpdateAPIMsg {
  type: typeof GET_APPOINTMENT_DETAILS_FOR_CASE_UPDATE_API_MSG;
  payload: GetAppointmentDetailsForCaseRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface GetAppointmentDetailsForCaseUpdateAPIRes {
  type: typeof GET_APPOINTMENT_DETAILS_FOR_CASE_UPDATE_API_RES;
  payload: GetAppointmentDetailsForCaseRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type GetAppointmentDetailsForCaseActionTypes =
  | GetAppointmentDetailsForCaseLoading
  | GetAppointmentDetailsForCaseSuccess
  | GetAppointmentDetailsForCaseFail
  | GetAppointmentDetailsForCaseUpdateAPIMsg
  | GetAppointmentDetailsForCaseUpdateAPIRes;
