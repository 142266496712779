import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Alert, AlertColor, Box, Stack } from "@mui/material";
import { getAppointmentDetailsForCase } from "../../../redux/Appointment/GetAppointmentList/API";
import { GetAppointmentDetailsForCaseBody } from "../../../redux/Appointment/GetAppointmentList/Model";
import { PaymentListBody } from "../../../redux/appointmentPayment/AppointmentPaymentList/Model";
import { getExistingAppointmentAndPaymentDetails } from "../../../redux/appointmentPayment/AppointmentPaymentList/API";
import Payment from "./Payment";
import ExistingAppointment from "./ExistingAppointment";
import SelectedOptions from "../BookAppointment/SelectedOptions";
import { AppState } from "../../../redux/store/Store";
import { clearPaymentApproveAPIRes } from "../../../redux/payment/PaymentApprove/API";

export default function AppointmentPayment() {
  const paymentApproveValue = useSelector(
    (state: AppState) => state.getPaymentApproveValue
  );
  const [paymentSuccessMsg, setPaymentSuccessMsg] = useState("");
  const [paymentSuccessMsgType, setPaymentSuccessMsgType] =
    useState<AlertColor>("success");
  const [paymentErrorMsg, setPaymentErrorMsg] = useState("");
  const [paymentErrorMsgType, setPaymentErrorMsgType] =
    useState<AlertColor>("success");

  const { caseid } = useParams() as {
    caseid: string;
  };
  const dispatch = useDispatch();
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(
      getExistingAppointmentAndPaymentDetails({
        caseId: caseid,
      } as PaymentListBody)
    );
    dispatch(
      getAppointmentDetailsForCase({
        caseId: caseid,
      } as GetAppointmentDetailsForCaseBody)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (paymentApproveValue?.paymentApproveResponse?.message !== undefined) {
      setPaymentSuccessMsg(paymentApproveValue?.paymentApproveResponse?.message);
      setPaymentSuccessMsgType("success");
    }
    if (paymentApproveValue?.errorMsg !== undefined) {
      setPaymentErrorMsg(paymentApproveValue?.errorMsg);
      setPaymentErrorMsgType("error");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentApproveValue])

  return (
    <Stack
      direction="column"
      justifyContent="flex-start"
      alignItems="stretch"
      spacing={2}
    >
      {paymentSuccessMsg !== "" ? (
        <Box my={2}>
          <Alert
            severity={paymentSuccessMsgType}
            onClose={() => {
              dispatch(clearPaymentApproveAPIRes());
              setPaymentSuccessMsg("");
            }}
          >
            {paymentSuccessMsg}
          </Alert>
        </Box>
      ) : null}
      {paymentErrorMsg !== "" ? (
        <Box my={2}>
          <Alert
            severity={paymentErrorMsgType}
            onClose={() => {
              dispatch(clearPaymentApproveAPIRes());
              setPaymentErrorMsg("");
            }}
          >
            {paymentErrorMsg}
          </Alert>
        </Box>
      ) : null}
      <SelectedOptions />
      <Payment />
      <ExistingAppointment />
    </Stack>
  );
}
