import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../Url";
import { DoctorDetailsActionTypes } from "./ActionTypes";
import { PatientId, DoctorDetailsResponse } from "./Model";
import {
  DoctorDetailsAPIResClearAction,
  DoctorDetailsErrorAction,
  DoctorDetailsLoadingAction,
  DoctorDetailsSuccessAction,
  DoctorDetailsAPIMsgAction,
} from "./Action";
import { headers } from "../../../components/Utility";
import { setupInterceptorsTo } from "../../Authentication/Interceptors";

let apiRes = {} as DoctorDetailsResponse;
export const doctorDetailsApi = (id: PatientId) => {
  return function (dispatch: Dispatch<DoctorDetailsActionTypes>) {
    dispatch(DoctorDetailsLoadingAction(true));
    setupInterceptorsTo(axios)
      .get(Url.baseUrl + `/landingeapp/api/doctor-details-by-id/${id.id}`, {
        headers: headers,
      })
      .then((res) => {
        apiRes = res.data;
        dispatch(DoctorDetailsLoadingAction(false));
        dispatch(
          DoctorDetailsSuccessAction(
            res.data,
            res.data.message !== undefined
              ? res.data.message
              : "Doctor details fetch successfully."
          )
        );
      })
      .catch((error) => {
        dispatch(DoctorDetailsLoadingAction(false));
        dispatch(
          DoctorDetailsErrorAction(
            {} as DoctorDetailsResponse,
            error.response.data !== undefined
              ? error.response.data.message !== undefined
                ? error.response.data.message
                : "Something went wrong"
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0
          )
        );
      });
  };
};

export const DoctorDetailsAPIResMsg = () => {
  return function (dispatch: Dispatch<DoctorDetailsActionTypes>) {
    dispatch(DoctorDetailsAPIMsgAction(apiRes as DoctorDetailsResponse, "", "", 0));
  };
};

export const clearDoctorDetailsAPIRes = () => {
  return function (dispatch: Dispatch<DoctorDetailsActionTypes>) {
    dispatch(DoctorDetailsAPIResClearAction({} as DoctorDetailsResponse, "", "", 0));
  };
};
