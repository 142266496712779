import { SecondOpinionListResponse } from "./Model";
export const SECOND_OPINION_TYPE_LOADING = "SECOND_OPINION_TYPE_LOADING";
export const SECOND_OPINION_TYPE_SUCCESS = "SECOND_OPINION_TYPE_SUCCESS";
export const SECOND_OPINION_TYPE_FAIL = "SECOND_OPINION_TYPE_FAIL";
export const SECOND_OPINION_TYPE_API_MSG = "SECOND_OPINION_TYPE_API_MSG";
export const SECOND_OPINION_TYPE_API_RES = "SECOND_OPINION_TYPE_API_RES";

export interface SecondOpinionTypeState {
  secondSecondOpinionTypeResponse: SecondOpinionListResponse;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface SecondOpinionTypeLoading {
  type: typeof SECOND_OPINION_TYPE_LOADING;
  loading: boolean;
}
export interface SecondOpinionTypeSuccess {
  type: typeof SECOND_OPINION_TYPE_SUCCESS;
  payload: SecondOpinionListResponse;
  successMsg: string;
}
export interface SecondOpinionTypeFail {
  type: typeof SECOND_OPINION_TYPE_FAIL;
  payload: SecondOpinionListResponse;
  errorMsg: string;
  status: number;
}
export interface SecondOpinionTypeAPIMsg {
  type: typeof SECOND_OPINION_TYPE_API_MSG;
  payload: SecondOpinionListResponse;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface SecondOpinionTypeAPIRes {
  type: typeof SECOND_OPINION_TYPE_API_RES;
  payload: SecondOpinionListResponse;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type SecondOpinionTypeActionTypes =
  | SecondOpinionTypeLoading
  | SecondOpinionTypeSuccess
  | SecondOpinionTypeFail
  | SecondOpinionTypeAPIMsg
  | SecondOpinionTypeAPIRes;
