import {
  emailOtpAuthDetails,
  emailOtpAuthActionTypes,
  EMAIL_OTP_AUTH_FAIL,
  EMAIL_OTP_AUTH_LOADING,
  EMAIL_OTP_AUTH_SUCCESS,
} from "./ActionTypes";
import { emailOtpAuthValue } from "./Model";

const initialStateGetPosts: emailOtpAuthDetails = {
  loading: false,
  emailOtpAuthRes: {} as emailOtpAuthValue,
  errRes: "",
};
export const emailOtpAuthReducer = (
  state = initialStateGetPosts,
  action: emailOtpAuthActionTypes
): emailOtpAuthDetails => {
  switch (action.type) {
    case EMAIL_OTP_AUTH_SUCCESS:
      return {
        ...state,
        loading: false,
        emailOtpAuthRes: action.payload,
        errRes: "",
      };
    case EMAIL_OTP_AUTH_LOADING:
      return {
        ...state,
        loading: true,
        emailOtpAuthRes: {} as emailOtpAuthValue,
      };
    case EMAIL_OTP_AUTH_FAIL:
      return {
        ...state,
        emailOtpAuthRes: {} as emailOtpAuthValue,
        loading: false,
        errRes: action.errRes,
      };
    default:
      return state;
  }
};
