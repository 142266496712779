import axios from "axios";
import { Dispatch } from "redux";
import { GetDoctorListByCategoryActionTypes } from "./ActionTypes";
import { GetDoctorListByCategory, GetDoctorListByCategoryRes } from "./Model";
import {
  getDoctorListByCategoryAPIResClearAction,
  getDoctorListByCategoryErrorAction,
  getDoctorListByCategoryLoadingAction,
  getDoctorListByCategorySuccessAction,
  getDoctorListByCategoryUpdateAPIMsgAction,
} from "./Actions";
import Url from "../../../Url";
import { headers } from "../../../components/Utility";
import { setupInterceptorsTo } from "../../Authentication/Interceptors";

let apiRes = {} as GetDoctorListByCategoryRes;
export const getDoctorListByCategory = (payload: GetDoctorListByCategory) => {
  return function (dispatch: Dispatch<GetDoctorListByCategoryActionTypes>) {
    dispatch(getDoctorListByCategoryLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(Url.baseUrl + `/patientapp/api/search-doctor/`, payload, {
        headers: headers,
      })
      .then((res) => {
        apiRes = res.data;
        dispatch(getDoctorListByCategoryLoadingAction(false));
        dispatch(
          getDoctorListByCategorySuccessAction(
            res.data,
            " work schedule details has been fetched successfully"
          )
        );
      })
      .catch((error) => {
        dispatch(getDoctorListByCategoryLoadingAction(false));
        dispatch(
          getDoctorListByCategoryErrorAction(
            {} as GetDoctorListByCategoryRes,
            error.response !== undefined
              ? error.response.data.message || error.response.data.detail
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || -1 : -1
          )
        );
      });
  };
};

export const updateGetDoctorListByCategoryAPIResMsg = () => {
  return function (dispatch: Dispatch<GetDoctorListByCategoryActionTypes>) {
    dispatch(
      getDoctorListByCategoryUpdateAPIMsgAction(
        apiRes as GetDoctorListByCategoryRes,
        "",
        "",
        0
      )
    );
  };
};

export const clearDoctorListByCategoryAPIRes = () => {
  return function (dispatch: Dispatch<GetDoctorListByCategoryActionTypes>) {
    dispatch(
      getDoctorListByCategoryAPIResClearAction(
        {} as GetDoctorListByCategoryRes,
        "",
        "",
        0
      )
    );
  };
};
