import {
  SEXUALORIENTATION_LIST_FAIL,
  SEXUALORIENTATION_LIST_LOADING,
  SEXUALORIENTATION_LIST_SUCCESS,
  SEXUALORIENTATION_LIST_API_MSG,
  SEXUALORIENTATION_LIST_API_RES,
  SexualOrientationActionTypes,
} from "./ActionTypes";
import { SexualOrientationResponse } from "./Model";

export const SexualOrientationLoadingAction = (loading: boolean): SexualOrientationActionTypes => {
  return {
    type: SEXUALORIENTATION_LIST_LOADING,
    loading: loading,
  };
};

export const SexualOrientationSuccessAction = (
  sexualOrientationResponse: SexualOrientationResponse,
  successMsg: string
): SexualOrientationActionTypes => {
  return {
    type: SEXUALORIENTATION_LIST_SUCCESS,
    payload: sexualOrientationResponse,
    successMsg: successMsg,
  };
};

export const SexualOrientationErrorAction = (
  sexualOrientationResponse: SexualOrientationResponse,
  errMsg: string,
  status: number
): SexualOrientationActionTypes => {
  return {
    type: SEXUALORIENTATION_LIST_FAIL,
    payload: sexualOrientationResponse,
    errorMsg: errMsg,
    status: status,
  };
};

export const SexualOrientationAPIMsgAction = (
  sexualOrientationResponse: SexualOrientationResponse,
  successMsg: string,
  errMsg: string,
  status: number
): SexualOrientationActionTypes => {
  return {
    type: SEXUALORIENTATION_LIST_API_MSG,
    payload: sexualOrientationResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const SexualOrientationAPIResClearAction = (
  sexualOrientationResponse: SexualOrientationResponse,
  successMsg: string,
  errMsg: string,
  status: number
): SexualOrientationActionTypes => {
  return {
    type: SEXUALORIENTATION_LIST_API_RES,
    payload: sexualOrientationResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
