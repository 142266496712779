import { Box, Button, Grid, Link, Typography } from "@mui/material";
import { t } from "i18next";
import React from "react";
import PatientFlow from "./image/design-assets/patient-flow-chart.png";

export default function EmptyPage() {
  return (
    <Grid container spacing={{ xs: 2, md: 3 }}>
      <Grid
        item
        xs={6}
        md={6}
        container
        alignItems="center"
        style={{ height: "50vh" }}
      >
        <Box pt={5} mt={5}>
          <Typography variant="h3">
            {t("perHealthRecPage.seekCon", { ns: ["home"] })!}
          </Typography>
          <>
            <br />
            {t("perHealthRecPage.oneStopSol", { ns: ["home"] })!}
            <br />
            <br />
          </>
          <div>
            <Link href="/demographicdetails">
              <Button variant="contained">
                {t("menu.patient", { ns: ["home"] })!}
              </Button>
            </Link>
          </div>
        </Box>
      </Grid>
      <Grid item xs={6} md={6}>
        <Box pt={5} mt={5}>
          <div>
            <img
              width="100%"
              height="100%"
              style={{
                objectFit: "cover",
                objectPosition: "center",
              }}
              src={PatientFlow}
              alt=""
            />
          </div>
        </Box>
      </Grid>
    </Grid>
  );
}
