import React, { useEffect, useState } from "react";
import {
  Alert,
  AlertColor,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../../redux/store/Store";
import CloseIcon from "@mui/icons-material/Close";
import MWLoader from "../../../../../components/MWLoader";
import { t } from "i18next";
import { AnsDoctorQuestionBody } from "../../../../../redux/CaseDetails/DoctorPatientInteraction/AnswerQuestion/Model";
import {
  answerDoctorQuestion,
  clearAnswerDoctorQuestionAPIRes,
} from "../../../../../redux/CaseDetails/DoctorPatientInteraction/AnswerQuestion/API";
import { AskQuestionBody } from "../../../../../redux/CaseDetails/DoctorPatientInteraction/AskQuestion/Model";
import {
  askDoctorQuestion,
  clearAskDoctorQuestionAPIRes,
} from "../../../../../redux/CaseDetails/DoctorPatientInteraction/AskQuestion/API";
import { GetQuestionListBody } from "../../../../../redux/CaseDetails/DoctorPatientInteraction/getQuestionListForPatient/Model";
import { getQuestionList } from "../../../../../redux/CaseDetails/DoctorPatientInteraction/getQuestionListForPatient/API";

interface Props {
  dialogEnable?: boolean | any;
  dialogUpdateState: boolean | any;
  selectedItem?: any;
  selectedItemUpdateState: any;
  addQuestion?: boolean | any;
  addQuestionUpdateState: any;
  readOnly?: boolean | any;
}

export default function AddEditPatientData({
  dialogEnable,
  dialogUpdateState,
  selectedItem,
  selectedItemUpdateState,
  addQuestion,
  addQuestionUpdateState,
  readOnly,
}: Props) {
  const dispatch = useDispatch();
  const { caseid } = useParams() as {
    caseid: string;
  };
  const [dialogOpen, setDialogOpen] = useState(false);
  const handleClose = () => {
    setDialogOpen(false);
    dialogUpdateState(false);
    setQuestionAnswerText("");
    setHealthProblemError("");
    selectedItemUpdateState({} as any);
    addQuestionUpdateState(false);
    dispatch(clearAnswerDoctorQuestionAPIRes());
    dispatch(clearAskDoctorQuestionAPIRes());
  };
  const [questionAnswerText, setQuestionAnswerText] = useState("");

  // enable dialog
  useEffect(() => {
    dialogEnable !== undefined
      ? setDialogOpen(dialogEnable)
      : setDialogOpen(false);
  }, [dialogEnable]);
  // Set Selected item in the corresponding filed which are passed from the table
  useEffect(() => {
    selectedItem !== undefined
      ? selectedItem.answer !== ""
        ? selectedItem.answer !== `${t("common.notSet", { ns: ["home"] })!}`
          ? setQuestionAnswerText(selectedItem.answer)
          : setQuestionAnswerText("")
        : setQuestionAnswerText("")
      : setQuestionAnswerText("");
  }, [selectedItem, addQuestion]);
  const [healthProblemError, setHealthProblemError] = useState("");

  const drQuestionAnswerPayload = {
    caseId: caseid,
  } as AnsDoctorQuestionBody;

  const submitPatientQuestionAnsData = () => {
    if (questionAnswerText !== "") {
      setHealthProblemError("");
      if (addQuestion) {
        dispatch(
          askDoctorQuestion(
            Object.assign(drQuestionAnswerPayload, {
              questionId: String(selectedItem?.id),
              questionType: "PatientToDoctor",
              questionText: questionAnswerText,
            }) as AskQuestionBody
          )
        );
      } else {
        if (!readOnly) {
          dispatch(
            answerDoctorQuestion(
              Object.assign(drQuestionAnswerPayload, {
                questionId: String(selectedItem?.id),
                type: "patient",
                answerText: questionAnswerText,
              }) as AnsDoctorQuestionBody
            )
          );
        }
      }
    } else setHealthProblemError(`${t("common.emptyCheck", { ns: ["home"] })}`);
  };
  const askDoctorQuestionRes = useSelector(
    (state: AppState) => state.askDoctorQuestionRes
  );

  const [askQuestionType, setAskQuestionType] = useState<AlertColor>("success");
  const [askQuestionMsg, setAskQuestionMsg] = useState("");

  useEffect(() => {
    if (askDoctorQuestionRes?.successMsg !== "") {
      setAskQuestionMsg(askDoctorQuestionRes?.successMsg);
      dispatch(getQuestionList({ caseId: caseid } as GetQuestionListBody));
      handleClose();
    } else if (askDoctorQuestionRes?.errorMsg !== "") {
      setAskQuestionMsg(askDoctorQuestionRes?.errorMsg);
      setAskQuestionType("error");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [askDoctorQuestionRes]);
  const answerDoctorQuestionRes = useSelector(
    (state: AppState) => state.answerDoctorQuestionRes
  );

  const [answerQuestionType, setAnswerQuestionType] =
    useState<AlertColor>("success");
  const [answerQuestionMsg, setAnswerQuestionMsg] = useState("");

  useEffect(() => {
    if (answerDoctorQuestionRes?.successMsg !== "") {
      setAnswerQuestionType("success");
      setAnswerQuestionMsg(answerDoctorQuestionRes?.successMsg);
      dispatch(getQuestionList({ caseId: caseid } as GetQuestionListBody));
      handleClose();
    } else if (answerDoctorQuestionRes?.errorMsg !== "") {
      setAnswerQuestionMsg(answerDoctorQuestionRes?.errorMsg);
      setAnswerQuestionType("error");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answerDoctorQuestionRes]);

  return (
    <Dialog open={dialogOpen} onClose={handleClose} fullWidth maxWidth="sm">
      {askDoctorQuestionRes?.loading || answerDoctorQuestionRes?.loading ? (
        <MWLoader />
      ) : null}
      <DialogTitle>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <Grid item>
            {addQuestion ? (
              <Typography>
                {t("orderPresPage.addQues", { ns: ["home"] })}
              </Typography>
            ) : readOnly ? (
              <Typography>
                {t("orderPresPage.viewAns", { ns: ["home"] })}
              </Typography>
            ) : (
              <Typography>
                {t("orderPresPage.addAns", { ns: ["home"] })}
              </Typography>
            )}
          </Grid>
          <Grid item>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={2}
        >
          <Grid item xs={12} sm={12} lg={12} xl={12}>
            {addQuestion ? null : (
              <Typography style={{ marginBottom: "5px" }} fontWeight="bold">
                {t("orderPresPage.ques", { ns: ["home"] })}:{" "}
                {selectedItem?.description}
              </Typography>
            )}
            <TextField
              placeholder={
                addQuestion
                  ? `${t("orderPresPage.ques", { ns: ["home"] })}`
                  : readOnly
                  ? `${t("orderPresPage.ans", { ns: ["home"] })}`
                  : `${t("orderPresPage.ans", { ns: ["home"] })}`
              }
              value={questionAnswerText}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setQuestionAnswerText(event.target.value)
              }
              disabled={readOnly}
              multiline
              rows={addQuestion ? 4 : 4}
              fullWidth
            />
            {healthProblemError !== "" ? (
              <Typography sx={{ color: "#c62828" }}>
                {healthProblemError}
              </Typography>
            ) : null}
          </Grid>
        </Grid>
        {askQuestionMsg !== "" ? (
          <Box py={2}>
            <Alert
              severity={askQuestionType}
              onClose={() => {
                dispatch(clearAskDoctorQuestionAPIRes());
                setAskQuestionMsg("");
              }}
            >
              {askQuestionMsg}
            </Alert>
          </Box>
        ) : null}
        {answerQuestionMsg !== "" ? (
          <Box py={2}>
            <Alert
              severity={answerQuestionType}
              onClose={() => {
                dispatch(clearAnswerDoctorQuestionAPIRes());
                setAnswerQuestionMsg("");
              }}
            >
              {answerQuestionMsg}
            </Alert>
          </Box>
        ) : null}
      </DialogContent>
      <Divider />
      <DialogActions>
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="flex-end"
          spacing={2}
        >
          {addQuestion || !readOnly ? (
            <Grid item>
              <Button
                variant={addQuestion || !readOnly ? "outlined" : "contained"}
                onClick={() => handleClose()}
                sx={{ textTransform: "none" }}
              >
                {t("common.cancel", { ns: ["home"] })}
              </Button>
            </Grid>
          ) : (
            <Grid item>
              <Button
                variant={addQuestion || !readOnly ? "outlined" : "contained"}
                onClick={() => handleClose()}
                sx={{ textTransform: "none" }}
              >
                {t("common.cancel", { ns: ["home"] })}
              </Button>
            </Grid>
          )}
          {addQuestion || !readOnly ? (
            <Grid item>
              <Button
                variant="contained"
                disabled={
                  askDoctorQuestionRes?.loading ||
                  answerDoctorQuestionRes?.loading
                }
                onClick={() => submitPatientQuestionAnsData()}
                sx={{ textTransform: "none" }}
              >
                {t("needhelppage.submit", { ns: ["home"] })}
              </Button>
            </Grid>
          ) : null}
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
