import { ConsultationTypeListResponse } from "./Model";
export const CONSULTATION_TYPE_LOADING = "CONSULTATION_TYPE_LOADING";
export const CONSULTATION_TYPE_SUCCESS = "CONSULTATION_TYPE_SUCCESS";
export const CONSULTATION_TYPE_FAIL = "CONSULTATION_TYPE_FAIL";
export const CONSULTATION_TYPE_API_MSG = "CONSULTATION_TYPE_API_MSG";
export const CONSULTATION_TYPE_API_RES = "CONSULTATION_TYPE_API_RES";

export interface ConsultationTypeState {
  consultationTypeResponse: ConsultationTypeListResponse;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface ConsultationTypeLoading {
  type: typeof CONSULTATION_TYPE_LOADING;
  loading: boolean;
}
export interface ConsultationTypeSuccess {
  type: typeof CONSULTATION_TYPE_SUCCESS;
  payload: ConsultationTypeListResponse;
  successMsg: string;
}
export interface ConsultationTypeFail {
  type: typeof CONSULTATION_TYPE_FAIL;
  payload: ConsultationTypeListResponse;
  errorMsg: string;
  status: number;
}
export interface ConsultationTypeAPIMsg {
  type: typeof CONSULTATION_TYPE_API_MSG;
  payload: ConsultationTypeListResponse;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface ConsultationTypeAPIRes {
  type: typeof CONSULTATION_TYPE_API_RES;
  payload: ConsultationTypeListResponse;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type ConsultationTypeActionTypes =
  | ConsultationTypeLoading
  | ConsultationTypeSuccess
  | ConsultationTypeFail
  | ConsultationTypeAPIMsg
  | ConsultationTypeAPIRes;
