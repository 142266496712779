import {
  AddResponse,
  ChiefComplaintList,
  SetChiefComplaint,
  SetDeleteDocument,
  TaskList,
  CaseAppoinmentList,
} from "../interfaces/Model";

// API call state
export const GET_LIST = "GET_LIST";
export const GET_PATIENT_LIST = "GET_PATIENT_LIST";
export const GET_SHARED_PATIENT_LIST = "GET_SHARED_PATIENT_LIST";
export const GET_CASE_LIST = "GET_CASE_LIST";
export const GET_QUESTION_LIST = "GET_QUESTION_LIST";
export const GET_CC_LIST = "GET_CC_LIST";
export const ADD_CC = "ADD_CC";
export const DELETE_DOCUMENT = "DELETE_DOCUMENT";
export const DELETE_PATIENT = "DELETE_PATIENT";

// Task List types
export const GET_TASK_LIST = "GET_TASK_LIST";
export const TASK_LIST_LOADING = "TASK_LIST_LOADING";
export const TASK_LIST_SUCCESS = "TASK_LIST_SUCCESS";
export const TASK_LIST_FAIL = "TASK_LIST_FAIL";

// PATIENT CASE Appoinment List types
export const CASE_APPOINMENT_LIST = "CASE_APPOINMENT_LIST";
export const CASE_APPOINMENT_LIST_LOADING = "CASE_APPOINMENT_LIST_LOADING";
export const CASE_APPOINMENT_LIST_SUCCESS = "CASE_APPOINMENT_LIST_SUCCESS";
export const CASE_APPOINMENT_LIST_FAIL = "CASE_APPOINMENT_LIST_FAIL";

// Chief Complaint Loading State
export const CC_LIST_LOADING = "CC_LIST_LOADING";
export const CC_LIST_SUCCESS = "CC_LIST_SUCCESS";
export const CC_LIST_FAIL = "CC_LIST_FAIL";

export interface GetChiefComplaintStateType {
  ccValue: ChiefComplaintList[];
  loading: boolean;
}
export interface CCListLoading {
  type: typeof CC_LIST_LOADING;
}
export interface CCListSuccess {
  type: typeof CC_LIST_SUCCESS;
  payload: ChiefComplaintList[];
}
export interface CCListFail {
  type: typeof CC_LIST_FAIL;
}

interface GetCCActionType {
  type: typeof GET_CC_LIST;
  payload: ChiefComplaintList[];
}

// ADD CHIEF COMPLAINT
export const CC_ADD_LIST_LOADING = "CC_ADD_LIST_LOADING";
export const CC_ADD_LIST_SUCCESS = "CC_ADD_LIST_SUCCESS";
export const CC_ADD_LIST_FAIL = "CC_ADD_LIST_FAIL";

export interface SetAddChiefComplaintStateType {
  addCC: SetChiefComplaint[];
  loading: boolean;
  response: AddResponse;
}
export interface CCAddLoading {
  type: typeof CC_ADD_LIST_LOADING;
}
export interface CCAddSuccess {
  type: typeof CC_ADD_LIST_SUCCESS;
  payload: AddResponse;
}
export interface CCAddFail {
  type: typeof CC_ADD_LIST_FAIL;
  payload: AddResponse;
}

interface SetAddChiefComplaintType {
  type: typeof ADD_CC;
  payload: SetChiefComplaint[];
}

// DELETE Document
export const DELETE_DOCUMENT_LIST_LOADING = "DELETE_DOCUMENT_LIST_LOADING";
export const DELETE_DOCUMENT_LIST_SUCCESS = "DELETE_DOCUMENT_LIST_SUCCESS";
export const DELETE_DOCUMENT_LIST_FAIL = "DELETE_DOCUMENT_LIST_FAIL";

export interface SetDeleteDocumentStateType {
  deleteDoc: SetDeleteDocument[];
  loading: boolean;
  response: AddResponse;
}
export interface DeleteDocumentLoading {
  type: typeof DELETE_DOCUMENT_LIST_LOADING;
}
export interface DeleteDocumentSuccess {
  type: typeof DELETE_DOCUMENT_LIST_SUCCESS;
  payload: AddResponse;
}
export interface DeleteDocumentFail {
  type: typeof DELETE_DOCUMENT_LIST_FAIL;
  payload: AddResponse;
}

interface SetDeleteDocumentType {
  type: typeof DELETE_DOCUMENT;
  payload: SetDeleteDocument[];
}

export interface TaskLoading {
  type: typeof TASK_LIST_LOADING;
}
export interface TaskSuccess {
  type: typeof TASK_LIST_SUCCESS;
  payload: TaskList[];
}
export interface TaskFail {
  type: typeof TASK_LIST_FAIL;
}

export interface GetTaskStateType {
  taskValue: TaskList[];
  loading: boolean;
}
// Task Loading State
interface GetTaskActionType {
  type: typeof GET_TASK_LIST;
  payload: TaskList[];
}

// patient case Appoinment List Interfaces
export interface CaseAppoinmentListLoading {
  type: typeof CASE_APPOINMENT_LIST_LOADING;
}
export interface CaseAppoinmentListSuccess {
  type: typeof CASE_APPOINMENT_LIST_SUCCESS;
  payload: CaseAppoinmentList[];
}
export interface CaseAppoinmentListFail {
  type: typeof CASE_APPOINMENT_LIST_FAIL;
}

export interface CaseAppoinmentStateType {
  CaseAppoinmentListValue: CaseAppoinmentList[];
  loading: boolean;
}
// Owner Appoinment List Loading State
interface CaseAppoinmentActionType {
  type: typeof CASE_APPOINMENT_LIST;
  payload: CaseAppoinmentList[];
}

export type ChiefComplaintGetActionTypes =
  | GetCCActionType
  | CCListLoading
  | CCListSuccess
  | CCListFail;
export type AddChiefComplaintTypes =
  | SetAddChiefComplaintType
  | CCAddLoading
  | CCAddSuccess
  | CCAddFail
  | GetCCActionType
  | CCListLoading
  | CCListSuccess
  | CCListFail;
export type DeleteDocumentTypes =
  | SetDeleteDocumentType
  | DeleteDocumentLoading
  | DeleteDocumentSuccess
  | DeleteDocumentFail
  | GetCCActionType
  | CCListLoading
  | CCListSuccess
  | CCListFail;
export type TaskGetActionTypes =
  | GetTaskActionType
  | TaskLoading
  | TaskSuccess
  | TaskFail;
export type CaseAppoinmentListActionTypes =
  | CaseAppoinmentActionType
  | CaseAppoinmentListLoading
  | CaseAppoinmentListSuccess
  | CaseAppoinmentListFail;
