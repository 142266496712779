import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../Url";
import { OwnerPaymentListActionTypes } from "./ActionTypes";
import { OwnerPaymentListResponse } from "./Model";
import {
  ownerPaymentListAPIResClearAction,
  ownerPaymentListErrorAction,
  ownerPaymentListLoadingAction,
  ownerPaymentListSuccessAction,
  ownerPaymentListAPIMsgAction,
} from "./Action";
import { headers } from "../../../components/Utility";
import { setupInterceptorsTo } from "../../Authentication/Interceptors";

let apiRes = {} as OwnerPaymentListResponse;
export const ownerPaymentListApi = () => {
  return function (dispatch: Dispatch<OwnerPaymentListActionTypes>) {
    dispatch(ownerPaymentListLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        Url.baseUrl + `/paymentapp/api/payment/get_payment_list_for_owner`,
        {},
        {
          headers: headers,
        }
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(ownerPaymentListLoadingAction(false));
        dispatch(
          ownerPaymentListSuccessAction(
            res.data,
            res.data.message !== undefined
              ? res.data.message
              : "Owner payment list fetch successfully"
          )
        );
      })
      .catch((error) => {
        dispatch(ownerPaymentListLoadingAction(false));
        dispatch(
          ownerPaymentListErrorAction(
            {} as OwnerPaymentListResponse,
            error.response.data !== undefined
              ? error.response.data.message !== undefined
                ? error.response.data.message
                : "Something went wrong"
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0
          )
        );
      });
  };
};

export const ownerPaymentListAPIResMsg = () => {
  return function (dispatch: Dispatch<OwnerPaymentListActionTypes>) {
    dispatch(
      ownerPaymentListAPIMsgAction(
        apiRes as OwnerPaymentListResponse,
        "",
        "",
        0
      )
    );
  };
};

export const clearownerPaymentListAPIRes = () => {
  return function (dispatch: Dispatch<OwnerPaymentListActionTypes>) {
    dispatch(
      ownerPaymentListAPIResClearAction(
        {} as OwnerPaymentListResponse,
        "",
        "",
        0
      )
    );
  };
};
