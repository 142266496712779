import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../Url";
import { SearchDoctorActionTypes } from "./ActionTypes";
import { SearchDoctorBody, SearchDoctorResponse } from "./Model";
import {
  searchDoctorAPIResClearAction,
  searchDoctorErrorAction,
  searchDoctorLoadingAction,
  searchDoctorSuccessAction,
  searchDoctorAPIMsgAction,
} from "./Action";
import { headers } from "../../../components/Utility";
import { setupInterceptorsTo } from "../../Authentication/Interceptors";

let apiRes = {} as SearchDoctorResponse;
export const searchDoctorApi = (payload: SearchDoctorBody) => {
  return function (dispatch: Dispatch<SearchDoctorActionTypes>) {
    dispatch(searchDoctorLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(Url.baseUrl + `/patientapp/api/search-doctor/`, payload, {
        headers: headers,
      })
      .then((res) => {
        apiRes = res.data;
        dispatch(searchDoctorLoadingAction(false));
        dispatch(
          searchDoctorSuccessAction(
            res.data,
            res.data.message !== undefined
              ? res.data.message
              : "Doctor search successfully"
          )
        );
      })
      .catch((error) => {
        dispatch(searchDoctorLoadingAction(false));
        dispatch(
          searchDoctorErrorAction(
            {} as SearchDoctorResponse,
            error.response.data !== undefined
              ? error.response.data.message !== undefined
                ? error.response.data.message
                : "Something went wrong"
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0
          )
        );
      });
  };
};

export const searchDoctorAPIResMsg = () => {
  return function (dispatch: Dispatch<SearchDoctorActionTypes>) {
    dispatch(searchDoctorAPIMsgAction(apiRes as SearchDoctorResponse, "", "", 0));
  };
};

export const clearsearchDoctorAPIRes = () => {
  return function (dispatch: Dispatch<SearchDoctorActionTypes>) {
    dispatch(searchDoctorAPIResClearAction({} as SearchDoctorResponse, "", "", 0));
  };
};
