import axios from "axios";
import { Dispatch } from "redux";
import baseUrl from "../../../Url";
import { AddPatientInsuranceActionTypes } from "./ActionTypes";
import { AddPatientInsuranceBody, AddPatientInsuranceRes } from "./Model";
import {
  addPatientInsuranceAPIResClearAction,
  addPatientInsuranceErrorAction,
  addPatientInsuranceLoadingAction,
  addPatientInsuranceSuccessAction,
  addPatientInsuranceAPIMsgAction,
} from "./Actions";
import { headers } from "../../../components/Utility";
import { setupInterceptorsTo } from "../../Authentication/Interceptors";

let apiRes = {} as AddPatientInsuranceRes;
export const addPatientInsurance = (payload: AddPatientInsuranceBody) => {
  return function (dispatch: Dispatch<AddPatientInsuranceActionTypes>) {
    dispatch(addPatientInsuranceLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        baseUrl.baseUrl + `/patientapp/api/patient/add_patient_insurance`, 
        payload,
        {
          headers: headers,
        }
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(addPatientInsuranceLoadingAction(false));
        dispatch(
          addPatientInsuranceSuccessAction(
            res.data,
            res.data.message !== undefined
              ? res.data.message
              : "Patient. legal details has been added successfully"
          )
        );
      })
      .catch((error) => {
        dispatch(addPatientInsuranceLoadingAction(false));
        dispatch(
          addPatientInsuranceErrorAction(
            {} as AddPatientInsuranceRes,
            error.response.data !== undefined
              ? error.response.data.message !== undefined
                ? error.response.data.message
                : "Something went wrong"
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0
          )
        );
      });
  };
};

export const updateAddPatientInsuranceAPIResMsg = () => {
  return function (dispatch: Dispatch<AddPatientInsuranceActionTypes>) {
    dispatch(
      addPatientInsuranceAPIMsgAction(apiRes as AddPatientInsuranceRes, "", "", 0)
    );
  };
};

export const clearAddPatientInsuranceAPIRes = () => {
  return function (dispatch: Dispatch<AddPatientInsuranceActionTypes>) {
    dispatch(
      addPatientInsuranceAPIResClearAction({} as AddPatientInsuranceRes, "", "", 0)
    );
  };
};
