import {
  GET_DOCTOR_SPECIALITY_LIST_FAIL,
  GET_DOCTOR_SPECIALITY_LIST_LOADING,
  GET_DOCTOR_SPECIALITY_LIST_SUCCESS,
  GET_DOCTOR_SPECIALITY_LIST_UPDATE_API_MSG,
  GET_DOCTOR_SPECIALITY_LIST_UPDATE_API_RES,
  GetDoctorSpecialityListActionTypes,
} from "./ActionTypes";
import { GetDoctorSpecialistListRes } from "./Model";

export const getDoctorSpecialityListLoadingAction = (
  loading: boolean
): GetDoctorSpecialityListActionTypes => {
  return {
    type: GET_DOCTOR_SPECIALITY_LIST_LOADING,
    loading: loading,
  };
};

export const getDoctorSpecialityListSuccessAction = (
  getDoctorSpecialityListResponse: GetDoctorSpecialistListRes,
  successMsg: string
): GetDoctorSpecialityListActionTypes => {
  return {
    type: GET_DOCTOR_SPECIALITY_LIST_SUCCESS,
    payload: getDoctorSpecialityListResponse,
    successMsg: successMsg,
  };
};

export const getDoctorSpecialityListErrorAction = (
  getDoctorSpecialityListResponse: GetDoctorSpecialistListRes,
  errMsg: string,
  status: number
): GetDoctorSpecialityListActionTypes => {
  return {
    type: GET_DOCTOR_SPECIALITY_LIST_FAIL,
    payload: getDoctorSpecialityListResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const getDoctorSpecialityListUpdateAPIMsgAction = (
  getDoctorSpecialityListResponse: GetDoctorSpecialistListRes,
  successMsg: string,
  errMsg: string,
  status: number
): GetDoctorSpecialityListActionTypes => {
  return {
    type: GET_DOCTOR_SPECIALITY_LIST_UPDATE_API_MSG,
    payload: getDoctorSpecialityListResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const getDoctorSpecialityListAPIResClearAction = (
  getDoctorSpecialityListResponse: GetDoctorSpecialistListRes,
  successMsg: string,
  errMsg: string,
  status: number
): GetDoctorSpecialityListActionTypes => {
  return {
    type: GET_DOCTOR_SPECIALITY_LIST_UPDATE_API_RES,
    payload: getDoctorSpecialityListResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
