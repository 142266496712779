import axios from "axios";
import { Dispatch } from "redux";
import baseUrl from "../../Url";
import { setupInterceptorsTo } from "../Authentication/Interceptors";
import { requestBookAppoiontmentAction } from "./Action";
import {
  requestBookAppoiontmentActionTypes,
  REQUEST_BOOK_APPOINTMENT_FAIL,
  REQUEST_BOOK_APPOINTMENT_LOADING,
  REQUEST_BOOK_APPOINTMENT_SUCCESS,
} from "./ActionTypes";
import { requestBookAppoiontmentDetails } from "./Model";
import { headers } from "../../components/Utility";

export const requestBookAppointmentApi = (
  patientId: requestBookAppoiontmentDetails
) => {
  return function (dispatch: Dispatch<requestBookAppoiontmentActionTypes>) {
    dispatch({
      type: REQUEST_BOOK_APPOINTMENT_LOADING,
    });

    setupInterceptorsTo(axios)
      .post(
        baseUrl.baseUrl +
          `/appointmentapp/api/appointment/request_appointment_by_patient`,
        { patientId: patientId },
        {
          headers: headers,
        }
      )
      .then((res) => {
        dispatch(requestBookAppoiontmentAction(res.data));
        dispatch({
          type: REQUEST_BOOK_APPOINTMENT_SUCCESS,
          payload: res.data,
        });
      })
      .catch((_error) => {
        dispatch({
          type: REQUEST_BOOK_APPOINTMENT_FAIL,
        });
      });
  };
};
export const updateApiResponse = () => {
  return function (dispatch: Dispatch<requestBookAppoiontmentActionTypes>) {
    dispatch({
      type: REQUEST_BOOK_APPOINTMENT_LOADING,
    });
  };
};
