import {
  SetEmailPhoneDetails,
  setEmailPhoneActionTypes,
  SET_EMAIL_PHONE_FAIL,
  SET_EMAIL_PHONE_LOADING,
  SET_EMAIL_PHONE_SUCCESS,
} from "./ActionTypes";
import { SetEmailPhoneValue } from "./Model";

const initialStateGetPosts: SetEmailPhoneDetails = {
  loading: false,
  setEmailPhoneRes: {} as SetEmailPhoneValue,
  errRes: "",
};
export const setEmailPhoneReducer = (
  state = initialStateGetPosts,
  action: setEmailPhoneActionTypes
): SetEmailPhoneDetails => {
  switch (action.type) {
    case SET_EMAIL_PHONE_SUCCESS:
      return {
        ...state,
        loading: false,
        setEmailPhoneRes: action.payload,
        errRes: "",
      };
    case SET_EMAIL_PHONE_LOADING:
      return {
        ...state,
        loading: true,
        setEmailPhoneRes: {} as SetEmailPhoneValue,
      };
    case SET_EMAIL_PHONE_FAIL:
      return {
        ...state,
        setEmailPhoneRes: {} as SetEmailPhoneValue,
        loading: false,
        errRes: action.errRes,
      };
    default:
      return state;
  }
};
