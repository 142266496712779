import { RelationshipResponse } from "./Model";
export const RELATIONSHIP_LIST_LOADING = "RELATIONSHIP_LIST_LOADING";
export const RELATIONSHIP_LIST_SUCCESS = "RELATIONSHIP_LIST_SUCCESS";
export const RELATIONSHIP_LIST_FAIL = "RELATIONSHIP_LIST_FAIL";
export const RELATIONSHIP_LIST_API_MSG = "RELATIONSHIP_LIST_API_MSG";
export const RELATIONSHIP_LIST_API_RES = "RELATIONSHIP_LIST_API_RES";

export interface RelationshipListState {
  maritalStatusResponse: RelationshipResponse;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface RelationshipListLoading {
  type: typeof RELATIONSHIP_LIST_LOADING;
  loading: boolean;
}
export interface RelationshipListSuccess {
  type: typeof RELATIONSHIP_LIST_SUCCESS;
  payload: RelationshipResponse;
  successMsg: string;
}
export interface RelationshipListFail {
  type: typeof RELATIONSHIP_LIST_FAIL;
  payload: RelationshipResponse;
  errorMsg: string;
  status: number;
}
export interface RelationshipListAPIMsg {
  type: typeof RELATIONSHIP_LIST_API_MSG;
  payload: RelationshipResponse;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface RelationshipListAPIRes {
  type: typeof RELATIONSHIP_LIST_API_RES;
  payload: RelationshipResponse;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type RelationshipListActionTypes =
  | RelationshipListLoading
  | RelationshipListSuccess
  | RelationshipListFail
  | RelationshipListAPIMsg
  | RelationshipListAPIRes;
