import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../../redux/store/Store";
import {
  Typography,
  Box,
  Button,
  Grid,
  CardContent,
  CardHeader,
  Card,
  IconButton,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridToolbar,
} from "@mui/x-data-grid";
import RefreshIcon from "@mui/icons-material/Refresh";
import {
  PatientDataImportConfigList,
  PatientImportConfigListDetailsBody,
} from "../../../../../redux/FilePreparation/ImportConfig/GetPatientImportConfigList/Model";
import { getPatientInfortConfigListApi } from "../../../../../redux/FilePreparation/ImportConfig/GetPatientImportConfigList/API";
import MWExceptionList from "../../../../../components/MWExceptionList";
import { t } from "i18next";
import dayjs from "dayjs";
import { ModifiedImportConfigList } from "./Model";
import UpdateImportConfig from "./UpdateImportConfig";
interface Props {
  label?: string;
  type?: string;
}
export default function ImportedConfig({ label, type }: Props) {
  const dispatch = useDispatch();

  const [masterConfigList, setMasterConfigList] = useState(
    [] as ModifiedImportConfigList[]
  );
  const { patientid } = useParams() as {
    patientid: string;
  };

  const getPatientImportConfigList = useSelector(
    (state: AppState) => state.getPatientImportConfigList
  );

  useEffect(() => {
    if (
      getPatientImportConfigList?.patientImportConfigListRes
        ?.patientDataImportConfigList !== undefined
    ) {
      if (
        getPatientImportConfigList?.patientImportConfigListRes
          ?.patientDataImportConfigList.length > 0
      ) {
        let masterConfig =
          getPatientImportConfigList?.patientImportConfigListRes.patientDataImportConfigList.map(
            (item: PatientDataImportConfigList) => ({
              id: item.id,
              createDate:
                item?.createDate !== undefined
                  ? item?.createDate !== null
                    ? dayjs(item?.createDate).format("DD-MM-YYYY")
                    : `${t("common.notSet", { ns: ["home"] })!}`
                  : `${t("common.notSet", { ns: ["home"] })!}`,
              modifiedDate:
                item?.modifiedDate !== undefined
                  ? item?.modifiedDate !== null
                    ? dayjs(item?.modifiedDate).format("DD-MM-YYYY")
                    : `${t("common.notSet", { ns: ["home"] })!}`
                  : `${t("common.notSet", { ns: ["home"] })!}`,
              email:
                item?.email !== undefined
                  ? item?.email !== ""
                    ? item.email
                    : `${t("common.notSet", { ns: ["home"] })!}`
                  : `${t("common.notSet", { ns: ["home"] })!}`,
            })
          );
        setMasterConfigList(masterConfig);
      } else setMasterConfigList([] as ModifiedImportConfigList[]);
    } else setMasterConfigList([] as ModifiedImportConfigList[]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getPatientImportConfigList]);

  useEffect(() => {
    dispatch(
      getPatientInfortConfigListApi({
        patientId: patientid,
      } as PatientImportConfigListDetailsBody)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns: GridColDef[] = [
    { field: "email", headerName: "Email", flex: 1 },
    {
      field: "createDate",
      headerName: `${t("appointment.createdate", { ns: ["home"] })}`,
      minWidth: 130,
    },
    {
      field: "modifiedDate",
      headerName: `${t("appointment.modifydate", { ns: ["home"] })}`,
      minWidth: 140,
    },
    {
      field: "id",
      headerName: "Edit",
      width: 130,
      renderCell: (params: GridCellParams) => (
        <Button
          startIcon={<EditIcon />}
          variant="outlined"
          onClick={() => {
            setEditItem(params.row);
            setAddEducationDetailsDialog(true);
          }}
          sx={{ textTransform: "none" }}
        >
          {t("common.edit", { ns: ["home"] })}
        </Button>
      ),
    },
  ];
  const [editItem, setEditItem] = useState({} as ModifiedImportConfigList);
  const selectedItemUpdateState = (passedVal: any) => {
    setEditItem(passedVal);
  };
  const [addEducationDetailsDialog, setAddEducationDetailsDialog] =
    useState(false);
  const updateAddEducationDetailsDialogValFromChild = (passedVal: boolean) => {
    setAddEducationDetailsDialog(passedVal);
  };

  return (
    <Box>
      <Card>
        <CardHeader
          title={
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  spacing={2}
                >
                  <Grid item>
                    <Typography variant="h5">
                      {t("impConfigPage.impCon", { ns: ["home"] })}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <IconButton
                      onClick={() =>
                        dispatch(
                          getPatientInfortConfigListApi({
                            patientId: patientid,
                          } as PatientImportConfigListDetailsBody)
                        )
                      }
                    >
                      <RefreshIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  onClick={() => setAddEducationDetailsDialog(true)}
                  sx={{ textTransform: "none" }}
                >
                  {t("impConfigPage.addConf", { ns: ["home"] })}
                </Button>
              </Grid>
            </Grid>
          }
        />
        <CardContent>
          {masterConfigList.length > 0 ? (
            <DataGrid
              rows={masterConfigList}
              columns={columns}
              pageSize={10}
              hideFooter
              hideFooterPagination
              disableSelectionOnClick
              experimentalFeatures={{ newEditingApi: true }}
              autoHeight
              components={{ Toolbar: GridToolbar }}
              componentsProps={{
                toolbar: {
                  csvOptions: { disableToolbarButton: true },
                  printOptions: { disableToolbarButton: true },
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 250 },
                },
              }}
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector
            />
          ) : (
            <MWExceptionList />
          )}
        </CardContent>
      </Card>
      {addEducationDetailsDialog ? (
        <UpdateImportConfig
          dialogEnable={addEducationDetailsDialog}
          dialogUpdateState={updateAddEducationDetailsDialogValFromChild}
          selectedItem={editItem}
          selectedItemUpdateState={selectedItemUpdateState}
          type={type}
        />
      ) : null}
    </Box>
  );
}
