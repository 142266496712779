import { ResponseTimeResponse } from "./Model";
export const RESPONSE_TIME_LOADING = "RESPONSE_TIME_LOADING";
export const RESPONSE_TIME_SUCCESS = "RESPONSE_TIME_SUCCESS";
export const RESPONSE_TIME_FAIL = "RESPONSE_TIME_FAIL";
export const RESPONSE_TIME_API_MSG = "RESPONSE_TIME_API_MSG";
export const RESPONSE_TIME_API_RES = "RESPONSE_TIME_API_RES";

export interface ResponseTimeState {
  responseTimeResponse: ResponseTimeResponse;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface ResponseTimeLoading {
  type: typeof RESPONSE_TIME_LOADING;
  loading: boolean;
}
export interface ResponseTimeSuccess {
  type: typeof RESPONSE_TIME_SUCCESS;
  payload: ResponseTimeResponse;
  successMsg: string;
}
export interface ResponseTimeFail {
  type: typeof RESPONSE_TIME_FAIL;
  payload: ResponseTimeResponse;
  errorMsg: string;
  status: number;
}
export interface ResponseTimeAPIMsg {
  type: typeof RESPONSE_TIME_API_MSG;
  payload: ResponseTimeResponse;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface ResponseTimeAPIRes {
  type: typeof RESPONSE_TIME_API_RES;
  payload: ResponseTimeResponse;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type ResponseTimeActionTypes =
  | ResponseTimeLoading
  | ResponseTimeSuccess
  | ResponseTimeFail
  | ResponseTimeAPIMsg
  | ResponseTimeAPIRes;
