import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  FormHelperText,
  InputLabel,
  InputAdornment,
  OutlinedInput,
  IconButton,
  Typography,
  Alert,
  Box,
  Grid,
  Divider,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { ResetPasswordDetails } from "../../redux/resetPassword/Model";
import { resetPasswordApi } from "../../redux/resetPassword/API";
import { logOut } from "../../redux/logOut/API";
import { t } from "i18next";
import { AppState } from "../../redux/store/Store";
import CloseIcon from "@mui/icons-material/Close";
interface Props {
  toggleResetPwd: () => void;
  isResetPwdOpen: boolean;
}
const ResetPwd = ({ toggleResetPwd, isResetPwdOpen }: Props) => {
  const dispatch = useDispatch();
  interface PwdState {
    password: string;
    showPassword: boolean;
    status?: string;
    msg?: string;
  }
  const [oldPassword, setOldPassword] = React.useState<PwdState>({
    password: "",
    showPassword: false,
  });
  const handleOldPasswordChange =
    (prop: keyof PwdState) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setOldPassword({
        ...oldPassword,
        [prop]: event.target.value,
        status: "",
      });
    };

  const handleOldPasswordClickShowPassword = () => {
    setOldPassword({ ...oldPassword, showPassword: !oldPassword.showPassword });
  };
  const handleOldMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const [newPassword, setNewPassword] = React.useState<PwdState>({
    password: "",
    showPassword: false,
  });

  const handleNewPasswordChange =
    (prop: keyof PwdState) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setNewPassword({
        ...newPassword,
        [prop]: event.target.value,
        status: "",
      });
    };

  const handleNewPasswordClickShowPassword = () => {
    setNewPassword({
      ...newPassword,
      showPassword: !newPassword.showPassword,
    });
  };

  const handleNewPasswordMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  // Reset Password Modal
  const [resetPasswordResetSuccess, setResetPasswordResetSuccess] =
    useState("");
  const handleBannerDismiss = () => {
    setResetPasswordResetSuccess("");
    setResetPasswordResetError("");
  };

  const resetPWSuccessMessage = resetPasswordResetSuccess ? (
    <Alert
      severity="success"
      onClose={() => {
        handleBannerDismiss();
      }}
    >
      <Typography>{resetPasswordResetSuccess}</Typography>
    </Alert>
  ) : (
    ""
  );

  const [resetPasswordResetError, setResetPasswordResetError] = useState("");
  const resetPWErrorMessage = resetPasswordResetError ? (
    <Alert
      severity="error"
      onClose={() => {
        handleBannerDismiss();
      }}
    >
      <Typography>{resetPasswordResetError}</Typography>
    </Alert>
  ) : (
    ""
  );

  // Reset Password Body
  const resetbody = {
    old_password: oldPassword.password,
    new_password: newPassword.password,
  } as ResetPasswordDetails;

  // Reset Password API
  const resetgetPasswordApicall = () => {
    if (oldPassword.password === "") {
      setOldPassword({
        ...oldPassword,
        msg: `${t("userprofile.enteroldpsw", { ns: ["home"] })!}`,
        status: "Error",
      });
    } else if (newPassword.password === "") {
      setNewPassword({
        ...newPassword,
        msg: `${t("userprofile.newpsw", { ns: ["home"] })!}`,
        status: "Error",
      });
    } else {
      setNewPassword({
        ...newPassword,
        status: "Success",
      });
      setOldPassword({
        ...oldPassword,
        status: "Success",
      });
      dispatch(resetPasswordApi(resetbody));
    }
  };

  const resetPasswordValue = useSelector(
    (state: AppState) => state.resetPassword
  );

  useEffect(() => {
    if (resetPasswordValue?.resetPasswordRes.message !== undefined) {
      setResetPasswordResetError("");
      setResetPasswordResetSuccess(
        resetPasswordValue?.resetPasswordRes.message
      );
    } else if (resetPasswordValue.errRes === "Email Failure") {
      setResetPasswordResetSuccess("");
      setResetPasswordResetError(resetPasswordValue.errRes);
      dispatch(logOut());
    } else {
      setResetPasswordResetSuccess("");
      setResetPasswordResetError(resetPasswordValue.errRes);
    }
  }, [resetPasswordValue, dispatch]);

  return (
    <Dialog
      open={isResetPwdOpen}
      onClose={toggleResetPwd}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Grid item> {t("userprofile.resetpsword", { ns: ["home"] })!}</Grid>
          <Grid item>
            <IconButton onClick={() => toggleResetPwd()}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <FormControl sx={{ my: 2 }} fullWidth variant="outlined">
          <InputLabel size="small" htmlFor="outlined-old-password">
            {t("userprofile.enteroldpsw", { ns: ["home"] })!}
          </InputLabel>
          <OutlinedInput
            id="outlined-old-password"
            size="small"
            error={oldPassword.status === "Error" ? true : false}
            placeholder={t("userprofile.enteroldpsw", { ns: ["home"] })!}
            label={t("userprofile.enteroldpsw", { ns: ["home"] })!}
            type={oldPassword.showPassword ? "text" : "password"}
            value={oldPassword.password}
            onChange={handleOldPasswordChange("password")}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleOldPasswordClickShowPassword}
                  onMouseDown={handleOldMouseDownPassword}
                  edge="end"
                  size="small"
                >
                  {oldPassword.showPassword ? (
                    <VisibilityIcon />
                  ) : (
                    <VisibilityOffIcon />
                  )}
                </IconButton>
              </InputAdornment>
            }
          />
          <FormHelperText>
            <Typography
              component={"span"}
              color={oldPassword.status === "Error" ? "error" : "success"}
            >
              {oldPassword.msg}
            </Typography>
          </FormHelperText>
        </FormControl>
        <FormControl fullWidth variant="outlined">
          <InputLabel size="small" htmlFor="outlined-new-password">
            {t("loginpage.enterpassword", { ns: ["home"] })!}
          </InputLabel>
          <OutlinedInput
            id="outlined-new-password"
            size="small"
            error={newPassword.status === "Error" ? true : false}
            placeholder={t("loginpage.enterpassword", { ns: ["home"] })!}
            label={t("loginpage.enterpassword", { ns: ["home"] })!}
            type={newPassword.showPassword ? "text" : "password"}
            value={newPassword.password}
            onChange={handleNewPasswordChange("password")}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleNewPasswordClickShowPassword}
                  onMouseDown={handleNewPasswordMouseDownPassword}
                  edge="end"
                  size="small"
                >
                  {newPassword.showPassword ? (
                    <VisibilityIcon />
                  ) : (
                    <VisibilityOffIcon />
                  )}
                </IconButton>
              </InputAdornment>
            }
          />
          <FormHelperText>
            <Typography
              component={"span"}
              color={newPassword.status === "Error" ? "error" : "success"}
            >
              {newPassword.msg}
            </Typography>
          </FormHelperText>
        </FormControl>
        <Box my={3}>{resetPWSuccessMessage}</Box>
        <Box my={3}>{resetPWErrorMessage}</Box>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={resetgetPasswordApicall}>
          {t("common.reset", { ns: ["home"] })!}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ResetPwd;
