import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Typography,
  styled,
} from "@mui/material";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";

interface Props {
  dialogState: boolean;
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function InformationPopUp({ dialogState }: Props) {
  const history = useHistory();
  const [dialogStateValue, setDialogState] = useState(false);

  useEffect(() => {
    setDialogState(dialogState);
  }, [dialogState]);

  return (
    <BootstrapDialog
      aria-labelledby="customized-dialog-title"
      open={dialogStateValue}
      fullWidth
    >
      <DialogTitle>
        <Typography>
          {t("dashboard.createpatient", { ns: ["home"] })!}
        </Typography>
        <IconButton
          aria-label="close"
          onClick={() => setDialogState(false)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <DialogContentText component="div">
          <Typography>{t("dashboard.infomsg", { ns: ["home"] })!}</Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          spacing={2}
        >
          <Grid item>
            <Button
              variant="outlined"
              onClick={() => history.push("/demographicdetails/other")}
            >
              {t("common.others", { ns: ["home"] })!}
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              onClick={() => {
                history.push("/demographicdetails/self");
              }}
            >
              {t("common.self", { ns: ["home"] })!}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </BootstrapDialog>
  );
}
