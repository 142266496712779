import {
  CreateAppointmentActionTypes,
  CREATE_APPOINTMENT_DETAILS_FOR_CASE_FAIL,
  CREATE_APPOINTMENT_DETAILS_FOR_CASE_LOADING,
  CREATE_APPOINTMENT_DETAILS_FOR_CASE_SUCCESS,
  CreateAppointmentState,
  CREATE_APPOINTMENT_DETAILS_FOR_CASE_UPDATE_API_MSG,
  CREATE_APPOINTMENT_DETAILS_FOR_CASE_UPDATE_API_RES,
} from "./ActionTypes";
import { CreateAppointmentReqRes } from "./Model";

const initialStateEditPosts: CreateAppointmentState = {
  loading: false,
  CreateAppointmentRes: {} as CreateAppointmentReqRes,
  successMsg: "",
  errorMsg: "",
  status: 0,
};
export const createAppointmentReducer = (
  state = initialStateEditPosts,
  action: CreateAppointmentActionTypes
): CreateAppointmentState => {
  switch (action.type) {
    case CREATE_APPOINTMENT_DETAILS_FOR_CASE_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case CREATE_APPOINTMENT_DETAILS_FOR_CASE_SUCCESS:
      return {
        ...state,
        CreateAppointmentRes: action.payload,
        successMsg: action.successMsg,
      };
    case CREATE_APPOINTMENT_DETAILS_FOR_CASE_FAIL:
      return {
        ...state,
        CreateAppointmentRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case CREATE_APPOINTMENT_DETAILS_FOR_CASE_UPDATE_API_MSG:
      return {
        ...state,
        CreateAppointmentRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case CREATE_APPOINTMENT_DETAILS_FOR_CASE_UPDATE_API_RES:
      return {
        ...state,
        CreateAppointmentRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
