import React from "react";
import {
  Card,
  CardHeader,
  Grid,
  Box,
  CardContent,
  Stack,
  Typography,
  Avatar,
} from "@mui/material";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
import { Link } from "react-router-dom";
import PageLayout from "../../Layout/PageLayout";
import { t } from "i18next";

type Props = {
  doctorList?: any;
};

export default function AllDoctor({ doctorList }: Props) {
  const actualPageMarkup = (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      spacing={2}
    >
      {doctorList !== undefined
        ? doctorList.map((doctorItem: any, index: number) => (
            <Grid item xs={12} sm={4} md={4} lg={4} xl={4} key={index}>
              <Link
                to={`/doctordetails/${doctorItem.id}`}
                style={{ textDecoration: "none" }}
              >
                <Card>
                  <CardHeader
                    avatar={
                      <Avatar sx={{ bgcolor: "#3f51b5" }} aria-label="recipe">
                        {<LocalHospitalIcon />}
                      </Avatar>
                    }
                    title={<Typography>{doctorItem.fullName}</Typography>}
                    subheader={
                      <Typography color="#6574c4">
                        {doctorItem?.qualification}
                      </Typography>
                    }
                  />
                  <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                  >
                    <Box style={{ height: "300px", width: "300px" }}>
                      <img
                        alt=""
                        width="100%"
                        height="100%"
                        src={
                          doctorItem?.image !== null
                            ? doctorItem?.image?.document
                            : "./dummydocImage.jpg"
                        }
                      />
                    </Box>
                  </Stack>

                  <CardContent>
                    <Grid
                      container
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      spacing={2}
                    >
                      <Grid item>
                        <Typography>
                          {t("finddoc.spcl", { ns: ["home"] })}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="body1">
                          {doctorItem.specialtyWithYearsOfExperience}
                        </Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Link>
            </Grid>
          ))
        : null}
    </Grid>
  );

  return <PageLayout>{actualPageMarkup}</PageLayout>;
}
