import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../Url";
import { SharedPatientListActionTypes } from "./ActionTypes";
import { SharedPatientDetailsResponse } from "./Model";
import {
  SharedPatientListAPIResClearAction,
  SharedPatientListErrorAction,
  SharedPatientListLoadingAction,
  SharedPatientListSuccessAction,
  SharedPatientListAPIMsgAction,
} from "./Action";
import { headers } from "../../../components/Utility";
import { setupInterceptorsTo } from "../../Authentication/Interceptors";

let apiRes = {} as SharedPatientDetailsResponse;
export const getSharedPatientListValue = () => {
  return function (dispatch: Dispatch<SharedPatientListActionTypes>) {
    dispatch(SharedPatientListLoadingAction(true));
    setupInterceptorsTo(axios)
      .get(Url.baseUrl + `/patientapp/api/patient/shared_patient_list`, {
        headers: headers,
      })
      .then((res) => {
        apiRes = res.data;
        dispatch(SharedPatientListLoadingAction(false));
        dispatch(
          SharedPatientListSuccessAction(
            res.data,
            res.data.message !== undefined
              ? res.data.message
              : "Shared patient list fetch successfully."
          )
        );
      })
      .catch((error) => {
        dispatch(SharedPatientListLoadingAction(false));
        dispatch(
          SharedPatientListErrorAction(
            {} as SharedPatientDetailsResponse,
            error.response.data !== undefined
              ? error.response.data.message !== undefined
                ? error.response.data.message
                : "Something went wrong"
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0
          )
        );
      });
  };
};

export const SharedPatientListAPIResMsg = () => {
  return function (dispatch: Dispatch<SharedPatientListActionTypes>) {
    dispatch(SharedPatientListAPIMsgAction(apiRes as SharedPatientDetailsResponse, "", "", 0));
  };
};

export const clearSharedPatientListAPIRes = () => {
  return function (dispatch: Dispatch<SharedPatientListActionTypes>) {
    dispatch(SharedPatientListAPIResClearAction({} as SharedPatientDetailsResponse, "", "", 0));
  };
};
