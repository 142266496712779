import {
  PatientDropDownListActionTypes,
  PATIENT_DROPDOWN_LIST_FAIL,
  PATIENT_DROPDOWN_LIST_LOADING,
  PATIENT_DROPDOWN_LIST_SUCCESS,
  PatientDropDownListState,
  PATIENT_DROPDOWN_LIST_API_MSG,
  PATIENT_DROPDOWN_LIST_API_RES,
} from "./ActionTypes";
import { PatientListRes } from "./Model";

const initialStateEditPosts: PatientDropDownListState = {
  loading: false,
  patientDropDownListRes: {} as PatientListRes,
  successMsg: "",
  errorMsg: "",
  status: 0,
};
export const patientDropDownListReducer = (
  state = initialStateEditPosts,
  action: PatientDropDownListActionTypes
): PatientDropDownListState => {
  switch (action.type) {
    case PATIENT_DROPDOWN_LIST_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case PATIENT_DROPDOWN_LIST_SUCCESS:
      return {
        ...state,
        patientDropDownListRes: action.payload,
        successMsg: action.successMsg,
      };
    case PATIENT_DROPDOWN_LIST_FAIL:
      return {
        ...state,
        patientDropDownListRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case PATIENT_DROPDOWN_LIST_API_MSG:
      return {
        ...state,
        patientDropDownListRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case PATIENT_DROPDOWN_LIST_API_RES:
      return {
        ...state,
        patientDropDownListRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
