import {
  BLOOD_GRP_FAIL,
  BLOOD_GRP_LOADING,
  BLOOD_GRP_SUCCESS,
  BLOOD_GRP_API_MSG,
  BLOOD_GRP_API_RES,
  BloodGrpActionTypes,
} from "./ActionTypes";
import { BloodGroupListResponse } from "./Model";

export const BloodGrpLoadingAction = (loading: boolean): BloodGrpActionTypes => {
  return {
    type: BLOOD_GRP_LOADING,
    loading: loading,
  };
};

export const BloodGrpSuccessAction = (
  BloodGrpResponse: BloodGroupListResponse,
  successMsg: string
): BloodGrpActionTypes => {
  return {
    type: BLOOD_GRP_SUCCESS,
    payload: BloodGrpResponse,
    successMsg: successMsg,
  };
};

export const BloodGrpErrorAction = (
  BloodGrpResponse: BloodGroupListResponse,
  errMsg: string,
  status: number
): BloodGrpActionTypes => {
  return {
    type: BLOOD_GRP_FAIL,
    payload: BloodGrpResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const BloodGrpAPIMsgAction = (
  BloodGrpResponse: BloodGroupListResponse,
  successMsg: string,
  errMsg: string,
  status: number
): BloodGrpActionTypes => {
  return {
    type: BLOOD_GRP_API_MSG,
    payload: BloodGrpResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const BloodGrpAPIResClearAction = (
  BloodGrpResponse: BloodGroupListResponse,
  successMsg: string,
  errMsg: string,
  status: number
): BloodGrpActionTypes => {
  return {
    type: BLOOD_GRP_API_RES,
    payload: BloodGrpResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
