import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../Url";
import { PaymentListActionTypes } from "./ActionTypes";
import { PaymentListBody, PaymentListResponse } from "./Model";
import {
  paymentListAPIResClearAction,
  paymentListErrorAction,
  paymentListLoadingAction,
  paymentListSuccessAction,
  paymentListAPIMsgAction,
} from "./Action";
import { headers } from "../../../components/Utility";
import { setupInterceptorsTo } from "../../Authentication/Interceptors";

let apiRes = {} as PaymentListResponse;
export const getExistingAppointmentAndPaymentDetails = (
  payload: PaymentListBody
) => {
  return function (dispatch: Dispatch<PaymentListActionTypes>) {
    dispatch(paymentListLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(Url.baseUrl + `/paymentapp/api/payment/get_payment_link`, payload, {
        headers: headers,
      })
      .then((res) => {
        apiRes = res.data;
        dispatch(paymentListLoadingAction(false));
        dispatch(
          paymentListSuccessAction(
            res.data,
            res.data.message !== undefined
              ? res.data.message
              : "Case added successfully"
          )
        );
      })
      .catch((error) => {
        dispatch(paymentListLoadingAction(false));
        dispatch(
          paymentListErrorAction(
            {} as PaymentListResponse,
            error.response.data !== undefined
              ? error.response.data.message !== undefined
                ? error.response.data.message
                : "Something went wrong"
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0
          )
        );
      });
  };
};

export const paymentListAPIResMsg = () => {
  return function (dispatch: Dispatch<PaymentListActionTypes>) {
    dispatch(paymentListAPIMsgAction(apiRes as PaymentListResponse, "", "", 0));
  };
};

export const clearpaymentListAPIRes = () => {
  return function (dispatch: Dispatch<PaymentListActionTypes>) {
    dispatch(
      paymentListAPIResClearAction({} as PaymentListResponse, "", "", 0)
    );
  };
};
