import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../Url";
import { RelationshipListActionTypes } from "./ActionTypes";
import { RelationshipResponse } from "./Model";
import {
  RelationshipListAPIResClearAction,
  RelationshipListErrorAction,
  RelationshipListLoadingAction,
  RelationshipListSuccessAction,
  RelationshipListAPIMsgAction,
} from "./Action";
import { headers } from "../../../components/Utility";
import { setupInterceptorsTo } from "../../Authentication/Interceptors";

let apiRes = {} as RelationshipResponse;
export const relationShipListApi = () => {
  return function (dispatch: Dispatch<RelationshipListActionTypes>) {
    dispatch(RelationshipListLoadingAction(true));
    setupInterceptorsTo(axios)
      .get(Url.baseUrl + `/patientapp/api/patient/patient_relationship_list`, {
        headers: headers,
      })
      .then((res) => {
        apiRes = res.data;
        dispatch(RelationshipListLoadingAction(false));
        dispatch(
          RelationshipListSuccessAction(
            res.data,
            res.data.message !== undefined
              ? res.data.message
              : "Relationship list fetch successfully."
          )
        );
      })
      .catch((error) => {
        dispatch(RelationshipListLoadingAction(false));
        dispatch(
          RelationshipListErrorAction(
            {} as RelationshipResponse,
            error.response.data !== undefined
              ? error.response.data.message !== undefined
                ? error.response.data.message
                : "Something went wrong"
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0
          )
        );
      });
  };
};

export const RelationshipListAPIResMsg = () => {
  return function (dispatch: Dispatch<RelationshipListActionTypes>) {
    dispatch(RelationshipListAPIMsgAction(apiRes as RelationshipResponse, "", "", 0));
  };
};

export const clearRelationshipListAPIRes = () => {
  return function (dispatch: Dispatch<RelationshipListActionTypes>) {
    dispatch(RelationshipListAPIResClearAction({} as RelationshipResponse, "", "", 0));
  };
};
