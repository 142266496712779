import { PatientCaseDetailsResponse } from "./Model";
export const CASE_DETAILS_LOADING = "CASE_DETAILS_LOADING";
export const CASE_DETAILS_SUCCESS = "CASE_DETAILS_SUCCESS";
export const CASE_DETAILS_FAIL = "CASE_DETAILS_FAIL";
export const CASE_DETAILS_API_MSG = "CASE_DETAILS_API_MSG";
export const CASE_DETAILS_API_RES = "CASE_DETAILS_API_RES";

export interface CaseDetailsState {
  caseDetailsResponse: PatientCaseDetailsResponse;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface CaseDetailsLoading {
  type: typeof CASE_DETAILS_LOADING;
  loading: boolean;
}
export interface CaseDetailsSuccess {
  type: typeof CASE_DETAILS_SUCCESS;
  payload: PatientCaseDetailsResponse;
  successMsg: string;
}
export interface CaseDetailsFail {
  type: typeof CASE_DETAILS_FAIL;
  payload: PatientCaseDetailsResponse;
  errorMsg: string;
  status: number;
}
export interface CaseDetailsAPIMsg {
  type: typeof CASE_DETAILS_API_MSG;
  payload: PatientCaseDetailsResponse;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface CaseDetailsAPIRes {
  type: typeof CASE_DETAILS_API_RES;
  payload: PatientCaseDetailsResponse;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type CaseDetailsActionTypes =
  | CaseDetailsLoading
  | CaseDetailsSuccess
  | CaseDetailsFail
  | CaseDetailsAPIMsg
  | CaseDetailsAPIRes;
