import { PaymentListResponse } from "./Model";
export const PAYMENT_LIST_LOADING = "PAYMENT_LIST_LOADING";
export const PAYMENT_LIST_SUCCESS = "PAYMENT_LIST_SUCCESS";
export const PAYMENT_LIST_FAIL = "PAYMENT_LIST_FAIL";
export const PAYMENT_LIST_API_MSG = "PAYMENT_LIST_API_MSG";
export const PAYMENT_LIST_API_RES = "PAYMENT_LIST_API_RES";

export interface PaymentListState {
  paymentListResponse: PaymentListResponse;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface PaymentListLoading {
  type: typeof PAYMENT_LIST_LOADING;
  loading: boolean;
}
export interface PaymentListSuccess {
  type: typeof PAYMENT_LIST_SUCCESS;
  payload: PaymentListResponse;
  successMsg: string;
}
export interface PaymentListFail {
  type: typeof PAYMENT_LIST_FAIL;
  payload: PaymentListResponse;
  errorMsg: string;
  status: number;
}
export interface PaymentListAPIMsg {
  type: typeof PAYMENT_LIST_API_MSG;
  payload: PaymentListResponse;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface PaymentListAPIRes {
  type: typeof PAYMENT_LIST_API_RES;
  payload: PaymentListResponse;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type PaymentListActionTypes =
  | PaymentListLoading
  | PaymentListSuccess
  | PaymentListFail
  | PaymentListAPIMsg
  | PaymentListAPIRes;
