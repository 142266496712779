import { AnsDoctorQuestionRes } from "./Model";
export const ANSWER_DOCTOR_QUESTION_LOADING = "ANSWER_DOCTOR_QUESTION_LOADING";
export const ANSWER_DOCTOR_QUESTION_SUCCESS = "ANSWER_DOCTOR_QUESTION_SUCCESS";
export const ANSWER_DOCTOR_QUESTION_FAIL = "ANSWER_DOCTOR_QUESTION_FAIL";
export const ANSWER_DOCTOR_QUESTION_API_MSG = "ANSWER_DOCTOR_QUESTION_API_MSG";
export const ANSWER_DOCTOR_QUESTION_API_RES = "ANSWER_DOCTOR_QUESTION_API_RES";

export interface AnswerDoctorQuestionState {
  answerDoctorQuestionRes: AnsDoctorQuestionRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface AnswerDoctorQuestionLoading {
  type: typeof ANSWER_DOCTOR_QUESTION_LOADING;
  loading: boolean;
}
export interface AnswerDoctorQuestionSuccess {
  type: typeof ANSWER_DOCTOR_QUESTION_SUCCESS;
  payload: AnsDoctorQuestionRes;
  successMsg: string;
}
export interface AnswerDoctorQuestionFail {
  type: typeof ANSWER_DOCTOR_QUESTION_FAIL;
  payload: AnsDoctorQuestionRes;
  errorMsg: string;
  status: number;
}
export interface AnswerDoctorQuestionAPIMsg {
  type: typeof ANSWER_DOCTOR_QUESTION_API_MSG;
  payload: AnsDoctorQuestionRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface AnswerDoctorQuestionAPIRes {
  type: typeof ANSWER_DOCTOR_QUESTION_API_RES;
  payload: AnsDoctorQuestionRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type AnswerDoctorQuestionActionTypes =
  | AnswerDoctorQuestionLoading
  | AnswerDoctorQuestionSuccess
  | AnswerDoctorQuestionFail
  | AnswerDoctorQuestionAPIMsg
  | AnswerDoctorQuestionAPIRes;
