import {
  DOCTOR_LIST_FAIL,
  DOCTOR_LIST_LOADING,
  DOCTOR_LIST_SUCCESS,
  DOCTOR_LIST_API_MSG,
  DOCTOR_LIST_API_RES,
  ActiveDoctorListActionTypes,
} from "./ActionTypes";
import { DoctorListResponse } from "./Model";

export const ActiveDoctorListLoadingAction = (loading: boolean): ActiveDoctorListActionTypes => {
  return {
    type: DOCTOR_LIST_LOADING,
    loading: loading,
  };
};

export const ActiveDoctorListSuccessAction = (
  doctorDetailsResponse: DoctorListResponse,
  successMsg: string
): ActiveDoctorListActionTypes => {
  return {
    type: DOCTOR_LIST_SUCCESS,
    payload: doctorDetailsResponse,
    successMsg: successMsg,
  };
};

export const ActiveDoctorListErrorAction = (
  doctorDetailsResponse: DoctorListResponse,
  errMsg: string,
  status: number
): ActiveDoctorListActionTypes => {
  return {
    type: DOCTOR_LIST_FAIL,
    payload: doctorDetailsResponse,
    errorMsg: errMsg,
    status: status,
  };
};

export const ActiveDoctorListAPIMsgAction = (
  doctorDetailsResponse: DoctorListResponse,
  successMsg: string,
  errMsg: string,
  status: number
): ActiveDoctorListActionTypes => {
  return {
    type: DOCTOR_LIST_API_MSG,
    payload: doctorDetailsResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const ActiveDoctorListAPIResClearAction = (
  doctorDetailsResponse: DoctorListResponse,
  successMsg: string,
  errMsg: string,
  status: number
): ActiveDoctorListActionTypes => {
  return {
    type: DOCTOR_LIST_API_RES,
    payload: doctorDetailsResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
