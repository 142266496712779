import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../Url";
import { OpinionTypeActionTypes } from "./ActionTypes";
import { OpinionTypeListResponse } from "./Model";
import {
  opinionTypeAPIResClearAction,
  opinionTypeErrorAction,
  opinionTypeLoadingAction,
  opinionTypeSuccessAction,
  opinionTypeAPIMsgAction,
} from "./Action";
import { headers } from "../../../components/Utility";
import { setupInterceptorsTo } from "../../Authentication/Interceptors";

let apiRes = {} as OpinionTypeListResponse;
export const patientOpinionType = () => {
  return function (dispatch: Dispatch<OpinionTypeActionTypes>) {
    dispatch(opinionTypeLoadingAction(true));
    setupInterceptorsTo(axios)
      .get(Url.baseUrl + `/secondopinionapp/api/secondopinion/opinion_type`, {
        headers: headers,
      })
      .then((res) => {
        apiRes = res.data;
        dispatch(opinionTypeLoadingAction(false));
        dispatch(
          opinionTypeSuccessAction(
            res.data,
            res.data.message !== undefined
              ? res.data.message
              : "Opinion type fetch successfully."
          )
        );
      })
      .catch((error) => {
        dispatch(opinionTypeLoadingAction(false));
        dispatch(
          opinionTypeErrorAction(
            {} as OpinionTypeListResponse,
            error.response.data !== undefined
              ? error.response.data.message !== undefined
                ? error.response.data.message
                : "Something went wrong"
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0
          )
        );
      });
  };
};

export const opinionTypeAPIResMsg = () => {
  return function (dispatch: Dispatch<OpinionTypeActionTypes>) {
    dispatch(opinionTypeAPIMsgAction(apiRes as OpinionTypeListResponse, "", "", 0));
  };
};

export const clearOpinionTypeAPIRes = () => {
  return function (dispatch: Dispatch<OpinionTypeActionTypes>) {
    dispatch(opinionTypeAPIResClearAction({} as OpinionTypeListResponse, "", "", 0));
  };
};
