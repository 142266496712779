import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  OutlinedInput,
  Typography,
} from "@mui/material";
import { t } from "i18next";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import { deletePatient } from "../../../redux/dashboard/deletePatient/API";
import { DeletePatientBody } from "../../../redux/dashboard/deletePatient/Model";
import { getPatientListValue } from "../../../redux/dashboard/patientList/API";

interface Props {
  modalName: string;
  dialogState: boolean;
  patientId: string;
  dialogChildState: boolean | any;
}

export default function MyPatientList({
  modalName,
  dialogState,
  patientId,
  dialogChildState,
}: Props) {
  const dispatch = useDispatch();
  const [shareTextValue, setShareTextValue] = useState("");

  const deleteBody = {
    patientId: patientId,
    isActive: false,
  } as DeletePatientBody;

  const sharePatient = () => {};

  const DeleteRecordSubmit = async () => {
    dialogChildState(false);
    await dispatch(deletePatient(deleteBody));
    await dispatch(getPatientListValue());
  };

  const closeDeleteDialog = () => {
    dialogChildState(false);
  };

  return (
    <Dialog open={dialogState} fullWidth>
      <DialogTitle>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Grid item>
            {modalName === "shared" ? (
              <Typography>
                {t("perHealthRecPage.shareHealthRec", { ns: ["home"] })!}
              </Typography>
            ) : (
              <Typography>
                {t("perHealthRecPage.delPat", { ns: ["home"] })!}
              </Typography>
            )}
          </Grid>
          <Grid item>
            <IconButton
              aria-label="close"
              onClick={closeDeleteDialog}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <DialogContentText>
          {modalName === "shared" ? (
            <>
              <Box style={{ marginBottom: "5px" }}>
                {t("perHealthRecPage.sharePeoGrp", { ns: ["home"] })!}
              </Box>
              <OutlinedInput
                value={shareTextValue}
                placeholder={t("perHealthRecPage.addPeoGrp", { ns: ["home"] })!}
                id="sharehealthrecord"
                onChange={(e) => setShareTextValue(e.target.value)}
                fullWidth
              />
            </>
          ) : (
            <Typography variant="h6" fontWeight="bold">
              {t("perHealthRecPage.qdelPat", { ns: ["home"] })}
            </Typography>
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {modalName === "shared" ? (
          <Button variant="contained" onClick={sharePatient}>
            {t("perHealthRecPage.share", { ns: ["home"] })!}
          </Button>
        ) : (
          <>
            <Button variant="outlined" onClick={closeDeleteDialog}>
              {t("common.no", { ns: ["home"] })!}
            </Button>
            <Button variant="contained" onClick={DeleteRecordSubmit}>
              {t("common.yes", { ns: ["home"] })!}
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
}
