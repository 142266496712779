import { PaymentApproveDetailsResponse } from "./Model";
export const PAYMENT_APPROVE_LOADING = "PAYMENT_APPROVE_LOADING";
export const PAYMENT_APPROVE_SUCCESS = "PAYMENT_APPROVE_SUCCESS";
export const PAYMENT_APPROVE_FAIL = "PAYMENT_APPROVE_FAIL";
export const PAYMENT_APPROVE_API_MSG = "PAYMENT_APPROVE_API_MSG";
export const PAYMENT_APPROVE_API_RES = "PAYMENT_APPROVE_API_RES";

export interface paymentApproveState {
  paymentApproveResponse: PaymentApproveDetailsResponse;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface paymentApproveLoading {
  type: typeof PAYMENT_APPROVE_LOADING;
  loading: boolean;
}
export interface paymentApproveSuccess {
  type: typeof PAYMENT_APPROVE_SUCCESS;
  payload: PaymentApproveDetailsResponse;
  successMsg: string;
}
export interface paymentApproveFail {
  type: typeof PAYMENT_APPROVE_FAIL;
  payload: PaymentApproveDetailsResponse;
  errorMsg: string;
  status: number;
}
export interface paymentApproveAPIMsg {
  type: typeof PAYMENT_APPROVE_API_MSG;
  payload: PaymentApproveDetailsResponse;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface paymentApproveAPIRes {
  type: typeof PAYMENT_APPROVE_API_RES;
  payload: PaymentApproveDetailsResponse;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type PaymentApproveActionTypes =
  | paymentApproveLoading
  | paymentApproveSuccess
  | paymentApproveFail
  | paymentApproveAPIMsg
  | paymentApproveAPIRes;
