import {
  GetPatientInsuranceListActionTypes,
  GET_PATIENT_INSURANCE_LIST_FAIL,
  GET_PATIENT_INSURANCE_LIST_LOADING,
  GET_PATIENT_INSURANCE_LIST_SUCCESS,
  GetPatientInsuranceListState,
  GET_PATIENT_INSURANCE_LIST_API_MSG,
  GET_PATIENT_INSURANCE_LIST_API_RES,
} from "./ActionTypes";
import { GetInsuranceListRes } from "./Model";

const initialStateUpdatePosts: GetPatientInsuranceListState = {
  loading: false,
  getPatientInsuranceListRes: {} as GetInsuranceListRes,
  successMsg: "",
  errorMsg: "",
  status: 0,
};
export const getInsuranceDetailsReducer = (
  state = initialStateUpdatePosts,
  action: GetPatientInsuranceListActionTypes
): GetPatientInsuranceListState => {
  switch (action.type) {
    case GET_PATIENT_INSURANCE_LIST_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case GET_PATIENT_INSURANCE_LIST_SUCCESS:
      return {
        ...state,
        getPatientInsuranceListRes: action.payload,
        successMsg: action.successMsg,
      };
    case GET_PATIENT_INSURANCE_LIST_FAIL:
      return {
        ...state,
        getPatientInsuranceListRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case GET_PATIENT_INSURANCE_LIST_API_MSG:
      return {
        ...state,
        getPatientInsuranceListRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case GET_PATIENT_INSURANCE_LIST_API_RES:
      return {
        ...state,
        getPatientInsuranceListRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
