import {
  EditPatientInsuranceActionTypes,
  EDIT_PATIENT_INSURANCE_FAIL,
  EDIT_PATIENT_INSURANCE_LOADING,
  EDIT_PATIENT_INSURANCE_SUCCESS,
  EditPatientInsuranceState,
  EDIT_PATIENT_INSURANCE_API_MSG,
  EDIT_PATIENT_INSURANCE_API_RES,
} from "./ActionTypes";
import { EditPatientInsuranceRes } from "./Model";

const initialStateEditPosts: EditPatientInsuranceState = {
  loading: false,
  editPatientInsuranceRes: {} as EditPatientInsuranceRes,
  successMsg: "",
  errorMsg: "",
  status: 0,
};
export const editPatientInsuranceReducer = (
  state = initialStateEditPosts,
  action: EditPatientInsuranceActionTypes
): EditPatientInsuranceState => {
  switch (action.type) {
    case EDIT_PATIENT_INSURANCE_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case EDIT_PATIENT_INSURANCE_SUCCESS:
      return {
        ...state,
        editPatientInsuranceRes: action.payload,
        successMsg: action.successMsg,
      };
    case EDIT_PATIENT_INSURANCE_FAIL:
      return {
        ...state,
        editPatientInsuranceRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case EDIT_PATIENT_INSURANCE_API_MSG:
      return {
        ...state,
        editPatientInsuranceRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case EDIT_PATIENT_INSURANCE_API_RES:
      return {
        ...state,
        editPatientInsuranceRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
