import { GetPatientTeamResponse } from "./Model";

// Patient Meeting Loading State
export const GET_PATIENT_TEAM_LOADING = "GET_PATIENT_TEAM_LOADING";
export const GET_PATIENT_TEAM_SUCCESS = "GET_PATIENT_TEAM_SUCCESS";
export const GET_PATIENT_TEAM_FAIL = "GET_PATIENT_TEAM_FAIL";
export const GET_PATIENT_TEAM_DATA = "GET_PATIENT_TEAM_DATA";

export interface answerQuestionDetails {
  getPatientTeamRes: GetPatientTeamResponse;
  loading: boolean;
  errRes: string;
}
export interface LogOutActionLoading {
  type: typeof GET_PATIENT_TEAM_LOADING;
}
export interface LogOutActionSuccess {
  type: typeof GET_PATIENT_TEAM_SUCCESS;
  payload: GetPatientTeamResponse;
  errRes: string;
}
export interface LogOutActionFail {
  type: typeof GET_PATIENT_TEAM_FAIL;
  payload: GetPatientTeamResponse;
  errRes: string;
}
interface LogOutActionAction {
  type: typeof GET_PATIENT_TEAM_DATA;
  payload: GetPatientTeamResponse;
  errRes: string;
}

export type LogOutActionTypes =
  | LogOutActionAction
  | LogOutActionLoading
  | LogOutActionSuccess
  | LogOutActionFail;
