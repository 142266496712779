import React from "react";
import { Box, Stack } from "@mui/material";
import DoctorTeamType from "./DoctorTeamType";
import SelectDoctorForCase from "./SelectDoctorForCase";
import SelectedDoctorList from "./SelectedDoctorList";

export default function AppointmentSelectDoctor() {
  // Get Loading value from Doctor Team Type section
  // const doctorTeamTypeLoaderValueFromChild = (passedVal: boolean) => {
  //   selectDoctorLoader(passedVal);
  // };

  // Get Loading value from Select Doctor section
  // const selectDoctorForCaseLoaderValueFromChild = (passedVal: boolean) => {
  //   selectDoctorLoader(passedVal);
  // };

  // Get Loading value from Selected Doctor List section
  // const selectedDoctorListLoaderValueFromChild = (passedVal: boolean) => {
  //   selectDoctorLoader(passedVal);
  // };

  return (
    <Box>
      <Stack
        direction="column"
        justifyContent="flex-start"
        alignItems="stretch"
        spacing={2}
      >
        <DoctorTeamType
        // doctorTeamTypeLoader={doctorTeamTypeLoaderValueFromChild}
        />
        <SelectDoctorForCase
        // selectDoctorForCaseLoader={selectDoctorForCaseLoaderValueFromChild}
        />
        <SelectedDoctorList
        // selectedDoctorListLoader={selectedDoctorListLoaderValueFromChild}
        />
      </Stack>
    </Box>
  );
}
