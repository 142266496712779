import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../Url";
import { ActiveDoctorListActionTypes } from "./ActionTypes";
import { DoctorListResponse } from "./Model";
import {
  ActiveDoctorListAPIResClearAction,
  ActiveDoctorListErrorAction,
  ActiveDoctorListLoadingAction,
  ActiveDoctorListSuccessAction,
  ActiveDoctorListAPIMsgAction,
} from "./Action";
import { headers } from "../../../components/Utility";
import { setupInterceptorsTo } from "../../Authentication/Interceptors";

let apiRes = {} as DoctorListResponse;
export const activeDoctorListApi = () => {
  return function (dispatch: Dispatch<ActiveDoctorListActionTypes>) {
    dispatch(ActiveDoctorListLoadingAction(true));
    setupInterceptorsTo(axios)
      .get(Url.baseUrl + `/doctorapp/api/doctor/active_doctor_list`, {
        headers: headers,
      })
      .then((res) => {
        apiRes = res.data;
        dispatch(ActiveDoctorListLoadingAction(false));
        dispatch(
          ActiveDoctorListSuccessAction(
            res.data,
            res.data.message !== undefined
              ? res.data.message
              : "Doctor details fetch successfully."
          )
        );
      })
      .catch((error) => {
        dispatch(ActiveDoctorListLoadingAction(false));
        dispatch(
          ActiveDoctorListErrorAction(
            {} as DoctorListResponse,
            error.response.data !== undefined
              ? error.response.data.message !== undefined
                ? error.response.data.message
                : "Something went wrong"
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0
          )
        );
      });
  };
};

export const ActiveDoctorListAPIResMsg = () => {
  return function (dispatch: Dispatch<ActiveDoctorListActionTypes>) {
    dispatch(ActiveDoctorListAPIMsgAction(apiRes as DoctorListResponse, "", "", 0));
  };
};

export const clearActiveDoctorListAPIRes = () => {
  return function (dispatch: Dispatch<ActiveDoctorListActionTypes>) {
    dispatch(ActiveDoctorListAPIResClearAction({} as DoctorListResponse, "", "", 0));
  };
};
