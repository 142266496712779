import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../Url";
import { SexualOrientationActionTypes } from "./ActionTypes";
import { SexualOrientationResponse } from "./Model";
import {
  SexualOrientationAPIResClearAction,
  SexualOrientationErrorAction,
  SexualOrientationLoadingAction,
  SexualOrientationSuccessAction,
  SexualOrientationAPIMsgAction,
} from "./Action";
import { headers } from "../../../components/Utility";
import { setupInterceptorsTo } from "../../Authentication/Interceptors";

let apiRes = {} as SexualOrientationResponse;
export const sexualOrientationListApi = () => {
  return function (dispatch: Dispatch<SexualOrientationActionTypes>) {
    dispatch(SexualOrientationLoadingAction(true));
    setupInterceptorsTo(axios)
      .get(Url.baseUrl + `/patientapp/api/patient/sexualOrientation_dropdown`, {
        headers: headers,
      })
      .then((res) => {
        apiRes = res.data;
        dispatch(SexualOrientationLoadingAction(false));
        dispatch(
          SexualOrientationSuccessAction(
            res.data,
            res.data.message !== undefined
              ? res.data.message
              : "Sexualorientation list fetch successfully."
          )
        );
      })
      .catch((error) => {
        dispatch(SexualOrientationLoadingAction(false));
        dispatch(
          SexualOrientationErrorAction(
            {} as SexualOrientationResponse,
            error.response.data !== undefined
              ? error.response.data.message !== undefined
                ? error.response.data.message
                : "Something went wrong"
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0
          )
        );
      });
  };
};

export const SexualOrientationAPIResMsg = () => {
  return function (dispatch: Dispatch<SexualOrientationActionTypes>) {
    dispatch(SexualOrientationAPIMsgAction(apiRes as SexualOrientationResponse, "", "", 0));
  };
};

export const clearSexualOrientationAPIRes = () => {
  return function (dispatch: Dispatch<SexualOrientationActionTypes>) {
    dispatch(SexualOrientationAPIResClearAction({} as SexualOrientationResponse, "", "", 0));
  };
};
