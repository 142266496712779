import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { t } from "i18next";
import {
  Alert,
  AlertColor,
  AlertTitle,
  Box,
  Button,
  Stack,
  Typography,
} from "@mui/material";
import QuestionAnswer from "./QuestionAnswer";
import { useDispatch, useSelector } from "react-redux";
import { GetQuestionListBody } from "../../../redux/CaseDetails/DoctorPatientInteraction/getQuestionListForPatient/Model";
import { getQuestionList } from "../../../redux/CaseDetails/DoctorPatientInteraction/getQuestionListForPatient/API";
import { getPatientDataList } from "../../../redux/FilePreparation/GetPatientDataList/API";
import { GetPatientDataDetailsBody } from "../../../redux/FilePreparation/GetPatientDataList/Model";
import DataList from "../FilePreparation/FIleData/DataList";
import { AppState } from "../../../redux/store/Store";
import MWSnackbar from "../../../components/MWSnackbar";
import {
  getMeetingDetails,
  updateGetMeetingDetailsAPIResMsg,
} from "../../../redux/CaseDetails/DoctorPatientInteraction/GetMeetingDetails/API";
import { GetMeetingDetailsBody } from "../../../redux/CaseDetails/DoctorPatientInteraction/GetMeetingDetails/Model";

export default function DoctorPatientInteraction() {
  const { caseid } = useParams() as {
    caseid: string;
  };
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getQuestionList({ caseId: caseid } as GetQuestionListBody));
    dispatch(
      getPatientDataList({
        caseId: caseid,
        secondOpinionUiSection: "ordersAndPrescriptions",
      } as GetPatientDataDetailsBody)
    );
    dispatch(getMeetingDetails({ caseId: caseid } as GetMeetingDetailsBody));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getMeetingDetailsRes = useSelector(
    (state: AppState) => state.getMeetingDetailsRes
  );
  const [askQuestionType, setAskQuestionType] = useState<AlertColor>("success");
  const [askQuestionMsg, setAskQuestionMsg] = useState("");
  const [meetingURL, setMeetingURL] = useState("");

  useEffect(() => {
    if (getMeetingDetailsRes?.successMsg !== "") {
      setAskQuestionMsg(getMeetingDetailsRes?.successMsg);
      dispatch(getQuestionList({ caseId: caseid } as GetQuestionListBody));
      setMeetingURL(
        getMeetingDetailsRes?.getMeetingDetailsRes?.joinUrl !== undefined
          ? getMeetingDetailsRes?.getMeetingDetailsRes?.joinUrl !== null
            ? getMeetingDetailsRes?.getMeetingDetailsRes?.joinUrl !== ""
              ? getMeetingDetailsRes?.getMeetingDetailsRes?.joinUrl
              : ""
            : ""
          : ""
      );
    } else if (getMeetingDetailsRes?.errorMsg !== "") {
      setAskQuestionMsg(getMeetingDetailsRes?.errorMsg);
      setAskQuestionType("error");
      setMeetingURL("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getMeetingDetailsRes]);
  const closeAlert = (passedVal: boolean) => {
    if (passedVal) {
      setAskQuestionMsg("");
      dispatch(updateGetMeetingDetailsAPIResMsg());
    }
  };

  // Open Join Meeting URL in New Page
  const joinMeeting = () => {
    if (meetingURL !== "") {
      window.open(meetingURL);
    }
  };

  return (
    <Box>
      <Stack
        direction="column"
        justifyContent="flex-start"
        alignItems="stretch"
        spacing={2}
      >
        <Alert
          severity="info"
          variant="outlined"
          action={
            <Button
              color="inherit"
              size="small"
              variant="outlined"
              disabled={meetingURL === "" ? true : false}
              onClick={() => {
                joinMeeting();
              }}
              sx={{ textTransform: "none" }}
            >
              {t("orderPresPage.joinMeet", { ns: ["home"] })!}
            </Button>
          }
        >
          <AlertTitle>
            <Typography variant="subtitle1" fontWeight="bold">
              {t("orderPresPage.telehealth", { ns: ["home"] })!}
            </Typography>
          </AlertTitle>
          <Typography>
            {t("orderPresPage.accessLink", { ns: ["home"] })!}
          </Typography>
        </Alert>
        <DataList
          type={"ordersAndPrescriptions"}
          label={`presComp`}
          prescription="ordersAndPrescriptions"
        />
        <QuestionAnswer caseId={caseid} />
      </Stack>
      {askQuestionMsg !== "" ? (
        <MWSnackbar
          msg={askQuestionMsg}
          type={askQuestionType}
          alertClose={closeAlert}
        />
      ) : null}
    </Box>
  );
}
