import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../Url";
import { LanguageListActionTypes } from "./ActionTypes";
import { LanguageListResponse } from "./Model";
import {
  LanguageListAPIResClearAction,
  LanguageListErrorAction,
  LanguageListLoadingAction,
  LanguageListSuccessAction,
  LanguageListAPIMsgAction,
} from "./Action";
import { headers } from "../../../components/Utility";
import { setupInterceptorsTo } from "../../Authentication/Interceptors";

let apiRes = {} as LanguageListResponse;
export const languageListApi = () => {
  return function (dispatch: Dispatch<LanguageListActionTypes>) {
    dispatch(LanguageListLoadingAction(true));
    setupInterceptorsTo(axios)
      .get(Url.baseUrl + `/doctorapp/api/onboarding/language_list_dropdown`, {
        headers: headers,
      })
      .then((res) => {
        apiRes = res.data;
        dispatch(LanguageListLoadingAction(false));
        dispatch(
          LanguageListSuccessAction(
            res.data,
            res.data.message !== undefined
              ? res.data.message
              : "Blood group list fetch successfully."
          )
        );
      })
      .catch((error) => {
        dispatch(LanguageListLoadingAction(false));
        dispatch(
          LanguageListErrorAction(
            {} as LanguageListResponse,
            error.response.data !== undefined
              ? error.response.data.message !== undefined
                ? error.response.data.message
                : "Something went wrong"
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0
          )
        );
      });
  };
};

export const LanguageListAPIResMsg = () => {
  return function (dispatch: Dispatch<LanguageListActionTypes>) {
    dispatch(LanguageListAPIMsgAction(apiRes as LanguageListResponse, "", "", 0));
  };
};

export const clearLanguageListAPIRes = () => {
  return function (dispatch: Dispatch<LanguageListActionTypes>) {
    dispatch(LanguageListAPIResClearAction({} as LanguageListResponse, "", "", 0));
  };
};
