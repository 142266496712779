import {
  PATIENT_DROPDOWN_LIST_FAIL,
  PATIENT_DROPDOWN_LIST_LOADING,
  PATIENT_DROPDOWN_LIST_SUCCESS,
  PATIENT_DROPDOWN_LIST_API_MSG,
  PATIENT_DROPDOWN_LIST_API_RES,
  PatientDropDownListActionTypes,
} from "./ActionTypes";
import { PatientListRes } from "./Model";

export const patientDropDownListLoadingAction = (
  loading: boolean
): PatientDropDownListActionTypes => {
  return {
    type: PATIENT_DROPDOWN_LIST_LOADING,
    loading: loading,
  };
};

export const patientDropDownListSuccessAction = (
  patientDropDownListResponse: PatientListRes,
  successMsg: string
): PatientDropDownListActionTypes => {
  return {
    type: PATIENT_DROPDOWN_LIST_SUCCESS,
    payload: patientDropDownListResponse,
    successMsg: successMsg,
  };
};

export const patientDropDownListErrorAction = (
  patientDropDownListResponse: PatientListRes,
  errMsg: string,
  status: number
): PatientDropDownListActionTypes => {
  return {
    type: PATIENT_DROPDOWN_LIST_FAIL,
    payload: patientDropDownListResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const patientDropDownListAPIMsgAction = (
  patientDropDownListResponse: PatientListRes,
  successMsg: string,
  errMsg: string,
  status: number
): PatientDropDownListActionTypes => {
  return {
    type: PATIENT_DROPDOWN_LIST_API_MSG,
    payload: patientDropDownListResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const patientDropDownListAPIResClearAction = (
  patientDropDownListResponse: PatientListRes,
  successMsg: string,
  errMsg: string,
  status: number
): PatientDropDownListActionTypes => {
  return {
    type: PATIENT_DROPDOWN_LIST_API_RES,
    payload: patientDropDownListResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
