import React, { useEffect, useState } from "react";
import {
  AlertColor,
  Box,
  Button,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../redux/store/Store";
import { useParams } from "react-router-dom";
import { t } from "i18next";
import MWSnackbar from "../../../components/MWSnackbar";
import {
  clearEditCaseAPIRes,
  editPatientCase,
} from "../../../redux/ConsultationOption/EditCase/API";
import { patientCaseDetails } from "../../../redux/ConsultationOption/CaseDetails/API";
import { CaseDetailsBody } from "../../../redux/ConsultationOption/CaseDetails/Model";
import { addPatientCase } from "../../../redux/ConsultationOption/AddCase/API";
import { AddCaseBody } from "../../../redux/ConsultationOption/AddCase/Model";
import { patientConsultationType } from "../../../redux/ConsultationOption/ConsultationType/API";

export default function ConsultationType() {
  const dispatch = useDispatch();

  const { caseid } = useParams() as {
    caseid: string;
  };

  const ConsultationTypeListValue = useSelector(
    (state: AppState) =>
      state.consultationTypeValue?.consultationTypeResponse
        ?.consulatationTyeList
  );

  const PatientCaseDetailsValue = useSelector(
    (state: AppState) =>
      state.getPatientCaseDetails?.caseDetailsResponse?.patientCaseDetail
  );

  const editPatientCaseIdvalue = useSelector(
    (state: AppState) => state.editPatientCase
  );

  const [getConsultationTypeValue, setConsultationTypeValue] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [isEnableSubmit, setEnableSubmit] = useState(true);
  const [value, setValue] = React.useState("");

  const [alertMsg, setAlertMsg] = useState("");
  const [alertMsgType, setAlertMsgType] = useState<AlertColor>("success");
  const closeAlert = (passedVal: boolean) => {
    if (passedVal) {
      setAlertMsg("");
      dispatch(clearEditCaseAPIRes());
    }
  };

  const addBody = {
    caseId: caseid,
    consultationTypeId: value,
  } as AddCaseBody;

  const createCase = async () => {
    if (caseid !== "") {
      await dispatch(
        editPatientCase(Object.assign(addBody, { caseId: caseid }))
      );
      await setIsEdit(false);
      await setEnableSubmit((isEnableSubmit) => !isEnableSubmit);
      await dispatch(patientCaseDetails({ caseId: caseid } as CaseDetailsBody));
    } else {
      await dispatch(addPatientCase(addBody));
      await dispatch(patientCaseDetails({ caseId: caseid } as CaseDetailsBody));
    }
  };

  useEffect(() => {
    dispatch(patientConsultationType());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(
      patientCaseDetails({
        caseId: caseid,
      } as CaseDetailsBody)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setValue(
      String(
        PatientCaseDetailsValue === undefined
          ? ""
          : PatientCaseDetailsValue?.consultationType?.value
      )
    );
    // window.location.reload();
    setConsultationTypeValue(PatientCaseDetailsValue?.consultationType?.label!);
  }, [PatientCaseDetailsValue]);

  useEffect(() => {
    if (editPatientCaseIdvalue?.successMsg !== "") {
      setAlertMsg(editPatientCaseIdvalue?.successMsg);
      setAlertMsgType("success");
      window.location.reload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editPatientCaseIdvalue]);

  const secondComponent = (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="stretch"
      spacing={2}
    >
      <Grid item>
        <Box>
          {ConsultationTypeListValue?.map((opinion: any) => (
            <FormControl
              key={opinion.id}
              disabled={getConsultationTypeValue ? !isEdit : isEdit}
            >
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={value}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setValue((event.target as HTMLInputElement).value)
                }
              >
                <Typography variant="h6" fontWeight="bold">
                  {opinion.title}
                </Typography>
                <FormControlLabel
                  value={opinion.id}
                  control={<Radio />}
                  label={opinion.description}
                />
              </RadioGroup>
            </FormControl>
          ))}
        </Box>
      </Grid>
      <Grid item>
        {getConsultationTypeValue ? (
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            spacing={1}
          >
            <Grid item>
              <Button
                size="large"
                variant="outlined"
                onClick={() => {
                  setIsEdit((isEdit) => !isEdit);
                  setEnableSubmit((isEnableSubmit) => !isEnableSubmit);
                }}
              >
                {isEdit
                  ? `${t("common.cancel", { ns: ["home"] })}`
                  : `${t("common.edit", { ns: ["home"] })}`}
              </Button>
            </Grid>
            <Grid item>
              <Button
                size="large"
                variant="contained"
                disabled={isEnableSubmit}
                onClick={createCase}
              >
                {t("common.update", { ns: ["home"] })}
              </Button>
            </Grid>
          </Grid>
        ) : (
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
          >
            <Button size="large" variant="contained" onClick={createCase}>
              {t("common.add", { ns: ["home"] })}
            </Button>
          </Grid>
        )}
      </Grid>
      {alertMsg !== "" ? (
        <MWSnackbar
          type={alertMsgType}
          msg={alertMsg}
          alertClose={closeAlert}
        />
      ) : null}
    </Grid>
  );

  return <Container maxWidth="xl">{secondComponent}</Container>;
}
