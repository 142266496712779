import axios from "axios";
import { Dispatch } from "redux";
import { OwnerAppointmentListActionTypes } from "./ActionTypes";
import { OwnerAppointmentResponse, OwnerAppointmentBody } from "./Model";
import {
  ownerAppointmentListAPIResClearAction,
  ownerAppointmentListErrorAction,
  ownerAppointmentListLoadingAction,
  ownerAppointmentListSuccessAction,
  ownerAppointmentListUpdateAPIMsgAction,
} from "./Actions";
import Url from "../../../Url";
import { headers } from "../../../components/Utility";
import { setupInterceptorsTo } from "../../Authentication/Interceptors";

let apiRes = {} as OwnerAppointmentResponse;
export const appoinmentRequestListForOwner = (
  payload: OwnerAppointmentBody
) => {
  return function (dispatch: Dispatch<OwnerAppointmentListActionTypes>) {
    dispatch(ownerAppointmentListLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        Url.baseUrl +
          `/appointmentapp/api/appointment/get_appointment_list_for_owner`,
        payload,

        {
          headers: headers,
        }
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(ownerAppointmentListLoadingAction(false));
        dispatch(
          ownerAppointmentListSuccessAction(
            res.data !== undefined
              ? res.data
              : ({} as OwnerAppointmentResponse),
            res.data.message !== undefined
              ? res.data.message !== ""
                ? res.data.message
                : "Owner appointment list has been fetched successfully"
              : "Owner appointment list has been fetched successfully"
          )
        );
      })
      .catch((error) => {
        dispatch(ownerAppointmentListLoadingAction(false));
        dispatch(
          ownerAppointmentListErrorAction(
            {} as OwnerAppointmentResponse,
            error.response !== undefined
              ? error.response.data.message || error.response.data.detail
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || -1 : -1
          )
        );
      });
  };
};

export const updateOwnerAppointmentListAPIResMsg = () => {
  return function (dispatch: Dispatch<OwnerAppointmentListActionTypes>) {
    dispatch(
      ownerAppointmentListUpdateAPIMsgAction(
        apiRes as OwnerAppointmentResponse,
        "",
        "",
        0
      )
    );
  };
};

export const clearOwnerAppointmentListAPIRes = () => {
  return function (dispatch: Dispatch<OwnerAppointmentListActionTypes>) {
    dispatch(
      ownerAppointmentListAPIResClearAction(
        {} as OwnerAppointmentResponse,
        "",
        "",
        0
      )
    );
  };
};
