import {
  AskDoctorQuestionActionTypes,
  ASK_DOCTOR_QUESTION_FAIL,
  ASK_DOCTOR_QUESTION_LOADING,
  ASK_DOCTOR_QUESTION_SUCCESS,
  AskDoctorQuestionState,
  ASK_DOCTOR_QUESTION_API_MSG,
  ASK_DOCTOR_QUESTION_API_RES,
} from "./ActionTypes";
import { AskQuestionRes } from "./Model";

const initialStateEditPosts: AskDoctorQuestionState = {
  loading: false,
  askDoctorQuestionRes: {} as AskQuestionRes,
  successMsg: "",
  errorMsg: "",
  status: 0,
};
export const askDoctorQuestionReducer = (
  state = initialStateEditPosts,
  action: AskDoctorQuestionActionTypes
): AskDoctorQuestionState => {
  switch (action.type) {
    case ASK_DOCTOR_QUESTION_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case ASK_DOCTOR_QUESTION_SUCCESS:
      return {
        ...state,
        askDoctorQuestionRes: action.payload,
        successMsg: action.successMsg,
      };
    case ASK_DOCTOR_QUESTION_FAIL:
      return {
        ...state,
        askDoctorQuestionRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case ASK_DOCTOR_QUESTION_API_MSG:
      return {
        ...state,
        askDoctorQuestionRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case ASK_DOCTOR_QUESTION_API_RES:
      return {
        ...state,
        askDoctorQuestionRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
