import * as React from "react";
import { useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { Box, Button, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../redux/store/Store";
import MWResourceList from "../components/MWResourceList";
import MWLoader from "../components/MWLoader";
import MWPageTitle from "../components/MWPageTitle";
import { t } from "i18next";
import EmptyCasePage from "../components/EmptyCasePage";
import { patientCaseList } from "../redux/caseList/API";
import { CaseListBody } from "../redux/caseList/Model";
import PageLayout from "./Layout/PageLayout";

export default function CaseHistory() {
  const history = useHistory();
  const { patientid } = useParams() as {
    patientid: string;
  };

  const handleAddEncounterToggle = () => {
    history.push(`/casedetails/${patientid}?&tab=consultationType`);
  };

  const handleDemographicToggle = () => {
    history.push(`/demographicdetails/${patientid}`);
  };

  const handleInsuranceToggle = () => {
    history.push(`/insurancedetails/${patientid}`);
  };

  const dispatch = useDispatch();

  // encounter list api call ...
  useEffect(() => {
    dispatch(patientCaseList({ patientId: patientid } as CaseListBody));
    localStorage.removeItem("paymentRedirect");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const caseHistory = useSelector((state: AppState) => state.caseValue);

  const caseHistoryName = useSelector(
    (state: AppState) => state.caseValue?.caseListResponse?.patientName
  );
  const pageTitle = `${t("common.caseHistof", { ns: ["home"] })} ${
    caseHistoryName ? caseHistoryName : ""
  }`;
  const reloadActionFromChild = (passedVal: boolean) => {
    if (passedVal) {
      dispatch(patientCaseList({ patientId: patientid } as CaseListBody));
    }
  };
  const pagebody = (
    <Box py={2}>
      {caseHistory?.caseListResponse?.patientCaseList !== undefined ? (
        caseHistory?.caseListResponse?.patientCaseList.length > 0 ? (
          caseHistory?.caseListResponse?.patientCaseList.map((element: any) => (
            <Box
              key={`${element.patientId}-${element.caseId}`}
              style={{ textDecoration: "none", textTransform: "none" }}
            >
              <Link
                to={`/casedetails/${element.patientId}/${element.caseId}`}
                style={{ textDecoration: "none" }}
              >
                <MWResourceList
                  icon={true}
                  title={element?.whyWant2ndOpinion}
                  showSubtitle={true}
                  subtitle={element?.secondOpinionReasons}
                  showReason={true}
                  reason={element?.consultationType}
                  showNavigation={true}
                />
              </Link>
            </Box>
          ))
        ) : (
          <EmptyCasePage />
        )
      ) : (
        <EmptyCasePage />
      )}
    </Box>
  );

  return (
    <>
      {caseHistory.loading ? <MWLoader /> : ""}
      <PageLayout>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Grid item>
            <MWPageTitle
              backButton={true}
              title={pageTitle}
              reload={true}
              reloadAction={reloadActionFromChild}
            />
          </Grid>
          <Grid item>
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="flex-start"
              mt={1}
              spacing={1}
            >
              <Grid item>
                <Button
                  variant="text"
                  onClick={() => handleInsuranceToggle()}
                  sx={{ textTransform: "none" }}
                >
                  {t("insurancePage.insDetail", { ns: ["home"] })}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="text"
                  onClick={() => handleDemographicToggle()}
                  sx={{ textTransform: "none" }}
                >
                  {t("addpatientpage.demodetail", { ns: ["home"] })}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  onClick={() => handleAddEncounterToggle()}
                  sx={{ textTransform: "none" }}
                >
                  {t("common.addCase", { ns: ["home"] })}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {pagebody}
      </PageLayout>
    </>
  );
}
