import axios from "axios";
import { Dispatch } from "redux";
import { GetAppointmentDetailsForCaseActionTypes } from "./ActionTypes";
import {
  GetAppointmentDetailsForCaseBody,
  GetAppointmentDetailsForCaseRes,
} from "./Model";
import {
  getAppointmentDetailsForCaseAPIResClearAction,
  getAppointmentDetailsForCaseErrorAction,
  getAppointmentDetailsForCaseLoadingAction,
  getAppointmentDetailsForCaseSuccessAction,
  getAppointmentDetailsForCaseUpdateAPIMsgAction,
} from "./Action";
import { setupInterceptorsTo } from "../../Authentication/Interceptors";
import Url from "../../../Url";
import { headers } from "../../../components/Utility";

let apiRes = {} as GetAppointmentDetailsForCaseRes;

export const getAppointmentDetailsForCase = (
  payload: GetAppointmentDetailsForCaseBody
) => {
  return function (
    dispatch: Dispatch<GetAppointmentDetailsForCaseActionTypes>
  ) {
    dispatch(getAppointmentDetailsForCaseLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        Url.baseUrl +
          `/appointmentapp/api/appointment/get_appointment_details_for_case`,
        payload,
        {
          headers: headers,
        }
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(getAppointmentDetailsForCaseLoadingAction(false));
        dispatch(
          getAppointmentDetailsForCaseSuccessAction(
            res.data !== undefined
              ? res.data
              : ({} as GetAppointmentDetailsForCaseRes),
            res.data.message !== undefined
              ? res.data.message
              : "Appointment details for patient case has been fetched successfully"
          )
        );
      })
      .catch((error) => {
        dispatch(getAppointmentDetailsForCaseLoadingAction(false));
        dispatch(
          getAppointmentDetailsForCaseErrorAction(
            {} as GetAppointmentDetailsForCaseRes,
            error.response !== undefined
              ? error.response.data.message || error.response.data.detail
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0
          )
        );
      });
  };
};

export const updateEditDoctorTeamTypeAPIResMsg = () => {
  return function (
    dispatch: Dispatch<GetAppointmentDetailsForCaseActionTypes>
  ) {
    dispatch(
      getAppointmentDetailsForCaseUpdateAPIMsgAction(
        apiRes as GetAppointmentDetailsForCaseRes,
        "",
        "",
        0
      )
    );
  };
};

export const clearGetAppointmentDetailsForCaseAPIRes = () => {
  return function (
    dispatch: Dispatch<GetAppointmentDetailsForCaseActionTypes>
  ) {
    dispatch(
      getAppointmentDetailsForCaseAPIResClearAction(
        {} as GetAppointmentDetailsForCaseRes,
        "",
        "",
        0
      )
    );
  };
};
