import { forgotPasswordValue } from "./Model";
export const FORGOT_PASSWORD_LOADING = "FORGOT_PASSWORD_LOADING";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAIL = "FORGOT_PASSWORD_FAIL";
export const FORGOT_PASSWORD_UPDATE_API_MSG = "FORGOT_PASSWORD_UPDATE_API_MSG";
export const FORGOT_PASSWORD_UPDATE_API_RES = "FORGOT_PASSWORD_UPDATE_API_RES";

export interface ForgotPasswordState {
  ForgotPasswordRes: forgotPasswordValue;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface ForgotPasswordLoading {
  type: typeof FORGOT_PASSWORD_LOADING;
  loading: boolean;
}
export interface ForgotPasswordSuccess {
  type: typeof FORGOT_PASSWORD_SUCCESS;
  payload: forgotPasswordValue;
  successMsg: string;
}
export interface ForgotPasswordFail {
  type: typeof FORGOT_PASSWORD_FAIL;
  payload: forgotPasswordValue;
  errorMsg: string;
  status: number;
}
export interface ForgotPasswordUpdateAPIMsg {
  type: typeof FORGOT_PASSWORD_UPDATE_API_MSG;
  payload: forgotPasswordValue;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface ForgotPasswordUpdateAPIRes {
  type: typeof FORGOT_PASSWORD_UPDATE_API_RES;
  payload: forgotPasswordValue;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type ForgotPasswordActionTypes =
  | ForgotPasswordLoading
  | ForgotPasswordSuccess
  | ForgotPasswordFail
  | ForgotPasswordUpdateAPIMsg
  | ForgotPasswordUpdateAPIRes;

