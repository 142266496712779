import {
  GetPatientDetailsActionTypes,
  GET_PATIENT_LOGIN_DETAILS_FAIL,
  GET_PATIENT_LOGIN_DETAILS_LOADING,
  GET_PATIENT_LOGIN_DETAILS_SUCCESS,
  GetPatientLoginDetailsState,
  GET_PATIENT_LOGIN_DETAILS_API_MSG,
  GET_PATIENT_LOGIN_DETAILS_API_RES,
} from "./ActionTypes";
import { GetPatientDetailsRes } from "./Model";

const initialStateUpdatePosts: GetPatientLoginDetailsState = {
  loading: false,
  getPatientLoginDetailsRes: {} as GetPatientDetailsRes,
  errorMsg: "",
  status: 0,
};
export const getPatientLoginDetailsReducer = (
  state = initialStateUpdatePosts,
  action: GetPatientDetailsActionTypes
): GetPatientLoginDetailsState => {
  switch (action.type) {
    case GET_PATIENT_LOGIN_DETAILS_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case GET_PATIENT_LOGIN_DETAILS_SUCCESS:
      return {
        ...state,
        getPatientLoginDetailsRes: action.payload,
      };
    case GET_PATIENT_LOGIN_DETAILS_FAIL:
      return {
        ...state,
        getPatientLoginDetailsRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case GET_PATIENT_LOGIN_DETAILS_API_MSG:
      return {
        ...state,
        getPatientLoginDetailsRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case GET_PATIENT_LOGIN_DETAILS_API_RES:
      return {
        ...state,
        getPatientLoginDetailsRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
