import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../../Url";
import { GetQuestionListActionTypes } from "./ActionTypes";
import { GetQuestionListBody, GetQuestionListRes } from "./Model";
import {
  getQuestionListAPIResClearAction,
  getQuestionListErrorAction,
  getQuestionListLoadingAction,
  getQuestionListSuccessAction,
  getQuestionListAPIMsgAction,
} from "./Action";
import { headers } from "../../../../components/Utility";
import { setupInterceptorsTo } from "../../../Authentication/Interceptors";

let apiRes = {} as GetQuestionListRes;
export const getQuestionList = (payload: GetQuestionListBody) => {
  return function (dispatch: Dispatch<GetQuestionListActionTypes>) {
    dispatch(getQuestionListLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        Url.baseUrl +
          `/questionandanswerapp/api/question/list_Question_for_patient`,
        payload,
        {
          headers: headers,
        }
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(getQuestionListLoadingAction(false));
        dispatch(
          getQuestionListSuccessAction(
            res.data,
            res.data.message !== undefined
              ? res.data.message
              : "Patient question list has been fetched successfully"
          )
        );
      })
      .catch((error) => {
        dispatch(getQuestionListLoadingAction(false));
        dispatch(
          getQuestionListErrorAction(
            {} as GetQuestionListRes,
            error.response.data !== undefined
              ? error.response.data.message !== undefined
                ? error.response.data.message
                : "Something went wrong"
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0
          )
        );
      });
  };
};

export const updateGetQuestionListAPIResMsg = () => {
  return function (dispatch: Dispatch<GetQuestionListActionTypes>) {
    dispatch(
      getQuestionListAPIMsgAction(apiRes as GetQuestionListRes, "", "", 0)
    );
  };
};

export const clearGetQuestionListAPIRes = () => {
  return function (dispatch: Dispatch<GetQuestionListActionTypes>) {
    dispatch(
      getQuestionListAPIResClearAction({} as GetQuestionListRes, "", "", 0)
    );
  };
};
