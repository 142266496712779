import axios from "axios";
import { Dispatch } from "redux";
import baseUrl from "../../../Url";
import { setupInterceptorsTo } from "../../Authentication/Interceptors";
import {
  needHelpActionTypes,
  NEED_HELP_FAIL,
  NEED_HELP_LOADING,
  NEED_HELP_SUCCESS,
} from "./ActionTypes";
import { needHelpBody, needHelpValue } from "./Model";
import { headers } from "../../../components/Utility";

export const needHelpAddApi = (
  payload: needHelpBody
) => {
  return function (dispatch: Dispatch<needHelpActionTypes>) {
    dispatch({
      type: NEED_HELP_LOADING,
    });

    setupInterceptorsTo(axios)
      .post(
        baseUrl.baseUrl + `/patientapp/api/patienthelp/submit_query`,
        payload,
        {
          headers:headers,
        }
      )
      .then((res) => {
        dispatch({
          type: NEED_HELP_SUCCESS,
          payload: res.data,
          errRes: "",
        });
      })
      .catch((error) => {
        dispatch({
          type: NEED_HELP_FAIL,
          payload: {} as needHelpValue,
          errRes: error.response.data.message,
        });
      });
  };
};
