import {
  ASK_DOCTOR_QUESTION_FAIL,
  ASK_DOCTOR_QUESTION_LOADING,
  ASK_DOCTOR_QUESTION_SUCCESS,
  ASK_DOCTOR_QUESTION_API_MSG,
  ASK_DOCTOR_QUESTION_API_RES,
  AskDoctorQuestionActionTypes,
} from "./ActionTypes";
import { AskQuestionRes } from "./Model";

export const askDoctorQuestionLoadingAction = (
  loading: boolean
): AskDoctorQuestionActionTypes => {
  return {
    type: ASK_DOCTOR_QUESTION_LOADING,
    loading: loading,
  };
};

export const askDoctorQuestionSuccessAction = (
  askDoctorQuestionResponse: AskQuestionRes,
  successMsg: string
): AskDoctorQuestionActionTypes => {
  return {
    type: ASK_DOCTOR_QUESTION_SUCCESS,
    payload: askDoctorQuestionResponse,
    successMsg: successMsg,
  };
};

export const askDoctorQuestionErrorAction = (
  askDoctorQuestionResponse: AskQuestionRes,
  errMsg: string,
  status: number
): AskDoctorQuestionActionTypes => {
  return {
    type: ASK_DOCTOR_QUESTION_FAIL,
    payload: askDoctorQuestionResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const askDoctorQuestionAPIMsgAction = (
  askDoctorQuestionResponse: AskQuestionRes,
  successMsg: string,
  errMsg: string,
  status: number
): AskDoctorQuestionActionTypes => {
  return {
    type: ASK_DOCTOR_QUESTION_API_MSG,
    payload: askDoctorQuestionResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const askDoctorQuestionAPIResClearAction = (
  askDoctorQuestionResponse: AskQuestionRes,
  successMsg: string,
  errMsg: string,
  status: number
): AskDoctorQuestionActionTypes => {
  return {
    type: ASK_DOCTOR_QUESTION_API_RES,
    payload: askDoctorQuestionResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
