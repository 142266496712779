import {
  AddChiefComplaintAction,
  DeleteDocumentAction,
  getChiefComplaintAction,
  getTaskAction,
} from "../actions/Actions";
import { Dispatch } from "redux";
import {
  AddChiefComplaintTypes,
  ADD_CC,
  CC_ADD_LIST_FAIL,
  CC_ADD_LIST_LOADING,
  CC_ADD_LIST_SUCCESS,
  CC_LIST_FAIL,
  CC_LIST_LOADING,
  CC_LIST_SUCCESS,
  ChiefComplaintGetActionTypes,
  DeleteDocumentTypes,
  DELETE_DOCUMENT,
  DELETE_DOCUMENT_LIST_FAIL,
  DELETE_DOCUMENT_LIST_LOADING,
  DELETE_DOCUMENT_LIST_SUCCESS,
  TaskGetActionTypes,
  TASK_LIST_SUCCESS,
  TASK_LIST_FAIL,
  TASK_LIST_LOADING,
} from "../types/ActionTypes";
import axios from "axios";
import baseUrl from "../../Url";
import { setupInterceptorsTo } from "../Authentication/Interceptors";
import { headers } from "../../components/Utility";

let ccBody = {};

export const getChiefComplaintList = (getChiefComplaintBody: object) => {
  return function (dispatch: Dispatch<ChiefComplaintGetActionTypes>) {
    ccBody = getChiefComplaintBody;

    dispatch({
      type: CC_LIST_LOADING,
    });

    setupInterceptorsTo(axios)
      .post(
        baseUrl.baseUrl +
          "/patientapp/api/patientMedicalData/get_patientDetailsList",
        getChiefComplaintBody,
        { headers: headers }
      )
      .then((res) => {
        let resData = res.data.patientDetailList;
        dispatch(getChiefComplaintAction(resData));
        dispatch({
          type: CC_LIST_SUCCESS,
          payload: resData,
        });
      })
      .catch((error) => {
        dispatch({
          type: CC_LIST_FAIL,
        });
      });
  };
};

export const addChiefComplaint = (state = {}) => {
  return function (dispatch: Dispatch<AddChiefComplaintTypes>) {
    dispatch({
      type: CC_ADD_LIST_LOADING,
    });
    dispatch(AddChiefComplaintAction(ADD_CC));
    setupInterceptorsTo(axios)
      .post(
        baseUrl.baseUrl +
          "/patientapp/api/patientMedicalData/add_patientDetails",
        state,
        { headers: headers }
      )
      .then((result) => {
        if (result) {
          dispatch({
            type: CC_ADD_LIST_SUCCESS,
            payload: result.data,
          });

          dispatch({
            type: CC_LIST_LOADING,
          });

          setupInterceptorsTo(axios)
            .post(
              baseUrl.baseUrl +
                "/patientapp/api/patientMedicalData/get_patientDetailsList",
              ccBody,
              { headers: headers }
            )
            .then((res) => {
              let resData = res.data.patientDetailList;
              dispatch(getChiefComplaintAction(resData));
              dispatch({
                type: CC_LIST_SUCCESS,
                payload: resData,
              });
            })
            .catch((error) => {
              dispatch({
                type: CC_LIST_FAIL,
              });
            });
        }
      })
      .catch((error) => {
        dispatch({
          type: CC_ADD_LIST_FAIL,
          payload: error.response.data,
        });
      });
  };
};

export const deleteFilePreparationDocument = (state = {}) => {
  return function (dispatch: Dispatch<DeleteDocumentTypes>) {
    dispatch({
      type: DELETE_DOCUMENT_LIST_LOADING,
    });
    dispatch(DeleteDocumentAction(DELETE_DOCUMENT));
    setupInterceptorsTo(axios)
      .post(
        baseUrl.baseUrl +
          "/patientapp/api/patientMedicalData/delete_patientDetails",
        state,
        { headers: headers }
      )
      .then((result) => {
        if (result) {
          dispatch({
            type: DELETE_DOCUMENT_LIST_SUCCESS,
            payload: result.data,
          });

          dispatch({
            type: CC_LIST_LOADING,
          });

          setupInterceptorsTo(axios)
            .post(
              baseUrl.baseUrl +
                "/patientapp/api/patientMedicalData/get_patientDetailsList",
              ccBody,
              { headers: headers }
            )
            .then((res) => {
              let resData = res.data.patientDetailList;
              dispatch(getChiefComplaintAction(resData));
              dispatch({
                type: CC_LIST_SUCCESS,
                payload: resData,
              });
            })
            .catch((error) => {
              dispatch({
                type: CC_LIST_FAIL,
              });
            });
        }
      })
      .catch((error) => {
        dispatch({
          type: DELETE_DOCUMENT_LIST_FAIL,
          payload: error.response.data,
        });
      });
  };
};

// Get task List.........
export const getTaskList = () => {
  return function (dispatch: Dispatch<TaskGetActionTypes>) {
    dispatch({
      type: TASK_LIST_LOADING,
    });

    setupInterceptorsTo(axios)
      .get(baseUrl.baseUrl + "/patientapp/api/patient/patient_task_list", {
        headers: headers,
      })
      .then((res) => {
        let resData = res.data.taskDataList;
        dispatch(getTaskAction(resData));
        dispatch({
          type: TASK_LIST_SUCCESS,
          payload: resData,
        });
      })
      .catch((error) => {
        dispatch({
          type: TASK_LIST_FAIL,
        });
      });
  };
};
