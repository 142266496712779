import { createTheme } from "@mui/material/styles";

const mwTheme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#2e73b9",
    },
    secondary: {
      main: "#208781",
    },
  },
  typography: {
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"San Francisco"',
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
  components: {
    MuiTabs: {
      styleOverrides: {
        root: {
          backgroundColor: "transparent",
          borderRadius: "8px",
          minHeight: "40px",
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          color: "black",
          "&.Mui-selected": {
            backgroundColor: "#DEF1FF",
            color: "#2e73b9",
            borderLeft: "solid 2px",
            "&.Mui-focusVisible": {
              background: "#2e73b9",
            },
            "& .MuiListItemIcon-root": {
              color: "#2e73b9",
            },
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          alignItems: "start",
          justifyContent: "start",
          textAlign: "left",
          textTransform: "none",
          minHeight: 0,
          padding: "10px 20px",
        },
      },
    },
  },
});

export default mwTheme;
