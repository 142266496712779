import axios from "axios";
import { Dispatch } from "redux";
import { PatientImportConfigListActionTypes } from "./ActionTypes";
import {
  PatientImportConfigListDetailsBody,
  PatientImportConfigListValueRes,
} from "./Model";
import {
  patientImportConfigListAPIResClearAction,
  patientImportConfigListErrorAction,
  patientImportConfigListLoadingAction,
  patientImportConfigListSuccessAction,
  patientImportConfigListUpdateAPIMsgAction,
} from "./Actions";
import baseUrl from "../../../../Url";
import { headers } from "../../../../components/Utility";
import { setupInterceptorsTo } from "../../../Authentication/Interceptors";

let apiRes = {} as PatientImportConfigListValueRes;
export const getPatientInfortConfigListApi = (
  payload: PatientImportConfigListDetailsBody
) => {
  return function (dispatch: Dispatch<PatientImportConfigListActionTypes>) {
    dispatch(patientImportConfigListLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        baseUrl.baseUrl +
          `/patientapp/api/dataimport/get_patient_import_config_list`,
        payload,
        {
          headers: headers,
        }
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(patientImportConfigListLoadingAction(false));
        dispatch(
          patientImportConfigListSuccessAction(
            res.data,
            res.data.message !== undefined
              ? res.data.message
              : "Imported Config List added successfully"
          )
        );
      })
      .catch((error) => {
        dispatch(patientImportConfigListLoadingAction(false));
        dispatch(
          patientImportConfigListErrorAction(
            {} as PatientImportConfigListValueRes,
            error.response !== undefined
              ? error.response.data.message || error.response.data.detail
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0
          )
        );
      });
  };
};

export const updatePatientImportConfigListAPIResMsg = () => {
  return function (dispatch: Dispatch<PatientImportConfigListActionTypes>) {
    dispatch(
      patientImportConfigListUpdateAPIMsgAction(
        apiRes as PatientImportConfigListValueRes,
        "",
        "",
        0
      )
    );
  };
};

export const clearPatientImportConfigListAPIRes = () => {
  return function (dispatch: Dispatch<PatientImportConfigListActionTypes>) {
    dispatch(
      patientImportConfigListAPIResClearAction(
        {} as PatientImportConfigListValueRes,
        "",
        "",
        0
      )
    );
  };
};
