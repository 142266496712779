import {
  GetDemographicsDetailsByPtIdActionTypes,
  GET_DEMOGRAPHICS_DETAILS_BY_PT_ID_LOADING,
  GET_DEMOGRAPHICS_DETAILS_BY_PT_ID_SUCCESS,
  GET_DEMOGRAPHICS_DETAILS_BY_PT_ID_FAIL,
  GET_DEMOGRAPHICS_DETAILS_BY_PT_ID_API_MSG,
  GET_DEMOGRAPHICS_DETAILS_BY_PT_ID_API_RES
} from "./ActionTypes";
import { GetDemographicsDetailsByPtIdRes } from "./Model";

export const getDemographicsDetailsByPtIdLoadingAction = (
  loading: boolean
): GetDemographicsDetailsByPtIdActionTypes => {
  return {
    type: GET_DEMOGRAPHICS_DETAILS_BY_PT_ID_LOADING,
    loading: loading,
  };
};

export const getDemographicsDetailsByPtIdSuccessAction = (
  getDemographicsDetailsByPtIdResponse: GetDemographicsDetailsByPtIdRes,
  successMsg: string
): GetDemographicsDetailsByPtIdActionTypes => {
  return {
    type: GET_DEMOGRAPHICS_DETAILS_BY_PT_ID_SUCCESS,
    payload: getDemographicsDetailsByPtIdResponse,
    successMsg: successMsg,
  };
};

export const getDemographicsDetailsByPtIdErrorAction = (
  getDemographicsDetailsByPtIdResponse: GetDemographicsDetailsByPtIdRes,
  errMsg: string,
  status: number
): GetDemographicsDetailsByPtIdActionTypes => {
  return {
    type: GET_DEMOGRAPHICS_DETAILS_BY_PT_ID_FAIL,
    payload: getDemographicsDetailsByPtIdResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const getDemographicsDetailsByPtIdUpdateAPIMsgAction = (
  getDemographicsDetailsByPtIdResponse: GetDemographicsDetailsByPtIdRes,
  successMsg: string,
  errMsg: string,
  status: number
): GetDemographicsDetailsByPtIdActionTypes => {
  return {
    type: GET_DEMOGRAPHICS_DETAILS_BY_PT_ID_API_MSG,
    payload: getDemographicsDetailsByPtIdResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const getDemographicsDetailsByPtIdAPIResClearAction = (
  getDemographicsDetailsByPtIdResponse: GetDemographicsDetailsByPtIdRes,
  successMsg: string,
  errMsg: string,
  status: number
): GetDemographicsDetailsByPtIdActionTypes => {
  return {
    type: GET_DEMOGRAPHICS_DETAILS_BY_PT_ID_API_RES,
    payload: getDemographicsDetailsByPtIdResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
