import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../Url";
import { PaymentStatusActionTypes } from "./ActionTypes";
import { CheckPaymentStatusReq, CheckPaymentStatusRes } from "./Model";
import {
  paymentStatusAPIResClearAction,
  paymentStatusErrorAction,
  paymentStatusLoadingAction,
  paymentStatusSuccessAction,
  paymentStatusAPIMsgAction,
} from "./Action";
import { headers } from "../../../components/Utility";
import { setupInterceptorsTo } from "../../Authentication/Interceptors";

let apiRes = {} as CheckPaymentStatusRes;
export const paymentStatus = (payload: CheckPaymentStatusReq) => {
  return function (dispatch: Dispatch<PaymentStatusActionTypes>) {
    dispatch(paymentStatusLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        Url.baseUrl + `/paymentapp/api/payment/get_payment_status`,
        payload,
        {
          headers: headers,
        }
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(paymentStatusLoadingAction(false));
        dispatch(
          paymentStatusSuccessAction(
            res.data,
            res.data.message !== undefined
              ? res.data.message
              : "Payment status has been updated successfully"
          )
        );
      })
      .catch((error) => {
        dispatch(paymentStatusLoadingAction(false));
        dispatch(
          paymentStatusErrorAction(
            {} as CheckPaymentStatusRes,
            error.response.data !== undefined
              ? error.response.data.message !== undefined
                ? error.response.data.message
                : "Something went wrong"
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0
          )
        );
      });
  };
};

export const paymentStatusAPIResMsg = () => {
  return function (dispatch: Dispatch<PaymentStatusActionTypes>) {
    dispatch(
      paymentStatusAPIMsgAction(apiRes as CheckPaymentStatusRes, "", "", 0)
    );
  };
};

export const clearPaymentStatusAPIRes = () => {
  return function (dispatch: Dispatch<PaymentStatusActionTypes>) {
    dispatch(
      paymentStatusAPIResClearAction({} as CheckPaymentStatusRes, "", "", 0)
    );
  };
};
