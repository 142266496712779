import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../../Url";
import { AnswerDoctorQuestionActionTypes } from "./ActionTypes";
import { AnsDoctorQuestionBody, AnsDoctorQuestionRes } from "./Model";
import {
  answerDoctorQuestionAPIResClearAction,
  answerDoctorQuestionErrorAction,
  answerDoctorQuestionLoadingAction,
  answerDoctorQuestionSuccessAction,
  answerDoctorQuestionAPIMsgAction,
} from "./Action";
import { headers } from "../../../../components/Utility";
import { setupInterceptorsTo } from "../../../Authentication/Interceptors";

let apiRes = {} as AnsDoctorQuestionRes;
export const answerDoctorQuestion = (payload: AnsDoctorQuestionBody) => {
  return function (dispatch: Dispatch<AnswerDoctorQuestionActionTypes>) {
    dispatch(answerDoctorQuestionLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        Url.baseUrl + `/questionandanswerapp/api/question/answer_Question`,
        payload,
        {
          headers: headers,
        }
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(answerDoctorQuestionLoadingAction(false));
        dispatch(
          answerDoctorQuestionSuccessAction(
            res.data,
            res.data.message !== undefined
              ? res.data.message
              : "Patient question list has been fetched successfully"
          )
        );
      })
      .catch((error) => {
        dispatch(answerDoctorQuestionLoadingAction(false));
        dispatch(
          answerDoctorQuestionErrorAction(
            {} as AnsDoctorQuestionRes,
            error.response.data !== undefined
              ? error.response.data.message !== undefined
                ? error.response.data.message
                : "Something went wrong"
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0
          )
        );
      });
  };
};

export const updateAnswerDoctorQuestionAPIResMsg = () => {
  return function (dispatch: Dispatch<AnswerDoctorQuestionActionTypes>) {
    dispatch(
      answerDoctorQuestionAPIMsgAction(
        apiRes as AnsDoctorQuestionRes,
        "",
        "",
        0
      )
    );
  };
};

export const clearAnswerDoctorQuestionAPIRes = () => {
  return function (dispatch: Dispatch<AnswerDoctorQuestionActionTypes>) {
    dispatch(
      answerDoctorQuestionAPIResClearAction(
        {} as AnsDoctorQuestionRes,
        "",
        "",
        0
      )
    );
  };
};
