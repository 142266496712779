import { PatientDetailsResponse } from "./Model";
export const PATIENT_LIST_LOADING = "PATIENT_LIST_LOADING";
export const PATIENT_LIST_SUCCESS = "PATIENT_LIST_SUCCESS";
export const PATIENT_LIST_FAIL = "PATIENT_LIST_FAIL";
export const PATIENT_LIST_API_MSG = "PATIENT_LIST_API_MSG";
export const PATIENT_LIST_API_RES = "PATIENT_LIST_API_RES";

export interface PatientListState {
  PatientListResponse: PatientDetailsResponse;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface PatientListLoading {
  type: typeof PATIENT_LIST_LOADING;
  loading: boolean;
}
export interface PatientListSuccess {
  type: typeof PATIENT_LIST_SUCCESS;
  payload: PatientDetailsResponse;
  successMsg: string;
}
export interface PatientListFail {
  type: typeof PATIENT_LIST_FAIL;
  payload: PatientDetailsResponse;
  errorMsg: string;
  status: number;
}
export interface PatientListAPIMsg {
  type: typeof PATIENT_LIST_API_MSG;
  payload: PatientDetailsResponse;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface PatientListAPIRes {
  type: typeof PATIENT_LIST_API_RES;
  payload: PatientDetailsResponse;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type PatientListActionTypes =
  | PatientListLoading
  | PatientListSuccess
  | PatientListFail
  | PatientListAPIMsg
  | PatientListAPIRes;
