import React, { useEffect, useState } from "react";
import DescriptionIcon from "@mui/icons-material/Description";
import EditIcon from "@mui/icons-material/Edit";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
} from "@mui/material";
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridToolbar,
} from "@mui/x-data-grid";
import {
  GetPatientBody,
  InsuranceList,
} from "../../redux/Insurance/GetInsuranceByPatient/Model";
import { t } from "i18next";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../redux/store/Store";
import MWExceptionList from "../../components/MWExceptionList";
import dayjs from "dayjs";
import { getInsuranceList } from "../../redux/Insurance/GetInsuranceByPatient/API";
import { ModifiedInsuranceList } from "./Model";
import DocumentViewer from "../../components/DocumentViewer";
import UpdateInsurance from "./UpdateInsurance";
import { useParams } from "react-router-dom";
import MWPageTitle from "../../components/MWPageTitle";
import PageLayout from "../Layout/PageLayout";

export default function InsuranceDetails() {
  const { patientid } = useParams() as {
    patientid: string;
  };
  const getInsuranceBody = {
    patientId: patientid,
  } as GetPatientBody;
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getInsuranceList(getInsuranceBody));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getInsuranceListRes = useSelector(
    (state: AppState) => state.getInsuranceDetails
  );
  const [insuranceList, setInsuranceList] = React.useState(
    [] as ModifiedInsuranceList[]
  );
  useEffect(() => {
    // insuranceDetailsLoading(getInsuranceListRes?.loading);
    if (
      getInsuranceListRes?.getPatientInsuranceListRes?.insuranceList !==
      undefined
    ) {
      if (
        getInsuranceListRes?.getPatientInsuranceListRes?.insuranceList.length >
        0
      ) {
        let InsuranceListItem =
          getInsuranceListRes?.getPatientInsuranceListRes?.insuranceList.map(
            (element: InsuranceList) =>
              ({
                id: element.id,
                supportingDocument:
                  element?.supportingDocuments !== undefined
                    ? element?.supportingDocuments !== null
                      ? element?.supportingDocuments[0]?.document
                      : ""
                    : "",
                tableDescription:
                  element?.description !== undefined
                    ? element?.description !== ""
                      ? element?.description.substring(0, 30)
                      : ""
                    : "",
                description:
                  element?.description !== undefined
                    ? element?.description !== ""
                      ? element?.description
                      : ""
                    : "",
                insuranceNumber:
                  element?.insuranceNumber !== undefined
                    ? element?.insuranceNumber !== ""
                      ? element?.insuranceNumber
                      : ""
                    : "",
                amount:
                  element?.amount !== undefined
                    ? element?.amount !== null
                      ? element?.amount
                      : 0
                    : 0,
                fromDate:
                  element?.fromDate !== undefined
                    ? element?.fromDate !== null
                      ? dayjs(element.fromDate).format("DD-MM-YYYY")
                      : null
                    : null,
                toDate:
                  element?.toDate !== undefined
                    ? element?.toDate !== null
                      ? dayjs(element.toDate).format("DD-MM-YYYY")
                      : null
                    : null,
              } as ModifiedInsuranceList)
          );
        setInsuranceList(InsuranceListItem);
      } else setInsuranceList([] as ModifiedInsuranceList[]);
    } else setInsuranceList([] as ModifiedInsuranceList[]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getInsuranceListRes]);

  const insuranceTableColumn: GridColDef[] = [
    {
      field: "supportingDocument",
      headerName: `${t("addpatientpage.doc", { ns: ["home"] })!}`,
      minWidth: 150,
      renderCell: (params: GridCellParams) => (
        <Button
          startIcon={<DescriptionIcon />}
          variant="outlined"
          onClick={() => {
            setViewInsuranceDocumentDialog(true);
            setEditItem(params.row);
          }}
          sx={{ textTransform: "none" }}
        >
          {
            t("appointment.view", {
              ns: ["home"],
            })!
          }
        </Button>
      ),
    },
    {
      field: "tableDescription",
      headerName: `${t("appointment.desc", { ns: ["home"] })!}`,
      minWidth: 250,
      flex: 1,
    },
    {
      field: "insuranceNumber",
      headerName: `${t("insurancePage.insNum", { ns: ["home"] })!}`,
      minWidth: 200,

      flex: 1,
    },
    {
      field: "amount",
      headerName: `${t("insurancePage.insCovAmt", { ns: ["home"] })!}`,
      minWidth: 200,
    },
    {
      field: "fromDate",
      headerName: `${t("insurancePage.frmDate", { ns: ["home"] })!}`,
      minWidth: 100,
      flex: 1,
    },
    {
      field: "toDate",
      headerName: `${t("insurancePage.validDate", { ns: ["home"] })}`,
      minWidth: 100,
      flex: 1,
    },

    {
      field: "id",
      headerName: `${t("common.edit", { ns: ["home"] })!}`,
      minWidth: 150,
      renderCell: (params: GridCellParams) => (
        <Button
          startIcon={<EditIcon />}
          variant="outlined"
          onClick={() => {
            setEditItem(params.row);
            setUpdateInsuranceDetailsDialog(true);
          }}
          sx={{ textTransform: "none" }}
        >
          {
            t("common.edit", {
              ns: ["home"],
            })!
          }
        </Button>
      ),
    },
  ];

  const [editItem, setEditItem] = useState({} as ModifiedInsuranceList);
  const selectedItemUpdateState = (passedVal: any) => {
    setEditItem(passedVal);
  };

  const [updateInsuranceDetailsDialog, setUpdateInsuranceDetailsDialog] =
    useState(false);
  const updateInsuranceDetailsDialogValFromChild = (passedVal: boolean) => {
    setUpdateInsuranceDetailsDialog(passedVal);
  };

  const [viewInsuranceDocumentDialog, setViewInsuranceDocumentDialog] =
    useState(false);
  const updateViewInsuranceDocumentDialogValFromChild = (
    passedVal: boolean
  ) => {
    setViewInsuranceDocumentDialog(passedVal);
  };
  return (
    <>
      <PageLayout>
        <MWPageTitle
          backButton={true}
          title={t("insurancePage.insDetail", { ns: ["home"] })}
        />
        <Card>
          <CardHeader
            title={
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
              >
                <Grid item>
                  <Typography variant="h6">
                    {t("insurancePage.insDetail", { ns: ["home"] })}
                  </Typography>
                </Grid>
                <Grid item>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="flex-start"
                  >
                    <Grid item>
                      <Button
                        variant="contained"
                        onClick={() => setUpdateInsuranceDetailsDialog(true)}
                        sx={{ textTransform: "none" }}
                      >
                        {
                          t("insurancePage.insAdd", {
                            ns: ["home"],
                          })!
                        }
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            }
          />
          <CardContent>
            {insuranceList && insuranceList.length ? (
              <DataGrid
                rows={insuranceList}
                columns={insuranceTableColumn}
                hideFooter
                hideFooterPagination
                disableSelectionOnClick
                experimentalFeatures={{ newEditingApi: true }}
                autoHeight
                components={{ Toolbar: GridToolbar }}
                componentsProps={{
                  toolbar: {
                    csvOptions: { disableToolbarButton: true },
                    printOptions: { disableToolbarButton: true },
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 250 },
                  },
                }}
                disableColumnFilter
                disableColumnSelector
                disableDensitySelector
              />
            ) : (
              <MWExceptionList />
            )}
          </CardContent>
        </Card>
      </PageLayout>

      {updateInsuranceDetailsDialog ? (
        <UpdateInsurance
          dialogEnable={updateInsuranceDetailsDialog}
          dialogUpdateState={updateInsuranceDetailsDialogValFromChild}
          selectedItem={editItem}
          selectedItemUpdateState={selectedItemUpdateState}
        />
      ) : null}
      {viewInsuranceDocumentDialog ? (
        <DocumentViewer
          dialogEnable={viewInsuranceDocumentDialog}
          dialogUpdateState={updateViewInsuranceDocumentDialogValFromChild}
          selectedItem={editItem}
          selectedItemUpdateState={selectedItemUpdateState}
        />
      ) : null}
    </>
  );
}
