import React from "react";
import DemographicDetails from "./DemographicDetails";
import ImportedData from "./ImportedData";
import FileData from "./FIleData";
interface FilePreparationTabList {
  type?: string;
  label: string;
  content: any;
}
export const TabList = [
  {
    label: `demodetails`,
    content: <DemographicDetails />,
  },
  {
    label: `presComp`,
    content: (
      <FileData type={"chiefCompliants"} label={`presComp`} />
    ),
  },
  {
    label: `ideaTitle`,
    content: (
      <FileData
        type={"ideasConcernsandExpectations"}
        label={`ideaTitle`}
      />
    ),
  },
  {
    label: `preComp`,
    content: (
      <FileData
        type={"historyofChiefPresentingComplaints"}
        label={`preComp`}
      />
    ),
  },
  {
    label: `revSys`,
    content: <FileData type={"systemsReview"} label={`revSys`} />,
  },
  {
    label: `pastHis`,
    content: <FileData type={"pastHistory"} label={`pastHis`} />,
  },
  {
    label: `testProcTitle`,
    content: (
      <FileData
        type={"testsProceduresandInvestigations"}
        label={`testProcTitle`}
      />
    ),
  },
  {
    label: `medPres`,
    content: (
      <FileData
        type={"medication/DrugHistory"}
        label={`medPres`}
      />
    ),
  },
  {
    label: `allergy`,
    content: <FileData type={"allergies"} label={`allergy`} />,
  },
  {
    label: `immTitle`,
    content: <FileData type={"immunization"} label={`immTitle`} />,
  },
  {
    label: `famHis`,
    content: <FileData type={"familyHistory"} label={`famHis`} />,
  },

  {
    label: `nutriHis`,
    content: (
      <FileData type={"nutritionalHistory"} label={`nutriHis`} />
    ),
  },
  {
    label: `perSocHis`,
    content: (
      <FileData
        type={"personalandSocialHistory"}
        label={`perSocHis`}
      />
    ),
  },
  {
    label: `healthMain`,
    content: (
      <FileData
        type={"healthMaintenanceHistory"}
        label={`healthMain`}
      />
    ),
  },
  {
    label: `riskFac`,
    content: <FileData type={"riskFactors"} label={`riskFac`} />,
  },
  {
    label: `diagTitle`,
    content: <FileData type={"diagnosis"} label={`diagTitle`} />,
  },
  {
    label: `medSum`,
    content: <FileData type={"medicalSummary"} label={`medSum`} />,
  },
  {
    label: `probList`,
    content: (
      <FileData type={"problemListandTreatmentPlan"} label={`probList`} />
    ),
  },
  {
    label: `trtTitle`,
    content: <FileData type={"treatmentPlan"} label={`trtTitle`} />,
  },
  {
    label: `imptdData`,
    content: <ImportedData type={"importedData"} label={`imptdData`} />,
  },
] as FilePreparationTabList[];
