import { GetUserDetailsListValue } from "./Model";

// Patient Meeting Loading State
export const GET_USER_DETAILS_LOADING = "GET_USER_DETAILS_LOADING";
export const GET_USER_DETAILS_SUCCESS = "GET_USER_DETAILS_SUCCESS";
export const GET_USER_DETAILS_FAIL = "GET_USER_DETAILS_FAIL";
export const GET_USER_DETAILS_LIST = "GET_USER_DETAILS_LIST";

export interface GetUserDetailsListDetails {
  patientDropdownListRes: GetUserDetailsListValue;
  loading: boolean;
  errRes: string;
}
export interface GetUserDetailsListLoading {
  type: typeof GET_USER_DETAILS_LOADING;
}
export interface GetUserDetailsListSuccess {
  type: typeof GET_USER_DETAILS_SUCCESS;
  payload: GetUserDetailsListValue;
  errRes: string;
}
export interface GetUserDetailsListFail {
  type: typeof GET_USER_DETAILS_FAIL;
  errRes: string;
}
interface GetUserDetailsListAction {
  type: typeof GET_USER_DETAILS_LIST;
  payload: GetUserDetailsListValue;
  errRes: string;
}

export type getUserDetailsActionTypes =
  | GetUserDetailsListAction
  | GetUserDetailsListLoading
  | GetUserDetailsListSuccess
  | GetUserDetailsListFail;
