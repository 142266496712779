import { Box, Grid, Typography } from "@mui/material";
import * as React from "react";
import DescriptionIcon from "@mui/icons-material/Description";
import { t } from "i18next";
export default function MWExceptionList() {
  return (
    <Box>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={2}
      >
        <Grid item>
          <DescriptionIcon sx={{ color: "#0080d7" }} />
        </Grid>
        <Grid item>
          <Typography variant="subtitle1" sx={{ color: "#0080d7" }}>
            {t("common.noContAvail", { ns: ["home"] })}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}
