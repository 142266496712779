import {
  EDIT_PATIENT_INSURANCE_FAIL,
  EDIT_PATIENT_INSURANCE_LOADING,
  EDIT_PATIENT_INSURANCE_SUCCESS,
  EDIT_PATIENT_INSURANCE_API_MSG,
  EDIT_PATIENT_INSURANCE_API_RES,
  EditPatientInsuranceActionTypes,
} from "./ActionTypes";
import { EditPatientInsuranceRes } from "./Model";

export const editPatientInsuranceLoadingAction = (
  loading: boolean
): EditPatientInsuranceActionTypes => {
  return {
    type: EDIT_PATIENT_INSURANCE_LOADING,
    loading: loading,
  };
};

export const editPatientInsuranceSuccessAction = (
  editPatientInsuranceResponse: EditPatientInsuranceRes,
  successMsg: string
): EditPatientInsuranceActionTypes => {
  return {
    type: EDIT_PATIENT_INSURANCE_SUCCESS,
    payload: editPatientInsuranceResponse,
    successMsg: successMsg,
  };
};

export const editPatientInsuranceErrorAction = (
  editPatientInsuranceResponse: EditPatientInsuranceRes,
  errMsg: string,
  status: number
): EditPatientInsuranceActionTypes => {
  return {
    type: EDIT_PATIENT_INSURANCE_FAIL,
    payload: editPatientInsuranceResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const editPatientInsuranceAPIMsgAction = (
  editPatientInsuranceResponse: EditPatientInsuranceRes,
  successMsg: string,
  errMsg: string,
  status: number
): EditPatientInsuranceActionTypes => {
  return {
    type: EDIT_PATIENT_INSURANCE_API_MSG,
    payload: editPatientInsuranceResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const editPatientInsuranceAPIResClearAction = (
  editPatientInsuranceResponse: EditPatientInsuranceRes,
  successMsg: string,
  errMsg: string,
  status: number
): EditPatientInsuranceActionTypes => {
  return {
    type: EDIT_PATIENT_INSURANCE_API_RES,
    payload: editPatientInsuranceResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
