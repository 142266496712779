import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { t } from "i18next";
import { Stack, Typography } from "@mui/material";
import DataList from "../FilePreparation/FIleData/DataList";
import { GetPatientDataDetailsBody } from "../../../redux/FilePreparation/GetPatientDataList/Model";
import { getPatientDataList } from "../../../redux/FilePreparation/GetPatientDataList/API";

export default function FinalOpinion() {
  const { caseid } = useParams() as {
    caseid: string;
  };
  const dispatch = useDispatch();
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(
      getPatientDataList({
        caseId: caseid,
        secondOpinionUiSection: "finalOpinion",
      } as GetPatientDataDetailsBody)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Stack
      direction="column"
      justifyContent="flex-start"
      alignItems="stretch"
      spacing={2}
    >
      <Typography variant="h5">
        {t("finalOpinionPage.finalOp", { ns: ["home"] })!}
      </Typography>
      <DataList type={"finalOpinion"} label={`finalOp`} finalOpinion={true} />
    </Stack>
  );
}
