import axios from "axios";
import { Dispatch } from "redux";
import baseUrl from "../../../Url";
import { EditPatientInsuranceActionTypes } from "./ActionTypes";
import { EditPatientInsuranceBody, EditPatientInsuranceRes } from "./Model";
import {
  editPatientInsuranceAPIResClearAction,
  editPatientInsuranceErrorAction,
  editPatientInsuranceLoadingAction,
  editPatientInsuranceSuccessAction,
  editPatientInsuranceAPIMsgAction,
} from "./Actions";
import { headers } from '../../../components/Utility';
import { setupInterceptorsTo } from "../../Authentication/Interceptors";

let apiRes = {} as EditPatientInsuranceRes;
export const editPatientInsurance = (payload: EditPatientInsuranceBody) => {
  return function (dispatch: Dispatch<EditPatientInsuranceActionTypes>) {
    dispatch(editPatientInsuranceLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        baseUrl.baseUrl + `/patientapp/api/patient/edit_patient_insurance`,
        payload,
        {
          headers: headers,
        }
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(editPatientInsuranceLoadingAction(false));
        dispatch(
          editPatientInsuranceSuccessAction(
            res.data,
            res.data.message !== undefined
              ? res.data.message
              : "Patient. legal details has been edited successfully"
          )
        );
      })
      .catch((error) => {
        dispatch(editPatientInsuranceLoadingAction(false));
        dispatch(
          editPatientInsuranceErrorAction(
            {} as EditPatientInsuranceRes,
            error.response.data !== undefined
              ? error.response.data.message !== undefined
                ? error.response.data.message
                : "Something went wrong"
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0
          )
        );
      });
  };
};

export const updateEditPatientInsuranceAPIResMsg = () => {
  return function (dispatch: Dispatch<EditPatientInsuranceActionTypes>) {
    dispatch(
      editPatientInsuranceAPIMsgAction(apiRes as EditPatientInsuranceRes, "", "", 0)
    );
  };
};

export const clearEditPatientInsuranceAPIRes = () => {
  return function (dispatch: Dispatch<EditPatientInsuranceActionTypes>) {
    dispatch(
      editPatientInsuranceAPIResClearAction({} as EditPatientInsuranceRes, "", "", 0)
    );
  };
};
