import {
  ChiefComplaintGetActionTypes,
  GET_CC_LIST,
  AddChiefComplaintTypes,
  ADD_CC,
  DELETE_DOCUMENT,
  DeleteDocumentTypes,
  TaskGetActionTypes,
  GET_TASK_LIST,
} from "../types/ActionTypes";

import {
  ChiefComplaintList,
  TaskList,
} from "../interfaces/Model";

export const getChiefComplaintAction = (
  cc: ChiefComplaintList[]
): ChiefComplaintGetActionTypes => {
  return {
    type: GET_CC_LIST,
    payload: cc,
  };
};

export const AddChiefComplaintAction = (addCC: any): AddChiefComplaintTypes => {
  return {
    type: ADD_CC,
    payload: addCC,
  };
};

export const DeleteDocumentAction = (
  deletedocument: any
): DeleteDocumentTypes => {
  return {
    type: DELETE_DOCUMENT,
    payload: deletedocument,
  };
};

export const getTaskAction = (tasks: TaskList[]): TaskGetActionTypes => {
  return {
    type: GET_TASK_LIST,
    payload: tasks,
  };
};
