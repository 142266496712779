import { OwnerAppointmentResponse } from "./Model";
export const OWNER_APPOINTMENT_LIST_LOADING = "OWNER_APPOINTMENT_LIST_LOADING";
export const OWNER_APPOINTMENT_LIST_SUCCESS = "OWNER_APPOINTMENT_LIST_SUCCESS";
export const OWNER_APPOINTMENT_LIST_FAIL = "OWNER_APPOINTMENT_LIST_FAIL";
export const OWNER_APPOINTMENT_LIST_UPDATE_API_MSG =
  "OWNER_APPOINTMENT_LIST_UPDATE_API_MSG";
export const OWNER_APPOINTMENT_LIST_UPDATE_API_RES =
  "OWNER_APPOINTMENT_LIST_UPDATE_API_RES";

export interface OwnerAppointmentListState {
  ownerAppointmentListResponse: OwnerAppointmentResponse;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface OwnerAppointmentListLoading {
  type: typeof OWNER_APPOINTMENT_LIST_LOADING;
  loading: boolean;
}
export interface OwnerAppointmentListSuccess {
  type: typeof OWNER_APPOINTMENT_LIST_SUCCESS;
  payload: OwnerAppointmentResponse;
  successMsg: string;
}
export interface OwnerAppointmentListFail {
  type: typeof OWNER_APPOINTMENT_LIST_FAIL;
  payload: OwnerAppointmentResponse;
  errorMsg: string;
  status: number;
}
export interface OwnerAppointmentListUpdateAPIMsg {
  type: typeof OWNER_APPOINTMENT_LIST_UPDATE_API_MSG;
  payload: OwnerAppointmentResponse;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface OwnerAppointmentListUpdateAPIRes {
  type: typeof OWNER_APPOINTMENT_LIST_UPDATE_API_RES;
  payload: OwnerAppointmentResponse;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type OwnerAppointmentListActionTypes =
  | OwnerAppointmentListLoading
  | OwnerAppointmentListSuccess
  | OwnerAppointmentListFail
  | OwnerAppointmentListUpdateAPIMsg
  | OwnerAppointmentListUpdateAPIRes;
