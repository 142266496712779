import { SharedPatientDetailsResponse } from "./Model";
export const SHARED_PATIENT_LIST_LOADING = "SHARED_PATIENT_LIST_LOADING";
export const SHARED_PATIENT_LIST_SUCCESS = "SHARED_PATIENT_LIST_SUCCESS";
export const SHARED_PATIENT_LIST_FAIL = "SHARED_PATIENT_LIST_FAIL";
export const SHARED_PATIENT_LIST_API_MSG = "SHARED_PATIENT_LIST_API_MSG";
export const SHARED_PATIENT_LIST_API_RES = "SHARED_PATIENT_LIST_API_RES";

export interface SharedPatientListState {
  SharedPatientListResponse: SharedPatientDetailsResponse;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface SharedPatientListLoading {
  type: typeof SHARED_PATIENT_LIST_LOADING;
  loading: boolean;
}
export interface SharedPatientListSuccess {
  type: typeof SHARED_PATIENT_LIST_SUCCESS;
  payload: SharedPatientDetailsResponse;
  successMsg: string;
}
export interface SharedPatientListFail {
  type: typeof SHARED_PATIENT_LIST_FAIL;
  payload: SharedPatientDetailsResponse;
  errorMsg: string;
  status: number;
}
export interface SharedPatientListAPIMsg {
  type: typeof SHARED_PATIENT_LIST_API_MSG;
  payload: SharedPatientDetailsResponse;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface SharedPatientListAPIRes {
  type: typeof SHARED_PATIENT_LIST_API_RES;
  payload: SharedPatientDetailsResponse;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type SharedPatientListActionTypes =
  | SharedPatientListLoading
  | SharedPatientListSuccess
  | SharedPatientListFail
  | SharedPatientListAPIMsg
  | SharedPatientListAPIRes;
