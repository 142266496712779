import { BloodGroupListResponse } from "./Model";
export const BLOOD_GRP_LOADING = "BLOOD_GRP_LOADING";
export const BLOOD_GRP_SUCCESS = "BLOOD_GRP_SUCCESS";
export const BLOOD_GRP_FAIL = "BLOOD_GRP_FAIL";
export const BLOOD_GRP_API_MSG = "BLOOD_GRP_API_MSG";
export const BLOOD_GRP_API_RES = "BLOOD_GRP_API_RES";

export interface BloodGrpState {
  BloodGrpResponse: BloodGroupListResponse;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface BloodGrpLoading {
  type: typeof BLOOD_GRP_LOADING;
  loading: boolean;
}
export interface BloodGrpSuccess {
  type: typeof BLOOD_GRP_SUCCESS;
  payload: BloodGroupListResponse;
  successMsg: string;
}
export interface BloodGrpFail {
  type: typeof BLOOD_GRP_FAIL;
  payload: BloodGroupListResponse;
  errorMsg: string;
  status: number;
}
export interface BloodGrpAPIMsg {
  type: typeof BLOOD_GRP_API_MSG;
  payload: BloodGroupListResponse;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface BloodGrpAPIRes {
  type: typeof BLOOD_GRP_API_RES;
  payload: BloodGroupListResponse;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type BloodGrpActionTypes =
  | BloodGrpLoading
  | BloodGrpSuccess
  | BloodGrpFail
  | BloodGrpAPIMsg
  | BloodGrpAPIRes;
