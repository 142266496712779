import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../redux/store/Store";
import { t } from "i18next";
import MWLoader from "../components/MWLoader";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import PageLayout from "./Layout/PageLayout";
import MWPageTitle from "../components/MWPageTitle";
import { PatientId } from "../redux/Doctor/doctorDetailsById/Model";
import { doctorDetailsApi } from "../redux/Doctor/doctorDetailsById/API";

export default function DoctorDetails(props: any) {
  const { id } = useParams() as {
    id: string;
  };
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const loadingMarkup = isLoading ? <MWLoader /> : null;
  const [qualification, setQualification] = useState("");
  const [secondHighestqualification, setSecondHighestqualification] =
    useState("");
  const [yearexp, setYearexp] = useState("");
  const [mbbsDegree, setMbbsDegree] = useState("");
  const [registration, setRegistration] = useState<any[]>([]);
  const [workExpName, setWorkExpName] = useState<any[]>([]);
  const [awardName, setAwardName] = useState<any[]>([]);
  const [languageName, setLanguageName] = useState<any[]>([]);
  const [membershipName, setMembershipName] = useState<any[]>([]);

  const doctorId = { id } as PatientId;
  // doctor list GET API

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(doctorDetailsApi(doctorId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const doctorDetailsById = useSelector(
    (state: AppState) => state.doctorDetailsById
  );
  useEffect(() => {
    doctorDetailsById?.loading ? setIsLoading(true) : setIsLoading(false);
  }, [doctorDetailsById]);

  useEffect(() => {
    if (doctorDetailsById?.doctorDetailsResponse?.doctorDetails !== undefined) {
      let doctorData = doctorDetailsById?.doctorDetailsResponse?.doctorDetails;
      setQualification(
        doctorData?.qualification?.highestQualification === null
          ? ""
          : doctorData.qualification.highestQualification.label
      );
      setSecondHighestqualification(
        doctorData?.qualification?.secondHighestQualification !== null
          ? doctorData?.qualification?.secondHighestQualification?.label
          : ""
      );
      setMbbsDegree(
        doctorData.qualification === null ||
          doctorData.qualification.mbbsDegree === null
          ? ""
          : doctorData.qualification.mbbsDegree.label
      );
      if (doctorData.yearofExperience !== null) {
        setYearexp(
          String(
            doctorData.yearofExperience
              .totalYearsOfPracticeAfterHighestQualification
          )
        );
      }

      let doctorexp =
        doctorDetailsById?.doctorDetailsResponse?.doctorDetails.workexperience;
      let workValueList = [];
      for (var i = 0; i < doctorexp.length; i++) {
        let description =
          doctorexp[i].name === ""
            ? `${t("docDetailPage.noDetailAvail", { ns: ["home"] })}`
            : doctorexp[i].name;
        workValueList.push([description]);
      }
      setWorkExpName(workValueList);
      let doctorAward =
        doctorDetailsById?.doctorDetailsResponse?.doctorDetails.awards;
      let awardList = [];
      for (var j = 0; j < doctorAward.length; j++) {
        let awards =
          doctorAward[j].description === "" ||
          doctorAward[j].description === null
            ? `${t("docDetailPage.noDetailAvail", { ns: ["home"] })}`
            : doctorAward[j].description;
        awardList.push([awards]);
      }
      setAwardName(awardList);
      let doctorLanguage =
        doctorDetailsById?.doctorDetailsResponse?.doctorDetails.language;
      let languageList = [];
      for (var k = 0; k < doctorLanguage.length; k++) {
        let languages =
          doctorLanguage[k].label === ""
            ? `${t("docDetailPage.noDetailAvail", { ns: ["home"] })}`
            : doctorLanguage[k].label;
        languageList.push([languages]);
      }
      setLanguageName(languageList);
      let doctorMembership =
        doctorDetailsById?.doctorDetailsResponse?.doctorDetails.membership;
      let membershipList = [];
      for (var l = 0; l < doctorMembership.length; l++) {
        let membership =
          doctorMembership[l].name === ""
            ? `${t("docDetailPage.noDetailAvail", { ns: ["home"] })}`
            : doctorMembership[l].name;
        membershipList.push([membership]);
      }
      setMembershipName(membershipList);
      let doctorRegIdNumber =
        doctorDetailsById?.doctorDetailsResponse?.doctorDetails.registration;
      let doctorRegIdNumberList = [];
      for (var m = 0; m < doctorRegIdNumber.length; m++) {
        let regIdNumber =
          doctorRegIdNumber[m].idNumber === ""
            ? `${t("docDetailPage.noDetailAvail", { ns: ["home"] })}`
            : doctorRegIdNumber[m].idNumber;
        let regIdType =
          doctorRegIdNumber[m].idType === null
            ? `${t("docDetailPage.noDetailAvail", { ns: ["home"] })}`
            : doctorRegIdNumber[m].idType.label;
        let regNumber = regIdNumber + " " + regIdType;
        doctorRegIdNumberList.push([regNumber]);
      }
      setRegistration(doctorRegIdNumberList);
    }
  }, [doctorDetailsById]);

  const doctorDetails = (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={2}
    >
      <Grid item>
        <Avatar
          alt="doctorimg"
          src={
            doctorDetailsById?.doctorDetailsResponse?.doctorDetails?.image
              ?.document
          }
          sx={{ width: 300, height: 300 }}
        />
      </Grid>
      <Grid item>
        <Card>
          <CardContent>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={1}
            >
              <Grid item xs={4} sm={4} md={4} lg={4}>
                <Typography variant="subtitle1" fontWeight="bold">
                  {t("docDetailPage.acaQual", { ns: ["home"] })}
                </Typography>
              </Grid>
              <Grid item xs={8} sm={8} md={8} lg={8}>
                <Typography component="dd" variant="body2">
                  {qualification}, {secondHighestqualification}, {mbbsDegree}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Divider />
              </Grid>
              <Grid item xs={4} sm={4} md={4} lg={4}>
                <Typography variant="subtitle1" fontWeight="bold">
                  {t("docDetailPage.regMedCoun", { ns: ["home"] })}
                </Typography>
              </Grid>
              <Grid item xs={8} sm={8} md={8} lg={8}>
                <Typography component="dd" variant="body2">
                  {registration.join(", ")}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Divider />
              </Grid>
              <Grid item xs={4} sm={4} md={4} lg={4}>
                <Typography variant="subtitle1" fontWeight="bold">
                  {t("docDetailPage.lngSkill", { ns: ["home"] })}
                </Typography>
              </Grid>
              <Grid item xs={8} sm={8} md={8} lg={8}>
                <Typography component="dd" variant="body2">
                  {languageName.join(", ")}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Divider />
              </Grid>
              <Grid item xs={4} sm={4} md={4} lg={4}>
                <Typography variant="subtitle1" fontWeight="bold">
                  {t("docDetailPage.yrsexp", { ns: ["home"] })}
                </Typography>
              </Grid>
              <Grid item xs={8} sm={8} md={8} lg={8}>
                <Typography component="dd" variant="body2">
                  {yearexp} years
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Divider />
              </Grid>
              <Grid item xs={4} sm={4} md={4} lg={4}>
                <Typography variant="subtitle1" fontWeight="bold">
                  {t("docDetailPage.wrkexp", { ns: ["home"] })}
                </Typography>
              </Grid>
              <Grid item xs={8} sm={8} md={8} lg={8}>
                <Typography component="dd" variant="body2">
                  {workExpName.join(", ")}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Divider />
              </Grid>
              <Grid item xs={4} sm={4} md={4} lg={4}>
                <Typography variant="subtitle1" fontWeight="bold">
                  {t("docDetailPage.profaff", { ns: ["home"] })}
                </Typography>
              </Grid>
              <Grid item xs={8} sm={8} md={8} lg={8}>
                <Typography component="dd" variant="body2">
                  {membershipName.join(", ")}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Divider />
              </Grid>
              <Grid item xs={4} sm={4} md={4} lg={4}>
                <Typography variant="subtitle1" fontWeight="bold">
                  {t("docDetailPage.awardRecv", { ns: ["home"] })}
                </Typography>
              </Grid>
              <Grid item xs={8} sm={8} md={8} lg={8}>
                <Typography component="dd" variant="body2">
                  {awardName.join(", ")}
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
  return (
    <Box>
      {doctorDetailsById.loading ? loadingMarkup : null}
      <PageLayout>
        <MWPageTitle
          title={
            t("docDetailPage.docDetail", { ns: ["home"] })! +
            ` ${
              doctorDetailsById?.doctorDetailsResponse?.doctorDetails
                ?.firstname !== undefined &&
              doctorDetailsById?.doctorDetailsResponse?.doctorDetails
                ?.lastname !== undefined
                ? `${doctorDetailsById?.doctorDetailsResponse?.doctorDetails?.firstname} ${doctorDetailsById?.doctorDetailsResponse?.doctorDetails?.lastname}`
                : ""
            }`
          }
          backButton={true}
        ></MWPageTitle>
        {doctorDetails}
      </PageLayout>
    </Box>
  );
}
