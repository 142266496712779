import {
  PAYMENT_APPROVE_FAIL,
  PAYMENT_APPROVE_LOADING,
  PAYMENT_APPROVE_SUCCESS,
  PAYMENT_APPROVE_API_MSG,
  PAYMENT_APPROVE_API_RES,
  PaymentApproveActionTypes,
} from "./ActionTypes";
import { PaymentApproveDetailsResponse } from "./Model";

export const paymentApproveLoadingAction = (loading: boolean): PaymentApproveActionTypes => {
  return {
    type: PAYMENT_APPROVE_LOADING,
    loading: loading,
  };
};

export const paymentApproveSuccessAction = (
  paymentApproveResponse: PaymentApproveDetailsResponse,
  successMsg: string
): PaymentApproveActionTypes => {
  return {
    type: PAYMENT_APPROVE_SUCCESS,
    payload: paymentApproveResponse,
    successMsg: successMsg,
  };
};

export const paymentApproveErrorAction = (
  paymentApproveResponse: PaymentApproveDetailsResponse,
  errMsg: string,
  status: number
): PaymentApproveActionTypes => {
  return {
    type: PAYMENT_APPROVE_FAIL,
    payload: paymentApproveResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const paymentApproveAPIMsgAction = (
  paymentApproveResponse: PaymentApproveDetailsResponse,
  successMsg: string,
  errMsg: string,
  status: number
): PaymentApproveActionTypes => {
  return {
    type: PAYMENT_APPROVE_API_MSG,
    payload: paymentApproveResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const paymentApproveAPIResClearAction = (
  paymentApproveResponse: PaymentApproveDetailsResponse,
  successMsg: string,
  errMsg: string,
  status: number
): PaymentApproveActionTypes => {
  return {
    type: PAYMENT_APPROVE_API_RES,
    payload: paymentApproveResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
