import { SearchDoctorResponse } from "./Model";
export const SEARCH_DOCTOR_LOADING = "SEARCH_DOCTOR_LOADING";
export const SEARCH_DOCTOR_SUCCESS = "SEARCH_DOCTOR_SUCCESS";
export const SEARCH_DOCTOR_FAIL = "SEARCH_DOCTOR_FAIL";
export const SEARCH_DOCTOR_API_MSG = "SEARCH_DOCTOR_API_MSG";
export const SEARCH_DOCTOR_API_RES = "SEARCH_DOCTOR_API_RES";

export interface SearchDoctorState {
  searchDoctorResponse: SearchDoctorResponse;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface SearchDoctorLoading {
  type: typeof SEARCH_DOCTOR_LOADING;
  loading: boolean;
}
export interface SearchDoctorSuccess {
  type: typeof SEARCH_DOCTOR_SUCCESS;
  payload: SearchDoctorResponse;
  successMsg: string;
}
export interface SearchDoctorFail {
  type: typeof SEARCH_DOCTOR_FAIL;
  payload: SearchDoctorResponse;
  errorMsg: string;
  status: number;
}
export interface SearchDoctorAPIMsg {
  type: typeof SEARCH_DOCTOR_API_MSG;
  payload: SearchDoctorResponse;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface SearchDoctorAPIRes {
  type: typeof SEARCH_DOCTOR_API_RES;
  payload: SearchDoctorResponse;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type SearchDoctorActionTypes =
  | SearchDoctorLoading
  | SearchDoctorSuccess
  | SearchDoctorFail
  | SearchDoctorAPIMsg
  | SearchDoctorAPIRes;
