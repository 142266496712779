import React, { useEffect, useState } from "react";
import {
  Alert,
  AlertColor,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Transition } from "../../../../../../components/MWDialogTransition";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ModifiedImportConfigList } from "../Model";
import { AppState } from "../../../../../../redux/store/Store";
import utc from "dayjs/plugin/utc";
import dayjs from "dayjs";
import MWLoader from "../../../../../../components/MWLoader";
import { useParams } from "react-router-dom";
import { AddPatientImportConfigDetailsBody } from "../../../../../../redux/FilePreparation/ImportConfig/AddPatientImportConfig/Model";
import {
  addPatientImportConfigData,
  clearAddAddPatientImportConfigDataAPIRes,
} from "../../../../../../redux/FilePreparation/ImportConfig/AddPatientImportConfig/API";
import { getPatientDataList } from "../../../../../../redux/FilePreparation/GetPatientDataList/API";
import { GetPatientDataDetailsBody } from "../../../../../../redux/FilePreparation/GetPatientDataList/Model";
import {
  clearEditPatientImportConfigsDataAPIRes,
  editPatientImportConfigData,
} from "../../../../../../redux/FilePreparation/ImportConfig/EditPatientImportConfig/API";
import { EditPatientImportConfigDetailsBody } from "../../../../../../redux/FilePreparation/ImportConfig/EditPatientImportConfig/Model";
import { getPatientInfortConfigListApi } from "../../../../../../redux/FilePreparation/ImportConfig/GetPatientImportConfigList/API";
import { PatientImportConfigListDetailsBody } from "../../../../../../redux/FilePreparation/ImportConfig/GetPatientImportConfigList/Model";
dayjs.extend(utc);
export interface Props {
  dialogEnable?: boolean | any;
  dialogUpdateState: boolean | any;
  selectedItem?: ModifiedImportConfigList | any;
  selectedItemUpdateState: any;
  type?: string;
}
export default function UpdatePatientImportConfigData({
  dialogEnable,
  dialogUpdateState,
  selectedItem,
  selectedItemUpdateState,
  type,
}: Props) {
  const { patientid } = useParams() as {
    patientid: string;
  };
  const { caseid } = useParams() as {
    caseid: string;
  };
  const dispatch = useDispatch();
  const { t } = useTranslation(["home"]);

  const theme = useTheme();

  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const [activeResetPasswordDialog, setActiveResetPasswordDialog] =
    useState(false);

  const closeDialog = () => {
    setActiveResetPasswordDialog(false);
    dialogUpdateState(false);
    setEmail("");
    setEmailError("");
    // setImageId("");
    // dispatch(clearImageUploadAPIRes());
    // setSelectedDate(dayjs(new Date()));
    // setSelectedToDate(dayjs(new Date()));
    selectedItemUpdateState({} as any);
    // dispatch(clearAddImportDataAPIRes());
    // setAddImportDataMsg("");
    // dispatch(clearEditImportDataAPIRes());
    // setEditPatientImportConfigDataMsg("");
  };
  const [email, setEmail] = useState("");
  useEffect(() => {
    setActiveResetPasswordDialog(dialogEnable);
  }, [dialogEnable]);

  const [emailError, setEmailError] = useState("");

  useEffect(() => {
    selectedItem?.email !== undefined
      ? selectedItem?.email !== ""
        ? selectedItem?.email !== `${t("common.notSet", { ns: ["home"] })!}`
          ? setEmail(selectedItem?.email)
          : setEmail("")
        : setEmail("")
      : setEmail("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItem]);

  const updateImportedDataBody = {
    email: email,
    phoneNumber: "",
    whatsappNo: "",
    patientId: patientid,
  } as AddPatientImportConfigDetailsBody;

  const submitEducationalDetails = () => {
    if (email === "") {
      setEmailError(`${t("impDataPage.impDataEmailError", { ns: ["home"] })}`);
    } else {
      setEmailError("");
      setEmailError("");
      if (selectedItem?.id === undefined) {
        // Add Dr. Import Config Data Details
        dispatch(addPatientImportConfigData(updateImportedDataBody));
      } else {
        // Edit Dr. Import Config Data Details
        dispatch(
          editPatientImportConfigData(
            Object.assign(updateImportedDataBody, {
              patientConfigId: selectedItem?.id,
            } as EditPatientImportConfigDetailsBody)
          )
        );
      }
    }
  };

  const addPatientImportConfigDataRes = useSelector(
    (state: AppState) => state.addPatientImportConfigDataRes
  );
  const [addImportDataMsg, setAddImportDataMsg] = useState("");
  const [addImportDataMsgType, setAddImportDataMsgType] =
    useState<AlertColor>("success");

  useEffect(() => {
    if (addPatientImportConfigDataRes?.successMsg !== "") {
      setAddImportDataMsgType("success");
      setAddImportDataMsg(addPatientImportConfigDataRes?.successMsg);
      dispatch(
        getPatientDataList({
          caseId: caseid,
          secondOpinionUiSection: type,
        } as GetPatientDataDetailsBody)
      );
      closeDialog();
    }
    if (addPatientImportConfigDataRes?.errorMsg !== "") {
      setAddImportDataMsgType("error");
      setAddImportDataMsg(addPatientImportConfigDataRes?.errorMsg);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addPatientImportConfigDataRes]);

  const editPatientImportConfigDataRes = useSelector(
    (state: AppState) => state.editPatientImportConfigDataRes
  );
  const [editPatientImportConfigDataMsg, setEditPatientImportConfigDataMsg] =
    useState("");
  const [
    editPatientImportConfigDataMsgType,
    setEditPatientImportConfigDataMsgType,
  ] = useState<AlertColor>("success");
  useEffect(() => {
    if (editPatientImportConfigDataRes?.successMsg !== "") {
      setEditPatientImportConfigDataMsgType("success");
      setEditPatientImportConfigDataMsg(
        editPatientImportConfigDataRes?.successMsg
      );
      dispatch(
        getPatientInfortConfigListApi({
          patientId: patientid,
        } as PatientImportConfigListDetailsBody)
      );
      closeDialog();
    }
    if (editPatientImportConfigDataRes?.errorMsg !== "") {
      setEditPatientImportConfigDataMsgType("error");
      setEditPatientImportConfigDataMsg(
        editPatientImportConfigDataRes?.errorMsg
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editPatientImportConfigDataRes]);

  return (
    <Dialog
      open={activeResetPasswordDialog}
      TransitionComponent={Transition}
      keepMounted
      onClose={closeDialog}
      aria-describedby="alert-dialog-slide-description"
      maxWidth="sm"
      fullScreen={fullScreen}
      fullWidth
    >
      {addPatientImportConfigDataRes?.loading ||
      editPatientImportConfigDataRes?.loading ? (
        <MWLoader />
      ) : null}
      <DialogTitle>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <Typography variant="h5">
              {selectedItem?.id
                ? `${t("impConfigPage.updateImpCon", { ns: ["home"] })}`
                : `${t("impConfigPage.addImpCon", { ns: ["home"] })}`}
            </Typography>
          </Grid>
          <Grid item>
            <IconButton onClick={() => closeDialog()}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="stretch"
        >
          <Grid item>
            <TextField
              label={t("impConfigPage.emailImpPat", { ns: ["home"] })}
              placeholder={t("impConfigPage.enterEmailAdd", { ns: ["home"] })!}
              value={email}
              onChange={(event: any) => setEmail(event?.target.value)}
              type="email"
              fullWidth
              size="small"
              id="Emailname"
            />
            {emailError !== "" ? (
              <Typography style={{ marginBottom: "5px", color: "#d32f2f" }}>
                {emailError}
              </Typography>
            ) : null}
          </Grid>
        </Grid>
        {addImportDataMsg !== "" ? (
          <Box my={2}>
            <Alert
              severity={addImportDataMsgType}
              onClose={() => {
                dispatch(clearAddAddPatientImportConfigDataAPIRes());
                setAddImportDataMsg("");
              }}
            >
              {addImportDataMsg}
            </Alert>
          </Box>
        ) : null}
        {editPatientImportConfigDataMsg !== "" ? (
          <Box my={2}>
            <Alert
              severity={editPatientImportConfigDataMsgType}
              onClose={() => {
                dispatch(clearEditPatientImportConfigsDataAPIRes());
                setEditPatientImportConfigDataMsg("");
              }}
            >
              {editPatientImportConfigDataMsg}
            </Alert>
          </Box>
        ) : null}
      </DialogContent>
      <Divider />
      <DialogActions>
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="flex-end"
          spacing={2}
        >
          <Grid item>
            <Button
              variant="outlined"
              onClick={() => closeDialog()}
              sx={{ textTransform: "none" }}
            >
              {t("common.cancel", { ns: ["home"] })}
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              disabled={
                addPatientImportConfigDataRes?.loading ||
                editPatientImportConfigDataRes?.loading
              }
              onClick={() => submitEducationalDetails()}
              sx={{ textTransform: "none" }}
            >
              {t("needhelppage.submit", { ns: ["home"] })!}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
