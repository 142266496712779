import {
  PaymentStatusActionTypes,
  PAYMENT_STATUS_FAIL,
  PAYMENT_STATUS_LOADING,
  PAYMENT_STATUS_SUCCESS,
  PaymentStatusState,
  PAYMENT_STATUS_API_MSG,
  PAYMENT_STATUS_API_RES,
} from "./ActionTypes";
import { CheckPaymentStatusRes } from "./Model";

const initialStateEditPosts: PaymentStatusState = {
  loading: false,
  paymentStatusResponse: {} as CheckPaymentStatusRes,
  successMsg: "",
  errorMsg: "",
  status: 0,
};
export const paymentStatusReducer = (
  state = initialStateEditPosts,
  action: PaymentStatusActionTypes
): PaymentStatusState => {
  switch (action.type) {
    case PAYMENT_STATUS_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case PAYMENT_STATUS_SUCCESS:
      return {
        ...state,
        paymentStatusResponse: action.payload,
        successMsg: action.successMsg,
      };
    case PAYMENT_STATUS_FAIL:
      return {
        ...state,
        paymentStatusResponse: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case PAYMENT_STATUS_API_MSG:
      return {
        ...state,
        paymentStatusResponse: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case PAYMENT_STATUS_API_RES:
      return {
        ...state,
        paymentStatusResponse: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
