import axios from "axios";
import { Dispatch } from "redux";
import { setupInterceptorsTo } from "../Authentication/Interceptors";
import { CaseListActionTypes } from "./ActionTypes";
import { CaseListResponse, CaseListBody } from "./Model";
import {
  caseListAPIResClearAction,
  caseListErrorAction,
  caseListLoadingAction,
  caseListSuccessAction,
  caseListUpdateAPIMsgAction,
} from "./Action";
import baseUrl from "../../Url";
import { headers } from "../../components/Utility";

let apiRes = {} as CaseListResponse;
export const patientCaseList = (payload: CaseListBody) => {
  return function (dispatch: Dispatch<CaseListActionTypes>) {
    dispatch(caseListLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        baseUrl.baseUrl + `/patientapp/api/patient/patient_case_list`,
        payload,
        { headers: headers }
      )
      .then((res) => {
        apiRes = res.data !== undefined ? res.data : ({} as CaseListResponse);
        dispatch(caseListLoadingAction(false));
        dispatch(
          caseListSuccessAction(
            res.data !== undefined ? res.data : ({} as CaseListResponse),
            res.data.message !== undefined
              ? res.data.message
              : "Case list fetch successfully"
          )
        );
      })
      .catch((error) => {
        dispatch(caseListLoadingAction(false));
        dispatch(
          caseListErrorAction(
            {} as CaseListResponse,
            error.response !== undefined
              ? error.response.data.message || error.response.data.detail
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || -1 : -1
          )
        );
      });
  };
};

export const updateCaseListAPIResMsg = () => {
  return function (dispatch: Dispatch<CaseListActionTypes>) {
    dispatch(caseListUpdateAPIMsgAction(apiRes as CaseListResponse, "", "", 0));
  };
};

export const clearCaseListAPIRes = () => {
  return function (dispatch: Dispatch<CaseListActionTypes>) {
    dispatch(caseListAPIResClearAction({} as CaseListResponse, "", "", 0));
  };
};
