import { LanguageListResponse } from "./Model";
export const LANGUAGE_LIST_LOADING = "LANGUAGE_LIST_LOADING";
export const LANGUAGE_LIST_SUCCESS = "LANGUAGE_LIST_SUCCESS";
export const LANGUAGE_LIST_FAIL = "LANGUAGE_LIST_FAIL";
export const LANGUAGE_LIST_API_MSG = "LANGUAGE_LIST_API_MSG";
export const LANGUAGE_LIST_API_RES = "LANGUAGE_LIST_API_RES";

export interface LanguageListState {
  languageListResponse: LanguageListResponse;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface LanguageListLoading {
  type: typeof LANGUAGE_LIST_LOADING;
  loading: boolean;
}
export interface LanguageListSuccess {
  type: typeof LANGUAGE_LIST_SUCCESS;
  payload: LanguageListResponse;
  successMsg: string;
}
export interface LanguageListFail {
  type: typeof LANGUAGE_LIST_FAIL;
  payload: LanguageListResponse;
  errorMsg: string;
  status: number;
}
export interface LanguageListAPIMsg {
  type: typeof LANGUAGE_LIST_API_MSG;
  payload: LanguageListResponse;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface LanguageListAPIRes {
  type: typeof LANGUAGE_LIST_API_RES;
  payload: LanguageListResponse;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type LanguageListActionTypes =
  | LanguageListLoading
  | LanguageListSuccess
  | LanguageListFail
  | LanguageListAPIMsg
  | LanguageListAPIRes;
