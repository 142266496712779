import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../../Url";
import { GetMeetingDetailsActionTypes } from "./ActionTypes";
import { GetMeetingDetailsBody, GetMeetingDetailsRes } from "./Model";
import {
  getMeetingDetailsAPIResClearAction,
  getMeetingDetailsErrorAction,
  getMeetingDetailsLoadingAction,
  getMeetingDetailsSuccessAction,
  getMeetingDetailsAPIMsgAction,
} from "./Action";
import { headers } from "../../../../components/Utility";
import { setupInterceptorsTo } from "../../../Authentication/Interceptors";

let apiRes = {} as GetMeetingDetailsRes;
export const getMeetingDetails = (payload: GetMeetingDetailsBody) => {
  return function (dispatch: Dispatch<GetMeetingDetailsActionTypes>) {
    dispatch(getMeetingDetailsLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(
        Url.baseUrl + `/telehealthapp/api/telehealth/getInstantMeeting`,
        payload,
        {
          headers: headers,
        }
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(getMeetingDetailsLoadingAction(false));
        dispatch(
          getMeetingDetailsSuccessAction(
            res.data,
            res.data.message !== undefined
              ? res.data.message
              : "Patient's question for doctor has been submitted successfully"
          )
        );
      })
      .catch((error) => {
        dispatch(getMeetingDetailsLoadingAction(false));
        dispatch(
          getMeetingDetailsErrorAction(
            {} as GetMeetingDetailsRes,
            error.response.data !== undefined
              ? error.response.data.message !== undefined
                ? error.response.data.message
                : "Something went wrong"
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0
          )
        );
      });
  };
};

export const updateGetMeetingDetailsAPIResMsg = () => {
  return function (dispatch: Dispatch<GetMeetingDetailsActionTypes>) {
    dispatch(
      getMeetingDetailsAPIMsgAction(apiRes as GetMeetingDetailsRes, "", "", 0)
    );
  };
};

export const clearGetMeetingDetailsAPIRes = () => {
  return function (dispatch: Dispatch<GetMeetingDetailsActionTypes>) {
    dispatch(
      getMeetingDetailsAPIResClearAction({} as GetMeetingDetailsRes, "", "", 0)
    );
  };
};
