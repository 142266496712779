import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  Button,
  Container,
  Link,
  Typography,
  Box,
  Alert,
  IconButton,
  OutlinedInput,
  InputAdornment,
  CircularProgress,
  AlertColor,
  AlertTitle,
} from "@mui/material";
import { emailOtpGenerationApi } from "../../redux/emailOtpGeneration/API";
import { EmailOTPGenBody } from "../../redux/emailOtpGeneration/Model";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../redux/store/Store";
import { emailOtpSignupApi } from "../../redux/emailOtpSignup/API";
import OnboardingLayout from "./OnboardingLayout";
import MWLoader from "../../components/MWLoader";
import MWTextField from "../../components/MWTextField";
import { t } from "i18next";
import { EmailOtpSignUpBody } from "../../redux/emailOtpSignup/Model";
import Url from "../../Url";

export default function Signup() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  // const [password, setPass] = useState('');
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  // const { t } = useTranslation(["home"]);

  const [error, setError] = useState("");
  const [errormail, setErrorMail] = useState("");
  const [errorotp, setErrorOtp] = useState("");
  const [errorFstName, setErrorFstName] = useState("");
  const [errorLstName, setErrorLstName] = useState("");
  const [errorpassword, setErrorPass] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const errorEmail = errormail ? <Typography>{errormail}</Typography> : "";

  const errorOtp = errorotp ? <Typography>{errorotp}</Typography> : "";
  const errorFirstName = errorFstName ? (
    <Typography>{errorFstName}</Typography>
  ) : (
    ""
  );
  const errorLastName = errorLstName ? (
    <Typography>{errorLstName}</Typography>
  ) : (
    ""
  );

  const errorPasswort = errorpassword ? (
    <Typography>{errorpassword}</Typography>
  ) : (
    ""
  );

  const BannerClose = () => {
    setError("");
  };
  const errorDiv = error ? (
    <Alert variant="standard" severity="error" onClose={() => BannerClose()}>
      {error}
    </Alert>
  ) : (
    ""
  );

  const sectionForm = isLoading ? <MWLoader /> : null;
  // store
  const emailOtpGen = useSelector(
    (state: AppState) => state.emailOtpGeneration
  );

  const emailOtpSignup = useSelector((state: AppState) => state.emailOtpSignup);
  const [emailSignupLoading, setEmailSignupLoading] = useState(false);
  const [emailOTPGenLoading, setEmailOTPGenLoading] = useState(false);
  const [genOTPMessageType, setGenOTPMessageType] =
    useState<AlertColor>("success");
  console.log(genOTPMessageType);

  const [isDisableOTPField, setIsDisableOTPField] = useState(true);
  const [isDisableFastnameField, setIsDisableFastnameField] = useState(true);
  const [isDisableLastnameField, setIsDisableLastnameField] = useState(true);
  const [isDisablePassword, setIsDisablePassword] = useState(true);
  const [buttonText, setButtonText] = useState(
    `${t("userprofile.generateotp", { ns: ["home"] })}`
  );
  const [signsuccessMessage, setsignsuccessMessage] = useState("");
  const [generateOtpSuccessMessage, setGenerateOtpSuccessMessage] =
    useState("");
  const [generateOtpErrorMessage, setGenerateOtpErrorMessage] = useState("");
  const closeSuccess = () => {
    setsignsuccessMessage("");
    history.push("/signin");
  };

  interface State {
    password: string;
    showPassword: boolean;
  }

  const [password, setPass] = React.useState<State>({
    password: "",
    showPassword: false,
  });

  const handleChange =
    (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setPass({ ...password, [prop]: event.target.value });
    };

  const handleClickShowPassword = () => {
    setPass({ ...password, showPassword: !password.showPassword });
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const closeSuccessBanner = () => {
    setGenerateOtpSuccessMessage("");
  };

  const successDiv = signsuccessMessage ? (
    <Alert severity="success" variant="standard" onClose={() => closeSuccess()}>
      <AlertTitle>Success</AlertTitle>
      <Typography>
        {signsuccessMessage}&nbsp;&nbsp;Please go to
        <Link href="/signin" underline="hover">
          &nbsp;&nbsp;Signin
        </Link>
        &nbsp;&nbsp;page.
      </Typography>
    </Alert>
  ) : null;

  const otpsuccessDiv = generateOtpSuccessMessage ? (
    <Alert
      variant="standard"
      severity="success"
      onClose={() => closeSuccessBanner()}
    >
      <Typography>{generateOtpSuccessMessage}</Typography>
    </Alert>
  ) : (
    ""
  );
  const closeOtpErrorBanner = () => {
    setGenerateOtpErrorMessage("");
  };

  const otpErrorDiv = generateOtpErrorMessage ? (
    <Alert
      variant="standard"
      severity="error"
      onClose={() => closeOtpErrorBanner()}
    >
      <p>{generateOtpErrorMessage}</p>
    </Alert>
  ) : (
    ""
  );

  // ******* generate otp section modal **********
  const generateOtpBody = {
    email: email.toLowerCase(),
  } as EmailOTPGenBody;

  const generateOtpApicall = () => {
    if (email === "") {
      setErrorMail(`${t("loginpage.emailorphnnotempty", { ns: ["home"] })}`);
    } else if (
      new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(
        email.toLowerCase()
      ) === false &&
      new RegExp(/^[0]?[6789]\d{9}$/).test(email.toLowerCase()) === false
    ) {
      setErrorMail(`${t("loginpage.entrvalidenorpass", { ns: ["home"] })}`);
    } else {
      setErrorMail("");
      dispatch(emailOtpGenerationApi(generateOtpBody));
    }
  };

  useEffect(() => {
    setEmailOTPGenLoading(emailOtpGen?.loading);
    if (emailOtpGen?.emailOTPGenRes?.message !== undefined) {
      setGenOTPMessageType("success");
      setGenerateOtpSuccessMessage(emailOtpGen?.emailOTPGenRes?.message);
      setIsDisableOTPField(false);
      setIsDisableFastnameField(false);
      setIsDisableLastnameField(false);
      setIsDisablePassword(false);
      setButtonText(`${t("loginpage.resendotp", { ns: ["home"] })}`);
      setGenerateOtpErrorMessage("");
    }
    if (emailOtpGen?.errorMsg !== "") {
      setGenOTPMessageType("success");
      setGenerateOtpErrorMessage(emailOtpGen?.errorMsg);
      setButtonText(`${t("loginpage.sendotp", { ns: ["home"] })}`);
      setGenerateOtpSuccessMessage("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailOtpGen]);

  const signupBody = {
    email: email.toLowerCase(),
    password: String(password.password),
    first_name: firstname,
    last_name: lastname,
    otp: otp,
  } as EmailOtpSignUpBody;

  const handleSignup = () => {
    if (email === "") {
      setErrorMail(`${t("loginpage.emailorphnnotempty", { ns: ["home"] })}`);
      setErrorOtp("");
      setErrorFstName("");
      setErrorLstName("");
    } else if (otp === "") {
      setErrorOtp(`${t("loginpage.entervalidotp", { ns: ["home"] })}`);
      setErrorFstName(`${t("signup.enterfname", { ns: ["home"] })}`);
      setErrorLstName(`${t("signup.enterlname", { ns: ["home"] })}`);
    } else if (firstname === "") {
      setErrorOtp("");
      setErrorFstName(`${t("signup.enterfname", { ns: ["home"] })}`);
      setErrorLstName(`${t("signup.enterlname", { ns: ["home"] })}`);
    } else if (lastname === "") {
      setErrorOtp("");
      setErrorFstName("");
      setErrorLstName(`${t("signup.enterlname", { ns: ["home"] })}`);
    } else if (String(password.password) === "") {
      setErrorOtp("");
      setErrorFstName("");
      setErrorLstName("");
      setErrorPass(`${t("loginpage.plsenterpass", { ns: ["home"] })}`);
    } else {
      dispatch(emailOtpSignupApi(signupBody));
    }
  };

  useEffect(() => {
    setEmailSignupLoading(emailOtpSignup?.loading);
    if (emailOtpSignup?.emailOTPSignUpRes.message !== undefined) {
      setsignsuccessMessage(emailOtpSignup?.emailOTPSignUpRes.message);
      setGenerateOtpErrorMessage("");
      setGenerateOtpSuccessMessage("");
    } else {
      setGenerateOtpErrorMessage("");
      setGenerateOtpSuccessMessage("");
      setsignsuccessMessage("");
      setError(emailOtpSignup?.errorMsg);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailOtpSignup]);

  useEffect(() => {
    emailOtpSignup?.loading ? setIsLoading(true) : setIsLoading(false);
  }, [emailOtpSignup]);

  const actualPageMarkup = (
    <Box>
      <MWTextField
        fullWidth
        sx={{ my: 2 }}
        size="medium"
        value={email}
        error={errorEmail === "" ? false : true}
        label={t("loginpage.emailorphno", { ns: ["home"] })}
        placeholder={t("signup.emailmsg", { ns: ["home"] })!}
        id="emailFieldID"
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setEmail(event.target.value);
          setErrorMail("");
        }}
        helperText={
          errorEmail === ""
            ? `${t("loginpage.provEmailOrPhn", { ns: ["home"] })}`
            : errorEmail
        }
      />
      <Button
        fullWidth
        variant="contained"
        size="large"
        onClick={() => generateOtpApicall()}
        sx={{ textTransform: "none" }}
      >
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <Grid item>{buttonText}</Grid>
          {emailOTPGenLoading ? (
            <Grid item>
              <CircularProgress size={20} style={{ color: "#ffffff" }} />
            </Grid>
          ) : null}
        </Grid>
      </Button>
      <Box>{otpsuccessDiv}</Box>
      <Box>{otpErrorDiv}</Box>
      <MWTextField
        fullWidth
        sx={{ my: 2 }}
        value={otp}
        error={errorOtp === "" ? false : true}
        label={t("loginpage.otp", { ns: ["home"] })}
        placeholder={t("loginpage.enterotp", { ns: ["home"] })!}
        id="OTPFieldID"
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setOtp(event.target.value);
        }}
        disabled={isDisableOTPField}
        size="medium"
        helperText={
          errorOtp === ""
            ? `${t("loginpage.provOtp", { ns: ["home"] })}`
            : errorOtp
        }
      />
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <MWTextField
            value={firstname}
            label={t("signup.fname", { ns: ["home"] })}
            placeholder={t("signup.enterfname", { ns: ["home"] })!}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setFirstname(event.target.value);
            }}
            disabled={isDisableFastnameField}
            id="firstnameID"
            error={errorFirstName === "" ? false : true}
            size="medium"
            helperText={
              errorFirstName === ""
                ? `${t("signup.provFirstNm", { ns: ["home"] })}`
                : errorFirstName
            }
          />
        </Grid>
        <Grid item xs={6}>
          <MWTextField
            value={lastname}
            label={t("signup.lname", { ns: ["home"] })}
            placeholder={t("signup.enterlname", { ns: ["home"] })!}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setLastname(event.target.value);
            }}
            error={errorLastName === "" ? false : true}
            size="medium"
            helperText={
              errorLastName === ""
                ? `${t("signup.provLastNm", { ns: ["home"] })}`
                : errorLastName
            }
            disabled={isDisableLastnameField}
            id="lastnameID"
          />
        </Grid>
      </Grid>
      <FormControl sx={{ my: 2 }} fullWidth variant="outlined">
        <InputLabel size="normal" htmlFor="outlined-adornment-password">
          {t("loginpage.enterpassword", { ns: ["home"] })}
        </InputLabel>
        <OutlinedInput
          id="outlined-adornment-password"
          size="medium"
          placeholder={t("signup.psworddetail", { ns: ["home"] })!}
          label={t("signup.psworddetail", { ns: ["home"] })!}
          type={password.showPassword ? "text" : "Password"}
          value={password.password}
          onChange={handleChange("password")}
          disabled={isDisablePassword}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                disabled={isDisablePassword}
                edge="end"
                size="medium"
              >
                {password.showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
          error={errorPasswort === "" ? false : true}
        />
        {errorPasswort === "" ? (
          <FormHelperText>
            {t("loginpage.plsenterpass", { ns: ["home"] })}
          </FormHelperText>
        ) : (
          errorPasswort
        )}
      </FormControl>
      <Typography variant="caption">
        {t("signup.byclickreg", { ns: ["home"] })}&nbsp;
        <Link
          href={`${Url?.redirectUrl}/termsofuse/`}
          target="_blank"
          rel="noreferrer"
          underline="none"
        >
          {t("loginpage.termofuse", { ns: ["home"] })}
        </Link>
        &nbsp;and&nbsp;
        <Link
          href={`${Url?.redirectUrl}/privacystatement/`}
          target="_blank"
          rel="noreferrer"
          underline="none"
        >
          {t("loginpage.privacypolicy", { ns: ["home"] })}.
        </Link>
      </Typography>
      <Box>
        <Button
          fullWidth
          onClick={handleSignup}
          disabled={isDisableLastnameField}
          variant="contained"
          size="large"
        >
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <Grid item> {t("loginpage.register", { ns: ["home"] })!}</Grid>
            {emailSignupLoading ? (
              <Grid item>
                <CircularProgress size={20} style={{ color: "#ffffff" }} />
              </Grid>
            ) : null}
          </Grid>
        </Button>
      </Box>
      <Box>{successDiv}</Box>
      <Box>{errorDiv}</Box>
      <hr />
      <Typography>
        {t(`loginpage.olduser`, { ns: ["home"] })} &nbsp;&nbsp;
        <Button variant="outlined" href="/signin">
          {t("loginpage.login", { ns: ["home"] })!}
        </Button>
      </Typography>
    </Box>
  );
  return (
    <Container maxWidth="xl">
      <OnboardingLayout>{actualPageMarkup}</OnboardingLayout>
      {sectionForm}
    </Container>
  );
}
