import {
  PATIENT_LIST_FAIL,
  PATIENT_LIST_LOADING,
  PATIENT_LIST_SUCCESS,
  PATIENT_LIST_API_MSG,
  PATIENT_LIST_API_RES,
  PatientListActionTypes,
} from "./ActionTypes";
import { PatientDetailsResponse } from "./Model";

export const PatientListLoadingAction = (loading: boolean): PatientListActionTypes => {
  return {
    type: PATIENT_LIST_LOADING,
    loading: loading,
  };
};

export const PatientListSuccessAction = (
  PatientListResponse: PatientDetailsResponse,
  successMsg: string
): PatientListActionTypes => {
  return {
    type: PATIENT_LIST_SUCCESS,
    payload: PatientListResponse,
    successMsg: successMsg,
  };
};

export const PatientListErrorAction = (
  PatientListResponse: PatientDetailsResponse,
  errMsg: string,
  status: number
): PatientListActionTypes => {
  return {
    type: PATIENT_LIST_FAIL,
    payload: PatientListResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const PatientListAPIMsgAction = (
  PatientListResponse: PatientDetailsResponse,
  successMsg: string,
  errMsg: string,
  status: number
): PatientListActionTypes => {
  return {
    type: PATIENT_LIST_API_MSG,
    payload: PatientListResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const PatientListAPIResClearAction = (
  PatientListResponse: PatientDetailsResponse,
  successMsg: string,
  errMsg: string,
  status: number
): PatientListActionTypes => {
  return {
    type: PATIENT_LIST_API_RES,
    payload: PatientListResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
