import axios from "axios";
import { Dispatch } from "redux";
import Url from "../../../Url";
import { GetStepsDetailsActionTypes } from "./ActionTypes";
import { GetStepsDetailsRes } from "./Model";
import {
  getStepsDetailsAPIResClearAction,
  getStepsDetailsErrorAction,
  getStepsDetailsLoadingAction,
  getStepsDetailsSuccessAction,
  getStepsDetailsAPIMsgAction,
} from "./Action";
import { headers } from "../../../components/Utility";
import { setupInterceptorsTo } from "../../Authentication/Interceptors";

let apiRes = {} as GetStepsDetailsRes;
export const getStepsDetails = () => {
  return function (dispatch: Dispatch<GetStepsDetailsActionTypes>) {
    dispatch(getStepsDetailsLoadingAction(true));
    setupInterceptorsTo(axios)
      .get(
        Url.baseUrl + `/secondopinionapp/api/patientcaseui/steps_and_sections`,

        {
          headers: headers,
        }
      )
      .then((res) => {
        apiRes = res.data;
        dispatch(getStepsDetailsLoadingAction(false));
        dispatch(
          getStepsDetailsSuccessAction(
            res.data,
            res.data.message !== undefined
              ? res.data.message
              : "Patient case details steps has been fetched successfully"
          )
        );
      })
      .catch((error) => {
        dispatch(getStepsDetailsLoadingAction(false));
        dispatch(
          getStepsDetailsErrorAction(
            {} as GetStepsDetailsRes,
            error.response.data !== undefined
              ? error.response.data.message !== undefined
                ? error.response.data.message
                : "Something went wrong"
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0
          )
        );
      });
  };
};

export const updateGetStepsDetailsAPIResMsg = () => {
  return function (dispatch: Dispatch<GetStepsDetailsActionTypes>) {
    dispatch(
      getStepsDetailsAPIMsgAction(apiRes as GetStepsDetailsRes, "", "", 0)
    );
  };
};

export const clearGetStepsDetailsAPIRes = () => {
  return function (dispatch: Dispatch<GetStepsDetailsActionTypes>) {
    dispatch(
      getStepsDetailsAPIResClearAction({} as GetStepsDetailsRes, "", "", 0)
    );
  };
};
