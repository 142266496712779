import {
  AlertColor,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../redux/store/Store";
import {
  PaymentList,
  PaymentListBody,
} from "../../../../redux/appointmentPayment/AppointmentPaymentList/Model";
import dayjs from "dayjs";
import { t } from "i18next";
import { ModifiedAppointmentPaymentList } from "./Model";
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridToolbar,
} from "@mui/x-data-grid";
import MWExceptionList from "../../../../components/MWExceptionList";
import PaymentIcon from "@mui/icons-material/Payment";
import RefreshIcon from "@mui/icons-material/Refresh";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import VerifiedIcon from "@mui/icons-material/Verified";
import { useParams } from "react-router-dom";
import { getExistingAppointmentAndPaymentDetails } from "../../../../redux/appointmentPayment/AppointmentPaymentList/API";
import { paymentStatus } from "../../../../redux/appointmentPayment/CheckPaymentStatusById/API";
import { CheckPaymentStatusReq } from "../../../../redux/appointmentPayment/CheckPaymentStatusById/Model";
import MWSnackbar from "../../../../components/MWSnackbar";

export default function AppointmentPaymentList() {
  const { caseid } = useParams() as {
    caseid: string;
  };
  const dispatch = useDispatch();
  const getAppointmentPaymentListRes = useSelector(
    (state: AppState) => state.getAppointmentPaymentListRes
  );
  const [paymentList, setPaymentList] = useState(
    [] as ModifiedAppointmentPaymentList[]
  );
  function paymentLink(response: any) {
    if (response !== null) {
      window.open(response, "_self");
    }
    else {
      alert("Payment link not found")
    }
  }
  
  useEffect(() => {
    if (
      getAppointmentPaymentListRes?.paymentListResponse?.paymentList !==
      undefined
    ) {
      if (
        getAppointmentPaymentListRes?.paymentListResponse?.paymentList.length >
        0
      ) {
        let paymentListItem =
          getAppointmentPaymentListRes?.paymentListResponse?.paymentList.map(
            (element: PaymentList) =>
            ({
              id: element?.id,
              amount:
                element?.amount !== undefined
                  ? element?.amount
                  : `${t("common.notSet", { ns: ["home"] })!}`,
              note:
                element?.note !== undefined
                  ? element?.note
                  : `${t("common.notSet", { ns: ["home"] })!}`,
              create_date:
                element?.create_date !== undefined
                  ? element?.create_date !== null
                    ? dayjs(element?.create_date).format("DD-MM-YYYY")
                    : `${t("common.notSet", { ns: ["home"] })!}`
                  : `${t("common.notSet", { ns: ["home"] })!}`,
              paymentLink:
                element?.paymentLink !== undefined
                  ? element?.paymentLink
                  : `${t("common.notSet", { ns: ["home"] })!}`,
              status:
                element?.status !== undefined
                  ? element?.status
                  : `${t("common.notSet", { ns: ["home"] })!}`,
              paidTime:
                element?.paidTime !== undefined
                  ? element?.paidTime !== null
                    ? dayjs(element?.paidTime).format("DD-MM-YYYY")
                    : `${t("common.notSet", { ns: ["home"] })!}`
                  : `${t("common.notSet", { ns: ["home"] })!}`,
            } as ModifiedAppointmentPaymentList)
          );
        setPaymentList(paymentListItem);
      } else setPaymentList([] as ModifiedAppointmentPaymentList[]);
    } else setPaymentList([] as ModifiedAppointmentPaymentList[]);
  }, [getAppointmentPaymentListRes]);
  const finalOpinionColumn: GridColDef[] = [
    {
      field: "note",
      headerName: `${t("appointment.desc", { ns: ["home"] })}`,
      minWidth: 120,
      flex: 1,
    },
    {
      field: "create_date",
      headerName: `${t("payment.cdate", { ns: ["home"] })}`,
      minWidth: 80,
    },
    {
      field: "amount",
      headerName: `${t("payment.amt", { ns: ["home"] })}`,
      minWidth: 120,
    },
    {
      field: "paymentLink",
      align: "center",
      headerName: `${t("payment.pay", { ns: ["home"] })}`,
      minWidth: 120,
      renderCell: (params: GridCellParams) => (
        <Button
          variant="outlined"
          disabled={
            checkPaymentStatusByIdRes?.loading ||
            params.row.status === "Paid" ||
            params.row.status === "Cancelled" ||
            params.row.status === "Expired" ||
            params.row.status === "COMPLETED"
          }
          onClick={() => {
            paymentLink(params.row.paymentLink);
          }}
          sx={{ textTransform: "none" }}
        >
          <PaymentIcon />
          &nbsp;Pay Now
        </Button>
      ),
    },
    {
      field: "paidTime",
      headerName: `${t("payment.paidon", { ns: ["home"] })}`,
      minWidth: 120,
    },
    {
      field: "status",
      headerName: `${t("payment.payStat", { ns: ["home"] })}`,
      minWidth: 120,
    },
    {
      field: "id",
      align: "center",
      headerName: `${t("payment.chStat", { ns: ["home"] })}`,
      minWidth: 120,
      renderCell: (params: GridCellParams) => (
        <Button
          variant="outlined"
          disabled={checkPaymentStatusByIdRes?.loading}
          onClick={() => {
            dispatch(
              paymentStatus({
                paymentId: String(params.row.id),
              } as CheckPaymentStatusReq)
            );
          }}
          sx={{ textTransform: "none" }}
        >
          {params?.row.status === "issued" ? (
            <HourglassTopIcon />
          ) : params?.row.status === "completed" ? (
            <VerifiedIcon />
          ) : (
            <HourglassTopIcon />
          )}
        </Button>
      ),
    },
  ];
  const checkPaymentStatusByIdRes = useSelector(
    (state: AppState) => state.checkPaymentStatusById
  );
  const [checkPaymentStatusByIDMsgType, setCheckPaymentStatusByIDMsgType] =
    useState<AlertColor>("success");
  const [checkPaymentStatusByIDErrorMsg, setCheckPaymentStatusByIDErrorMsg] =
    useState("");

  useEffect(() => {
    if (checkPaymentStatusByIdRes?.successMsg !== "") {
      setCheckPaymentStatusByIDErrorMsg(checkPaymentStatusByIdRes?.successMsg);
      dispatch(
        getExistingAppointmentAndPaymentDetails({
          caseId: caseid,
        } as PaymentListBody)
      );
    } else if (checkPaymentStatusByIdRes?.errorMsg !== "") {
      setCheckPaymentStatusByIDErrorMsg(checkPaymentStatusByIdRes?.errorMsg);
      setCheckPaymentStatusByIDMsgType("error");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkPaymentStatusByIdRes]);
  const closeAlert = (passedVal: boolean) => {
    if (passedVal) {
      setCheckPaymentStatusByIDErrorMsg("");
      dispatch(
        getExistingAppointmentAndPaymentDetails({
          caseId: caseid,
        } as PaymentListBody)
      );
    }
  };
  return (
    <Box>
      <Card>
        <CardHeader
          title={
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
            >
              <Grid item>{t("payment.paylist", { ns: ["home"] })!}</Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  startIcon={<RefreshIcon />}
                  onClick={() =>
                    dispatch(
                      getExistingAppointmentAndPaymentDetails({
                        caseId: caseid,
                      } as PaymentListBody)
                    )
                  }
                  sx={{ textTransform: "none" }}
                >
                  {t("payment.reloadPayList", { ns: ["home"] })!}
                </Button>
              </Grid>
            </Grid>
          }
        />
        <CardContent>
          {paymentList.length > 0 ? (
            <DataGrid
              rows={paymentList}
              columns={finalOpinionColumn}
              pageSize={10}
              hideFooter
              hideFooterPagination
              disableSelectionOnClick
              experimentalFeatures={{ newEditingApi: true }}
              autoHeight
              components={{ Toolbar: GridToolbar }}
              componentsProps={{
                toolbar: {
                  csvOptions: { disableToolbarButton: true },
                  printOptions: { disableToolbarButton: true },
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 250 },
                },
              }}
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector
            />
          ) : (
            <MWExceptionList />
          )}
        </CardContent>
      </Card>
      {checkPaymentStatusByIDErrorMsg !== "" ? (
        <MWSnackbar
          type={checkPaymentStatusByIDMsgType}
          msg={checkPaymentStatusByIDErrorMsg}
          alertClose={closeAlert}
        />
      ) : null}
    </Box>
  );
}
