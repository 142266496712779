import {
  GET_MEETING_DETAILS_FAIL,
  GET_MEETING_DETAILS_LOADING,
  GET_MEETING_DETAILS_SUCCESS,
  GET_MEETING_DETAILS_API_MSG,
  GET_MEETING_DETAILS_API_RES,
  GetMeetingDetailsActionTypes,
} from "./ActionTypes";
import { GetMeetingDetailsRes } from "./Model";

export const getMeetingDetailsLoadingAction = (
  loading: boolean
): GetMeetingDetailsActionTypes => {
  return {
    type: GET_MEETING_DETAILS_LOADING,
    loading: loading,
  };
};

export const getMeetingDetailsSuccessAction = (
  getMeetingDetailsResponse: GetMeetingDetailsRes,
  successMsg: string
): GetMeetingDetailsActionTypes => {
  return {
    type: GET_MEETING_DETAILS_SUCCESS,
    payload: getMeetingDetailsResponse,
    successMsg: successMsg,
  };
};

export const getMeetingDetailsErrorAction = (
  getMeetingDetailsResponse: GetMeetingDetailsRes,
  errMsg: string,
  status: number
): GetMeetingDetailsActionTypes => {
  return {
    type: GET_MEETING_DETAILS_FAIL,
    payload: getMeetingDetailsResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const getMeetingDetailsAPIMsgAction = (
  getMeetingDetailsResponse: GetMeetingDetailsRes,
  successMsg: string,
  errMsg: string,
  status: number
): GetMeetingDetailsActionTypes => {
  return {
    type: GET_MEETING_DETAILS_API_MSG,
    payload: getMeetingDetailsResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const getMeetingDetailsAPIResClearAction = (
  getMeetingDetailsResponse: GetMeetingDetailsRes,
  successMsg: string,
  errMsg: string,
  status: number
): GetMeetingDetailsActionTypes => {
  return {
    type: GET_MEETING_DETAILS_API_RES,
    payload: getMeetingDetailsResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
