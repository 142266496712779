import React, { useEffect } from "react";
import { t } from "i18next";
import { Box, Grid, Tab, Typography } from "@mui/material";
import MWLoader from "../../components/MWLoader";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import PageLayout from "../Layout/PageLayout";
import AllDoctor from "./AllDoctorList";
import FindDoctor from "./FindDoctorList";
import { useDispatch, useSelector } from "react-redux";
import { activeDoctorListApi } from "../../redux/Doctor/ActiveDoctorList/API";
import { AppState } from "../../redux/store/Store";
import { searchDoctorApi } from "../../redux/Doctor/findDoctor/API";

export default function Doctor() {
  const dispatch = useDispatch();
  const [selected, setSelected] = React.useState("1");
  const handleTabChange = (_event: React.SyntheticEvent, newValue: string) => {
    setSelected(newValue);
  };
  const [loading, setLoading] = React.useState<boolean | undefined>(false);

  const LoadingState = (passedVal: boolean) => {
    setLoading(passedVal);
  };

  const sortCategoryValue = (sortValue: {}) => {
    dispatch(searchDoctorApi(sortValue));
  };
  const sortSpecialistValue = (sortValue: {}) => {
    dispatch(searchDoctorApi(sortValue));
  };
  const sortNameValue = (sortValue: {}) => {
    dispatch(searchDoctorApi(sortValue));
  };

  const doctorList = useSelector((state: AppState) => state.doctorValue);
  const searchdoctorList = useSelector((state: AppState) => state.searchDoctor);

  useEffect(() => {
    dispatch(activeDoctorListApi());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setLoading(doctorList?.loading);
  }, [doctorList]);

  useEffect(() => {
    setLoading(searchdoctorList?.loading);
  }, [searchdoctorList]);

  const actualPageMarkup = (
    <Grid>
      <Grid item>
        <Box sx={{ width: "100%" }}>
          <TabContext value={String(selected)}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList onChange={handleTabChange}>
                <Tab label={t("finddoc.alldoc", { ns: ["home"] })!} value="1" />
                <Tab
                  label={t("menu.finddoctor", { ns: ["home"] })!}
                  value="2"
                />
              </TabList>
            </Box>
            <TabPanel value="1">
              {
                <AllDoctor
                  doctorList={doctorList?.doctorDetailsResponse?.doctorsList}
                />
              }
            </TabPanel>
            <TabPanel value="2">
              {
                <FindDoctor
                  loading={LoadingState}
                  selectedCategoryValue={sortCategoryValue}
                  selectedSpecialistValue={sortSpecialistValue}
                  selectedNameValue={sortNameValue}
                  searchdoctorList={
                    searchdoctorList?.searchDoctorResponse?.doctorsList
                  }
                />
              }
            </TabPanel>
          </TabContext>
        </Box>
      </Grid>
    </Grid>
  );

  return (
    <>
      {loading ? <MWLoader /> : null}
      <PageLayout>
        <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
          {t("finddoc.doclist", { ns: ["home"] })}
        </Typography>
        {actualPageMarkup}
      </PageLayout>
    </>
  );
}
