import {
  PAYMENT_STATUS_FAIL,
  PAYMENT_STATUS_LOADING,
  PAYMENT_STATUS_SUCCESS,
  PAYMENT_STATUS_API_MSG,
  PAYMENT_STATUS_API_RES,
  PaymentStatusActionTypes,
} from "./ActionTypes";
import { CheckPaymentStatusRes } from "./Model";

export const paymentStatusLoadingAction = (
  loading: boolean
): PaymentStatusActionTypes => {
  return {
    type: PAYMENT_STATUS_LOADING,
    loading: loading,
  };
};

export const paymentStatusSuccessAction = (
  paymentStatusResponse: CheckPaymentStatusRes,
  successMsg: string
): PaymentStatusActionTypes => {
  return {
    type: PAYMENT_STATUS_SUCCESS,
    payload: paymentStatusResponse,
    successMsg: successMsg,
  };
};

export const paymentStatusErrorAction = (
  paymentStatusResponse: CheckPaymentStatusRes,
  errMsg: string,
  status: number
): PaymentStatusActionTypes => {
  return {
    type: PAYMENT_STATUS_FAIL,
    payload: paymentStatusResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const paymentStatusAPIMsgAction = (
  paymentStatusResponse: CheckPaymentStatusRes,
  successMsg: string,
  errMsg: string,
  status: number
): PaymentStatusActionTypes => {
  return {
    type: PAYMENT_STATUS_API_MSG,
    payload: paymentStatusResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const paymentStatusAPIResClearAction = (
  paymentStatusResponse: CheckPaymentStatusRes,
  successMsg: string,
  errMsg: string,
  status: number
): PaymentStatusActionTypes => {
  return {
    type: PAYMENT_STATUS_API_RES,
    payload: paymentStatusResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
