import { Box, Button, Grid, Typography } from "@mui/material";
import { t } from "i18next";
import * as React from "react";
import { useHistory, useParams } from "react-router-dom";
import PatientFlow from "../../src/components/image/design-assets/patient-flow-chart.png";

export default function CaseHistory() {
  const history = useHistory();
  const { patientid } = useParams() as {
    patientid: string;
  };

  const handleAddEncounterToggle = () => {
    history.push(`/casedetails/${patientid}?&tab=consultationType`);
  };
  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      spacing={2}
    >
      <Grid item xs={7} sm={7} md={7} lg={7}>
        <Box pt={5} mt={5}>
          <Typography variant="h4" gutterBottom>
            {t("perHealthRecPage.seekCon", { ns: ["home"] })}
          </Typography>
          <Typography variant="h6" gutterBottom>
            {t("perHealthRecPage.oneStopSol", { ns: ["home"] })}
          </Typography>
          <Box>
            <Button
              variant="contained"
              onClick={() => handleAddEncounterToggle()}
            >
              {t("common.addCase", { ns: ["home"] })}
            </Button>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={5} sm={5} md={5} lg={5}>
        <Box pt={5} mt={5}>
          <img
            width="100%"
            height="100%"
            style={{
              objectFit: "cover",
              objectPosition: "center",
            }}
            src={PatientFlow}
            alt=""
          />
        </Box>
      </Grid>
    </Grid>
  );
}
