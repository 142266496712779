import {
  OPINION_TYPE_FAIL,
  OPINION_TYPE_LOADING,
  OPINION_TYPE_SUCCESS,
  OPINION_TYPE_API_MSG,
  OPINION_TYPE_API_RES,
  OpinionTypeActionTypes,
} from "./ActionTypes";
import { OpinionTypeListResponse } from "./Model";

export const opinionTypeLoadingAction = (loading: boolean): OpinionTypeActionTypes => {
  return {
    type: OPINION_TYPE_LOADING,
    loading: loading,
  };
};

export const opinionTypeSuccessAction = (
  opinionTypeResponse: OpinionTypeListResponse,
  successMsg: string
): OpinionTypeActionTypes => {
  return {
    type: OPINION_TYPE_SUCCESS,
    payload: opinionTypeResponse,
    successMsg: successMsg,
  };
};

export const opinionTypeErrorAction = (
  opinionTypeResponse: OpinionTypeListResponse,
  errMsg: string,
  status: number
): OpinionTypeActionTypes => {
  return {
    type: OPINION_TYPE_FAIL,
    payload: opinionTypeResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const opinionTypeAPIMsgAction = (
  opinionTypeResponse: OpinionTypeListResponse,
  successMsg: string,
  errMsg: string,
  status: number
): OpinionTypeActionTypes => {
  return {
    type: OPINION_TYPE_API_MSG,
    payload: opinionTypeResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const opinionTypeAPIResClearAction = (
  opinionTypeResponse: OpinionTypeListResponse,
  successMsg: string,
  errMsg: string,
  status: number
): OpinionTypeActionTypes => {
  return {
    type: OPINION_TYPE_API_RES,
    payload: opinionTypeResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
