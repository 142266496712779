import { CheckPaymentStatusRes } from "./Model";
export const PAYMENT_STATUS_LOADING = "PAYMENT_STATUS_LOADING";
export const PAYMENT_STATUS_SUCCESS = "PAYMENT_STATUS_SUCCESS";
export const PAYMENT_STATUS_FAIL = "PAYMENT_STATUS_FAIL";
export const PAYMENT_STATUS_API_MSG = "PAYMENT_STATUS_API_MSG";
export const PAYMENT_STATUS_API_RES = "PAYMENT_STATUS_API_RES";

export interface PaymentStatusState {
  paymentStatusResponse: CheckPaymentStatusRes;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface PaymentStatusLoading {
  type: typeof PAYMENT_STATUS_LOADING;
  loading: boolean;
}
export interface PaymentStatusSuccess {
  type: typeof PAYMENT_STATUS_SUCCESS;
  payload: CheckPaymentStatusRes;
  successMsg: string;
}
export interface PaymentStatusFail {
  type: typeof PAYMENT_STATUS_FAIL;
  payload: CheckPaymentStatusRes;
  errorMsg: string;
  status: number;
}
export interface PaymentStatusAPIMsg {
  type: typeof PAYMENT_STATUS_API_MSG;
  payload: CheckPaymentStatusRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface PaymentStatusAPIRes {
  type: typeof PAYMENT_STATUS_API_RES;
  payload: CheckPaymentStatusRes;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type PaymentStatusActionTypes =
  | PaymentStatusLoading
  | PaymentStatusSuccess
  | PaymentStatusFail
  | PaymentStatusAPIMsg
  | PaymentStatusAPIRes;
