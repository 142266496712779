import {
  GET_APPOINTMENT_DETAILS_FOR_CASE_FAIL,
  GET_APPOINTMENT_DETAILS_FOR_CASE_LOADING,
  GET_APPOINTMENT_DETAILS_FOR_CASE_SUCCESS,
  GET_APPOINTMENT_DETAILS_FOR_CASE_UPDATE_API_MSG,
  GET_APPOINTMENT_DETAILS_FOR_CASE_UPDATE_API_RES,
  GetAppointmentDetailsForCaseActionTypes,
} from "./ActionTypes";
import { GetAppointmentDetailsForCaseRes } from "./Model";

export const getAppointmentDetailsForCaseLoadingAction = (
  loading: boolean
): GetAppointmentDetailsForCaseActionTypes => {
  return {
    type: GET_APPOINTMENT_DETAILS_FOR_CASE_LOADING,
    loading: loading,
  };
};

export const getAppointmentDetailsForCaseSuccessAction = (
  getAppointmentDetailsForCaseResponse: GetAppointmentDetailsForCaseRes,
  successMsg: string
): GetAppointmentDetailsForCaseActionTypes => {
  return {
    type: GET_APPOINTMENT_DETAILS_FOR_CASE_SUCCESS,
    payload: getAppointmentDetailsForCaseResponse,
    successMsg: successMsg,
  };
};

export const getAppointmentDetailsForCaseErrorAction = (
  getAppointmentDetailsForCaseResponse: GetAppointmentDetailsForCaseRes,
  errMsg: string,
  status: number
): GetAppointmentDetailsForCaseActionTypes => {
  return {
    type: GET_APPOINTMENT_DETAILS_FOR_CASE_FAIL,
    payload: getAppointmentDetailsForCaseResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const getAppointmentDetailsForCaseUpdateAPIMsgAction = (
  getAppointmentDetailsForCaseResponse: GetAppointmentDetailsForCaseRes,
  successMsg: string,
  errMsg: string,
  status: number
): GetAppointmentDetailsForCaseActionTypes => {
  return {
    type: GET_APPOINTMENT_DETAILS_FOR_CASE_UPDATE_API_MSG,
    payload: getAppointmentDetailsForCaseResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const getAppointmentDetailsForCaseAPIResClearAction = (
  getAppointmentDetailsForCaseResponse: GetAppointmentDetailsForCaseRes,
  successMsg: string,
  errMsg: string,
  status: number
): GetAppointmentDetailsForCaseActionTypes => {
  return {
    type: GET_APPOINTMENT_DETAILS_FOR_CASE_UPDATE_API_RES,
    payload: getAppointmentDetailsForCaseResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
