import {
  ResponseTimeActionTypes,
  RESPONSE_TIME_FAIL,
  RESPONSE_TIME_LOADING,
  RESPONSE_TIME_SUCCESS,
  ResponseTimeState,
  RESPONSE_TIME_API_MSG,
  RESPONSE_TIME_API_RES,
} from "./ActionTypes";
import { ResponseTimeResponse } from "./Model";

const initialStateEditPosts: ResponseTimeState = {
  loading: false,
  responseTimeResponse: {} as ResponseTimeResponse,
  successMsg: "",
  errorMsg: "",
  status: 0,
};
export const responseTimeReducer = (
  state = initialStateEditPosts,
  action: ResponseTimeActionTypes
): ResponseTimeState => {
  switch (action.type) {
    case RESPONSE_TIME_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case RESPONSE_TIME_SUCCESS:
      return {
        ...state,
        responseTimeResponse: action.payload,
        successMsg: action.successMsg,
      };
    case RESPONSE_TIME_FAIL:
      return {
        ...state,
        responseTimeResponse: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case RESPONSE_TIME_API_MSG:
      return {
        ...state,
        responseTimeResponse: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case RESPONSE_TIME_API_RES:
      return {
        ...state,
        responseTimeResponse: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
