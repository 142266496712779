import { SetEmailPhoneValue } from "./Model";

// Patient Meeting Loading State
export const SET_EMAIL_PHONE_LOADING =
  "SET_EMAIL_PHONE_LOADING";
export const SET_EMAIL_PHONE_SUCCESS =
  "SET_EMAIL_PHONE_SUCCESS";
export const SET_EMAIL_PHONE_FAIL = "SET_EMAIL_PHONE_FAIL";
export const SET_EMAIL_PHONE_LIST = "SET_EMAIL_PHONE_LIST";

export interface SetEmailPhoneDetails {
  setEmailPhoneRes: SetEmailPhoneValue;
  loading: boolean;
  errRes: string;
}
export interface SetEmailPhoneLoading {
  type: typeof SET_EMAIL_PHONE_LOADING;
}
export interface SetEmailPhoneSuccess {
  type: typeof SET_EMAIL_PHONE_SUCCESS;
  payload: SetEmailPhoneValue;
  errRes: string;
}
export interface SetEmailPhoneFail {
  type: typeof SET_EMAIL_PHONE_FAIL;
  errRes: string;
}

interface SetEmailPhoneAction {
  type: typeof SET_EMAIL_PHONE_LIST;
  payload: SetEmailPhoneValue;
  errRes: string;
}

export type setEmailPhoneActionTypes =
  | SetEmailPhoneAction
  | SetEmailPhoneLoading
  | SetEmailPhoneSuccess
  | SetEmailPhoneFail;
