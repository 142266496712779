import {
  AnswerDoctorQuestionActionTypes,
  ANSWER_DOCTOR_QUESTION_FAIL,
  ANSWER_DOCTOR_QUESTION_LOADING,
  ANSWER_DOCTOR_QUESTION_SUCCESS,
  AnswerDoctorQuestionState,
  ANSWER_DOCTOR_QUESTION_API_MSG,
  ANSWER_DOCTOR_QUESTION_API_RES,
} from "./ActionTypes";
import { AnsDoctorQuestionRes } from "./Model";

const initialStateEditPosts: AnswerDoctorQuestionState = {
  loading: false,
  answerDoctorQuestionRes: {} as AnsDoctorQuestionRes,
  successMsg: "",
  errorMsg: "",
  status: 0,
};
export const answerDoctorQuestionReducer = (
  state = initialStateEditPosts,
  action: AnswerDoctorQuestionActionTypes
): AnswerDoctorQuestionState => {
  switch (action.type) {
    case ANSWER_DOCTOR_QUESTION_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case ANSWER_DOCTOR_QUESTION_SUCCESS:
      return {
        ...state,
        answerDoctorQuestionRes: action.payload,
        successMsg: action.successMsg,
      };
    case ANSWER_DOCTOR_QUESTION_FAIL:
      return {
        ...state,
        answerDoctorQuestionRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case ANSWER_DOCTOR_QUESTION_API_MSG:
      return {
        ...state,
        answerDoctorQuestionRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case ANSWER_DOCTOR_QUESTION_API_RES:
      return {
        ...state,
        answerDoctorQuestionRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
