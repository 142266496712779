import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  clearDeleteDoctorForCaseAPIRes,
  deleteDoctorForCase,
  updateDeleteDoctorForCaseAPIResMsg,
} from "../../../../../redux/AppointmentSelectDoctor/DeleteDoctor/API";
import MWLoader from "../../../../../components/MWLoader";
import { DeleteDoctorForCaseBody } from "../../../../../redux/AppointmentSelectDoctor/DeleteDoctor/Model";
import { AppState } from "../../../../../redux/store/Store";
import { t } from "i18next";

type Props = {
  itemId?: string | any;
  name?: string | any;
  deleteDoctorDialogEnable?: boolean | any;
  deleteDoctorDialogUpdateState?: boolean | any;
};

export default function DeleteDoctor({
  itemId,
  name,
  deleteDoctorDialogEnable,
  deleteDoctorDialogUpdateState,
}: Props) {
  const { caseid } = useParams() as {
    caseid: string;
  };
  const dispatch = useDispatch();
  const [selectedItem, setSelectedItem] = React.useState("");
  const [dialogState, setDialogState] = React.useState(false);
  const handleClose = () => {
    setDialogState(false);
    deleteDoctorDialogUpdateState(false);
    dispatch(clearDeleteDoctorForCaseAPIRes());
  };
  useEffect(() => {
    setSelectedItem(itemId);
    setDialogState(deleteDoctorDialogEnable);
  }, [itemId, deleteDoctorDialogEnable]);

  const deleteDoctorForCaseRes = useSelector(
    (state: AppState) => state.deleteDoctorForCaseRes
  );
  const [deleteDocErrMsg, setDeleteDocErrMsg] = useState("");
  useEffect(() => {
    if (deleteDoctorForCaseRes?.successMsg !== "") {
      setDialogState(false);
      deleteDoctorDialogUpdateState(false);
    }
    if (deleteDoctorForCaseRes?.errorMsg !== "") {
      setDeleteDocErrMsg(deleteDoctorForCaseRes?.errorMsg);
    }
    if (deleteDoctorForCaseRes?.errorMsg === "") {
      setDeleteDocErrMsg("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteDoctorForCaseRes]);

  return (
    <Dialog open={dialogState} onClose={handleClose}>
      {deleteDoctorForCaseRes?.loading ? <MWLoader /> : null}
      <DialogTitle>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>{t("selDocPage.delDoc", { ns: ["home"] })!}</Grid>
          <Grid item>
            <IconButton onClick={() => handleClose()}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <DialogContentText>
          <Typography style={{ fontWeight: "bold" }}>
            Are you sure, you want to delete this "{name}" from your doctor team
            ?
          </Typography>
        </DialogContentText>
        {deleteDocErrMsg !== "" ? (
          <Box my={2}>
            <Alert
              severity="error"
              onClose={() => {
                dispatch(updateDeleteDoctorForCaseAPIResMsg());
              }}
            >
              <AlertTitle>{deleteDocErrMsg}</AlertTitle>
            </Alert>
          </Box>
        ) : null}
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          {t("common.no", { ns: ["home"] })!}
        </Button>
        <Button
          variant="contained"
          size="medium"
          disabled={deleteDoctorForCaseRes?.loading}
          onClick={() =>
            dispatch(
              deleteDoctorForCase({
                caseId: caseid,
                doctorId: selectedItem,
              } as DeleteDoctorForCaseBody)
            )
          }
        >
          {t("common.yes", { ns: ["home"] })!}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
