import {
  GetChiefComplaintStateType,
  ChiefComplaintGetActionTypes,
  CC_LIST_SUCCESS,
  CC_LIST_LOADING,
  CC_LIST_FAIL,
  AddChiefComplaintTypes,
  SetAddChiefComplaintStateType,
  CC_ADD_LIST_SUCCESS,
  CC_ADD_LIST_LOADING,
  CC_ADD_LIST_FAIL,
  DeleteDocumentTypes,
  SetDeleteDocumentStateType,
  DELETE_DOCUMENT_LIST_SUCCESS,
  DELETE_DOCUMENT_LIST_LOADING,
  DELETE_DOCUMENT_LIST_FAIL,
  GetTaskStateType,
  TaskGetActionTypes,
  TASK_LIST_LOADING,
  TASK_LIST_SUCCESS,
  TASK_LIST_FAIL,
  CASE_APPOINMENT_LIST_SUCCESS,
  CASE_APPOINMENT_LIST_LOADING,
  CASE_APPOINMENT_LIST_FAIL,
  CaseAppoinmentListActionTypes,
  CaseAppoinmentStateType,
} from '../types/ActionTypes';

const initialStateGetCC: GetChiefComplaintStateType = { loading: false, ccValue: [] };
const initialStateGetCCAdd: SetAddChiefComplaintStateType = { loading: false, addCC: [], response: Object.assign({}) };
const initialStateGetDeleteDocument: SetDeleteDocumentStateType = { loading: false, deleteDoc: [], response: Object.assign({}) };
// Initial Task State
const initialStateGetTask: GetTaskStateType = { loading: false, taskValue: [] };

// Initial patient case Appoinment List State
const initialStateGetCaseAppoinmentList: CaseAppoinmentStateType = { loading: false, CaseAppoinmentListValue: [] };

export const getCCReducer = (state = initialStateGetCC, action: ChiefComplaintGetActionTypes): GetChiefComplaintStateType => {
  switch (action.type) {
    case CC_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        ccValue: action.payload
      };
    case CC_LIST_LOADING:
      return {
        ...state,
        loading: true,
        ccValue: []
      };
    case CC_LIST_FAIL:
      return {
        ...state,
        ccValue: [],
        loading: false
      };
    default:
      return state;
  }
};

export const addCCReducer = (state = initialStateGetCCAdd, action: AddChiefComplaintTypes): SetAddChiefComplaintStateType => {
  switch (action.type) {
    case CC_ADD_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        response: action.payload,
      };
    case CC_ADD_LIST_LOADING:
      return {
        ...state,
        loading: true,
        addCC: []
      };
    case CC_ADD_LIST_FAIL:
      return {
        ...state,
        addCC: [],
        response: action.payload,
        loading: false
      };
    default:
      return state;
  }
};

export const deleteDocumentReducer = (state = initialStateGetDeleteDocument, action: DeleteDocumentTypes): SetDeleteDocumentStateType => {
  switch (action.type) {
    case DELETE_DOCUMENT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        response: action.payload,
      };
    case DELETE_DOCUMENT_LIST_LOADING:
      return {
        ...state,
        loading: true,
        deleteDoc: []
      };
    case DELETE_DOCUMENT_LIST_FAIL:
      return {
        ...state,
        deleteDoc: [],
        loading: false,
        response: action.payload,
      };
    default:
      return state;
  }
};

// Get  Task List Reducer
export const getTaskReducer = (state = initialStateGetTask, action: TaskGetActionTypes): GetTaskStateType => {
  switch (action.type) {
    case TASK_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        taskValue: action.payload
      };
    case TASK_LIST_LOADING:
      return {
        ...state,
        loading: true,
        taskValue: []
      };
    case TASK_LIST_FAIL:
      return {
        ...state,
        taskValue: [],
        loading: false
      };
    default:
      return state;
  }
};

// Get  patient case Appoinment List Reducer
export const getCaseAppoinmentListReducer = (state = initialStateGetCaseAppoinmentList, action: CaseAppoinmentListActionTypes): CaseAppoinmentStateType => {
  switch (action.type) {
    case CASE_APPOINMENT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        CaseAppoinmentListValue: action.payload
      };
    case CASE_APPOINMENT_LIST_LOADING:
      return {
        ...state,
        loading: true,
        CaseAppoinmentListValue: []
      };
    case CASE_APPOINMENT_LIST_FAIL:
      return {
        ...state,
        CaseAppoinmentListValue: [],
        loading: false
      };
    default:
      return state;
  }
};

