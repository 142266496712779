import { OwnerPaymentListResponse } from "./Model";
export const OWNER_PAYMENT_LIST_LOADING = "OWNER_PAYMENT_LIST_LOADING";
export const OWNER_PAYMENT_LIST_SUCCESS = "OWNER_PAYMENT_LIST_SUCCESS";
export const OWNER_PAYMENT_LIST_FAIL = "OWNER_PAYMENT_LIST_FAIL";
export const OWNER_PAYMENT_LIST_API_MSG = "OWNER_PAYMENT_LIST_API_MSG";
export const OWNER_PAYMENT_LIST_API_RES = "OWNER_PAYMENT_LIST_API_RES";

export interface OwnerPaymentListState {
  ownerPaymentListResponse: OwnerPaymentListResponse;
  loading: boolean;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface OwnerPaymentListLoading {
  type: typeof OWNER_PAYMENT_LIST_LOADING;
  loading: boolean;
}
export interface OwnerPaymentListSuccess {
  type: typeof OWNER_PAYMENT_LIST_SUCCESS;
  payload: OwnerPaymentListResponse;
  successMsg: string;
}
export interface OwnerPaymentListFail {
  type: typeof OWNER_PAYMENT_LIST_FAIL;
  payload: OwnerPaymentListResponse;
  errorMsg: string;
  status: number;
}
export interface OwnerPaymentListAPIMsg {
  type: typeof OWNER_PAYMENT_LIST_API_MSG;
  payload: OwnerPaymentListResponse;
  successMsg: string;
  errorMsg: string;
  status: number;
}
export interface OwnerPaymentListAPIRes {
  type: typeof OWNER_PAYMENT_LIST_API_RES;
  payload: OwnerPaymentListResponse;
  successMsg: string;
  errorMsg: string;
  status: number;
}

export type OwnerPaymentListActionTypes =
  | OwnerPaymentListLoading
  | OwnerPaymentListSuccess
  | OwnerPaymentListFail
  | OwnerPaymentListAPIMsg
  | OwnerPaymentListAPIRes;
