import {
  OWNER_APPOINTMENT_LIST_FAIL,
  OWNER_APPOINTMENT_LIST_LOADING,
  OWNER_APPOINTMENT_LIST_SUCCESS,
  OWNER_APPOINTMENT_LIST_UPDATE_API_MSG,
  OWNER_APPOINTMENT_LIST_UPDATE_API_RES,
  OwnerAppointmentListActionTypes,
} from "./ActionTypes";
import { OwnerAppointmentResponse } from "./Model";

export const ownerAppointmentListLoadingAction = (
  loading: boolean
): OwnerAppointmentListActionTypes => {
  return {
    type: OWNER_APPOINTMENT_LIST_LOADING,
    loading: loading,
  };
};

export const ownerAppointmentListSuccessAction = (
  ownerAppointmentListResponse: OwnerAppointmentResponse,
  successMsg: string
): OwnerAppointmentListActionTypes => {
  return {
    type: OWNER_APPOINTMENT_LIST_SUCCESS,
    payload: ownerAppointmentListResponse,
    successMsg: successMsg,
  };
};

export const ownerAppointmentListErrorAction = (
  ownerAppointmentListResponse: OwnerAppointmentResponse,
  errMsg: string,
  status: number
): OwnerAppointmentListActionTypes => {
  return {
    type: OWNER_APPOINTMENT_LIST_FAIL,
    payload: ownerAppointmentListResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const ownerAppointmentListUpdateAPIMsgAction = (
  ownerAppointmentListResponse: OwnerAppointmentResponse,
  successMsg: string,
  errMsg: string,
  status: number
): OwnerAppointmentListActionTypes => {
  return {
    type: OWNER_APPOINTMENT_LIST_UPDATE_API_MSG,
    payload: ownerAppointmentListResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const ownerAppointmentListAPIResClearAction = (
  ownerAppointmentListResponse: OwnerAppointmentResponse,
  successMsg: string,
  errMsg: string,
  status: number
): OwnerAppointmentListActionTypes => {
  return {
    type: OWNER_APPOINTMENT_LIST_UPDATE_API_RES,
    payload: ownerAppointmentListResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
