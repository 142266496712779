import axios from "axios";
import { Dispatch } from "redux";
import baseUrl from "../../Url";
import { EmailOTPSignupActionTypes } from "./ActionTypes";
import { EmailOtpSignUpBody, EmailOTPSignUpRes } from "./Model";
import {
  emailOTPGenAPIResClearAction,
  emailOTPGenErrorAction,
  emailOTPGenLoadingAction,
  emailOTPGenSuccessAction,
  emailOTPGenUpdateAPIMsgAction,
} from "./Action";
import { ApiHeader } from "../../components/Utility";
import { setupInterceptorsTo } from "../Authentication/Interceptors";

let apiRes = {} as EmailOTPSignUpRes;
export const emailOtpSignupApi = (payload: EmailOtpSignUpBody) => {
  return function (dispatch: Dispatch<EmailOTPSignupActionTypes>) {
    dispatch(emailOTPGenLoadingAction(true));
    setupInterceptorsTo(axios)
      .post(baseUrl.baseUrl + `/patientapp/email-otp-signup/`, payload, {
        headers: ApiHeader,
      })
      .then((res) => {
        apiRes = res.data;
        dispatch(emailOTPGenLoadingAction(false));
        dispatch(
          emailOTPGenSuccessAction(
            res.data,
            res.data.message !== undefined
              ? res.data.message
              : "OTP generated successfully"
          )
        );
      })
      .catch((error) => {
        dispatch(emailOTPGenLoadingAction(false));
        dispatch(
          emailOTPGenErrorAction(
            {} as EmailOTPSignUpRes,
            error.response !== undefined
              ? error.response.data.message || error.response.data.detail
              : "Something went wrong",
            error.status !== undefined ? error.response.data.status || 0 : 0
          )
        );
      });
  };
};

export const updateEmailOTPSignUpAPIResMsg = () => {
  return function (dispatch: Dispatch<EmailOTPSignupActionTypes>) {
    dispatch(
      emailOTPGenUpdateAPIMsgAction(apiRes as EmailOTPSignUpRes, "", "", 0)
    );
  };
};

export const clearEmailOTPSignUpAPIRes = () => {
  return function (dispatch: Dispatch<EmailOTPSignupActionTypes>) {
    dispatch(emailOTPGenAPIResClearAction({} as EmailOTPSignUpRes, "", "", 0));
  };
};