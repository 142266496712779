import React, { useEffect, useState } from "react";
import SharePatientList from "./SharedPatientList";
import PatientList from "./MyPatientList";
import { Box, Grid, Tab, Typography } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { t } from "i18next";
import MWLoader from "../../components/MWLoader";
import PageLayout from "../Layout/PageLayout";

export default function Dashboard() {
  const [selected, setSelected] = React.useState("1");
  const handleTabChange = (_event: React.SyntheticEvent, newValue: string) => {
    setSelected(newValue);
  };
  const loadingMarkup = <MWLoader />;
  const [loading, setLoading] = React.useState<boolean | undefined>(false);

  const [myPatientListLoader, setMyPatientListLoader] = useState<
    boolean | undefined
  >(false);
  const [sharedPatientListLoader, setSharedPatientListLoader] = useState<
    boolean | undefined
  >(false);
  useEffect(() => {
    setLoading(myPatientListLoader);
  }, [myPatientListLoader]);
  useEffect(() => {
    setLoading(sharedPatientListLoader);
  }, [sharedPatientListLoader]);

  const actualPageMarkup = (
    <Grid>
      <Grid item>
        <Box sx={{ width: "100%" }}>
          <TabContext value={String(selected)}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList onChange={handleTabChange}>
                <Tab label={t("menu.mypatient", { ns: ["home"] })} value="1" />
                <Tab
                  label={t("dashboard.sharepatlist", { ns: ["home"] })}
                  value="2"
                />
              </TabList>
            </Box>
            <TabPanel value="1">
              {
                <PatientList
                  myPatientListLoader={setMyPatientListLoader}
                />
              }
            </TabPanel>
            <TabPanel value="2">
              {
                <SharePatientList
                  sharedPatientListLoader={setSharedPatientListLoader}
                />
              }
            </TabPanel>
          </TabContext>
        </Box>
      </Grid>
    </Grid>
  );

  return (
    <>
      {loading ? loadingMarkup : null}
      <PageLayout>
        <Typography variant="h5" sx={{ fontWeight: "bold" }}>
          {t("dashboard.patientlist", { ns: ["home"] })}
        </Typography>
        {actualPageMarkup}
      </PageLayout>
    </>
  );
}
