import { Card, CardHeader, CardContent, Button, Box } from "@mui/material";
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridToolbar,
} from "@mui/x-data-grid";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { AppState } from "../../../../../redux/store/Store";
import MWExceptionList from "../../../../../components/MWExceptionList";
import { PatientQuestionnaireList } from "../../../../../redux/FilePreparation/Questionnaire/Model";
import { t } from "i18next";
import DocumentViewer from "../../../../../components/DocumentViewer";
import { ModifiedQuestionnaireList } from "./Model";
export interface Props {
  label?: string | any;
}
export default function Questionnaire({ label }: Props) {
  const questionnaireListRes = useSelector(
    (state: AppState) => state.patientQuestionnaireListRes
  );
  const [questionnaireList, setQuestionnaireList] = useState(
    [] as ModifiedQuestionnaireList[]
  );
  useEffect(() => {
    if (
      questionnaireListRes?.getQuestionnaireListRes
        ?.patientQuestionnaireList !== undefined
    ) {
      let questionItem =
        questionnaireListRes?.getQuestionnaireListRes?.patientQuestionnaireList.map(
          (item: PatientQuestionnaireList) => {
            return {
              id: item.id!,
              description:
                item?.questionnaireType?.displayValue !== undefined
                  ? item.questionnaireType?.displayValue
                  : "",
              supportingDocument:
                item?.templateUrl !== undefined ? item?.templateUrl : "",
            };
          }
        );
      setQuestionnaireList(questionItem);
    } else setQuestionnaireList([] as ModifiedQuestionnaireList[]);
  }, [questionnaireListRes]);

  const [dialogOpen, setDialogOpen] = useState(false);
  const dialogUpdateState = (passedVal: boolean) => {
    setDialogOpen(passedVal);
  };
  const [selectedItem, setSelectedItem] = useState({} as any);
  const selectedItemUpdateState = (passedVal: any) => {
    setSelectedItem(passedVal);
  };
  const columns: GridColDef[] = [
    {
      field: "description",
      headerName: `${t("common.quesName", { ns: ["home"] })!}`,
      flex: 1,
    },
    {
      field: "supportingDocument",
      headerName: `${t("appointment.view", { ns: ["home"] })!}`,
      minWidth: 120,
      renderCell: (params: GridCellParams) => (
        <Button
          variant="outlined"
          onClick={() => {
            setDialogOpen(true);
            setSelectedItem(params.row);
          }}
          sx={{ textTransform: "none" }}
        >
          {t("common.viewQues", { ns: ["home"] })}
        </Button>
      ),
    },
  ];
  return (
    <Box>
      <Card>
        <CardHeader title={t("preCompPage.ques", { ns: ["home"] })!} />
        <CardContent>
          {questionnaireList.length > 0 ? (
            <DataGrid
              rows={questionnaireList}
              columns={columns}
              pageSize={10}
              hideFooter
              hideFooterPagination
              disableSelectionOnClick
              experimentalFeatures={{ newEditingApi: true }}
              autoHeight
              components={{ Toolbar: GridToolbar }}
              componentsProps={{
                toolbar: {
                  csvOptions: { disableToolbarButton: true },
                  printOptions: { disableToolbarButton: true },
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 250 },
                },
              }}
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector
            />
          ) : (
            <MWExceptionList />
          )}
        </CardContent>
      </Card>
      {dialogOpen ? (
        <DocumentViewer
          dialogEnable={dialogOpen}
          dialogUpdateState={dialogUpdateState}
          selectedItem={selectedItem}
          selectedItemUpdateState={selectedItemUpdateState}
          questionnaire={true}
          questionnaireSecName={label}
        />
      ) : null}
    </Box>
  );
}
