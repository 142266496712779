import {
  GetAppointmentDetailsForCaseActionTypes,
  GET_APPOINTMENT_DETAILS_FOR_CASE_FAIL,
  GET_APPOINTMENT_DETAILS_FOR_CASE_LOADING,
  GET_APPOINTMENT_DETAILS_FOR_CASE_SUCCESS,
  GetAppointmentDetailsForCaseState,
  GET_APPOINTMENT_DETAILS_FOR_CASE_UPDATE_API_MSG,
  GET_APPOINTMENT_DETAILS_FOR_CASE_UPDATE_API_RES,
} from "./ActionTypes";
import { GetAppointmentDetailsForCaseRes } from "./Model";

const initialStateEditPosts: GetAppointmentDetailsForCaseState = {
  loading: false,
  getAppointmentDetailsForCaseRes: {} as GetAppointmentDetailsForCaseRes,
  successMsg: "",
  errorMsg: "",
  status: 0,
};
export const getAppointmentDetailsForCaseReducer = (
  state = initialStateEditPosts,
  action: GetAppointmentDetailsForCaseActionTypes
): GetAppointmentDetailsForCaseState => {
  switch (action.type) {
    case GET_APPOINTMENT_DETAILS_FOR_CASE_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case GET_APPOINTMENT_DETAILS_FOR_CASE_SUCCESS:
      return {
        ...state,
        getAppointmentDetailsForCaseRes: action.payload,
        successMsg: action.successMsg,
      };
    case GET_APPOINTMENT_DETAILS_FOR_CASE_FAIL:
      return {
        ...state,
        getAppointmentDetailsForCaseRes: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case GET_APPOINTMENT_DETAILS_FOR_CASE_UPDATE_API_MSG:
      return {
        ...state,
        getAppointmentDetailsForCaseRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case GET_APPOINTMENT_DETAILS_FOR_CASE_UPDATE_API_RES:
      return {
        ...state,
        getAppointmentDetailsForCaseRes: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
