import {
  MARITAL_STATUS_FAIL,
  MARITAL_STATUS_LOADING,
  MARITAL_STATUS_SUCCESS,
  MARITAL_STATUS_API_MSG,
  MARITAL_STATUS_API_RES,
  MaritalListActionTypes,
} from "./ActionTypes";
import { MaritalStatusResponse } from "./Model";

export const MaritalListLoadingAction = (loading: boolean): MaritalListActionTypes => {
  return {
    type: MARITAL_STATUS_LOADING,
    loading: loading,
  };
};

export const MaritalListSuccessAction = (
  maritalStatusResponse: MaritalStatusResponse,
  successMsg: string
): MaritalListActionTypes => {
  return {
    type: MARITAL_STATUS_SUCCESS,
    payload: maritalStatusResponse,
    successMsg: successMsg,
  };
};

export const MaritalListErrorAction = (
  maritalStatusResponse: MaritalStatusResponse,
  errMsg: string,
  status: number
): MaritalListActionTypes => {
  return {
    type: MARITAL_STATUS_FAIL,
    payload: maritalStatusResponse,
    errorMsg: errMsg,
    status: status,
  };
};

export const MaritalListAPIMsgAction = (
  maritalStatusResponse: MaritalStatusResponse,
  successMsg: string,
  errMsg: string,
  status: number
): MaritalListActionTypes => {
  return {
    type: MARITAL_STATUS_API_MSG,
    payload: maritalStatusResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const MaritalListAPIResClearAction = (
  maritalStatusResponse: MaritalStatusResponse,
  successMsg: string,
  errMsg: string,
  status: number
): MaritalListActionTypes => {
  return {
    type: MARITAL_STATUS_API_RES,
    payload: maritalStatusResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
