import {
  SearchDoctorActionTypes,
  SEARCH_DOCTOR_FAIL,
  SEARCH_DOCTOR_LOADING,
  SEARCH_DOCTOR_SUCCESS,
  SearchDoctorState,
  SEARCH_DOCTOR_API_MSG,
  SEARCH_DOCTOR_API_RES,
} from "./ActionTypes";
import { SearchDoctorResponse } from "./Model";

const initialStateEditPosts: SearchDoctorState = {
  loading: false,
  searchDoctorResponse: {} as SearchDoctorResponse,
  successMsg: "",
  errorMsg: "",
  status: 0,
};
export const searchDoctorReducer = (
  state = initialStateEditPosts,
  action: SearchDoctorActionTypes
): SearchDoctorState => {
  switch (action.type) {
    case SEARCH_DOCTOR_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case SEARCH_DOCTOR_SUCCESS:
      return {
        ...state,
        searchDoctorResponse: action.payload,
        successMsg: action.successMsg,
      };
    case SEARCH_DOCTOR_FAIL:
      return {
        ...state,
        searchDoctorResponse: action.payload,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case SEARCH_DOCTOR_API_MSG:
      return {
        ...state,
        searchDoctorResponse: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    case SEARCH_DOCTOR_API_RES:
      return {
        ...state,
        searchDoctorResponse: action.payload,
        successMsg: action.successMsg,
        errorMsg: action.errorMsg,
        status: action.status,
      };
    default:
      return state;
  }
};
