import {
  ADD_PATIENT_INSURANCE_FAIL,
  ADD_PATIENT_INSURANCE_LOADING,
  ADD_PATIENT_INSURANCE_SUCCESS,
  ADD_PATIENT_INSURANCE_API_MSG,
  ADD_PATIENT_INSURANCE_API_RES,
  AddPatientInsuranceActionTypes,
} from "./ActionTypes";
import { AddPatientInsuranceRes } from "./Model";

export const addPatientInsuranceLoadingAction = (
  loading: boolean
): AddPatientInsuranceActionTypes => {
  return {
    type: ADD_PATIENT_INSURANCE_LOADING,
    loading: loading,
  };
};

export const addPatientInsuranceSuccessAction = (
  addPatientInsuranceResponse: AddPatientInsuranceRes,
  successMsg: string
): AddPatientInsuranceActionTypes => {
  return {
    type: ADD_PATIENT_INSURANCE_SUCCESS,
    payload: addPatientInsuranceResponse,
    successMsg: successMsg,
  };
};

export const addPatientInsuranceErrorAction = (
  addPatientInsuranceResponse: AddPatientInsuranceRes,
  errMsg: string,
  status: number
): AddPatientInsuranceActionTypes => {
  return {
    type: ADD_PATIENT_INSURANCE_FAIL,
    payload: addPatientInsuranceResponse,
    errorMsg: errMsg,
    status: status,
  };
};
export const addPatientInsuranceAPIMsgAction = (
  addPatientInsuranceResponse: AddPatientInsuranceRes,
  successMsg: string,
  errMsg: string,
  status: number
): AddPatientInsuranceActionTypes => {
  return {
    type: ADD_PATIENT_INSURANCE_API_MSG,
    payload: addPatientInsuranceResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};

export const addPatientInsuranceAPIResClearAction = (
  addPatientInsuranceResponse: AddPatientInsuranceRes,
  successMsg: string,
  errMsg: string,
  status: number
): AddPatientInsuranceActionTypes => {
  return {
    type: ADD_PATIENT_INSURANCE_API_RES,
    payload: addPatientInsuranceResponse,
    successMsg: successMsg,
    errorMsg: errMsg,
    status: status,
  };
};
