import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../redux/store/Store";
import {
  AppointmentRequestList,
  PaymentListBody,
} from "../../../../redux/appointmentPayment/AppointmentPaymentList/Model";
import dayjs from "dayjs";
import { t } from "i18next";
import { ModifiedAppointmentList } from "./Model";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import MWExceptionList from "../../../../components/MWExceptionList";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useParams } from "react-router-dom";
import { getExistingAppointmentAndPaymentDetails } from "../../../../redux/appointmentPayment/AppointmentPaymentList/API";

export default function ExistingAppointment() {
  const { caseid } = useParams() as {
    caseid: string;
  };
  const dispatch = useDispatch();
  const getAppointmentPaymentListRes = useSelector(
    (state: AppState) => state.getAppointmentPaymentListRes
  );
  const [paymentList, setPaymentList] = useState(
    [] as ModifiedAppointmentList[]
  );
  useEffect(() => {
    if (
      getAppointmentPaymentListRes?.paymentListResponse
        ?.appointmentRequestList !== undefined
    ) {
      if (
        getAppointmentPaymentListRes?.paymentListResponse
          ?.appointmentRequestList.length > 0
      ) {
        let paymentListItem =
          getAppointmentPaymentListRes?.paymentListResponse?.appointmentRequestList.map(
            (element: AppointmentRequestList) =>
              ({
                id: element?.id,
                name:
                  element?.name !== undefined
                    ? element?.name
                    : `${t("common.notSet", { ns: ["home"] })!}`,
                createDate:
                  element?.createDate !== undefined
                    ? element?.createDate !== null
                      ? dayjs(element?.createDate).format("DD-MM-YYYY")
                      : `${t("common.notSet", { ns: ["home"] })!}`
                    : `${t("common.notSet", { ns: ["home"] })!}`,
                isActive:
                  element?.isActive !== undefined
                    ? element?.isActive
                      ? `${t("common.true", { ns: ["home"] })!}`
                      : `${t("common.true", { ns: ["home"] })!}`
                    : `${t("common.notSet", { ns: ["home"] })!}`,
                modifiedDate:
                  element?.modifiedDate !== undefined
                    ? element?.modifiedDate !== null
                      ? dayjs(element?.modifiedDate).format("DD-MM-YYYY")
                      : `${t("common.notSet", { ns: ["home"] })!}`
                    : `${t("common.notSet", { ns: ["home"] })!}`,
                status:
                  element?.status !== undefined
                    ? element?.status
                    : `${t("common.notSet", { ns: ["home"] })!}`,
              } as ModifiedAppointmentList)
          );
        setPaymentList(paymentListItem);
      } else setPaymentList([] as ModifiedAppointmentList[]);
    } else setPaymentList([] as ModifiedAppointmentList[]);
  }, [getAppointmentPaymentListRes]);
  const finalOpinionColumn: GridColDef[] = [
    {
      field: "name",
      headerName: `${t("appointment.desc", { ns: ["home"] })}`,
      minWidth: 120,
      flex: 1,
    },
    {
      field: "createDate",
      headerName: `${t("payment.cdate", { ns: ["home"] })}`,
      minWidth: 80,
    },
    {
      field: "isActive",
      headerName: `${t("selOpPage.active", { ns: ["home"] })}`,
      minWidth: 80,
    },
    {
      field: "modifiedDate",
      headerName: `${t("appointment.modifydate", { ns: ["home"] })}`,
      minWidth: 80,
    },
    {
      field: "status",
      headerName: `${t("appointment.status", { ns: ["home"] })}`,
      minWidth: 80,
    },
  ];
  return (
    <Box>
      <Card>
        <CardHeader
          title={
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
            >
              <Grid item>{t("appointment.exisappo", { ns: ["home"] })}</Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  startIcon={<RefreshIcon />}
                  onClick={() =>
                    dispatch(
                      getExistingAppointmentAndPaymentDetails({
                        caseId: caseid,
                      } as PaymentListBody)
                    )
                  }
                >
                  {t("appointment.appolist", { ns: ["home"] })!}
                </Button>
              </Grid>
            </Grid>
          }
        />
        <CardContent>
          {paymentList.length > 0 ? (
            <DataGrid
              rows={paymentList}
              columns={finalOpinionColumn}
              pageSize={10}
              hideFooter
              hideFooterPagination
              disableSelectionOnClick
              experimentalFeatures={{ newEditingApi: true }}
              autoHeight
              components={{ Toolbar: GridToolbar }}
              componentsProps={{
                toolbar: {
                  csvOptions: { disableToolbarButton: true },
                  printOptions: { disableToolbarButton: true },
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 250 },
                },
              }}
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector
            />
          ) : (
            <MWExceptionList />
          )}
        </CardContent>
      </Card>
    </Box>
  );
}
